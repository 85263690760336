import React,{ useState } from 'react'
import './styles.scss'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Link } from 'react-router-dom'
import ChapterModal from '../../../components/modal/chapterDetail'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}));

function Information(props) {
    const {
        activeStep,
        getStatus
    } = props
    const [modalChapterToggle, setModalChapterToggle] = useState(false);
    const profile = useSelector(state => state.pesertaState.profile);
    const information = useSelector(state => state.filterState.information);
    const chapterList = useSelector(state=> state.chapterState.chapter )
    const classes = useStyles();

    const getChapterCode = (chapterList, chapter) => {
        if (chapterList && chapter) {
            let temp = chapterList?.filter(a=> a._id === chapter )?.[0]
            return temp?.code || '-'
        }
    }

    const getChapter = (chapterList, chapter) => {
        if (chapterList && chapter) {
            let temp = chapterList?.filter(a=> a._id === chapter )?.[0]
            return temp
        }
    }

    const getChapterName = (chapterList, chapter) => {
        if (chapterList && chapter) {
            let temp = chapterList?.filter(a=> a._id === chapter )?.[0]
            return temp?.chapter || '-'
        }
    }

    const capitalizeFirstLetter = (string) => {
        if (string) {
            return ' '+string.replace(/(^\w|\s\w)(\S*)/g, (_,m1,m2) => m1.toUpperCase()+m2.toLowerCase())
        } else {
            return '-'
        }
    }

    return (
        <div className="input-data-container" >
            <span className="input-data-header">
                Informasi
            </span>
            <div className="bread-cab-container" >
                <span className="bread-grey">
                    Informasi /
                </span>
            </div>
            <div className="input-container-2021" >
                <div className="data-pribadi-header">
                    Informasi
                </div>
                <div className="document-wrapper-2021">
                    <div className="information-chapters-pribadi-09013" >
                        <div >
                            Pilihan Chapter : { capitalizeFirstLetter(getChapterName(chapterList, profile?.chapter)) }
                        </div>
                        <div>
                            Kontak Chapter ? <span onClick={e=>setModalChapterToggle(true)} className="txt-blur-uinfop2131" > Klik Disini </span>
                        </div>
                    </div>
                    <div className={classes.root}>
                        <Stepper
                            activeStep={activeStep}
                            orientation="vertical"
                        >
                            {
                                profile &&
                                information?.map((data, index) => (
                                    <Step
                                        key={index}
                                        style={{
                                            textAlign: 'left',
                                        }}
                                    >
                                        <StepLabel>{data.title}</StepLabel>
                                        <div
                                            className="stepper-content"
                                            style={{
                                                borderLeft: index !== information.length -1 && '1px solid #9fa29f'
                                            }}
                                        >
                                            {
                                                data?.description?.map((desc, i)=>(
                                                    <div
                                                        key={i}
                                                        style={{
                                                            fontWeight: 'normal',
                                                            textAlign: 'left',
                                                            display: 'flex',
                                                            padding: '5px 0px',
                                                            flexDirection: 'column',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                fontWeight: 'normal',
                                                                textAlign: 'left',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                padding: '5px 0px',
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    getStatus(
                                                                        index,
                                                                        desc,
                                                                    ) === 'gagal' ?
                                                                    faTimesCircle :
                                                                    getStatus(
                                                                        index,
                                                                        desc,
                                                                    ) === true ?
                                                                    faCheckCircle
                                                                    :
                                                                    faCircle
                                                                }
                                                                style={{
                                                                    color: 
                                                                    getStatus(
                                                                        index,
                                                                        desc,
                                                                    ) === 'gagal' ?
                                                                    '#ff4c4c' :
                                                                    getStatus(
                                                                        index,
                                                                        desc,
                                                                    ) === true ?
                                                                    '#3c98dc'
                                                                    :
                                                                    '#9fa29f',
                                                                    fontSize: 20,
                                                                    marginRight: 10
                                                                }}
                                                            />
                                                            <div>
                                                                {`${desc}${(((index === 3) || (index === 7)) && i === 0) ? capitalizeFirstLetter(getChapterName(chapterList, profile?.chapter)):''}`}
                                                            </div>
                                                        </div>
                                                        {
                                                            (
                                                                ((profile?.nomorUrut !== '0000') && (profile?.statusVerifikasiBerkas === true)) &&
                                                                (index === 3 && i === 1)
                                                            ) &&
                                                            <div
                                                                style={{
                                                                    fontWeight: 'normal',
                                                                    textAlign: 'left',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    padding: '0px 0px',
                                                                }}
                                                            >
                                                            <FontAwesomeIcon
                                                                icon={ faCircle }
                                                                style={{
                                                                    fontSize: 20,
                                                                    marginRight: 10,
                                                                    opacity: 0
                                                                }}
                                                            />
                                                            <div
                                                                style={{
                                                                    fontWeight: "bolder"
                                                                }}
                                                            >
                                                                YBA/YP25-26/
                                                                {
                                                                    getChapterCode(chapterList, profile?.chapter)
                                                                }
                                                                /
                                                                {
                                                                    profile?.program === 'KAKEHASHI' ?
                                                                    'MXT' : profile?.program
                                                                }
                                                                /
                                                                {
                                                                    (profile?.nomorUrut !== '0000' && profile?.statusVerifikasiBerkas === true) ? 
                                                                    profile?.nomorUrut : '-'
                                                                }
                                                            </div>
                                                        </div>
                                                        }
                                                        {
                                                            ((index === 7 && i === 0) && activeStep >=8) &&
                                                            <div
                                                            style={{
                                                                fontWeight: 'normal',
                                                                textAlign: 'left',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                padding: '0px 0px',
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={ faCircle }
                                                                style={{
                                                                    fontSize: 20,
                                                                    marginRight: 10,
                                                                    opacity: 0
                                                                }}
                                                            />
                                                            <Link
                                                                to={{ pathname: '/hasil-seleksi'}}
                                                                target="_BLANK"
                                                                style={{
                                                                    textDecoration: 'none',
                                                                    cursor: 'pointer',
                                                                    color: 'black',
                                                                    fontWeight: "bolder",
                                                                    cursor: 'pointer'
                                                                }}
                                                            >
                                                                Klik di Sini Untuk Informasi Kelulusan
                                                            </Link> 
                                                        </div>
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </Step>
                                ))
                            }
                        </Stepper>
                    </div>
                </div>
            </div>
            <ChapterModal
                show={modalChapterToggle}
                modalClosed={e=> setModalChapterToggle(false) }
                children={"modal"}
                chapter={getChapter(chapterList, profile?.chapter)}
            />
        </div>
    )
}

export default Information