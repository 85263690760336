export const SET_DATAPRIBADI = 'SET_DATAPRIBADI'
export const SET_INFOKELUARGA = 'SET_INFOKELUARGA'
export const SET_PASFOTO = 'SET_PASFOTO'
export const GET_CHAPTER = 'GET_CHAPTER'
export const GET_PROFILE = 'GET_PROFILE'
export const GET_DATAINPUT = 'GET_DATAINPUT'
export const IS_LOGOUT = 'IS_LOGOUT'
export const SET_SAVE = 'SET_SAVE'
export const SET_PAGE = 'SET_PAGE'
export const SET_RIWAYATPENDIDIKAN = 'SET_RIWAYATPENDIDIKAN'
export const SET_RIWAYATKEGIATAN = 'SET_RIWAYATKEGIATAN'
export const SET_KEPRIBADIAN = 'SET_KEPRIBADIAN'
export const SET_PILIHANPROGRAM = 'SET_PILIHANPROGRAM'
export const SET_PILIHANNEGARA = 'SET_PILIHANNEGARA'
export const SET_RIWAYATPERJALANAN = 'SET_RIWAYATPERJALANAN'
export const SET_MOTIVASI = 'SET_MOTIVASI'
export const SET_ESSAY = 'SET_ESSAY'
export const SET_REKOMENDASI = 'SET_REKOMENDASI'
export const SET_FINALISASI = 'SET_FINALISASI'
export const BERKAS_PERNYATAANSEKOLAH = 'BERKAS_PERNYATAANSEKOLAH'
export const BERKAS_IZINORANGTUA = 'BERKAS_IZINORANGTUA'
export const BERKAS_REKOMENDASIKELUARGA = 'BERKAS_REKOMENDASIKELUARGA'
export const BERKAS_REKOMENDASISEKOLAH = 'BERKAS_REKOMENDASISEKOLAH'
export const BERKAS_REKOMENDASITEMANDEKAT = 'BERKAS_REKOMENDASITEMANDEKAT'
export const BERKAS_RAPORT = 'BERKAS_RAPORT'
export const BERKAS_AKTAKELAHIRAN = 'BERKAS_AKTAKELAHIRAN'
export const BERKAS_SKHUNIJAZAH = 'BERKAS_SKHUNIJAZAH'
export const BERKAS_SERTIFIKATDLL = 'BERKAS_SERTIFIKATDLL'
export const SET_OPTIONS = 'SET_OPTIONS'
export const SET_FAILSELECTION = 'SET_FAILSELECTION'
export const SET_HASIL_SELEKSI = 'SET_HASIL_SELEKSI'
export const SET_INFORMATION_STATUS = 'SET_INFORMATION_STATUS'