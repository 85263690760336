import axios from "axios";
import ReactGa from "react-ga";
import server from "../../endpoint";
import { generateToken } from "../../helpers/bearer";
import {
  GET_PROFILE,
  IS_LOGOUT,
  GET_CHAPTER,
  SET_DATAPRIBADI,
  SET_PASFOTO,
  SET_SAVE,
  SET_INFOKELUARGA,
  SET_PAGE,
  SET_RIWAYATPENDIDIKAN,
  SET_RIWAYATKEGIATAN,
  SET_KEPRIBADIAN,
  SET_PILIHANPROGRAM,
  SET_PILIHANNEGARA,
  SET_RIWAYATPERJALANAN,
  SET_MOTIVASI,
  SET_ESSAY,
  SET_REKOMENDASI,
  SET_FINALISASI,
  SET_OPTIONS,
  SET_FAILSELECTION,
  SET_HASIL_SELEKSI,
  SET_INFORMATION_STATUS,
} from "../constanta";

export const logoutPeserta = (History, Swal, saveOut) => async (dispatch) => {
  const ToastMid = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  try {
    Swal.showLoading();
    localStorage.clear();
    sessionStorage.clear();
    dispatch({
      type: IS_LOGOUT,
    });
    History.replace("/");
    ToastMid.fire({
      icon: "success",
      title: `Berhasil ${saveOut ? "Menyimpan Data & " : ""}Keluar`,
    });
  } catch (err) {
    console.error(err);
  }
};

export const generateKartuPeserta = () => async () => {
  try {
    let token = localStorage.getItem("token");
    const { data } = await axios({
      method: "get",
      url: `${server}/peserta/generate-kartu-peserta`,
      headers: {
        token,
        "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
      },
    });
    console.log(data);
  } catch (error) {
    console.error(error);
  }
};

export const getProfile = (History, Swal) => async (dispatch) => {
  const ToastMid = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  try {
    let token = localStorage.getItem("token");
    let options = JSON.parse(localStorage.getItem("options"));
    if (
      token &&
      (options.remember ||
        (History.location.pathname !== "/" &&
          !History.location.pathname.includes("/masuk") &&
          !History.location.pathname.includes("/daftar")))
    ) {
      const { data } = await axios({
        method: "get",
        url: `${server}/peserta/profile`,
        headers: {
          token,
          "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
        },
      });
      if (
        History.location.pathname !== "/download-berkas/pendaftaran-lengkap" &&
        History.location.pathname !== "/download-berkas/essay" &&
        History.location.pathname !== "/hasil-seleksi" &&
        History.location.pathname !== "/download-berkas/kartu-peserta"
      ) {
        if (
          History.location.pathname === "/" ||
          History.location.pathname.includes("masuk?program=") ||
          History.location.pathname.includes("daftar?program=") ||
          History.location.pathname.includes("/verification") ||
          History.location.pathname.includes("/aktivasi-pin") ||
          History.location.pathname.includes("/lupa-password") ||
          History.location.pathname.includes("/ganti-password")
        ) {
          if (options.remember) {
            if (data) {
              if (data.page >= 12) {
                History.replace("/informasi");
              } else {
                History.replace("/input");
              }
            }
          }
        } else {
          if (data) {
            if (data.page >= 12) {
              History.replace("/informasi");
            } else {
              History.replace("/input");
            }
          }
        }
      }
      dispatch({
        type: GET_PROFILE,
        profile: data,
      });
    } else {
      if (
        History.location.pathname === "/input" ||
        History.location.pathname.includes("/upload-berkas") ||
        History.location.pathname.includes("/download-berkas") ||
        History.location.pathname.includes("/informasi")
      ) {
        History.replace("/");
        ToastMid.fire({
          icon: "error",
          title: `Akses Diblokir`,
        });
      }
      dispatch({
        type: IS_LOGOUT,
      });
    }
  } catch (err) {
    Swal.close();
    if (
      History.location.pathname !== "/" &&
      !History.location.pathname.includes("/masuk") &&
      !History.location.pathname.includes("/daftar")
    ) {
      if (err) {
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              if (err.response.data.message.includes("buffering timed out")) {
                ToastMid.fire({
                  icon: "error",
                  title: "Periksa Jaringan",
                });
              } else {
                ToastMid.fire({
                  icon: "error",
                  title: err.response.data.message,
                });
              }
            }
          }
        }
      }
    }
  }
};

export const getChapter = () => async (dispatch) => {
  try {
    const { data } = await axios({
      method: "get",
      url: `${server}/chapter`,
      headers: {
        "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
      },
    });
    dispatch({
      type: GET_CHAPTER,
      data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const createPesertaYK = (History, data, Swal) => async (dispatch) => {
  const ToastMid = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  try {
    Swal.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    await axios({
      method: "post",
      url: `${server}/peserta/no-token`,
      data: data,
      headers: {
        "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
      },
    });
    Swal.close();
    Swal.fire({
      title: "Mendaftar Berhasil",
      text: "Silahkan cek email untuk verifikasi akun, *Periksa SPAM Email",
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#3598dc",
      confirmButtonText: "Masuk",
    }).then((result) => {
      // localStorage.clear()
      if (result) {
        History.replace("/masuk?program=" + data.program.toLowerCase());
      } else {
        History.replace("/masuk?program=" + data.program.toLowerCase());
      }
    });
  } catch (err) {
    Swal.close();
    if (err) {
      if (err.response) {
        if (err.response.data) {
          if (err.response.data.message) {
            if (err.response.data.message === "Email Sudah Digunakan!") {
              ToastMid.fire({
                icon: "warning",
                title: `Email Telah Digunakan`,
              });
            } else if (
              err.response.data.message ===
              "Mohon Maaf Pendaftaran Telah Ditutup"
            ) {
              ToastMid.fire({
                icon: "warning",
                title: `Mohon Maaf Pendaftaran Telah Ditutup`,
              });
            } else if (
              err.response.data.message ===
              "Pengiriman Email Bermasalah, Tunggu Beberapa Saat atau Gunakan Email lain"
            ) {
              ToastMid.fire({
                icon: "warning",
                title: `Pengiriman Email Bermasalah, Tunggu Beberapa Saat atau Gunakan Email lain`,
              });
            }
          }
          if (err.response.data.errors) {
            [...err.response.data.errors].forEach((a) => {
              if (a === "Isi alamat email dengan benar") {
                ToastMid.fire({
                  icon: "warning",
                  title: `Isi alamat email dengan benar`,
                });
              } else if (a === "Password kurang dari 8 karakter") {
                ToastMid.fire({
                  icon: "warning",
                  title: `Password kurang dari 8 karakter`,
                });
              }
            });
          }
        }
      }
    }
  }
};

export const createPesertaAFS = (History, data, Swal) => async (dispatch) => {
  const ToastMid = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  try {
    Swal.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    await axios({
      method: "post",
      url: `${server}/peserta/token`,
      data: data,
      headers: {
        "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
      },
    });
    Swal.close();
    Swal.fire({
      title: "Mendaftar Berhasil",
      text: "Silahkan cek email untuk verifikasi akun, *Periksa SPAM Email",
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#3598dc",
      confirmButtonText: "Masuk",
    }).then((result) => {
      // localStorage.clear()
      if (result) {
        History.replace("/masuk?program=" + data.program.toLowerCase());
      } else {
        History.replace("/masuk?program=" + data.program.toLowerCase());
      }
    });
  } catch (err) {
    Swal.close();
    if (err) {
      if (err.response) {
        if (err.response.data) {
          if (err.response.data.message) {
            if (err.response.data.message === "Email Sudah Digunakan!") {
              ToastMid.fire({
                icon: "warning",
                title: `Email Telah Digunakan`,
              });
            } else if (err.response.data.message === "PIN salah") {
              ToastMid.fire({
                icon: "warning",
                title: `PIN salah`,
              });
              History.push("/aktivasi-pin");
              sessionStorage.clear();
            } else if (
              err.response.data.message ===
              "Mohon Maaf Pendaftaran Telah Ditutup"
            ) {
              ToastMid.fire({
                icon: "warning",
                title: `Mohon Maaf Pendaftaran Telah Ditutup`,
              });
            } else if (
              err.response.data.message ===
              "Pengiriman Email Bermasalah, Tunggu Beberapa Saat atau Gunakan Email lain"
            ) {
              ToastMid.fire({
                icon: "warning",
                title: `Pengiriman Email Bermasalah, Tunggu Beberapa Saat atau Gunakan Email lain`,
              });
            }
          }
          if (err.response.data.errors) {
            [...err.response.data.errors].forEach((a) => {
              if (a === "Isi alamat email dengan benar") {
                ToastMid.fire({
                  icon: "warning",
                  title: `Isi alamat email dengan benar`,
                });
              } else if (a === "Password kurang dari 8 karakter") {
                ToastMid.fire({
                  icon: "warning",
                  title: `Password kurang dari 8 karakter`,
                });
              }
            });
          }
        }
      }
    }
  }
};

export const loginPeserta =
  (History, sendData, Swal, checked) => async (dispatch) => {
    const ToastMid = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    let options = JSON.parse(localStorage.getItem("options"));
    try {
      Swal.fire({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        onOpen: () => {
          Swal.showLoading();
        },
      });
      const { data } = await axios({
        method: "post",
        url: `${server}/peserta/login`,
        data: sendData,
        headers: {
          "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
        },
      });
      Swal.close();
      localStorage.setItem("token", data.token);
      localStorage.setItem(
        "options",
        JSON.stringify({ ...options, remember: checked, sidebar: true })
      );
      if (data?.profile?.page >= 12) {
        History.replace("/informasi");
      } else {
        History.replace("/input");
      }
      dispatch({
        type: GET_PROFILE,
        profile: data.profile,
      });
      ToastMid.fire({
        icon: "success",
        title: `Selamat Berjuang ${data.profile.username}`,
      });
    } catch (err) {
      Swal.close();
      if (err) {
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              if (err.response.data.message.includes("buffering timed out")) {
                ToastMid.fire({
                  icon: "error",
                  title: "Periksa Jaringan",
                });
              } else {
                ToastMid.fire({
                  icon: "error",
                  title: err.response.data.message,
                });
              }
            }
          }
        }
      }
    }
  };

export const verifikasiEmail =
  (History, { id, setSuccess }, Swal) =>
  async (dispatch) => {
    try {
      await axios({
        method: "patch",
        url: `${server}/peserta/verification/${id}`,
        headers: {
          "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
        },
      });
      setSuccess(true);
    } catch (err) {
      Swal.close();
      if (err) {
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              setSuccess({ message: err.response.data.message });
            }
          }
        }
      }
    }
  };

export const saveDataPribadi =
  (data, Swal, option, next, logout, page, goTop) => async (dispatch) => {
    const ToastMid = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    try {
      let token = localStorage.getItem("token");
      if (token) {
        Swal.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        await axios({
          method: "patch",
          url: `${server}/peserta/input/data-pribadi`,
          data: data,
          headers: {
            token,
            "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
          },
        });
        Swal.close();
        dispatch({
          type: SET_DATAPRIBADI,
          data: {
            data: data.data,
            page: data.page,
          },
        });
        if (option === "next") {
          ToastMid.fire({
            icon: "success",
            title: `Data Pribadi Berhasil Disimpan`,
          });
          next(page);
          goTop();
        } else if (option === "out") {
          ToastMid.fire({
            icon: "success",
            title: `Data Pribadi Berhasil Disimpan`,
          });
          logout(null, true);
        }
      }
    } catch (err) {
      Swal.close();
      if (err?.response?.data?.message) {
        if (
          err.response.data.message ===
          "Mohon Maaf Batas Pengisian Data / Finalisasi Telah Ditutup"
        ) {
          ToastMid.fire({
            icon: "warning",
            title: `Batas Pengisian Data / Finalisasi Telah Ditutup`,
          });
        }
      }
    }
  };

export const savePasFoto =
  (data, Swal, option, next, logout, page, photoSelect, goTop, pages) =>
  async (dispatch) => {
    const ToastMid = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    try {
      let token = localStorage.getItem("token");
      if (token) {
        Swal.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        await axios({
          method: "patch",
          url: `${server}/peserta/input/pas-foto`,
          data: data,
          headers: {
            token,
            "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
          },
        });
        Swal.close();
        dispatch({
          type: SET_PASFOTO,
          data: {
            data: photoSelect,
            page: pages,
          },
        });
        if (option === "next") {
          ToastMid.fire({
            icon: "success",
            title: `Pas Foto Berhasil Disimpan`,
          });
          next(page);
          goTop();
        } else if (option === "out") {
          ToastMid.fire({
            icon: "success",
            title: `Pas Foto Berhasil Disimpan`,
          });
          logout(null, true);
        }
      }
    } catch (err) {
      Swal.close();
      if (err?.response?.data?.message) {
        if (
          err?.response?.data?.message ===
          "Mohon Maaf Batas Pengisian Data / Finalisasi Telah Ditutup"
        ) {
          ToastMid.fire({
            icon: "warning",
            title: `Batas Pengisian Data / Finalisasi Telah Ditutup`,
          });
        } else if (err?.response?.data?.message === "File too large") {
          ToastMid.fire({
            icon: "error",
            title: `Ukuran foto terlalu besar`,
          });
        }
      }
    }
  };

export const saveInfoKeluarga =
  (data, Swal, option, next, logout, page, goTop) => async (dispatch) => {
    const ToastMid = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    try {
      let token = localStorage.getItem("token");
      if (token) {
        Swal.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        await axios({
          method: "patch",
          url: `${server}/peserta/input/info-keluarga`,
          data: data,
          headers: {
            token,
            "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
          },
        });
        Swal.close();
        dispatch({
          type: SET_INFOKELUARGA,
          data: {
            data: data.data,
            page: data.page,
          },
        });
        if (option === "next") {
          ToastMid.fire({
            icon: "success",
            title: `Info Keluarga Berhasil Disimpan`,
          });
          next(page);
          goTop();
        } else if (option === "out") {
          ToastMid.fire({
            icon: "success",
            title: `Info Keluarga Berhasil Disimpan`,
          });
          logout(null, true);
        }
      }
    } catch (err) {
      Swal.close();
      if (err) {
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              if (
                err.response.data.message ===
                "Mohon Maaf Batas Pengisian Data / Finalisasi Telah Ditutup"
              ) {
                ToastMid.fire({
                  icon: "warning",
                  title: `Batas Pengisian Data / Finalisasi Telah Ditutup`,
                });
              }
            }
          }
        }
      }
    }
  };

export const saveRiwayatPendidikan =
  (data, Swal, option, next, logout, page, goTop) => async (dispatch) => {
    const ToastMid = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    try {
      let token = localStorage.getItem("token");
      if (token) {
        Swal.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        await axios({
          method: "patch",
          url: `${server}/peserta/input/riwayat-pendidikan`,
          data: data,
          headers: {
            token,
            "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
          },
        });
        Swal.close();
        dispatch({
          type: SET_RIWAYATPENDIDIKAN,
          data: {
            data: data.data,
            page: data.page,
          },
        });
        if (option === "next") {
          ToastMid.fire({
            icon: "success",
            title: `Riwayat Pendidikan Berhasil Disimpan`,
          });
          next(page);
          goTop();
        } else if (option === "out") {
          ToastMid.fire({
            icon: "success",
            title: `Riwayat Pendidikan Berhasil Disimpan`,
          });
          logout(null, true);
        }
      }
    } catch (err) {
      Swal.close();
      if (err) {
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              if (
                err.response.data.message ===
                "Mohon Maaf Batas Pengisian Data / Finalisasi Telah Ditutup"
              ) {
                ToastMid.fire({
                  icon: "warning",
                  title: `Batas Pengisian Data / Finalisasi Telah Ditutup`,
                });
              }
            }
          }
        }
      }
    }
  };

export const saveRiwayatKegiatan =
  (data, Swal, option, next, logout, page, goTop) => async (dispatch) => {
    const ToastMid = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    try {
      let token = localStorage.getItem("token");
      if (token) {
        Swal.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        await axios({
          method: "patch",
          url: `${server}/peserta/input/riwayat-kegiatan`,
          data: data,
          headers: {
            token,
            "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
          },
        });
        Swal.close();
        dispatch({
          type: SET_RIWAYATKEGIATAN,
          data: {
            data: data.data,
            page: data.page,
          },
        });
        if (option === "next") {
          ToastMid.fire({
            icon: "success",
            title: `Riwayat Kegiatan Berhasil Disimpan`,
          });
          next(page);
          goTop();
        } else if (option === "out") {
          ToastMid.fire({
            icon: "success",
            title: `Riwayat Kegiatan Berhasil Disimpan`,
          });
          logout(null, true);
        }
      }
    } catch (err) {
      Swal.close();
      if (err) {
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              if (
                err.response.data.message ===
                "Mohon Maaf Batas Pengisian Data / Finalisasi Telah Ditutup"
              ) {
                ToastMid.fire({
                  icon: "warning",
                  title: `Batas Pengisian Data / Finalisasi Telah Ditutup`,
                });
              }
            }
          }
        }
      }
    }
  };

export const saveKepribadian =
  (data, Swal, option, next, logout, page, goTop) => async (dispatch) => {
    const ToastMid = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    try {
      let token = localStorage.getItem("token");
      if (token) {
        Swal.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        const res = await axios({
          method: "patch",
          url: `${server}/peserta/input/kepribadian`,
          data: data.formData,
          headers: {
            token,
            "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
          },
        });
        Swal.close();
        data.data.file = res?.data?.file || data.data.file
        dispatch({
          type: SET_KEPRIBADIAN,
          data: {
            data: data.data,
            page: data.page,
          },
        });
        if (option === "next") {
          ToastMid.fire({
            icon: "success",
            title: `Kepribadian Berhasil Disimpan`,
          });
          next(page);
          goTop();
        } else if (option === "out") {
          ToastMid.fire({
            icon: "success",
            title: `Kepribadian Berhasil Disimpan`,
          });
          logout(null, true);
        }
      }
    } catch (err) {
      Swal.close();
      if (err) {
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              if (
                err.response.data.message ===
                "Mohon Maaf Batas Pengisian Data / Finalisasi Telah Ditutup"
              ) {
                ToastMid.fire({
                  icon: "warning",
                  title: `Batas Pengisian Data / Finalisasi Telah Ditutup`,
                });
              }
            }
          }
        }
      }
    }
  };

export const savePilihanProgram =
  (data, Swal, option, next, logout, page, goTop) => async (dispatch) => {
    const ToastMid = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    try {
      let token = localStorage.getItem("token");
      if (token) {
        Swal.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        await axios({
          method: "patch",
          url: `${server}/peserta/input/pilihan-program`,
          data: data,
          headers: {
            token,
            "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
          },
        });
        Swal.close();
        dispatch({
          type: SET_PILIHANPROGRAM,
          data: {
            data: data.data,
            page: data.page,
          },
        });
        if (option === "next") {
          ToastMid.fire({
            icon: "success",
            title: `Pilihan Program Berhasil Disimpan`,
          });
          next(page);
          goTop();
        } else if (option === "out") {
          ToastMid.fire({
            icon: "success",
            title: `Pilihan Program Berhasil Disimpan`,
          });
          logout(null, true);
        }
      }
    } catch (err) {
      Swal.close();
      if (err) {
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              if (
                err.response.data.message ===
                "Mohon Maaf Batas Pengisian Data / Finalisasi Telah Ditutup"
              ) {
                ToastMid.fire({
                  icon: "warning",
                  title: `Batas Pengisian Data / Finalisasi Telah Ditutup`,
                });
              }
            }
          }
        }
      }
    }
  };

export const savePilihanNegara =
  (data, Swal, option, next, logout, page, goTop) => async (dispatch) => {
    const ToastMid = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    try {
      let token = localStorage.getItem("token");
      if (token) {
        Swal.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        await axios({
          method: "patch",
          url: `${server}/peserta/input/pilihan-negara`,
          data: data,
          headers: {
            token,
            "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
          },
        });
        Swal.close();
        dispatch({
          type: SET_PILIHANNEGARA,
          data: {
            data: data.data,
            page: data.page,
          },
        });
        if (option === "next") {
          ToastMid.fire({
            icon: "success",
            title: `Pilihan Negara Berhasil Disimpan`,
          });
          next(page);
          goTop();
        } else if (option === "out") {
          ToastMid.fire({
            icon: "success",
            title: `Pilihan Negara Berhasil Disimpan`,
          });
          logout(null, true);
        }
      }
    } catch (err) {
      Swal.close();
      if (err) {
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              if (
                err.response.data.message ===
                "Mohon Maaf Batas Pengisian Data / Finalisasi Telah Ditutup"
              ) {
                ToastMid.fire({
                  icon: "warning",
                  title: `Batas Pengisian Data / Finalisasi Telah Ditutup`,
                });
              }
            }
          }
        }
      }
    }
  };

export const saveRiwayatPerjalanan =
  (data, Swal, option, next, logout, page, goTop) => async (dispatch) => {
    const ToastMid = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    try {
      let token = localStorage.getItem("token");
      if (token) {
        Swal.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        await axios({
          method: "patch",
          url: `${server}/peserta/input/riwayat-perjalanan`,
          data: data,
          headers: {
            token,
            "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
          },
        });
        Swal.close();
        dispatch({
          type: SET_RIWAYATPERJALANAN,
          data: {
            data: data.data,
            page: data.page,
          },
        });
        if (option === "next") {
          ToastMid.fire({
            icon: "success",
            title: `Riwayat Perjalanan Berhasil Disimpan`,
          });
          next(page);
          goTop();
        } else if (option === "out") {
          ToastMid.fire({
            icon: "success",
            title: `Riwayat Perjalanan Berhasil Disimpan`,
          });
          logout(null, true);
        }
      }
    } catch (err) {
      Swal.close();
      if (err) {
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              if (
                err.response.data.message ===
                "Mohon Maaf Batas Pengisian Data / Finalisasi Telah Ditutup"
              ) {
                ToastMid.fire({
                  icon: "warning",
                  title: `Batas Pengisian Data / Finalisasi Telah Ditutup`,
                });
              }
            }
          }
        }
      }
    }
  };

export const saveMotivasi =
  (data, Swal, option, next, logout, page, goTop) => async (dispatch) => {
    const ToastMid = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    try {
      let token = localStorage.getItem("token");
      if (token) {
        Swal.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        const res = await axios({
          method: "patch",
          url: `${server}/peserta/input/motivasi`,
          data: data.formData,
          headers: {
            token,
            "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
          },
        })
        Swal.close();
        data.data.file = res?.data?.file || data.data.file
        dispatch({
          type: SET_MOTIVASI,
          data: {
            data: data.data,
            page: data.page,
          },
        });
        if (option === "next") {
          ToastMid.fire({
            icon: "success",
            title: `Motivasi Berhasil Disimpan`,
          });
          next(page);
          goTop();
        } else if (option === "out") {
          ToastMid.fire({
            icon: "success",
            title: `Motivasi Berhasil Disimpan`,
          });
          logout(null, true);
        }
      }
    } catch (err) {
      Swal.close();
      if (err) {
        console.error(err);
      }
    }
  };

export const saveEssay =
  (data, Swal, option, next, logout, page, goTop) => async (dispatch) => {
    const ToastMid = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    try {
      let token = localStorage.getItem("token");
      if (token) {
        Swal.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        const res = await axios({
          method: "patch",
          url: `${server}/peserta/input/essay`,
          data: data.formData,
          headers: {
            token,
            "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
          },
        });
        Swal.close();
        data.data.file = res?.data?.file || data.data.file
        dispatch({
          type: SET_ESSAY,
          data: {
            data: data.data,
            page: data.page,
          },
        });
        if (option === "next") {
          ToastMid.fire({
            icon: "success",
            title: `Essay Berhasil Disimpan`,
          });
          next(page);
          goTop();
        } else if (option === "out") {
          ToastMid.fire({
            icon: "success",
            title: `Essay Berhasil Disimpan`,
          });
          logout(null, true);
        }
      }
    } catch (err) {
      Swal.close();
      if (err) {
        console.error(err);
      }
    }
  };

export const saveRekomendasi =
  (data, Swal, option, next, logout, page, goTop, History, profile) =>
  async (dispatch) => {
    const ToastMid = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    try {
      let token = localStorage.getItem("token");
      if (token) {
        Swal.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        await axios({
          method: "patch",
          url: `${server}/peserta/input/rekomendasi`,
          data: data,
          headers: {
            token,
            "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
          },
        });
        Swal.close();
        dispatch({
          type: SET_REKOMENDASI,
          data: {
            data: data.data,
            page: data.page,
          },
        });
        if (option === "next") {
          if (History) {
            if (profile?.page === 12) {
              ToastMid.fire({
                icon: "success",
                title: `Rekomendasi Berhasil Disimpan, Silahkan Lengkapi Berkas`,
              });
              next(11);
              goTop();
            } else {
              ToastMid.fire({
                icon: "success",
                title: `Rekomendasi Berhasil Disimpan, Silahkan Lengkapi Berkas`,
              });
              History.replace("/download-berkas");
              next(11);
              goTop();
            }
          } else {
            ToastMid.fire({
              icon: "success",
              title: `Rekomendasi Berhasil Disimpan`,
            });
            next(11);
            goTop();
          }
        } else if (option === "out") {
          ToastMid.fire({
            icon: "success",
            title: `Rekomendasi Berhasil Disimpan`,
          });
          logout(null, true);
        }
      }
    } catch (err) {
      Swal.close();
      if (err) {
        console.error(err);
      }
    }
  };

export const saveFinalisasi =
  (data, Swal, History, goTop, root) => async (dispatch) => {
    const ToastMid = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    try {
      let token = localStorage.getItem("token");
      if (token) {
        Swal.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        await axios({
          method: "patch",
          url: `${server}/peserta/input/finalisasi`,
          data: {
            data,
            tanggal: new Date(),
          },
          headers: {
            token,
            "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
          },
        });
        ReactGa.event({
          category: "Finalisasi",
          action: data ? `Finalisasi` : `Batal Finalisasi`,
          label: "Finalisasi",
        });
        Swal.close();
        dispatch({
          type: SET_FINALISASI,
          data: {
            data: data,
          },
        });
        History.replace(root);
        ToastMid.fire({
          icon: "success",
          title: `${data === false ? "Batal" : ""} Finalisasi Berhasil`,
        });
        goTop();
      }
    } catch (err) {
      Swal.close();
      if (err) {
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              if (
                err.response.data.message ===
                "Mohon Maaf Batas Pengisian Data / Finalisasi Telah Ditutup"
              ) {
                ToastMid.fire({
                  icon: "warning",
                  title: `Batas Pengisian Data / Finalisasi Telah Ditutup`,
                });
              }
            }
          }
        }
      }
    }
  };

export const berkasAction =
  (file, Swal, key, method, type) => async (dispatch) => {
    const ToastMid = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    try {
      let token = localStorage.getItem("token");
      if (token) {
        Swal.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        const { data } = await axios({
          method,
          url: `${server}/peserta/berkas/${key}`,
          data: file,
          headers: {
            token,
            "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
          },
        });
        Swal.close();
        dispatch({
          type: type,
          data: {
            data: data,
          },
        });
        ToastMid.fire({
          icon: "success",
          title: `${
            method === "PATCH" ? "Menyimpan" : "Hapus"
          } Berkas Berhasil`,
        });
      }
    } catch (err) {
      Swal.close();
      if (err) {
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              if (
                err.response.data.message ===
                  "Mohon Maaf Upload File Telah Ditutup" ||
                err.response.data.message ===
                  "Mohon Maaf Delete File Telah Ditutup"
              ) {
                ToastMid.fire({
                  icon: "error",
                  title: `Mohon Maaf Upload Berkas Telah Ditutup`,
                });
              } else if (err.response.data.message === "File too large") {
                ToastMid.fire({
                  icon: "error",
                  title: `Ukuran file terlalu besar maksimal ${
                    [
                      "pernyataanSekolah",
                      "izinOrangTua",
                      "rekomendasiKeluarga",
                      "rekomendasiSekolah",
                      "rekomendasiTemanDekat",
                      "aktaKelahiran",
                      "skhunIjazah",
                    ].includes(key)
                      ? "2MB"
                      : "10MB"
                  }`,
                });
              }
            }
          }
        }
      }
    }
  };

export const setSaveAction = (action) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SAVE,
      action,
    });
  } catch (err) {
    if (err) {
      console.error(err);
    }
  }
};

export const setPageAction = (action) => async (dispatch) => {
  try {
    dispatch({
      type: SET_PAGE,
      action,
    });
  } catch (err) {
    if (err) {
      console.error(err);
    }
  }
};

export const changePassword =
  (sendData, Swal, setError, resetPassword) => async (dispatch) => {
    const ToastMid = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    try {
      let token = localStorage.getItem("token");
      if (token) {
        Swal.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        await axios({
          method: "patch",
          url: `${server}/peserta/change-password`,
          headers: {
            token,
            "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
          },
          data: sendData,
        });
        Swal.close();
        ToastMid.fire({
          icon: "success",
          title: `Password Berhasil Diganti`,
        });
        resetPassword();
      }
    } catch (err) {
      if (err) {
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              setError(err.response.data.message);
            }
            if (err.response.data.errors) {
              [...err.response.data.errors].forEach((a) => {
                if (a === "Password kurang dari 8 karakter") {
                  setError(`Password kurang dari 8 karakter`);
                }
              });
            }
          }
        }
      }
    }
  };

export const forgotPassword = (email, Swal, History) => async (dispatch) => {
  const ToastMid = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const SwalBootstrap1 = Swal.mixin({
    customClass: {
      confirmButton: "btn-finalisasi-yakin",
      cancelButton: "btn-finalisasi-tidak",
    },
    buttonsStyling: false,
  });
  try {
    Swal.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    await axios({
      method: "POST",
      url: `${server}/peserta/forgot-password`,
      data: { email },
      headers: {
        "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
      },
    });
    Swal.close();
    SwalBootstrap1.fire({
      title: "Email Terkirim",
      text: "Silahkan cek email untuk mengganti password, *Periksa SPAM Email",
      icon: "success",
      showCancelButton: false,
      confirmButtonText: "OK",
      cancelButtonText: "",
      reverseButtons: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.value) {
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  } catch (err) {
    if (err) {
      if (err.response) {
        if (err.response.data) {
          if (err.response.data.message) {
            ToastMid.fire({
              icon: "error",
              title: err.response.data.message,
            });
          }
        }
      }
    }
  }
};

export const newPassword = (sendData, Swal, History) => async (dispatch) => {
  const ToastMid = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const SwalBootstrap1 = Swal.mixin({
    customClass: {
      confirmButton: "btn-finalisasi-yakin",
      cancelButton: "btn-finalisasi-tidak",
    },
    buttonsStyling: false,
  });
  try {
    Swal.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    const { data } = await axios({
      method: "POST",
      url: `${server}/peserta/new-password`,
      data: sendData,
      headers: {
        "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
      },
    });
    Swal.close();
    SwalBootstrap1.fire({
      title: "Ganti Password Berhasil",
      text: "Silahkan Masuk",
      icon: "success",
      showCancelButton: false,
      confirmButtonText: "Masuk",
      cancelButtonText: "",
      reverseButtons: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.value) {
        History.replace(`/masuk?program=${data.toLowerCase()}`);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  } catch (err) {
    if (err) {
      if (err.response) {
        if (err.response.data) {
          if (err.response.data.message) {
            ToastMid.fire({
              icon: "error",
              title: err.response.data.message,
            });
          }
        }
      }
    }
  }
};

export const getOptions = () => async (dispatch) => {
  try {
    const { data } = await axios({
      method: "get",
      url: `${server}/option`,
      headers: {
        "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
      },
    });
    dispatch({
      type: SET_OPTIONS,
      data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const setFailSelection = (action) => async (dispatch) => {
  try {
    dispatch({
      type: SET_FAILSELECTION,
      action,
    });
  } catch (err) {
    if (err) {
      console.error(err);
    }
  }
};

export const getHasilSeleksiChapter = (Swal, History) => async (dispatch) => {
  const SwalBootstrap1 = Swal.mixin({
    customClass: {
      confirmButton: "btn-finalisasi-yakin",
      cancelButton: "btn-finalisasi-tidak",
    },
    buttonsStyling: false,
  });
  try {
    dispatch({
      type: SET_HASIL_SELEKSI,
      data: null,
    });
    const { data } = await axios({
      method: "get",
      url: `${server}/peserta/hasil-seleksi`,
      headers: {
        "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
      },
    });
    dispatch({
      type: SET_HASIL_SELEKSI,
      data,
    });
  } catch (error) {
    console.error(error);
    SwalBootstrap1.fire({
      title: "Hasil Seleksi Ditutup",
      text: "",
      icon: "warning",
      showCancelButton: false,
      confirmButtonText: "OK",
      cancelButtonText: "",
      reverseButtons: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.value) {
        History.replace("/");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }
};

export const setInformationStatus = (information) => async (dispatch) => {
  try {
    dispatch({
      type: SET_INFORMATION_STATUS,
      information,
    });
  } catch (err) {
    if (err) {
      console.error(err);
    }
  }
};

export const getSekolah = (name, cb) => async (dispatch) => {
  try {
    const { data } = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_SEKOLAH}/sekolah/s?sekolah=${name}`,
    });
    cb(data?.dataSekolah || []);
  } catch (error) {
    console.error(error);
  }
};
