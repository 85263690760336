import React,{ useState, useEffect } from 'react'
import './styles.scss'
import BinabudayaLogo from '../../images/homepage/binabud.svg'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2'
import { forgotPassword } from '../../store/actions/peserta'

const VerificationButton = withStyles((theme) => ({
    root: {
        height: '44px',
        color: '#ffffff',
        backgroundColor: '#3598DC',
        fontFamily: ['Roboto', 'sans-serif'],
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '19px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#2f88c6',
            boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)',
        },
    },
}))(Button);

const BackButton = withStyles((theme) => ({
    root: {
        height: '44px',
        border: '1px solid #3598DC',
        backgroundColor: '#ffffff',
        color: '#3598DC',
        fontFamily: ['Roboto', 'sans-serif'],
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '19px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
            // backgroundColor: '',
            boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)',
        },
    },
}))(Button);

function ForgotPassword() {
    const History = useHistory()
    const Dispatch = useDispatch()
    const [email, setEmail] = useState('')
    const [click, setClick] = useState(false)
    const [timing, setTiming] = useState(null)

    const add_minutes = (dt, minutes) => {
        return new Date(dt.getTime() + minutes*60000);
    }
    
    const sendMail = () => {
        const ToastMid = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        setClick(true)
        if (email) {
            let countDown = add_minutes(new Date(), 1).getTime()
            localStorage.setItem('ccfgpw-6799011', countDown)
            setClick(false)
            Dispatch(forgotPassword(email, Swal, History))
        } else {
            ToastMid.fire({
                icon: 'error',
                title: 'Isi Email'
            })
        }
    }

    useEffect(()=>{
        let countDownDate = localStorage.getItem('ccfgpw-6799011')
        console.log(countDownDate);
        if (countDownDate) {
            var x = setInterval(function() {
                var now = new Date().getTime();
                var distance = countDownDate - now;
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                document.getElementById("limitter").innerHTML = seconds + "s";
                if (!timing) setTiming(true)
                if (distance < 0) {
                    setTiming(false)
                    clearInterval(x);
                    document.getElementById("limitter").innerHTML = "Kirim Verifikasi";
                    localStorage.removeItem('ccfgpw-6799011')
                }
            }, 1000);
        } else {
            if (timing === null || timing === true) setTiming(false)
        }
    },[localStorage.getItem('ccfgpw-6799011')])

    return(
        <div className="activation-container-wrapper">
            <div className="activation-binabud-logo" >
                <img src={BinabudayaLogo} alt="binabudaya" className="logo-binabud-activation" />
            </div>
            <div className="activation-contain-text" >
                <span className="activation-font-top" >
                    Reset Password
                </span>
                <span className="forgot-password-font-text">
                    Silahkan masukan email yang terdaftar saat membuat akun untuk me-reset password
                </span>
                <div className="activation-container-card" >
                    <span className="card-font-text" >Email</span>
                    <input
                        onChange={e=>setEmail(e.target.value)}
                        type="text"
                        placeholder="contoh: email@gmail.com"
                        className="activation-input-theme"
                        style={{ border: (click && !email) && '1px solid red' }}
                    />
                    <div className="button-forgot-pass-contain" >
                        <BackButton className="btn-left-81623" onClick={()=>History.goBack()}>Kembali</BackButton>
                        <VerificationButton className="btn-right-81623" onClick={e=> timing === false && sendMail()} id="limitter">{timing === false && 'Kirim Verifikasi'}</VerificationButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword