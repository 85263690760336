import React, {
  useState,
  useEffect,
  Fragment,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import "./styles.scss";
import { DatePicker } from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import {
  saveRiwayatPendidikan,
  setSaveAction,
  getSekolah,
} from "../../../../store/actions/peserta";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import debounce from "lodash/debounce";

const SaveAndOutButton = withStyles((theme) => ({
  root: {
    border: "1px solid #3598DC",
    // marginTop: '50px',
    width: "200px",
    height: "44px",
    color: "#3598DC",
    backgroundColor: "#FFFFFF",
    fontFamily: ["Roboto", "sans-serif"],
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "19px",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      // backgroundColor: '#e5e5e5',
      // boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)',
    },
  },
}))(Button);

const SaveAndNextButton = withStyles((theme) => ({
  root: {
    // marginTop: '50px',
    width: "200px",
    height: "44px",
    color: "#ffffff",
    backgroundColor: "#3598DC",
    fontFamily: ["Roboto", "sans-serif"],
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "19px",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#2f88c6",
      boxShadow:
        "0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)",
    },
    marginLeft: 20,
  },
}))(Button);

const AddButton = withStyles((theme) => ({
  root: {
    border: "1px solid #3598DC",
    height: "44px",
    color: "#3598DC",
    backgroundColor: "#FFFFFF",
    fontFamily: ["Roboto", "sans-serif"],
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "19px",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {},
  },
}))(Button);

const TextFieldCustom = withStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      // borderBottomColor: 'yellow',
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D1D1D1",
      },
      "&:hover fieldset": {
        borderColor: "#D1D1D1",
      },
      "&.Mui-focused fieldset": {
        border: "0.5px solid #D1D1D1",
      },
    },
  },
}))(TextField);

const RiwayatPendidikan = forwardRef((props, ref) => {
  const { NextPage, handleLogout, goTop } = props;
  const Dispatch = useDispatch();
  const options = useSelector((state) => state.pesertaState.options);
  const score = useSelector((state) => state.filterState.score);
  const propinsiDB = useSelector((state) => state.daerahState.propinsi);
  const kabupatenDB = useSelector((state) => state.daerahState.kabupaten);
  const kecamatanDB = useSelector((state) => state.daerahState.kecamatan);
  const profile = useSelector((state) => state.pesertaState.profile);
  const save = useSelector((state) => state.pesertaState.checkerSave);
  const [click, setClick] = useState(false);
  const [sendData, setSendData] = useState({
    jenisSekolah: "",
    namaSMA: "",
    alamatSekolah: "",
    propinsiSekolah: "",
    kabupatenSekolah: "",
    kecamatanSekolah: "",
    kelurahanSekolah: "",
    kodeposSekolah: "",
    teleponSekolah: "",
    faksSekolah: "",
    tahunMasukSekolah: null,
    tahunKeluarSekolah: null,
    prestasiSekolah: [
      {
        kelas: "",
        semester: "",
        agama: "",
        pancasila: "",
        indonesia: "",
        matematika: "",
        sejarah: "",
        inggris: "",
      },
    ],
    namaSMP: "",
    tahunIjazahSMP: null,
    prestasiSMP: [
      {
        kelas: "",
        semester: "",
        agama: "",
        pancasila: "",
        indonesia: "",
        matematika: "",
        ipa: "",
        ips: "",
        inggris: "",
      },
    ],
    namaSD: "",
    tahunIjazahSD: null,
    pengetahuanBahasa: [
      {
        bahasa: "Bahasa Inggris",
        lama: "",
      },
    ],
    pelajaranFavorit: "",
    citaCita: "",
  });
  const [daftarSekolah, setDaftarSekolah] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const getOptionsDelayed = useCallback(
    debounce((text, callback) => {
      if (
        sendData?.propinsiSekolah &&
        sendData?.kabupatenSekolah &&
        sendData?.jenisSekolah
      ) {
        const filtSchool = kabupatenDB[sendData?.propinsiSekolah]?.filter(
          (x) => x?.id === sendData?.kabupatenSekolah
        )[0];
        const result =
          filtSchool?.[sendData?.jenisSekolah]?.filter((y) =>
            text ? y?.toLowerCase()?.includes(text?.toLowerCase()) : y
          ) || [];
        callback(result);
      }
      // Dispatch(getSekolah(text, (data=>callback(data))))
    }, 200),
    [
      sendData?.propinsiSekolah,
      sendData?.kabupatenSekolah,
      sendData?.jenisSekolah,
    ]
  );

  useEffect(() => {
    if (profile?.riwayatPendidikan) {
      setSendData(profile?.riwayatPendidikan);
    }
  }, [profile && profile?.riwayatPendidikan]);

  const convertToRoman = (num) => {
    let roman = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1,
    };
    var str = "";
    for (var i of Object.keys(roman)) {
      var q = Math.floor(num / roman[i]);
      num -= q * roman[i];
      str += i.repeat(q);
    }
    return str;
  };

  const handleChange = (key, value, i) => {
    if (!save) Dispatch(setSaveAction(true));
    switch (key) {
      case "jenisSekolah":
        setSendData({
          ...sendData,
          jenisSekolah: value,
        });
        break;
      case "namaSMA":
        setSendData({
          ...sendData,
          namaSMA: value,
        });
        break;
      case "alamatSekolah":
        setSendData({
          ...sendData,
          alamatSekolah: value,
        });
        break;
      case "propinsiSekolah":
        setSendData({
          ...sendData,
          propinsiSekolah: value,
          kabupatenSekolah: "",
          kecamatanSekolah: "",
          kelurahanSekolah: "",
          kodeposSekolah: "",
        });
        break;
      case "kabupatenSekolah":
        setSendData({
          ...sendData,
          kabupatenSekolah: value,
          kecamatanSekolah: "",
          kelurahanSekolah: "",
          kodeposSekolah: "",
        });
        break;
      case "kecamatanSekolah":
        setSendData({
          ...sendData,
          kecamatanSekolah: value,
          kelurahanSekolah: "",
          kodeposSekolah: "",
        });
        break;
      case "kelurahanSekolah":
        setSendData({
          ...sendData,
          kelurahanSekolah: value,
          kodeposSekolah: "",
        });
        break;
      case "kodeposSekolah":
        setSendData({
          ...sendData,
          kodeposSekolah: value,
        });
        break;
      case "teleponSekolah":
        setSendData({
          ...sendData,
          teleponSekolah: value,
        });
        break;
      case "faksSekolah":
        setSendData({
          ...sendData,
          faksSekolah: value,
        });
        break;
      case "tahunMasukSekolah":
        setSendData({
          ...sendData,
          tahunMasukSekolah: value,
        });
        break;
      case "tahunKeluarSekolah":
        setSendData({
          ...sendData,
          tahunKeluarSekolah: value,
        });
        break;
      case "kelasSMA":
        let temp1 = [...sendData.prestasiSekolah];
        temp1[i].kelas = value;
        setSendData({
          ...sendData,
          prestasiSekolah: temp1,
        });
        break;
      case "semesterSMA":
        let temp2 = [...sendData.prestasiSekolah];
        temp2[i].semester = value;
        setSendData({
          ...sendData,
          prestasiSekolah: temp2,
        });
        break;
      case "agamaSMA":
        let temp3 = [...sendData.prestasiSekolah];
        if (value >= 0 && value <= 100) {
          temp3[i].agama = value;
          setSendData({
            ...sendData,
            prestasiSekolah: temp3,
          });
        }
        break;
      case "pancasilaSMA":
        let temp4 = [...sendData.prestasiSekolah];
        if (value >= 0 && value <= 100) {
          temp4[i].pancasila = value;
          setSendData({
            ...sendData,
            prestasiSekolah: temp4,
          });
        }
        break;
      case "indonesiaSMA":
        let temp5 = [...sendData.prestasiSekolah];
        if (value >= 0 && value <= 100) {
          temp5[i].indonesia = value;
          setSendData({
            ...sendData,
            prestasiSekolah: temp5,
          });
        }
        break;
      case "matematikaSMA":
        let temp6 = [...sendData.prestasiSekolah];
        if (value >= 0 && value <= 100) {
          temp6[i].matematika = value;
          setSendData({
            ...sendData,
            prestasiSekolah: temp6,
          });
        }
        break;
      case "sejarahSMA":
        let temp7 = [...sendData.prestasiSekolah];
        if (value >= 0 && value <= 100) {
          temp7[i].sejarah = value;
          setSendData({
            ...sendData,
            prestasiSekolah: temp7,
          });
        }
        break;
      case "inggrisSMA":
        let temp8 = [...sendData.prestasiSekolah];
        if (value >= 0 && value <= 100) {
          temp8[i].inggris = value;
          setSendData({
            ...sendData,
            prestasiSekolah: temp8,
          });
        }
        break;
      case "namaSMP":
        setSendData({
          ...sendData,
          namaSMP: value,
        });
        break;
      case "tahunIjazahSMP":
        setSendData({
          ...sendData,
          tahunIjazahSMP: value,
        });
        break;
      case "kelasSMP":
        let temp9 = [...sendData.prestasiSMP];
        temp9[i].kelas = value;
        setSendData({
          ...sendData,
          prestasiSMP: temp9,
        });
        break;
      case "semesterSMP":
        let temp10 = [...sendData.prestasiSMP];
        temp10[i].semester = value;
        setSendData({
          ...sendData,
          prestasiSMP: temp10,
        });
        break;
      case "agamaSMP":
        let temp11 = [...sendData.prestasiSMP];
        if (value >= 0 && value <= 100) {
          temp11[i].agama = value;
          setSendData({
            ...sendData,
            prestasiSMP: temp11,
          });
        }
        break;
      case "pancasilaSMP":
        let temp12 = [...sendData.prestasiSMP];
        if (value >= 0 && value <= 100) {
          temp12[i].pancasila = value;
          setSendData({
            ...sendData,
            prestasiSMP: temp12,
          });
        }
        break;
      case "indonesiaSMP":
        let temp13 = [...sendData.prestasiSMP];
        if (value >= 0 && value <= 100) {
          temp13[i].indonesia = value;
          setSendData({
            ...sendData,
            prestasiSMP: temp13,
          });
        }
        break;
      case "matematikaSMP":
        let temp14 = [...sendData.prestasiSMP];
        if (value >= 0 && value <= 100) {
          temp14[i].matematika = value;
          setSendData({
            ...sendData,
            prestasiSMP: temp14,
          });
        }
        break;
      case "ipaSMP":
        let temp15 = [...sendData.prestasiSMP];
        if (value >= 0 && value <= 100) {
          temp15[i].ipa = value;
          setSendData({
            ...sendData,
            prestasiSMP: temp15,
          });
        }
        break;
      case "ipsSMP":
        let temp16 = [...sendData.prestasiSMP];
        if (value >= 0 && value <= 100) {
          temp16[i].ips = value;
          setSendData({
            ...sendData,
            prestasiSMP: temp16,
          });
        }
        break;
      case "inggrisSMP":
        let temp17 = [...sendData.prestasiSMP];
        if (value >= 0 && value <= 100) {
          temp17[i].inggris = value;
          setSendData({
            ...sendData,
            prestasiSMP: temp17,
          });
        }
        break;
      case "namaSD":
        setSendData({
          ...sendData,
          namaSD: value,
        });
        break;
      case "tahunIjazahSD":
        setSendData({
          ...sendData,
          tahunIjazahSD: value,
        });
        break;
      case "pengetahuanBahasa":
        let temp18 = [...sendData.pengetahuanBahasa];
        temp18[i].bahasa = value;
        setSendData({
          ...sendData,
          pengetahuanBahasa: temp18,
        });
        break;
      case "pengetahuanLama":
        let temp19 = [...sendData.pengetahuanBahasa];
        temp19[i].lama = value;
        setSendData({
          ...sendData,
          pengetahuanBahasa: temp19,
        });
        break;
      case "pelajaranFavorit":
        setSendData({
          ...sendData,
          pelajaranFavorit: value,
        });
        break;
      case "citaCita":
        setSendData({
          ...sendData,
          citaCita: value,
        });
        break;
      default:
        break;
    }
  };

  const handleAddSMA = () => {
    if (!save) Dispatch(setSaveAction(true));
    let temp = [...sendData.prestasiSekolah];
    temp.push({
      kelas: "",
      semester: "",
      agama: "",
      pancasila: "",
      indonesia: "",
      matematika: "",
      sejarah: "",
      inggris: "",
    });
    setSendData({
      ...sendData,
      prestasiSekolah: temp,
    });
  };

  const handleRemoveSMA = (i) => {
    if (!save) Dispatch(setSaveAction(true));
    let temp = [...sendData.prestasiSekolah];
    temp.splice(i, 1);
    setSendData({
      ...sendData,
      prestasiSekolah: temp,
    });
  };

  const handleAddSMP = () => {
    if (!save) Dispatch(setSaveAction(true));
    let temp = [...sendData.prestasiSMP];
    temp.push({
      kelas: "",
      semester: "",
      agama: "",
      pancasila: "",
      indonesia: "",
      matematika: "",
      ipa: "",
      ips: "",
      inggris: "",
    });
    setSendData({
      ...sendData,
      prestasiSMP: temp,
    });
  };

  const handleRemoveSMP = (i) => {
    if (!save) Dispatch(setSaveAction(true));
    let temp = [...sendData.prestasiSMP];
    temp.splice(i, 1);
    setSendData({
      ...sendData,
      prestasiSMP: temp,
    });
  };

  const handleAddPengetahuanBahasa = () => {
    if (!save) Dispatch(setSaveAction(true));
    let temp = [...sendData.pengetahuanBahasa];
    temp.push({
      bahasa: "",
      lama: "",
    });
    setSendData({
      ...sendData,
      pengetahuanBahasa: temp,
    });
  };

  const handleRemovePengetahuanBahasa = (i) => {
    if (!save) Dispatch(setSaveAction(true));
    let temp = [...sendData.pengetahuanBahasa];
    temp.splice(i, 1);
    setSendData({
      ...sendData,
      pengetahuanBahasa: temp,
    });
  };

  const handleFilter = (key, data) => {
    let cloud = [];
    switch (key) {
      case "kecamatan":
        if (data) {
          data.map((props) => {
            if (!cloud.includes(props.kecamatan)) {
              cloud.push(props.kecamatan);
            }
          });
        }
        break;
      case "kelurahan":
        if (data) {
          data.map((props) => {
            if (props.kecamatan === sendData.kecamatanSekolah) {
              if (!cloud.includes(props.kelurahan)) {
                cloud.push(props.kelurahan);
              }
            }
          });
        }
        break;
      case "kodepos":
        if (data) {
          data.map((props) => {
            if (props.kecamatan === sendData.kecamatanSekolah) {
              if (props.kelurahan === sendData.kelurahanSekolah) {
                if (!cloud.includes(props.kodepos)) {
                  cloud.push(props.kodepos);
                }
              }
            }
          });
        }
        break;
      default:
        break;
    }
    return cloud;
  };

  const goToTab = (page) => {
    NextPage(page);
    goTop();
  };

  const saving = (option) => {
    const ToastMid = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    const SwalBootstrap2 = Swal.mixin({
      customClass: {
        confirmButton: "btn-finalisasi-yakin-ok",
        cancelButton: "btn-finalisasi-tidak",
      },
      buttonsStyling: false,
    });
    if (options?.[profile?.program]?.finalisasi) {
      setClick(true);
      let flag = false;
      for (let key in sendData) {
        if (
          key === "jenisSekolah" ||
          key === "namaSMA" ||
          key === "alamatSekolah" ||
          key === "propinsiSekolah" ||
          key === "kabupatenSekolah" ||
          key === "kecamatanSekolah" ||
          key === "teleponSekolah" ||
          key === "tahunMasukSekolah" ||
          key === "tahunKeluarSekolah" ||
          key === "namaSMP" ||
          key === "tahunIjazahSMP" ||
          key === "namaSD" ||
          key === "tahunIjazahSD" ||
          key === "pelajaranFavorit" ||
          key === "citaCita"
        ) {
          if (!sendData[key]) {
            flag = true;
            ToastMid.fire({
              icon: "warning",
              title: `Silahkan Lengkapi Data`,
            });
            goTop();
            return;
          }
          if (flag) return;
        }

        if (key === "prestasiSMP" || key === "pengetahuanBahasa") {
          if (sendData[key]) {
            sendData[key].map((a) => {
              for (const keyA in a) {
                if (!a[keyA]) {
                  flag = true;
                  ToastMid.fire({
                    icon: "warning",
                    title: `Silahkan Lengkapi Data`,
                  });
                  goTop();
                  return;
                }
                if (flag) return;
              }
              if (flag) return;
            });
          }
        }
        if (flag) return;
      }
      let page = profile.page;
      if (profile.page < 4) {
        page = 4;
      }
      if (!flag) {
        Dispatch(
          saveRiwayatPendidikan(
            { data: sendData, page },
            Swal,
            option,
            NextPage,
            handleLogout,
            4,
            goTop
          )
        );
      }
    } else {
      SwalBootstrap2.fire({
        title: "Pengisian Data Telah Ditutup",
        text: "",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        cancelButtonText: "",
        reverseButtons: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.value) {
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      });
    }
  };

  useImperativeHandle(ref, () => ({
    saving(option, num) {
      const ToastMid = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      const SwalBootstrap2 = Swal.mixin({
        customClass: {
          confirmButton: "btn-finalisasi-yakin-ok",
          cancelButton: "btn-finalisasi-tidak",
        },
        buttonsStyling: false,
      });
      if (options?.[profile?.program]?.finalisasi) {
        setClick(true);
        let flag = false;
        for (let key in sendData) {
          if (
            key === "jenisSekolah" ||
            key === "namaSMA" ||
            key === "alamatSekolah" ||
            key === "propinsiSekolah" ||
            key === "kabupatenSekolah" ||
            key === "kecamatanSekolah" ||
            key === "teleponSekolah" ||
            key === "tahunMasukSekolah" ||
            key === "tahunKeluarSekolah" ||
            key === "namaSMP" ||
            key === "tahunIjazahSMP" ||
            key === "namaSD" ||
            key === "tahunIjazahSD" ||
            key === "pelajaranFavorit" ||
            key === "citaCita"
          ) {
            if (!sendData[key]) {
              flag = true;
              ToastMid.fire({
                icon: "warning",
                title: `Silahkan Lengkapi Data`,
              });
              goTop();
              return;
            }
            if (flag) return;
          }

          if (key === "prestasiSMP" || key === "pengetahuanBahasa") {
            if (sendData[key]) {
              sendData[key].map((a) => {
                for (const keyA in a) {
                  if (!a[keyA]) {
                    flag = true;
                    ToastMid.fire({
                      icon: "warning",
                      title: `Silahkan Lengkapi Data`,
                    });
                    goTop();
                    return;
                  }
                  if (flag) return;
                }
                if (flag) return;
              });
            }
          }
          if (flag) return;
        }
        let page = profile.page;
        if (profile.page < 4) {
          page = 4;
        }
        if (!flag) {
          Dispatch(
            saveRiwayatPendidikan(
              { data: sendData, page },
              Swal,
              option,
              NextPage,
              handleLogout,
              num,
              goTop
            )
          );
        }
      } else {
        SwalBootstrap2.fire({
          title: "Pengisian Data Telah Ditutup",
          text: "",
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "OK",
          cancelButtonText: "",
          reverseButtons: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.value) {
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
          }
        });
      }
    },
  }));

  useEffect(() => {
    getOptionsDelayed(inputValue, (filteredOptions) => {
      setDaftarSekolah(filteredOptions);
    });
  }, [inputValue, getOptionsDelayed]);

  return (
    <Fragment>
      <form autoComplete="off">
        {/* SMA/SMK/MA */}
        <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
          <span className="data-pribadi-font title-input-desktop" />
          <div className="info-keluarga-font-title">
            SMA/SMK/MA(Non Pesantren)/Pesantren
          </div>
        </div>

        {/* JENIS SEKOLAH */}
        <span className="data-pribadi-font-mobile title-input-mobile">
          Jenis Sekolah <b style={{ color: "red" }}> * </b>
        </span>
        <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
          <span className="data-pribadi-font title-input-desktop">
            Jenis Sekolah <b style={{ color: "red" }}> * </b>
          </span>
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input"
            onChange={(e) => handleChange("jenisSekolah", e.target.value)}
            value={sendData.jenisSekolah}
            style={{
              color: sendData.jenisSekolah ? "" : "#C9C9C9",
              border: click && !sendData.jenisSekolah && "1px solid red",
            }}
          >
            <option
              value=""
              style={{ color: sendData.kelamin ? "" : "#d3d5d7" }}
              disabled
              selected
            >
              Pilih Jenis Sekolah
            </option>
            <option
              selected={sendData.jenisSekolah === "SMA" ? true : false}
              style={{
                color: sendData.jenisSekolah === "SMA" ? "#3c98dc" : "black",
              }}
              value="SMA"
            >
              SMA
            </option>
            <option
              selected={sendData.jenisSekolah === "SMK" ? true : false}
              style={{
                color: sendData.jenisSekolah === "SMK" ? "#3c98dc" : "black",
              }}
              value="SMK"
            >
              SMK
            </option>
            <option
              selected={
                sendData.jenisSekolah === "MA (Non Pesantren)" ? true : false
              }
              style={{
                color:
                  sendData.jenisSekolah === "MA (Non Pesantren)"
                    ? "#3c98dc"
                    : "black",
              }}
              value="MA (Non Pesantren)"
            >
              MA (Non Pesantren)
            </option>
            <option
              selected={sendData.jenisSekolah === "Pesantren" ? true : false}
              style={{
                color:
                  sendData.jenisSekolah === "Pesantren" ? "#3c98dc" : "black",
              }}
              value="Pesantren"
            >
              Pesantren
            </option>
          </select>
        </div>

        {/* ALAMAT SEKOLAH */}
        <span className="data-pribadi-font-mobile title-input-mobile2row">
          Alamat Sekolah<b style={{ color: "red" }}> * </b>
        </span>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span className="data-pribadi-font title-input-desktop">
            Alamat Sekolah<b style={{ color: "red" }}> * </b>
          </span>
          <div className="input-wrapper-data-pribadi">
            <input
              disabled={
                options?.[profile?.program]?.finalisasi === false ||
                profile?.finalisasi === true
              }
              onChange={(e) => handleChange("alamatSekolah", e.target.value)}
              value={sendData.alamatSekolah}
              placeholder="Alamat Lengkap Sekolah"
              className="data-pribadi-input3"
              style={{
                border: click && !sendData.alamatSekolah && "1px solid red",
              }}
            />
            <select
              disabled={
                options?.[profile?.program]?.finalisasi === false ||
                profile?.finalisasi === true
              }
              className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
              onChange={(e) => handleChange("propinsiSekolah", e.target.value)}
              value={sendData.propinsiSekolah}
              style={{
                color: sendData.propinsiSekolah ? "" : "#C9C9C9",
                border: click && !sendData.propinsiSekolah && "1px solid red",
              }}
            >
              <option
                value=""
                style={{ color: sendData.propinsiSekolah ? "" : "#d3d5d7" }}
                selected
              >
                Propinsi
              </option>
              {propinsiDB?.map((data, i) => (
                <option
                  key={i}
                  selected={sendData.propinsiSekolah === data.id ? true : false}
                  style={{
                    color:
                      sendData.propinsiSekolah === data.id
                        ? "#3c98dc"
                        : "black",
                  }}
                  value={data.id}
                >
                  {data.propinsi}
                </option>
              ))}
            </select>
            <select
              disabled={
                options?.[profile?.program]?.finalisasi === false ||
                profile?.finalisasi === true
              }
              className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
              onChange={(e) => handleChange("kabupatenSekolah", e.target.value)}
              value={sendData.kabupatenSekolah}
              style={{
                color: sendData.kabupatenSekolah ? "" : "#C9C9C9",
                border: click && !sendData.kabupatenSekolah && "1px solid red",
              }}
            >
              <option
                value=""
                style={{ color: sendData.kabupatenSekolah ? "" : "#d3d5d7" }}
                selected
              >
                Kota/Kabupaten
              </option>
              {kabupatenDB[sendData.propinsiSekolah]?.map((data, i) => (
                <option
                  key={i}
                  selected={
                    sendData.kabupatenSekolah === data.id ? true : false
                  }
                  style={{
                    color:
                      sendData.kabupatenSekolah === data.id
                        ? "#3c98dc"
                        : "black",
                  }}
                  value={data.id}
                >
                  {data.kabupaten}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span className="data-pribadi-font title-input-desktop" />
          <div className="input-wrapper-data-pribadi">
            <select
              disabled={
                options?.[profile?.program]?.finalisasi === false ||
                profile?.finalisasi === true
              }
              className="data-pribadi-input3"
              onChange={(e) => handleChange("kecamatanSekolah", e.target.value)}
              value={sendData.kecamatanSekolah}
              style={{
                color: sendData.kecamatanSekolah ? "" : "#C9C9C9",
                border: click && !sendData.kecamatanSekolah && "1px solid red",
              }}
            >
              <option
                value=""
                style={{ color: sendData.kecamatanSekolah ? "" : "#d3d5d7" }}
                selected
              >
                Kecamatan
              </option>
              {kecamatanDB[sendData.kabupatenSekolah] &&
                handleFilter(
                  "kecamatan",
                  kecamatanDB[sendData.kabupatenSekolah]
                ).map((data, i) => (
                  <option
                    key={i}
                    selected={sendData.kecamatanSekolah === data ? true : false}
                    style={{
                      color:
                        sendData.kecamatanSekolah === data
                          ? "#3c98dc"
                          : "black",
                    }}
                    value={data}
                  >
                    {data}
                  </option>
                ))}
            </select>
            <select
              disabled={
                options?.[profile?.program]?.finalisasi === false ||
                profile?.finalisasi === true
              }
              className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
              onChange={(e) => handleChange("kelurahanSekolah", e.target.value)}
              value={sendData.kelurahanSekolah}
              style={{ color: sendData.kelurahanSekolah ? "" : "#C9C9C9" }}
            >
              <option
                value=""
                style={{ color: sendData.kelurahanSekolah ? "" : "#d3d5d7" }}
                selected
              >
                Desa/Kelurahan
              </option>
              {kecamatanDB[sendData.kabupatenSekolah] &&
                handleFilter(
                  "kelurahan",
                  kecamatanDB[sendData.kabupatenSekolah]
                ).map((data, i) => (
                  <option
                    key={i}
                    selected={sendData.kelurahanSekolah === data ? true : false}
                    style={{
                      color:
                        sendData.kelurahanSekolah === data
                          ? "#3c98dc"
                          : "black",
                    }}
                    value={data.id}
                  >
                    {data}
                  </option>
                ))}
            </select>
            <select
              disabled={
                options?.[profile?.program]?.finalisasi === false ||
                profile?.finalisasi === true
              }
              className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
              onChange={(e) => handleChange("kodeposSekolah", e.target.value)}
              value={sendData.kodeposSekolah}
              style={{ color: sendData.kodeposSekolah ? "" : "#C9C9C9" }}
            >
              <option
                value=""
                style={{ color: sendData.kodeposSekolah ? "" : "#d3d5d7" }}
                selected
              >
                Kode Pos
              </option>
              {kecamatanDB[sendData.kabupatenSekolah] &&
                handleFilter(
                  "kodepos",
                  kecamatanDB[sendData.kabupatenSekolah]
                ).map((data, i) => (
                  <option
                    key={i}
                    selected={sendData.kodeposSekolah === data ? true : false}
                    style={{
                      color:
                        sendData.kodeposSekolah === data ? "#3c98dc" : "black",
                    }}
                    value={data}
                  >
                    {data}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span className="data-pribadi-font title-input-desktop" />
          <div
            className="input-wrapper-data-pribadi"
            style={{
              justifyContent: "flex-start",
              textAlign: "left",
              alignItems: "flex-start",
              flexDirection: "row",
            }}
          >
            <b style={{ color: "red", marginRight: 5 }}> * </b> Kosongkan
            Desa/Kelurahan atau Kodepos jika tidak ditemukan, tulis alamat
            lengkap pada kolom isi
          </div>
        </div>

        {/* NAMA SEKOLAH */}
        <span className="data-pribadi-font-mobile title-input-mobile2row">
          Nama Sekolah <b style={{ color: "red" }}> * </b>
        </span>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span className="data-pribadi-font title-input-desktop">
            Nama Sekolah <b style={{ color: "red" }}> * </b>
          </span>
          {/* <input
                    disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                    onChange={e=>handleChange('namaSMA',e.target.value)}
                    value={sendData.namaSMA}
                    placeholder="Nama Sekolah"
                    className="data-pribadi-input"
                    style={{ border: (click && !sendData.namaSMA) && '1px solid red' }}
                /> */}
          <Autocomplete
            onInputChange={(_, newInputValue) => {
              handleChange("namaSMA", newInputValue);
              setInputValue(newInputValue);
            }}
            inputValue={sendData.namaSMA}
            freeSolo
            fullWidth
            id="free-solo-2-demo"
            disableClearable
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            getOptionLabel={(option) => option}
            options={daftarSekolah}
            renderInput={(params) => (
              <TextFieldCustom
                {...params}
                value={sendData.namaSMA}
                className="data-pribadi-input"
                placeholder="Nama Sekolah"
                style={{
                  border: click && !sendData.namaSMA && "1px solid red",
                }}
                variant="outlined"
                size="small"
              />
            )}
            renderOption={(option, index) => <div key={index}>{option}</div>}
          />
        </div>

        {/* TELEPON */}
        <span className="data-pribadi-font-mobile title-input-mobile2row">
          Telepon <b style={{ color: "red" }}> * </b>
        </span>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span className="data-pribadi-font title-input-desktop">
            Telepon <b style={{ color: "red" }}> * </b>
          </span>
          <input
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            onChange={(e) => handleChange("teleponSekolah", e.target.value)}
            value={sendData.teleponSekolah}
            placeholder="Nomor Telepon"
            className="data-pribadi-input"
            style={{
              border: click && !sendData.teleponSekolah && "1px solid red",
            }}
          />
        </div>

        {/* FAKS */}
        <span className="data-pribadi-font-mobile title-input-mobile2row">
          Faks
        </span>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span className="data-pribadi-font title-input-desktop">Faks</span>
          <input
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            onChange={(e) => handleChange("faksSekolah", e.target.value)}
            value={sendData.faksSekolah}
            placeholder="Nomor Faks"
            className="data-pribadi-input"
          />
        </div>

        {/* TAHUN MASUK */}
        <span className="data-pribadi-font-mobile title-input-mobile2row">
          Tahun Masuk <b style={{ color: "red" }}> * </b>
        </span>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span className="data-pribadi-font title-input-desktop">
            Tahun Masuk <b style={{ color: "red" }}> * </b>
          </span>
          <div
            className="data-pribadi-input"
            style={{
              padding: 0,
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              border: click && !sendData.tahunMasukSekolah && "1px solid red",
            }}
          >
            <DatePicker
              disabled={
                options?.[profile?.program]?.finalisasi === false ||
                profile?.finalisasi === true
              }
              autoOk
              clearable
              clearLabel="Hapus"
              views={["year"]}
              placeholder="Tahun Masuk"
              value={sendData.tahunMasukSekolah}
              onChange={(newValue) => {
                handleChange("tahunMasukSekolah", newValue);
              }}
              animateYearScrolling
              okLabel=""
              cancelLabel=""
              helperText=""
              format="yyyy"
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                paddingLeft: "19px",
                border: "0px",
                zIndex: "9",
                height: "44px",
                borderRadius: "4px",
              }}
              minDate={new Date("2023")}
              maxDate={new Date("2024")}
            />
            <FontAwesomeIcon
              icon={faCalendarAlt}
              style={{
                color: "#3c98dc",
                fontSize: 18,
                position: "absolute",
                right: "60",
              }}
            />
          </div>
        </div>

        {/* TAHUN KELUAR */}
        <span className="data-pribadi-font-mobile title-input-mobile2row">
          Tahun Keluar <b style={{ color: "red" }}> * </b>
        </span>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span className="data-pribadi-font title-input-desktop">
            Tahun Keluar <b style={{ color: "red" }}> * </b>
          </span>
          <div
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input"
            style={{
              padding: 0,
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              border: click && !sendData.tahunKeluarSekolah && "1px solid red",
            }}
          >
            <DatePicker
              clearable
              clearLabel="Hapus"
              disabled={
                options?.[profile?.program]?.finalisasi === false ||
                profile?.finalisasi === true
              }
              autoOk
              views={["year"]}
              placeholder="Tahun Lulus"
              value={sendData.tahunKeluarSekolah}
              onChange={(newValue) => {
                handleChange("tahunKeluarSekolah", newValue);
              }}
              animateYearScrolling
              okLabel=""
              cancelLabel=""
              helperText=""
              format="yyyy"
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                paddingLeft: "19px",
                border: "0px",
                zIndex: "9",
                height: "44px",
                borderRadius: "4px",
              }}
              minDate={new Date("2025")}
              maxDate={new Date("2027")}
            />
            <FontAwesomeIcon
              icon={faCalendarAlt}
              style={{
                color: "#3c98dc",
                fontSize: 18,
                position: "absolute",
                right: "60",
              }}
            />
          </div>
        </div>

        {/* BREAK */}
        <div
          style={{
            borderTop: "5px solid #fafafa",
            margin: "30px 0px",
          }}
        />

        {/* DATA PRESTASI SMA/SMK/MA */}
        <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
          <span className="data-pribadi-font title-input-desktop" />
          <div className="info-keluarga-font-title">
            Data prestasi SMA/SMK/MA(Non Pesantren)/Pesantren (Skala 0-100)
          </div>
        </div>

        {sendData?.prestasiSekolah?.map((data, i) => (
          <Fragment key={i}>
            {/* BREAK */}
            {i > 0 && (
              <div
                style={{
                  borderTop: "2px solid #f0f0f0",
                  margin: "15px auto",
                  width: "70%",
                }}
              />
            )}

            {/* KELAS & SEMESTER */}
            <span className="data-pribadi-font-mobile title-input-mobile">
              Kelas & Semester
            </span>
            <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
              <span className="data-pribadi-font title-input-desktop">
                Kelas & Semester
              </span>
              <div className="input-wrapper-data-pribadi">
                <select
                  disabled={
                    options?.[profile?.program]?.finalisasi === false ||
                    profile?.finalisasi === true
                  }
                  className="data-pribadi-input2"
                  onChange={(e) => handleChange("kelasSMA", e.target.value, i)}
                  value={data.kelas}
                  style={{ color: data.kelas ? "" : "#C9C9C9" }}
                >
                  <option
                    value=""
                    style={{ color: data.kelas ? "" : "#d3d5d7" }}
                    selected
                  >
                    Kelas
                  </option>
                  {score?.map(
                    (nilai, i) =>
                      (nilai === 10 || nilai === 11) && (
                        <option
                          key={i}
                          value={nilai}
                          style={{
                            color:
                              Number(data.kelas) === Number(nilai)
                                ? "#3c98dc"
                                : "black",
                          }}
                        >
                          {convertToRoman(nilai)}
                        </option>
                      )
                  )}
                </select>
                <select
                  disabled={
                    options?.[profile?.program]?.finalisasi === false ||
                    profile?.finalisasi === true
                  }
                  className="data-pribadi-input2 data-pribadi-input-margin-top-mobile"
                  onChange={(e) =>
                    handleChange("semesterSMA", e.target.value, i)
                  }
                  value={data.semester}
                  style={{ color: data.semester ? "" : "#C9C9C9" }}
                >
                  <option
                    value=""
                    style={{ color: data.semester ? "" : "#d3d5d7" }}
                    selected
                  >
                    Semester
                  </option>
                  {score?.map(
                    (nilai, i) =>
                      nilai >= 1 &&
                      nilai <= 2 && (
                        <option
                          key={i}
                          value={nilai}
                          style={{
                            color:
                              Number(data.semester) === Number(nilai)
                                ? "#3c98dc"
                                : "black",
                          }}
                        >
                          {convertToRoman(nilai)}
                        </option>
                      )
                  )}
                </select>
              </div>
            </div>

            {/* AGAMA */}
            <span className="data-pribadi-font-mobile title-input-mobile2row">
              Pendidikan Agama
            </span>
            <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
              <span className="data-pribadi-font title-input-desktop">
                Pendidikan Agama
              </span>
              <input
                disabled={
                  options?.[profile?.program]?.finalisasi === false ||
                  profile?.finalisasi === true
                }
                onChange={(e) => handleChange("agamaSMA", e.target.value, i)}
                value={data.agama}
                type="number"
                maxLength={3}
                placeholder="Nilai yang Diraih (0-100)"
                className="data-pribadi-input"
              />
              {/* <select
                                disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                className="data-pribadi-input"
                                onChange={e=>handleChange('agamaSMA',e.target.value, i)}
                                value={data.agama}
                                style={{ color: data.agama ? '' : '#C9C9C9' }}
                            >
                                <option value="" style={{ color: data.agama ? '' : '#d3d5d7' }} selected></option>
                                {
                                    score?.sort((a,b) => b-a)
                                    .map((nilai, i)=>(
                                        <option
                                            key={i}
                                            value={nilai}
                                            style={{ color: Number(data.agama) === Number(nilai) ? '#3c98dc' : 'black' }}
                                        >
                                            {nilai}
                                        </option>
                                    ))
                                }
                            </select> */}
            </div>

            {/* PANCASILA */}
            <span className="data-pribadi-font-mobile title-input-mobile2row">
              Pancasila dan Kewarganegaraan
            </span>
            <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
              <span className="data-pribadi-font title-input-desktop">
                Pancasila dan Kewarganegaraan
              </span>
              <input
                disabled={
                  options?.[profile?.program]?.finalisasi === false ||
                  profile?.finalisasi === true
                }
                onChange={(e) =>
                  handleChange("pancasilaSMA", e.target.value, i)
                }
                value={data.pancasila}
                type="number"
                maxLength={3}
                placeholder="Nilai yang Diraih (0-100)"
                className="data-pribadi-input"
              />
              {/* <select
                                disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                className="data-pribadi-input"
                                onChange={e=>handleChange('pancasilaSMA',e.target.value, i)}
                                value={data.pancasila}
                                style={{ color: data.pancasila ? '' : '#C9C9C9' }}
                            >
                                <option value="" style={{ color: data.pancasila ? '' : '#d3d5d7' }} selected>Nilai yang Diraih</option>
                                {
                                    score?.sort((a,b) => b-a)
                                    .map((nilai, i)=>(
                                        <option
                                            key={i}
                                            value={nilai}
                                            style={{ color: Number(data.pancasila) === Number(nilai) ? '#3c98dc' : 'black' }}
                                        >
                                            {nilai}
                                        </option>
                                    ))
                                }
                            </select> */}
            </div>

            {/* BAHASA INDONESIA */}
            <span className="data-pribadi-font-mobile title-input-mobile2row">
              Bahasa Indonesia
            </span>
            <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
              <span className="data-pribadi-font title-input-desktop">
                Bahasa Indonesia
              </span>
              <input
                disabled={
                  options?.[profile?.program]?.finalisasi === false ||
                  profile?.finalisasi === true
                }
                onChange={(e) =>
                  handleChange("indonesiaSMA", e.target.value, i)
                }
                value={data.indonesia}
                type="number"
                maxLength={3}
                placeholder="Nilai yang Diraih (0-100)"
                className="data-pribadi-input"
              />
              {/* <select
                                disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                className="data-pribadi-input"
                                onChange={e=>handleChange('indonesiaSMA',e.target.value, i)}
                                value={data.indonesia}
                                style={{ color: data.indonesia ? '' : '#C9C9C9' }}
                            >
                                <option value="" style={{ color: data.indonesia ? '' : '#d3d5d7' }} selected>Nilai yang Diraih</option>
                                {
                                    score?.sort((a,b) => b-a)
                                    .map((nilai, i)=>(
                                        <option
                                            key={i}
                                            value={nilai}
                                            style={{ color: Number(data.indonesia) === Number(nilai) ? '#3c98dc' : 'black' }}
                                        >
                                            {nilai}
                                        </option>
                                    ))
                                }
                            </select> */}
            </div>

            {/* MATEMATIKA */}
            <span className="data-pribadi-font-mobile title-input-mobile2row">
              Matematika
            </span>
            <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
              <span className="data-pribadi-font title-input-desktop">
                Matematika
              </span>
              <input
                disabled={
                  options?.[profile?.program]?.finalisasi === false ||
                  profile?.finalisasi === true
                }
                onChange={(e) =>
                  handleChange("matematikaSMA", e.target.value, i)
                }
                value={data.matematika}
                type="number"
                maxLength={3}
                placeholder="Nilai yang Diraih (0-100)"
                className="data-pribadi-input"
              />
              {/* <select
                                disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                className="data-pribadi-input"
                                onChange={e=>handleChange('matematikaSMA',e.target.value, i)}
                                value={data.matematika}
                                style={{ color: data.matematika ? '' : '#C9C9C9' }}
                            >
                                <option value="" style={{ color: data.matematika ? '' : '#d3d5d7' }} selected>Nilai yang Diraih</option>
                                {
                                    score?.sort((a,b) => b-a)
                                    .map((nilai, i)=>(
                                        <option
                                            key={i}
                                            value={nilai}
                                            style={{ color: Number(data.matematika) === Number(nilai) ? '#3c98dc' : 'black' }}
                                        >
                                            {nilai}
                                        </option>
                                    ))
                                }
                            </select> */}
            </div>

            {/* SEJARAH INDONESIA */}
            <span className="data-pribadi-font-mobile title-input-mobile2row">
              Sejarah Indonesia
            </span>
            <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
              <span className="data-pribadi-font title-input-desktop">
                Sejarah Indonesia
              </span>
              <input
                disabled={
                  options?.[profile?.program]?.finalisasi === false ||
                  profile?.finalisasi === true
                }
                onChange={(e) => handleChange("sejarahSMA", e.target.value, i)}
                value={data.sejarah}
                type="number"
                maxLength={3}
                placeholder="Nilai yang Diraih (0-100)"
                className="data-pribadi-input"
              />
              {/* <select
                                disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                className="data-pribadi-input"
                                onChange={e=>handleChange('sejarahSMA',e.target.value, i)}
                                value={data.sejarah}
                                style={{ color: data.sejarah ? '' : '#C9C9C9' }}
                            >
                                <option value="" style={{ color: data.sejarah ? '' : '#d3d5d7' }} selected>Nilai yang Diraih</option>
                                {
                                    score?.sort((a,b) => b-a)
                                    .map((nilai, i)=>(
                                        <option
                                            key={i}
                                            value={nilai}
                                            style={{ color: Number(data.sejarah) === Number(nilai) ? '#3c98dc' : 'black' }}
                                        >
                                            {nilai}
                                        </option>
                                    ))
                                }
                            </select> */}
            </div>

            {/* INGGRIS */}
            <span className="data-pribadi-font-mobile title-input-mobile2row">
              Bahasa Inggris
            </span>
            <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
              <span className="data-pribadi-font title-input-desktop">
                Bahasa Inggris
              </span>
              <input
                disabled={
                  options?.[profile?.program]?.finalisasi === false ||
                  profile?.finalisasi === true
                }
                onChange={(e) => handleChange("inggrisSMA", e.target.value, i)}
                value={data.inggris}
                type="number"
                maxLength={3}
                placeholder="Nilai yang Diraih (0-100)"
                className="data-pribadi-input"
              />
              {/* <select
                                disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                className="data-pribadi-input"
                                onChange={e=>handleChange('inggrisSMA',e.target.value, i)}
                                value={data.inggris}
                                style={{ color: data.inggris ? '' : '#C9C9C9' }}
                            >
                                <option value="" style={{ color: data.inggris ? '' : '#d3d5d7' }} selected>Nilai yang Diraih</option>
                                {
                                    score?.sort((a,b) => b-a)
                                    .map((nilai, i)=>(
                                        <option
                                            key={i}
                                            value={nilai}
                                            style={{ color: Number(data.inggris) === Number(nilai) ? '#3c98dc' : 'black' }}
                                        >
                                            {nilai}
                                        </option>
                                    ))
                                }
                            </select> */}
            </div>

            {/* ACTION */}
            {!(
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            ) && (
              <div
                className="data-pribadi-wrapper"
                style={{ marginTop: "20px" }}
              >
                <span className="data-pribadi-font title-input-desktop" />
                <div className="btn-saudara-wrap">
                  {sendData.prestasiSekolah.length - 1 === i && (
                    <AddButton
                      onClick={() => handleAddSMA()}
                      className="btn-add-sma"
                    >
                      <span className="btn-add-sma-txt">
                        Tambah Kelas & Semester
                      </span>
                    </AddButton>
                  )}
                  {sendData.prestasiSekolah.length > 1 && (
                    <AddButton
                      onClick={() => handleRemoveSMA(i)}
                      className="btn-delete-saudara"
                    >
                      Hapus
                    </AddButton>
                  )}
                </div>
              </div>
            )}
          </Fragment>
        ))}

        {/* BREAK */}
        <div
          style={{
            borderTop: "5px solid #fafafa",
            margin: "30px 0px",
          }}
        />

        {/* SMP/MTS */}
        <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
          <span className="data-pribadi-font title-input-desktop" />
          <div className="info-keluarga-font-title">SMP/MTs</div>
        </div>

        {/* NAMA SEKOLAH */}
        <span className="data-pribadi-font-mobile title-input-mobile">
          Nama Sekolah <b style={{ color: "red" }}> * </b>
        </span>
        <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
          <span className="data-pribadi-font title-input-desktop">
            Nama Sekolah <b style={{ color: "red" }}> * </b>
          </span>
          <input
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            onChange={(e) => handleChange("namaSMP", e.target.value)}
            value={sendData.namaSMP}
            placeholder="Nama Sekolah"
            className="data-pribadi-input"
            style={{ border: click && !sendData.namaSMP && "1px solid red" }}
          />
        </div>

        {/* TAHUN IJAZAH */}
        <span className="data-pribadi-font-mobile title-input-mobile2row">
          Tahun Ijazah <b style={{ color: "red" }}> * </b>
        </span>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span className="data-pribadi-font title-input-desktop">
            Tahun Ijazah <b style={{ color: "red" }}> * </b>
          </span>
          <div
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input"
            style={{
              padding: 0,
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              border: click && !sendData.tahunIjazahSMP && "1px solid red",
            }}
          >
            <DatePicker
              disabled={
                options?.[profile?.program]?.finalisasi === false ||
                profile?.finalisasi === true
              }
              autoOk
              clearable
              clearLabel="Hapus"
              views={["year"]}
              placeholder="Tahun Lulus"
              value={sendData.tahunIjazahSMP}
              onChange={(newValue) => {
                handleChange("tahunIjazahSMP", newValue);
              }}
              animateYearScrolling
              disableFuture
              okLabel=""
              cancelLabel=""
              helperText=""
              format="yyyy"
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                paddingLeft: "19px",
                border: "0px",
                zIndex: "9",
                height: "44px",
                borderRadius: "4px",
              }}
              minDate={new Date("2023")}
              maxDate={new Date("2024")}
            />
            <FontAwesomeIcon
              icon={faCalendarAlt}
              style={{
                color: "#3c98dc",
                fontSize: 18,
                position: "absolute",
                right: "60",
              }}
            />
          </div>
        </div>

        {/* BREAK */}
        <div
          style={{
            borderTop: "5px solid #fafafa",
            margin: "30px 0px",
          }}
        />

        {/* DATA PRESTASI SMP/MTs */}
        <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
          <span className="data-pribadi-font title-input-desktop" />
          <div className="info-keluarga-font-title">
            Data prestasi SMP/MTs (Skala 0-100, Indeks Prestasi, atau bentuk
            abjad)
          </div>
        </div>

        {sendData?.prestasiSMP?.map((data, i) => (
          <Fragment key={i}>
            {/* BREAK */}
            {i > 0 && (
              <div
                style={{
                  borderTop: "2px solid #f0f0f0",
                  margin: "15px auto",
                  width: "70%",
                }}
              />
            )}

            {/* KELAS & SEMESTER */}
            <span className="data-pribadi-font-mobile title-input-mobile">
              Kelas & Semester <b style={{ color: "red" }}> * </b>
            </span>
            <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
              <span className="data-pribadi-font title-input-desktop">
                Kelas & Semester <b style={{ color: "red" }}> * </b>
              </span>
              <div className="input-wrapper-data-pribadi">
                <select
                  disabled={
                    options?.[profile?.program]?.finalisasi === false ||
                    profile?.finalisasi === true
                  }
                  className="data-pribadi-input2"
                  onChange={(e) => handleChange("kelasSMP", e.target.value, i)}
                  value={data.kelas}
                  style={{
                    color: data.kelas ? "" : "#C9C9C9",
                    border: click && !data.kelas && "1px solid red",
                  }}
                >
                  <option
                    value=""
                    style={{ color: data.kelas ? "" : "#d3d5d7" }}
                    selected
                  >
                    Kelas
                  </option>
                  {score?.map(
                      (nilai, i) =>
                        nilai >= 8 &&
                        nilai <= 9 && (
                          <option
                            key={i}
                            value={nilai}
                            style={{
                              color:
                                Number(data.kelas) === Number(nilai)
                                  ? "#3c98dc"
                                  : "black",
                            }}
                          >
                            {convertToRoman(nilai)}
                          </option>
                        )
                    )}
                </select>
                <select
                  disabled={
                    options?.[profile?.program]?.finalisasi === false ||
                    profile?.finalisasi === true
                  }
                  className="data-pribadi-input2 data-pribadi-input-margin-top-mobile"
                  onChange={(e) =>
                    handleChange("semesterSMP", e.target.value, i)
                  }
                  value={data.semester}
                  style={{
                    color: data.semester ? "" : "#C9C9C9",
                    border: click && !data.semester && "1px solid red",
                  }}
                >
                  <option
                    value=""
                    style={{ color: data.semester ? "" : "#d3d5d7" }}
                    selected
                  >
                    Semester
                  </option>
                  {score?.map(
                      (nilai, i) =>
                        nilai >= 1 &&
                        nilai <= 2 && (
                          <option
                            key={i}
                            value={nilai}
                            style={{
                              color:
                                Number(data.semester) === Number(nilai)
                                  ? "#3c98dc"
                                  : "black",
                            }}
                          >
                            {convertToRoman(nilai)}
                          </option>
                        )
                    )}
                </select>
              </div>
            </div>

            {/* AGAMA */}
            <span className="data-pribadi-font-mobile title-input-mobile2row">
              Pendidikan Agama <b style={{ color: "red" }}> * </b>
            </span>
            <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
              <span className="data-pribadi-font title-input-desktop">
                Pendidikan Agama <b style={{ color: "red" }}> * </b>
              </span>
              <input
                disabled={
                  options?.[profile?.program]?.finalisasi === false ||
                  profile?.finalisasi === true
                }
                onChange={(e) => handleChange("agamaSMP", e.target.value, i)}
                value={data.agama}
                type="number"
                maxLength={3}
                placeholder="Nilai yang Diraih (0-100)"
                className="data-pribadi-input"
              />
              {/* <select
                                disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                className="data-pribadi-input"
                                onChange={e=>handleChange('agamaSMP',e.target.value, i)}
                                value={data.agama}
                                style={{
                                    color: data.agama ? '' : '#C9C9C9',
                                    border: (click && !data.agama) && '1px solid red'
                                }}
                            >
                                <option value="" style={{ color: data.agama ? '' : '#d3d5d7' }} selected>Nilai yang Diraih</option>
                                {
                                    score?.sort((a,b) => b-a)
                                    .map((nilai, i)=>(
                                        <option
                                            key={i}
                                            value={nilai}
                                            style={{ color: Number(data.agama) === Number(nilai) ? '#3c98dc' : 'black' }}
                                        >
                                            {nilai}
                                        </option>
                                    ))
                                }
                            </select> */}
            </div>

            {/* PANCASILA */}
            <span className="data-pribadi-font-mobile title-input-mobile2row">
              Pancasila dan Kewarganegaraan <b style={{ color: "red" }}> * </b>
            </span>
            <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
              <span className="data-pribadi-font title-input-desktop">
                Pancasila dan Kewarganegaraan{" "}
                <b style={{ color: "red" }}> * </b>
              </span>
              <input
                disabled={
                  options?.[profile?.program]?.finalisasi === false ||
                  profile?.finalisasi === true
                }
                onChange={(e) =>
                  handleChange("pancasilaSMP", e.target.value, i)
                }
                value={data.pancasila}
                type="number"
                maxLength={3}
                placeholder="Nilai yang Diraih (0-100)"
                className="data-pribadi-input"
              />
              {/* <select
                                disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                className="data-pribadi-input"
                                onChange={e=>handleChange('pancasilaSMP',e.target.value, i)}
                                value={data.pancasila}
                                style={{
                                    color: data.pancasila ? '' : '#C9C9C9',
                                    border: (click && !data.pancasila) && '1px solid red'
                                }}
                            >
                                <option value="" style={{ color: data.pancasila ? '' : '#d3d5d7' }} selected>Nilai yang Diraih</option>
                                {
                                    score?.sort((a,b) => b-a)
                                    .map((nilai, i)=>(
                                        <option
                                            key={i}
                                            value={nilai}
                                            style={{ color: Number(data.pancasila) === Number(nilai) ? '#3c98dc' : 'black' }}
                                        >
                                            {nilai}
                                        </option>
                                    ))
                                }
                            </select> */}
            </div>

            {/* BAHASA INDONESIA */}
            <span className="data-pribadi-font-mobile title-input-mobile2row">
              Bahasa Indonesia <b style={{ color: "red" }}> * </b>
            </span>
            <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
              <span className="data-pribadi-font title-input-desktop">
                Bahasa Indonesia <b style={{ color: "red" }}> * </b>
              </span>
              <input
                disabled={
                  options?.[profile?.program]?.finalisasi === false ||
                  profile?.finalisasi === true
                }
                onChange={(e) =>
                  handleChange("indonesiaSMP", e.target.value, i)
                }
                value={data.indonesia}
                type="number"
                maxLength={3}
                placeholder="Nilai yang Diraih (0-100)"
                className="data-pribadi-input"
              />
              {/* <select
                                disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                className="data-pribadi-input"
                                onChange={e=>handleChange('indonesiaSMP',e.target.value, i)}
                                value={data.indonesia}
                                style={{
                                    color: data.indonesia ? '' : '#C9C9C9',
                                    border: (click && !data.indonesia) && '1px solid red'
                                }}
                            >
                                <option value="" style={{ color: data.indonesia ? '' : '#d3d5d7' }} selected>Nilai yang Diraih</option>
                                {
                                    score?.sort((a,b) => b-a)
                                    .map((nilai, i)=>(
                                        <option
                                            key={i}
                                            value={nilai}
                                            style={{ color: Number(data.indonesia) === Number(nilai) ? '#3c98dc' : 'black' }}
                                        >
                                            {nilai}
                                        </option>
                                    ))
                                }
                            </select> */}
            </div>

            {/* MATEMATIKA */}
            <span className="data-pribadi-font-mobile title-input-mobile2row">
              Matematika <b style={{ color: "red" }}> * </b>
            </span>
            <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
              <span className="data-pribadi-font title-input-desktop">
                Matematika <b style={{ color: "red" }}> * </b>
              </span>
              <input
                disabled={
                  options?.[profile?.program]?.finalisasi === false ||
                  profile?.finalisasi === true
                }
                onChange={(e) =>
                  handleChange("matematikaSMP", e.target.value, i)
                }
                value={data.matematika}
                type="number"
                maxLength={3}
                placeholder="Nilai yang Diraih (0-100)"
                className="data-pribadi-input"
              />
              {/* <select
                                disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                className="data-pribadi-input"
                                onChange={e=>handleChange('matematikaSMP',e.target.value, i)}
                                value={data.matematika}
                                style={{
                                    color: data.matematika ? '' : '#C9C9C9',
                                    border: (click && !data.matematika) && '1px solid red'
                                }}
                            >
                                <option value="" style={{ color: data.matematika ? '' : '#d3d5d7' }} selected>Nilai yang Diraih</option>
                                {
                                    score?.sort((a,b) => b-a)
                                    .map((nilai, i)=>(
                                        <option
                                            key={i}
                                            value={nilai}
                                            style={{ color: Number(data.matematika) === Number(nilai) ? '#3c98dc' : 'black' }}
                                        >
                                            {nilai}
                                        </option>
                                    ))
                                }
                            </select> */}
            </div>

            {/* ILMU PENGETAHUAN ALAM */}
            <span className="data-pribadi-font-mobile title-input-mobile2row">
              Ilmu Pengetahuan Alam <b style={{ color: "red" }}> * </b>
            </span>
            <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
              <span className="data-pribadi-font title-input-desktop">
                Ilmu Pengetahuan Alam <b style={{ color: "red" }}> * </b>
              </span>
              <input
                disabled={
                  options?.[profile?.program]?.finalisasi === false ||
                  profile?.finalisasi === true
                }
                onChange={(e) => handleChange("ipaSMP", e.target.value, i)}
                value={data.ipa}
                type="number"
                maxLength={3}
                placeholder="Nilai yang Diraih (0-100)"
                className="data-pribadi-input"
              />
              {/* <select
                                disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                className="data-pribadi-input"
                                onChange={e=>handleChange('ipaSMP',e.target.value, i)}
                                value={data.ipa}
                                style={{
                                    color: data.ipa ? '' : '#C9C9C9',
                                    border: (click && !data.ipa) && '1px solid red'
                                }}
                            >
                                <option value="" style={{ color: data.ipa ? '' : '#d3d5d7' }} selected>Nilai yang Diraih</option>
                                {
                                    score?.sort((a,b) => b-a)
                                    .map((nilai, i)=>(
                                        <option
                                            key={i}
                                            value={nilai}
                                            style={{ color: Number(data.ipa) === Number(nilai) ? '#3c98dc' : 'black' }}
                                        >
                                            {nilai}
                                        </option>
                                    ))
                                }
                            </select> */}
            </div>

            {/* ILMU PENGETAHUAN SOSIAL */}
            <span className="data-pribadi-font-mobile title-input-mobile2row">
              Ilmu Pengetahuan Sosial <b style={{ color: "red" }}> * </b>
            </span>
            <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
              <span className="data-pribadi-font title-input-desktop">
                Ilmu Pengetahuan Sosial <b style={{ color: "red" }}> * </b>
              </span>
              <input
                disabled={
                  options?.[profile?.program]?.finalisasi === false ||
                  profile?.finalisasi === true
                }
                onChange={(e) => handleChange("ipsSMP", e.target.value, i)}
                value={data.ips}
                type="number"
                maxLength={3}
                placeholder="Nilai yang Diraih (0-100)"
                className="data-pribadi-input"
              />
              {/* <select
                                disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                className="data-pribadi-input"
                                onChange={e=>handleChange('ipsSMP',e.target.value, i)}
                                value={data.ips}
                                style={{
                                    color: data.ips ? '' : '#C9C9C9',
                                    border: (click && !data.ips) && '1px solid red'
                                }}
                            >
                                <option value="" style={{ color: data.ips ? '' : '#d3d5d7' }} selected>Nilai yang Diraih</option>
                                {
                                    score?.sort((a,b) => b-a)
                                    .map((nilai, i)=>(
                                        <option
                                            key={i}
                                            value={nilai}
                                            style={{ color: Number(data.ips) === Number(nilai) ? '#3c98dc' : 'black' }}
                                        >
                                            {nilai}
                                        </option>
                                    ))
                                }
                            </select> */}
            </div>

            {/* INGGRIS */}
            <span className="data-pribadi-font-mobile title-input-mobile2row">
              Bahasa Inggris <b style={{ color: "red" }}> * </b>
            </span>
            <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
              <span className="data-pribadi-font title-input-desktop">
                Bahasa Inggris <b style={{ color: "red" }}> * </b>
              </span>
              <input
                disabled={
                  options?.[profile?.program]?.finalisasi === false ||
                  profile?.finalisasi === true
                }
                onChange={(e) => handleChange("inggrisSMP", e.target.value, i)}
                value={data.inggris}
                type="number"
                maxLength={3}
                placeholder="Nilai yang Diraih (0-100)"
                className="data-pribadi-input"
              />
              {/* <select
                                disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                className="data-pribadi-input"
                                onChange={e=>handleChange('inggrisSMP',e.target.value, i)}
                                value={data.inggris}
                                style={{
                                    color: data.inggris ? '' : '#C9C9C9',
                                    border: (click && !data.inggris) && '1px solid red'
                                }}
                            >
                                <option value="" style={{ color: data.inggris ? '' : '#d3d5d7' }} selected>Nilai yang Diraih</option>
                                {
                                    score?.sort((a,b) => b-a)
                                    .map((nilai, i)=>(
                                        <option
                                            key={i}
                                            value={nilai}
                                            style={{ color: Number(data.inggris) === Number(nilai) ? '#3c98dc' : 'black' }}
                                        >
                                            {nilai}
                                        </option>
                                    ))
                                }
                            </select> */}
            </div>

            {/* ACTION */}
            {!(
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            ) && (
              <div
                className="data-pribadi-wrapper"
                style={{ marginTop: "20px" }}
              >
                <span className="data-pribadi-font title-input-desktop" />
                <div className="btn-saudara-wrap">
                  {sendData.prestasiSMP.length - 1 === i && (
                    <AddButton
                      onClick={() => handleAddSMP()}
                      className="btn-add-sma"
                    >
                      <span className="btn-add-sma-txt">
                        Tambah Kelas & Semester
                      </span>
                    </AddButton>
                  )}
                  {sendData.prestasiSMP.length > 1 && (
                    <AddButton
                      onClick={() => handleRemoveSMP(i)}
                      className="btn-delete-saudara"
                    >
                      Hapus
                    </AddButton>
                  )}
                </div>
              </div>
            )}
          </Fragment>
        ))}

        {/* BREAK */}
        <div
          style={{
            borderTop: "5px solid #fafafa",
            margin: "30px 0px",
          }}
        />

        {/* DATA PRESTASI SMP/MTs */}
        <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
          <span className="data-pribadi-font title-input-desktop" />
          <div className="info-keluarga-font-title">SD/MI</div>
        </div>

        {/* NAMA SEKOLAH */}
        <span className="data-pribadi-font-mobile title-input-mobile">
          Nama Sekolah <b style={{ color: "red" }}> * </b>
        </span>
        <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
          <span className="data-pribadi-font title-input-desktop">
            Nama Sekolah <b style={{ color: "red" }}> * </b>
          </span>
          <input
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            onChange={(e) => handleChange("namaSD", e.target.value)}
            value={sendData.namaSD}
            placeholder="Nama Sekolah"
            className="data-pribadi-input"
            style={{ border: click && !sendData.namaSD && "1px solid red" }}
          />
        </div>

        {/* TAHUN IJAZAH */}
        <span className="data-pribadi-font-mobile title-input-mobile2row">
          Tahun Ijazah <b style={{ color: "red" }}> * </b>
        </span>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span className="data-pribadi-font title-input-desktop">
            Tahun Ijazah <b style={{ color: "red" }}> * </b>
          </span>
          <div
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input"
            style={{
              padding: 0,
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              border: click && !sendData.tahunIjazahSD && "1px solid red",
            }}
          >
            <DatePicker
              disabled={
                options?.[profile?.program]?.finalisasi === false ||
                profile?.finalisasi === true
              }
              clearable
              clearLabel="Hapus"
              autoOk
              views={["year"]}
              placeholder="Tahun Lulus"
              value={sendData.tahunIjazahSD}
              onChange={(newValue) => {
                handleChange("tahunIjazahSD", newValue);
              }}
              animateYearScrolling
              disableFuture
              okLabel=""
              cancelLabel=""
              helperText=""
              format="yyyy"
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                paddingLeft: "19px",
                border: "0px",
                zIndex: "9",
                height: "44px",
                borderRadius: "4px",
              }}
              minDate={new Date("2020")}
              maxDate={new Date("2021")}
            />
            <FontAwesomeIcon
              icon={faCalendarAlt}
              style={{
                color: "#3c98dc",
                fontSize: 18,
                position: "absolute",
                right: "60",
              }}
            />
          </div>
        </div>

        {/* BREAK */}
        <div
          style={{
            borderTop: "5px solid #fafafa",
            margin: "30px 0px",
          }}
        />

        {/* PENGETAHUAN BAHASA */}
        <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
          <span className="data-pribadi-font title-input-desktop" />
          <div className="info-keluarga-font-title">Pengetahuan Bahasa</div>
        </div>

        {sendData.pengetahuanBahasa &&
          sendData.pengetahuanBahasa.map((data, i) => (
            <Fragment key={i}>
              {/* BREAK */}
              {i > 0 && (
                <div
                  style={{
                    borderTop: "2px solid #f0f0f0",
                    margin: "15px auto",
                    width: "70%",
                  }}
                />
              )}

              {/* BAHASA YANG DIKUASAI */}
              <span className="data-pribadi-font-mobile title-input-mobile">
                Bahasa yang Dikuasai <b style={{ color: "red" }}> * </b>
              </span>
              <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
                <span className="data-pribadi-font title-input-desktop">
                  Bahasa yang Dikuasai <b style={{ color: "red" }}> * </b>
                </span>
                <div className="input-wrapper-data-pribadi">
                  <input
                    disabled={
                      (options?.[profile?.program]?.finalisasi === false &&
                        profile?.finalisasi === false) ||
                      i === 0
                    }
                    onChange={(e) =>
                      handleChange("pengetahuanBahasa", e.target.value, i)
                    }
                    value={data.bahasa}
                    placeholder="Bahasa"
                    className="data-pribadi-input2"
                    style={{ border: click && !data.bahasa && "1px solid red" }}
                  />
                  <input
                    disabled={
                      options?.[profile?.program]?.finalisasi === false ||
                      profile?.finalisasi === true
                    }
                    onChange={(e) =>
                      handleChange("pengetahuanLama", e.target.value, i)
                    }
                    value={data.lama}
                    placeholder="Lama Menguasai Bahasa"
                    className="data-pribadi-input2 data-pribadi-input-margin-top-mobile"
                    style={{ border: click && !data.lama && "1px solid red" }}
                  />
                </div>
              </div>

              {/* ACTION */}
              {!(
                options?.[profile?.program]?.finalisasi === false ||
                profile?.finalisasi === true
              ) && (
                <div
                  className="data-pribadi-wrapper"
                  style={{
                    marginTop:
                      i === 0
                        ? sendData.pengetahuanBahasa.length <= 1 && "20px"
                        : "20px",
                  }}
                >
                  <span className="data-pribadi-font title-input-desktop" />
                  <div className="btn-saudara-wrap">
                    {sendData.pengetahuanBahasa.length - 1 === i && (
                      <AddButton
                        onClick={() => handleAddPengetahuanBahasa()}
                        className="btn-add-sma"
                      >
                        <span className="btn-add-sma-txt">Tambah Bahasa</span>
                      </AddButton>
                    )}
                    {sendData.pengetahuanBahasa.length > 1 && i !== 0 && (
                      <AddButton
                        onClick={() => handleRemovePengetahuanBahasa(i)}
                        className="btn-delete-saudara"
                      >
                        Hapus
                      </AddButton>
                    )}
                  </div>
                </div>
              )}
            </Fragment>
          ))}

        {/* BREAK */}
        <div
          style={{
            borderTop: "5px solid #fafafa",
            margin: "30px 0px",
          }}
        />

        {/* PELAJARAN FAVORIT */}
        <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
          <span className="data-pribadi-font title-input-desktop" />
          <div className="info-keluarga-font-title">
            Pelajaran Favorit dan Cita-Cita
          </div>
        </div>

        {/* MATA PELAJARAN FAVORIT */}
        <span className="data-pribadi-font-mobile title-input-mobile">
          Mata Pelajaran Favorit <b style={{ color: "red" }}> * </b>
        </span>
        <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
          <span className="data-pribadi-font title-input-desktop">
            Mata Pelajaran Favorit <b style={{ color: "red" }}> * </b>
          </span>
          <input
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            onChange={(e) => handleChange("pelajaranFavorit", e.target.value)}
            value={sendData.pelajaranFavorit}
            placeholder="Mata Pelajaran Favorit"
            className="data-pribadi-input"
            style={{
              border: click && !sendData.pelajaranFavorit && "1px solid red",
            }}
          />
        </div>

        {/* CITA - CITA */}
        <span className="data-pribadi-font-mobile title-input-mobile2row">
          Cita-Cita <b style={{ color: "red" }}> * </b>
        </span>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span className="data-pribadi-font title-input-desktop">
            Cita-Cita <b style={{ color: "red" }}> * </b>
          </span>
          <input
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            onChange={(e) => handleChange("citaCita", e.target.value)}
            value={sendData.citaCita}
            placeholder="Cita-Cita"
            className="data-pribadi-input"
            style={{ border: click && !sendData.citaCita && "1px solid red" }}
          />
        </div>

        <div className="data-pribadi-wrapper action-wrapper-2021-margintop">
          {options?.[profile?.program]?.finalisasi === false ||
          profile?.finalisasi === true ? (
            <div className="data-pribadi-button-wripper">
              <SaveAndOutButton
                className="btn-save-input2021-out"
                onClick={(e) => goToTab(2)}
              >
                {" "}
                Lihat Data Sebelumnya{" "}
              </SaveAndOutButton>
              <SaveAndNextButton
                className="btn-save-input2021-next"
                onClick={(e) => goToTab(4)}
              >
                {" "}
                Lihat Data Selanjutnya{" "}
              </SaveAndNextButton>
            </div>
          ) : (
            <div className="data-pribadi-button-wripper">
              <SaveAndOutButton
                className="btn-save-input2021-out"
                onClick={(e) => saving("out")}
              >
                {" "}
                Simpan & Keluar{" "}
              </SaveAndOutButton>
              <SaveAndNextButton
                className="btn-save-input2021-next"
                onClick={(e) => saving("next")}
              >
                {" "}
                Simpan & Lanjutkan{" "}
              </SaveAndNextButton>
            </div>
          )}
        </div>
      </form>
    </Fragment>
  );
});

export default RiwayatPendidikan;
