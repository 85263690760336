import React, { useState, useEffect } from 'react'
import './styles.scss'
import BinabudayaLogo from '../../images/homepage/binabud.svg'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2'
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { newPassword } from '../../store/actions/peserta'

const ActivationButton = withStyles((theme) => ({
    root: {
        marginTop: '50px',
        width: '100%',
        height: '44px',
        color: '#ffffff',
        backgroundColor: '#3598DC',
        fontFamily: ['Roboto', 'sans-serif'],
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '19px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#2f88c6',
            boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)',
        },
    },
}))(Button);

function NewPassword(props) {
    const History = useHistory()
    const Dispatch = useDispatch()
    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)
    const [sendData, setSendData] = useState({
        username: '',
        id: '',
        password: '',
        passwordRepeat: ''
    })

    useEffect(()=>{
        const ToastMid = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        if (props) {
            if (props.location) {
                if (props.location.state) {
                    setSendData({
                        username: props.location.state.username,
                        id: props.location.state.id,
                        password: '',
                        passwordRepeat: ''
                    })
                } else {
                    History.replace('/')
                    ToastMid.fire({
                        icon: 'error',
                        title: 'Akses Diblokir'
                    })
                }
            }
        }
    },[props])

    const handleShowPassword = () => {
        showPassword ? setShowPassword(false) : setShowPassword(true)
    }

    const handleShowPasswordConfirmation = () => {
        showPasswordConfirmation ? setShowPasswordConfirmation(false) : setShowPasswordConfirmation(true)
    }

    const changePassword = () => {
        const ToastMid = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        if (sendData.password && sendData.passwordRepeat) {
            if (sendData.password === sendData.passwordRepeat) {
                Dispatch(newPassword(sendData, Swal, History))
            } else  {
                ToastMid.fire({
                    icon: 'error',
                    title: 'Pastikan Password Sama'
                })
            }
        } else {
            ToastMid.fire({
                icon: 'error',
                title: 'Isi Password Baru'
            })
        }
    }

    const handleChangeValue = (key, value) => {
        switch (key) {
            case 'password':
                setSendData({
                    ...sendData,
                    password: value
                })
                break;
            case 'passwordRepeat':
                setSendData({
                    ...sendData,
                    passwordRepeat: value
                })
                break;
            default:
                break;
        }
    }

    return (
        <div className="activation-container-wrapper">
            <div className="activation-binabud-logo" >
                <img src={BinabudayaLogo} alt="binabudaya" className="logo-binabud-activation" />
            </div>
            <div className="activation-contain-text" >
                <span className="activation-font-top" >
                    Buat Password Baru
                </span>
                <div className="activation-container-card" style={{ marginTop: 85 }} >
                    <div className="new-password-container" >
                        <span className="card-font-text-password" style={{ marginTop: 0 }}>Password</span>
                        <div className="password-wraper" >
                            <input
                                onChange={e=>handleChangeValue('password', e.target.value)}
                                type={!showPassword ? 'password' : 'text'}
                                placeholder="Password Baru"
                                className="activation-input-theme"
                            />
                            {
                                showPassword ?
                                    <FontAwesomeIcon
                                        icon={faEye}
                                        className="eye-custom"
                                        style={{ color: '#3c98dc' }}
                                        onClick={() => handleShowPassword()}
                                    />
                                    :
                                    <FontAwesomeIcon
                                        icon={faEye}
                                        className="eye-custom"
                                        onClick={() => handleShowPassword()}
                                    />
                            }
                        </div>
                    </div>
                    <div className="new-password-container" style={{ marginTop: 20 }}>
                        <span className="card-font-text-password" style={{ marginTop: 0 }}>Konfirmasi Password</span>
                        <div className="password-wraper" >
                            <input
                                onChange={e=>handleChangeValue('passwordRepeat', e.target.value)}
                                type={!showPasswordConfirmation ? 'password' : 'text'}
                                placeholder="Ulangi Password"
                                className="activation-input-theme"
                            />
                            {
                                showPasswordConfirmation ?
                                    <FontAwesomeIcon
                                        icon={faEye}
                                        style={{ color: '#3c98dc' }}
                                        className="eye-custom"
                                        onClick={() => handleShowPasswordConfirmation()}
                                    />
                                    :
                                    <FontAwesomeIcon
                                        icon={faEye}
                                        className="eye-custom"
                                        onClick={() => handleShowPasswordConfirmation()}
                                    />
                            }
                        </div>
                    </div>
                    <ActivationButton onClick={()=>changePassword()} >Buat Password Baru</ActivationButton>
                </div>
            </div>
        </div>
    )
}

export default NewPassword