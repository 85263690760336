import React from "react";
import "./styles.scss";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DownloadIcon from "../../../images/berkas/download.svg";
import server from "../../../endpoint";
import Swal from "sweetalert2";
import { downloadFile } from "../../../store/actions/document";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const DownloadButton = withStyles((theme) => ({
  root: {
    marginTop: "auto",
    width: "100%",
    height: "44px",
    color: "#FFFFFF",
    backgroundColor: "#3598DC",
    fontFamily: ["Roboto", "sans-serif"],
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "19px",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#2f88c6",
    },
  },
}))(Button);

function DownloadBerkas() {
  const Dispatch = useDispatch();
  const profile = useSelector((state) => state.pesertaState.profile);
  const berkas = useSelector((state) => state.filterState.berkas);

  const handleDownload = (e, program, option) => {
    if (e) e.preventDefault();
    let fileName = berkas[program][option].fileName;
    let uri = `${server}${berkas[program][option].link}`;
    if (fileName && uri) {
      Dispatch(downloadFile(uri, fileName, Swal));
    }
  };

  return (
    <div className="input-data-container">
      <span className="input-data-header">Download Berkas</span>
      <div className="bread-cab-container">
        <span className="bread-grey">Download Berkas /</span>
      </div>
      <div className="input-container-2021">
        <div className="data-pribadi-header">Download Berkas</div>
        <div
          style={{
            textAlign: "left",
            padding: "20px 20px",
            paddingBottom: 0,
          }}
        >
          * Gunakan hanya browser Google Chrome atau Firefox di Laptop/Komputer
          untuk mendownload dokumen di halaman ini. Tidak diperkenankan
          mendownload menggunakan HP
        </div>
        <div
          className="document-wrapper-2021"
          style={{
            paddingTop: 10,
          }}
        >
          {/* Berkas Pendaftaran Lengkap */}
          <div className="box-white-download-berkas">
            <div className="box-white-blue-download-berkas">
              <img src={DownloadIcon} alt="icon" className="icon-download" />
              <span className="box-white-blue-download-berkas-text-dwd">
                Berkas Pendaftaran Lengkap
              </span>
            </div>
            <div style={{ marginTop: "auto" }}>
              <Link
                style={{ textDecoration: "none" }}
                to={{ pathname: "/download-berkas/pendaftaran-lengkap" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <DownloadButton>Download</DownloadButton>
              </Link>
            </div>
          </div>
          {/* Berkas Halaman Essay */}
          <div className="box-white-download-berkas">
            <div className="box-white-blue-download-berkas">
              <img src={DownloadIcon} alt="icon" className="icon-download" />
              <span className="box-white-blue-download-berkas-text-dwd">
                Berkas Halaman Essay
              </span>
            </div>
            <div style={{ marginTop: "auto" }}>
              <Link
                style={{ textDecoration: "none" }}
                to={{ pathname: profile?.essay?.file || "" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <DownloadButton>Download</DownloadButton>
              </Link>
            </div>
          </div>
          {/* Surat Penyataan Sekolah */}
          <div className="box-white-download-berkas">
            <div className="box-white-blue-download-berkas">
              <img src={DownloadIcon} alt="icon" className="icon-download" />
              <span className="box-white-blue-download-berkas-text-dwd">
                Surat Penyataan Sekolah
              </span>
            </div>
            <DownloadButton
              onClick={(e) =>
                handleDownload(
                  e,
                  profile && profile.program,
                  "SuratPenyataanSekolah"
                )
              }
            >
              Download
            </DownloadButton>
          </div>
          {/* Surat Izin Orang Tua */}
          <div className="box-white-download-berkas">
            <div className="box-white-blue-download-berkas">
              <img src={DownloadIcon} alt="icon" className="icon-download" />
              <span className="box-white-blue-download-berkas-text-dwd">
                Surat Pernyataan Orang Tua
              </span>
            </div>
            <DownloadButton
              onClick={(e) =>
                handleDownload(
                  e,
                  profile && profile.program,
                  "SuratIzinOrangTua"
                )
              }
            >
              Download
            </DownloadButton>
          </div>
          {/* Surat Rekomendasi Lingkungan Rumah */}
          <div className="box-white-download-berkas">
            <div className="box-white-blue-download-berkas">
              <img src={DownloadIcon} alt="icon" className="icon-download" />
              <span className="box-white-blue-download-berkas-text-dwd">
                Surat Rekomendasi Lingkungan Rumah
              </span>
              {/* <span className="box-white-blue-download-berkas-text-dwd-title"> (Usia min. 18 th, berasal dari organisasi, tetangga, pembimbing rohani, dll. Bukan anggota keluarga atau tinggal serumah) </span> */}
            </div>
            <DownloadButton
              onClick={(e) =>
                handleDownload(
                  e,
                  profile && profile.program,
                  "SuratRekomendasiLingkunganRumah"
                )
              }
            >
              Download
            </DownloadButton>
          </div>
          {/* Surat Rekomendasi Lingkungan Sekolah */}
          <div className="box-white-download-berkas">
            <div className="box-white-blue-download-berkas">
              <img src={DownloadIcon} alt="icon" className="icon-download" />
              <span className="box-white-blue-download-berkas-text-dwd">
                Surat Rekomendasi Lingkungan Sekolah
              </span>
              {/* <span className="box-white-blue-download-berkas-text-dwd-title">  (Guru, Kepala Sekolah/Wakil Kepala Sekolah, Pembina OSIS) </span> */}
            </div>
            <DownloadButton
              onClick={(e) =>
                handleDownload(
                  e,
                  profile && profile.program,
                  "SuratRekomendasiLingkunganSekolah"
                )
              }
            >
              Download
            </DownloadButton>
          </div>
          {/* Surat Rekomendasi Teman Dekat */}
          <div className="box-white-download-berkas">
            <div className="box-white-blue-download-berkas">
              <img src={DownloadIcon} alt="icon" className="icon-download" />
              <span className="box-white-blue-download-berkas-text-dwd">
                Surat Rekomendasi Teman Dekat
              </span>
              {/* <span className="box-white-blue-download-berkas-text-dwd-title"> (Sahabat, teman sebaya, teman sekelas/satu angkatan) </span> */}
            </div>
            <DownloadButton
              onClick={(e) =>
                handleDownload(
                  e,
                  profile && profile.program,
                  "SuratRekomendasiTemanDekat"
                )
              }
            >
              Download
            </DownloadButton>
          </div>
          {/* Kartu Tanda Peserta */}
          <div className="box-white-download-berkas">
            <div className="box-white-blue-download-berkas">
              <img src={DownloadIcon} alt="icon" className="icon-download" />
              <span className="box-white-blue-download-berkas-text-dwd">
                Kartu Tanda Peserta
              </span>
            </div>
            <div style={{ marginTop: "auto" }}>
              {profile?.nomorUrut !== "0000" ? (
                <Link
                  style={{ textDecoration: "none" }}
                  to={{ pathname: "/download-berkas/kartu-peserta" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <DownloadButton>Download</DownloadButton>
                </Link>
              ) : (
                <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ color: "#f44336", fontSize: 17, marginRight: 5 }}
                  />
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {profile?.statusVerifikasiBerkas === false
                      ? "Berkas Tidak Lolos Verifikasi"
                      : "Berkas Belum Diverifikasi"}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownloadBerkas;
