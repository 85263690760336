import React, { useState, useEffect, Fragment, forwardRef, useImperativeHandle } from 'react'
import './styles.scss'
import { DatePicker } from "@material-ui/pickers";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2'
import { useSelector, useDispatch } from 'react-redux'
import { saveInfoKeluarga, setSaveAction } from '../../../../store/actions/peserta'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

const SaveAndOutButton = withStyles((theme) => ({
    root: {
        border: '1px solid #3598DC',
        // marginTop: '50px',
        width: '200px',
        height: '44px',
        color: '#3598DC',
        backgroundColor: '#FFFFFF',
        fontFamily: ['Roboto', 'sans-serif'],
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '19px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
            // backgroundColor: '#e5e5e5',
            // boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)',
        },
    },
}))(Button);

const SaveAndNextButton = withStyles((theme) => ({
    root: {
        // marginTop: '50px',
        width: '200px',
        height: '44px',
        color: '#ffffff',
        backgroundColor: '#3598DC',
        fontFamily: ['Roboto', 'sans-serif'],
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '19px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#2f88c6',
            boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)',
        },
        marginLeft: 20
    },
}))(Button);

const AddSaudaraButton = withStyles((theme) => ({
    root: {
        border: '1px solid #3598DC',
        height: '44px',
        color: '#3598DC',
        backgroundColor: '#FFFFFF',
        fontFamily: ['Roboto', 'sans-serif'],
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '19px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
        },
    },
}))(Button);

const InfoKeluarga = forwardRef((props, ref) => {
    const {
        NextPage,
        handleLogout,
        goTop
    } = props
    const options = useSelector(state => state.pesertaState.options)
    const profile = useSelector(state => state.pesertaState.profile);
    const save = useSelector(state => state.pesertaState.checkerSave);
    const Dispatch = useDispatch()
    const [click, setClick] = useState(false)
    const [sendData, setSendData] = useState({
        namaAyah: '',
        pendidikanAyah: '',
        pekerjaanAyah: '',
        jabatanAyah: '',
        emailAyah: '',
        teleponAyah: '',
        namaKantorAyah: '',
        alamatKantorAyah: '',
        namaIbu: '',
        pendidikanIbu: '',
        pekerjaanIbu: '',
        jabatanIbu: '',
        emailIbu: '',
        teleponIbu: '',
        namaKantorIbu: '',
        alamatKantorIbu: '',
        namaWali: '',
        hubunganWali: '',
        pendidikanWali: '',
        pekerjaanWali: '',
        jabatanWali: '',
        emailWali: '',
        teleponWali: '',
        alamatRumahWali: '',
        namaKantorWali: '',
        alamatKantorWali: '',
        anakKe: 1,
        dariAnak: 1,
        saudara: [
            {
                nama: '',
                tanggalLahir: null,
                sekolahPekerjaan: ''
            }
        ],
        alumniNama: '',
        alumniHubungan: '',
        alumniProgram: '',
        alumniTujuanAsal: '',
        alumniAlamat: '',
        alumniKegiatan: '',
        alumniTahun: '',
    })

    useEffect(() => {
        if (profile) {
            if (profile.infoKeluarga) {
                setSendData(profile.infoKeluarga)
            }
        }
    }, [profile && profile.infoKeluarga])

    const handleChange = (key, value, i) => {
        if (!save) Dispatch(setSaveAction(true))
        switch (key) {
            case 'namaAyah':
                setSendData({
                    ...sendData,
                    namaAyah: value
                })
                break;
            case 'pendidikanAyah':
                setSendData({
                    ...sendData,
                    pendidikanAyah: value
                })
                break;
            case 'pekerjaanAyah':
                setSendData({
                    ...sendData,
                    pekerjaanAyah: value
                })
                break;
            case 'jabatanAyah':
                setSendData({
                    ...sendData,
                    jabatanAyah: value
                })
                break;
            case 'emailAyah':
                setSendData({
                    ...sendData,
                    emailAyah: value
                })
                break;
            case 'teleponAyah':
                setSendData({
                    ...sendData,
                    teleponAyah: value
                })
                break;
            case 'namaKantorAyah':
                setSendData({
                    ...sendData,
                    namaKantorAyah: value
                })
                break;
            case 'alamatKantorAyah':
                setSendData({
                    ...sendData,
                    alamatKantorAyah: value
                })
                break;
            case 'namaIbu':
                setSendData({
                    ...sendData,
                    namaIbu: value
                })
                break;
            case 'pendidikanIbu':
                setSendData({
                    ...sendData,
                    pendidikanIbu: value
                })
                break;
            case 'pekerjaanIbu':
                setSendData({
                    ...sendData,
                    pekerjaanIbu: value
                })
                break;
            case 'jabatanIbu':
                setSendData({
                    ...sendData,
                    jabatanIbu: value
                })
                break;
            case 'emailIbu':
                setSendData({
                    ...sendData,
                    emailIbu: value
                })
                break;
            case 'teleponIbu':
                setSendData({
                    ...sendData,
                    teleponIbu: value
                })
                break;
            case 'namaKantorIbu':
                setSendData({
                    ...sendData,
                    namaKantorIbu: value
                })
                break;
            case 'alamatKantorIbu':
                setSendData({
                    ...sendData,
                    alamatKantorIbu: value
                })
                break;
            case 'namaWali':
                setSendData({
                    ...sendData,
                    namaWali: value
                })
                break;
            case 'hubunganWali':
                setSendData({
                    ...sendData,
                    hubunganWali: value
                })
                break;
            case 'pendidikanWali':
                setSendData({
                    ...sendData,
                    pendidikanWali: value
                })
                break;
            case 'pekerjaanWali':
                setSendData({
                    ...sendData,
                    pekerjaanWali: value
                })
                break;
            case 'jabatanWali':
                setSendData({
                    ...sendData,
                    jabatanWali: value
                })
                break;
            case 'emailWali':
                setSendData({
                    ...sendData,
                    emailWali: value
                })
                break;
            case 'teleponWali':
                setSendData({
                    ...sendData,
                    teleponWali: value
                })
                break;
            case 'alamatRumahWali':
                setSendData({
                    ...sendData,
                    alamatRumahWali: value
                })
                break;
            case 'namaKantorWali':
                setSendData({
                    ...sendData,
                    namaKantorWali: value
                })
                break;
            case 'alamatKantorWali':
                setSendData({
                    ...sendData,
                    alamatKantorWali: value
                })
                break;
            case 'anakKe':
                if (value > sendData.dariAnak) {
                    setSendData({
                        ...sendData,
                        anakKe: value,
                        dariAnak: value
                    })
                } else {
                    setSendData({
                        ...sendData,
                        anakKe: value
                    })
                }
                break;
            case 'dariAnak':
                setSendData({
                    ...sendData,
                    dariAnak: value,
                })
                break;
            case 'saudaraNama':
                let temp1 = [...sendData.saudara]
                temp1[i].nama = value
                setSendData({
                    ...sendData,
                    saudara: temp1
                })
                break;
            case 'saudaraTanggal':
                let temp2 = [...sendData.saudara]
                temp2[i].tanggalLahir = value
                setSendData({
                    ...sendData,
                    saudara: temp2
                })
                break;
            case 'saudaraSekolahPekerjaan':
                let temp3 = [...sendData.saudara]
                temp3[i].sekolahPekerjaan = value
                setSendData({
                    ...sendData,
                    saudara: temp3
                })
                break;
            case 'alumniNama':
                setSendData({
                    ...sendData,
                    alumniNama: value
                })
                break;
            case 'alumniHubungan':
                setSendData({
                    ...sendData,
                    alumniHubungan: value
                })
                break;
            case 'alumniProgram':
                setSendData({
                    ...sendData,
                    alumniProgram: value
                })
                break;
            case 'alumniTujuanAsal':
                setSendData({
                    ...sendData,
                    alumniTujuanAsal: value
                })
                break;
            case 'alumniAlamat':
                setSendData({
                    ...sendData,
                    alumniAlamat: value
                })
                break;
            case 'alumniKegiatan':
                setSendData({
                    ...sendData,
                    alumniKegiatan: value
                })
                break;
            case 'alumniTahun':
                setSendData({
                    ...sendData,
                    alumniTahun: value
                })
                break;
            default:
                break;
        }
    }

    const handleAddSaudara = () => {
        if (!save) Dispatch(setSaveAction(true))
        let temp = [...sendData.saudara]
        temp.push({
            nama: '',
            tanggalLahir: null,
            sekolahPekerjaan: ''
        })
        setSendData({
            ...sendData,
            saudara: temp
        })
    }

    const handleRemoveSaudara = (i) => {
        if (!save) Dispatch(setSaveAction(true))
        let temp = [...sendData.saudara]
        temp.splice(i, 1)
        setSendData({
            ...sendData,
            saudara: temp
        })
    }

    const goToTab = (page) => {
        NextPage(page)
        goTop()
    }

    const saving = (option) => {
        const ToastMid = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        const SwalBootstrap2 = Swal.mixin({
            customClass: {
                confirmButton: 'btn-finalisasi-yakin-ok',
                cancelButton: 'btn-finalisasi-tidak'
            },
            buttonsStyling: false
        })
        if (options?.[profile?.program]?.finalisasi) {
            setClick(true)
            let flag = false
            for (let key in sendData) {
                if (
                    key === 'namaAyah' ||
                    key === 'pendidikanAyah' ||
                    key === 'pekerjaanAyah' ||
                    key === 'jabatanAyah' ||
                    key === 'emailAyah' ||
                    key === 'namaKantorAyah' ||
                    key === 'alamatKantorAyah' ||
                    key === 'namaIbu' ||
                    key === 'pendidikanIbu' ||
                    key === 'pekerjaanIbu' ||
                    key === 'jabatanIbu' ||
                    key === 'emailIbu' ||
                    key === 'namaKantorIbu' ||
                    key === 'alamatKantorIbu' ||
                    key === 'teleponAyah' ||
                    key === 'teleponIbu'
                ) {
                    if (!sendData[key]) {
                        flag = true
                        ToastMid.fire({
                            icon: 'warning',
                            title: `Silahkan Lengkapi Data`
                        })
                        goTop()
                        return
                    }
                    if (flag) return
                }
                if (flag) return
            }
            let page = profile.page
            if (profile.page < 3) {
                page = 3
            }
            if (!flag) {
                Dispatch(saveInfoKeluarga({ data: sendData, page }, Swal, option, NextPage, handleLogout, 3, goTop))
            }
        } else {
            SwalBootstrap2.fire({
                title: 'Pengisian Data Telah Ditutup',
                text: "",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'OK',
                cancelButtonText: '',
                reverseButtons: true,
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.value) {
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) { }
            })
        }
    }

    useImperativeHandle(ref, () => ({
        saving(option, num) {
            const ToastMid = Swal.mixin({
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                onOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            const SwalBootstrap2 = Swal.mixin({
                customClass: {
                    confirmButton: 'btn-finalisasi-yakin-ok',
                    cancelButton: 'btn-finalisasi-tidak'
                },
                buttonsStyling: false
            })
            if (options?.[profile?.program]?.finalisasi) {
                setClick(true)
                let flag = false
                for (let key in sendData) {
                    if (
                        key === 'namaAyah' ||
                        key === 'pendidikanAyah' ||
                        key === 'pekerjaanAyah' ||
                        key === 'jabatanAyah' ||
                        key === 'emailAyah' ||
                        key === 'namaKantorAyah' ||
                        key === 'alamatKantorAyah' ||
                        key === 'namaIbu' ||
                        key === 'pendidikanIbu' ||
                        key === 'pekerjaanIbu' ||
                        key === 'jabatanIbu' ||
                        key === 'emailIbu' ||
                        key === 'namaKantorIbu' ||
                        key === 'alamatKantorIbu' ||
                        key === 'teleponAyah' ||
                        key === 'teleponIbu'
                    ) {
                        if (!sendData[key]) {
                            flag = true
                            ToastMid.fire({
                                icon: 'warning',
                                title: `Silahkan Lengkapi Data`
                            })
                            goTop()
                            return
                        }
                        if (flag) return
                    }
                    if (flag) return
                }
                let page = profile.page
                if (profile.page < 3) {
                    page = 3
                }
                if (!flag) {
                    Dispatch(saveInfoKeluarga({ data: sendData, page }, Swal, option, NextPage, handleLogout, num, goTop))
                }
            } else {
                SwalBootstrap2.fire({
                    title: 'Pengisian Data Telah Ditutup',
                    text: "",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'OK',
                    cancelButtonText: '',
                    reverseButtons: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then((result) => {
                    if (result.value) {
                    } else if (
                        /* Read more about handling dismissals below */
                        result.dismiss === Swal.DismissReason.cancel
                    ) { }
                })
            }
        }
    }))

    return (
        <Fragment className="data-pribadi-container" >

            <form autoComplete="off">
                {/* AYAH */}
                <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile" >
                    <span className="data-pribadi-font title-input-desktop" />
                    <div className="info-keluarga-font-title">
                        Ayah
                    </div>
                </div>
                {/* NAMA LENGKAP */}
                <span className="data-pribadi-font-mobile title-input-mobile">
                    Nama Lengkap <b style={{ color: 'red' }} > * </b>
                </span>
                <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile" >
                    <span className="data-pribadi-font title-input-desktop">
                        Nama Lengkap <b style={{ color: 'red' }} > * </b>
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('namaAyah', e.target.value)}
                        value={sendData.namaAyah}
                        type="text"
                        placeholder="Nama Lengkap"
                        className="data-pribadi-input"
                        style={{ border: (click && !sendData.namaAyah) && '1px solid red' }}
                    />
                </div>
                {/* PENDIDIKAN TERAKHIR */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Pendidikan Terakhir <b style={{ color: 'red' }} > * </b>
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Pendidikan Terakhir <b style={{ color: 'red' }} > * </b>
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('pendidikanAyah', e.target.value)}
                        value={sendData.pendidikanAyah}
                        type="text"
                        placeholder="Pendidikan Terakhir"
                        className="data-pribadi-input"
                        style={{ border: (click && !sendData.pendidikanAyah) && '1px solid red' }}
                    />
                </div>
                {/* PEKERJAAN */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Pekerjaan <b style={{ color: 'red' }} > * </b>
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Pekerjaan <b style={{ color: 'red' }} > * </b>
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('pekerjaanAyah', e.target.value)}
                        value={sendData.pekerjaanAyah}
                        type="text"
                        placeholder="Pekerjaan"
                        className="data-pribadi-input"
                        style={{ border: (click && !sendData.pekerjaanAyah) && '1px solid red' }}
                    />
                </div>
                {/* JABATAN/PANGKAT/GOLONGAN */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Jabatan/Pangkat/Golongan <b style={{ color: 'red' }} > * </b>
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Jabatan/Pangkat/Golongan <b style={{ color: 'red' }} > * </b>
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('jabatanAyah', e.target.value)}
                        value={sendData.jabatanAyah}
                        type="text"
                        placeholder="Jabatan/Pangkat/Golongan"
                        className="data-pribadi-input"
                        style={{ border: (click && !sendData.jabatanAyah) && '1px solid red' }}
                    />
                </div>
                {/* ALAMAT EMAIL */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Alamat Email <b style={{ color: 'red' }} > * </b>
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Alamat Email <b style={{ color: 'red' }} > * </b>
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('emailAyah', e.target.value)}
                        value={sendData.emailAyah}
                        type="email"
                        placeholder="Alamat Email"
                        className="data-pribadi-input"
                        style={{ border: (click && !sendData.emailAyah) && '1px solid red' }}
                    />
                </div>
                {/* NOMOR PONSEL */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Nomor Ponsel <b style={{ color: 'red' }} > * </b>
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Nomor Ponsel <b style={{ color: 'red' }} > * </b>
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('teleponAyah', e.target.value)}
                        value={sendData.teleponAyah}
                        type="text"
                        placeholder="Nomor Ponsel"
                        className="data-pribadi-input"
                        style={{ border: (click && !sendData.teleponAyah) && '1px solid red' }}
                    />
                </div>
                {/* NAMA & ALAMAT KANTOR */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Nama & Alamat Kantor <b style={{ color: 'red' }} > * </b>
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Nama & Alamat Kantor <b style={{ color: 'red' }} > * </b>
                    </span>
                    <div className="input-wrapper-data-pribadi" >
                        <input
                            disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                            onChange={e => handleChange('namaKantorAyah', e.target.value)}
                            value={sendData.namaKantorAyah}
                            type="text"
                            placeholder="Nama Kantor"
                            className="data-pribadi-input2"
                            style={{ border: (click && !sendData.namaKantorAyah) && '1px solid red' }}
                        />
                        <input
                            disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                            onChange={e => handleChange('alamatKantorAyah', e.target.value)}
                            value={sendData.alamatKantorAyah}
                            type="text"
                            placeholder="Alamat Kantor"
                            className="data-pribadi-input2 data-pribadi-input-margin-top-mobile"
                            style={{ border: (click && !sendData.alamatKantorAyah) && '1px solid red' }}
                        />
                    </div>
                </div>

                {/* BREAK */}
                <div
                    style={{
                        borderTop: '5px solid #fafafa',
                        margin: '30px 0px'
                    }}
                />

                {/* IBU */}
                <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile" >
                    <span className="data-pribadi-font title-input-desktop" />
                    <div className="info-keluarga-font-title">
                        Ibu
                    </div>
                </div>
                {/* NAMA LENGKAP */}
                <span className="data-pribadi-font-mobile title-input-mobile">
                    Nama Lengkap <b style={{ color: 'red' }} > * </b>
                </span>
                <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile" >
                    <span className="data-pribadi-font title-input-desktop">
                        Nama Lengkap <b style={{ color: 'red' }} > * </b>
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('namaIbu', e.target.value)}
                        value={sendData.namaIbu}
                        type="text"
                        placeholder="Nama Lengkap"
                        className="data-pribadi-input"
                        style={{ border: (click && !sendData.namaIbu) && '1px solid red' }}
                    />
                </div>
                {/* PENDIDIKAN TERAKHIR */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Pendidikan Terakhir <b style={{ color: 'red' }} > * </b>
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Pendidikan Terakhir <b style={{ color: 'red' }} > * </b>
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('pendidikanIbu', e.target.value)}
                        value={sendData.pendidikanIbu}
                        type="text"
                        placeholder="Pendidikan Terakhir"
                        className="data-pribadi-input"
                        style={{ border: (click && !sendData.pendidikanIbu) && '1px solid red' }}
                    />
                </div>
                {/* PEKERJAAN */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Pekerjaan <b style={{ color: 'red' }} > * </b>
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Pekerjaan <b style={{ color: 'red' }} > * </b>
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('pekerjaanIbu', e.target.value)}
                        value={sendData.pekerjaanIbu}
                        type="text"
                        placeholder="Pekerjaan"
                        className="data-pribadi-input"
                        style={{ border: (click && !sendData.pekerjaanIbu) && '1px solid red' }}
                    />
                </div>
                {/* JABATAN/PANGKAT/GOLONGAN */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Jabatan/Pangkat/Golongan <b style={{ color: 'red' }} > * </b>
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Jabatan/Pangkat/Golongan <b style={{ color: 'red' }} > * </b>
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('jabatanIbu', e.target.value)}
                        value={sendData.jabatanIbu}
                        type="text"
                        placeholder="Jabatan/Pangkat/Golongan"
                        className="data-pribadi-input"
                        style={{ border: (click && !sendData.jabatanIbu) && '1px solid red' }}
                    />
                </div>
                {/* ALAMAT EMAIL */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Alamat Email <b style={{ color: 'red' }} > * </b>
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Alamat Email <b style={{ color: 'red' }} > * </b>
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('emailIbu', e.target.value)}
                        value={sendData.emailIbu}
                        type="email"
                        placeholder="Alamat Email"
                        className="data-pribadi-input"
                        style={{ border: (click && !sendData.emailIbu) && '1px solid red' }}
                    />
                </div>
                {/* NOMOR PONSEL */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Nomor Ponsel <b style={{ color: 'red' }} > * </b>
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Nomor Ponsel <b style={{ color: 'red' }} > * </b>
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('teleponIbu', e.target.value)}
                        value={sendData.teleponIbu}
                        type="text"
                        placeholder="Nomor Ponsel"
                        className="data-pribadi-input"
                        style={{ border: (click && !sendData.teleponIbu) && '1px solid red' }}
                    />
                </div>
                {/* NAMA & ALAMAT KANTOR */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Nama & Alamat Kantor <b style={{ color: 'red' }} > * </b>
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Nama & Alamat Kantor <b style={{ color: 'red' }} > * </b>
                    </span>
                    <div className="input-wrapper-data-pribadi" >
                        <input
                            disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                            onChange={e => handleChange('namaKantorIbu', e.target.value)}
                            value={sendData.namaKantorIbu}
                            type="text"
                            placeholder="Nama Kantor"
                            className="data-pribadi-input2"
                            style={{ border: (click && !sendData.namaKantorIbu) && '1px solid red' }}
                        />
                        <input
                            disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                            onChange={e => handleChange('alamatKantorIbu', e.target.value)}
                            value={sendData.alamatKantorIbu}
                            type="text"
                            placeholder="Alamat Kantor"
                            className="data-pribadi-input2 data-pribadi-input-margin-top-mobile"
                            style={{ border: (click && !sendData.alamatKantorIbu) && '1px solid red' }}
                        />
                    </div>
                </div>

                {/* BREAK */}
                <div
                    style={{
                        borderTop: '5px solid #fafafa',
                        margin: '30px 0px'
                    }}
                />

                {/* WALI */}
                <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile" >
                    <span className="data-pribadi-font title-input-desktop" />
                    <div className="info-keluarga-font-title">
                        Wali
                    </div>
                </div>
                {/* NAMA LENGKAP */}
                <span className="data-pribadi-font-mobile title-input-mobile">
                    Nama Lengkap
                </span>
                <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile" >
                    <span className="data-pribadi-font title-input-desktop">
                        Nama Lengkap
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('namaWali', e.target.value)}
                        value={sendData.namaWali}
                        type="text"
                        placeholder="Nama Lengkap"
                        className="data-pribadi-input"
                    />
                </div>
                {/* HUBUNGAN */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Hubungan
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Hubungan
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('hubunganWali', e.target.value)}
                        value={sendData.hubunganWali}
                        type="text"
                        placeholder="Hubungan Dengan adik"
                        className="data-pribadi-input"
                    />
                </div>
                {/* PENDIDIKAN TERAKHIR */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Pendidikan Terakhir
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Pendidikan Terakhir
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('pendidikanWali', e.target.value)}
                        value={sendData.pendidikanWali}
                        type="text"
                        placeholder="Pendidikan Terakhir"
                        className="data-pribadi-input"
                    />
                </div>
                {/* PEKERJAAN */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Pekerjaan
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Pekerjaan
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('pekerjaanWali', e.target.value)}
                        value={sendData.pekerjaanWali}
                        type="text"
                        placeholder="Pekerjaan"
                        className="data-pribadi-input"
                    />
                </div>
                {/* JABATAN/PANGKAT/GOLONGAN */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Jabatan/Pangkat/Golongan
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Jabatan/Pangkat/Golongan
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('jabatanWali', e.target.value)}
                        value={sendData.jabatanWali}
                        type="text"
                        placeholder="Jabatan/Pangkat/Golongan"
                        className="data-pribadi-input"
                    />
                </div>
                {/* ALAMAT EMAIL */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Alamat Email
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Alamat Email
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('emailWali', e.target.value)}
                        value={sendData.emailWali}
                        type="email"
                        placeholder="Alamat Email"
                        className="data-pribadi-input"
                    />
                </div>
                {/* NOMOR PONSEL */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Nomor Ponsel
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Nomor Ponsel
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('teleponWali', e.target.value)}
                        value={sendData.teleponWali}
                        type="text"
                        placeholder="Nomor Ponsel"
                        className="data-pribadi-input"
                    />
                </div>
                {/* ALAMAT RUMAH */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Alamat Rumah
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Alamat Rumah
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('alamatRumahWali', e.target.value)}
                        value={sendData.alamatRumahWali}
                        type="text"
                        placeholder="Alamat Rumah"
                        className="data-pribadi-input"
                    />
                </div>
                {/* NAMA & ALAMAT KANTOR */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Nama & Alamat Kantor
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Nama & Alamat Kantor
                    </span>
                    <div className="input-wrapper-data-pribadi" >
                        <input
                            disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                            onChange={e => handleChange('namaKantorWali', e.target.value)}
                            value={sendData.namaKantorWali}
                            type="text"
                            placeholder="Nama Kantor"
                            className="data-pribadi-input2"
                        />
                        <input
                            disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                            onChange={e => handleChange('alamatKantorWali', e.target.value)}
                            value={sendData.alamatKantorWali}
                            type="text"
                            placeholder="Alamat Kantor"
                            className="data-pribadi-input2 data-pribadi-input-margin-top-mobile"
                        />
                    </div>
                </div>

                {/* BREAK */}
                <div
                    style={{
                        borderTop: '5px solid #fafafa',
                        margin: '30px 0px'
                    }}
                />

                {/* KAKAK DAN ADIK */}
                <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile" >
                    <span className="data-pribadi-font title-input-desktop" />
                    <div className="info-keluarga-font-title">
                        Kakak dan adik
                    </div>
                </div>
                {/* STRUKTUR SAUDARA */}
                <span className="data-pribadi-font-mobile title-input-mobile">
                    Struktur Saudara <b style={{ color: 'red' }} > * </b>
                </span>
                <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile" >
                    <span className="data-pribadi-font title-input-desktop">
                        Struktur Saudara <b style={{ color: 'red' }} > * </b>
                    </span>
                    <div className="struktur-saudara-row">
                        <div className="input-struktur" >
                            <span className="input-struktur-text">
                                Anak ke
                            </span>
                            <select
                                disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                onChange={e => handleChange('anakKe', e.target.value)} value={sendData.anakKe} className="input-struktur-select" style={{ marginLeft: 8 }}>
                                <option value="1" selected>1</option>
                                <option value="2" >2</option>
                                <option value="3" >3</option>
                                <option value="4" >4</option>
                                <option value="5" >5</option>
                                <option value="6" >6</option>
                                <option value="7" >7</option>
                                <option value="8" >8</option>
                                <option value="9" >9</option>
                                <option value="9" >10</option>
                            </select>
                        </div>
                        <div className="from-input-flicker" >
                            Dari
                        </div>
                        <div className="input-struktur" >
                            <select
                                disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                onChange={e => handleChange('dariAnak', e.target.value)} value={sendData.dariAnak} className="input-struktur-select" style={{ marginRight: 8 }}>
                                <option disabled={sendData.anakKe > 1 ? true : false} style={{ color: sendData.anakKe > 1 ? '#C9C9C9' : '' }} value="1" selected>1</option>
                                <option disabled={sendData.anakKe > 2 ? true : false} style={{ color: sendData.anakKe > 2 ? '#C9C9C9' : '' }} value="2" >2</option>
                                <option disabled={sendData.anakKe > 3 ? true : false} style={{ color: sendData.anakKe > 3 ? '#C9C9C9' : '' }} value="3" >3</option>
                                <option disabled={sendData.anakKe > 4 ? true : false} style={{ color: sendData.anakKe > 4 ? '#C9C9C9' : '' }} value="4" >4</option>
                                <option disabled={sendData.anakKe > 5 ? true : false} style={{ color: sendData.anakKe > 5 ? '#C9C9C9' : '' }} value="5" >5</option>
                                <option disabled={sendData.anakKe > 6 ? true : false} style={{ color: sendData.anakKe > 6 ? '#C9C9C9' : '' }} value="6" >6</option>
                                <option disabled={sendData.anakKe > 7 ? true : false} style={{ color: sendData.anakKe > 7 ? '#C9C9C9' : '' }} value="7" >7</option>
                                <option disabled={sendData.anakKe > 8 ? true : false} style={{ color: sendData.anakKe > 8 ? '#C9C9C9' : '' }} value="8" >8</option>
                                <option disabled={sendData.anakKe > 9 ? true : false} style={{ color: sendData.anakKe > 9 ? '#C9C9C9' : '' }} value="9" >9</option>
                                <option disabled={sendData.anakKe > 10 ? true : false} style={{ color: sendData.anakKe > 10 ? '#C9C9C9' : '' }} value="10" >10</option>
                            </select>
                            <span className="input-struktur-text">
                                Bersaudara
                            </span>
                        </div>
                    </div>
                </div>

                {
                    sendData?.saudara?.map((data, i) => (
                        <Fragment key={i}>
                            {/* BREAK */}
                            {
                                i > 0 &&
                                <div
                                    style={{
                                        borderTop: '2px solid #f0f0f0',
                                        margin: '15px auto',
                                        width: '70%',
                                    }}
                                />
                            }
                            {/* NAMA SAUDARA */}
                            <span className="data-pribadi-font-mobile title-input-mobile2row">
                                Nama Saudara
                            </span>
                            <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                                <span className="data-pribadi-font title-input-desktop">
                                    Nama Saudara
                                </span>
                                <input
                                    disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                    onChange={e => handleChange('saudaraNama', e.target.value, i)}
                                    value={data.nama}
                                    type="text"
                                    placeholder="Nama Saudara"
                                    className="data-pribadi-input"
                                />
                            </div>
                            {/* TANGGAL LAHIR */}
                            <span className="data-pribadi-font-mobile title-input-mobile2row">
                                Tanggal Lahir
                            </span>
                            <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                                <span className="data-pribadi-font title-input-desktop">
                                    Tanggal Lahir
                                </span>
                                <div
                                    className="data-pribadi-input"
                                    style={{
                                        padding: 0,
                                        boxSizing: 'border-box',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <DatePicker
                                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                        clearable
                                        clearLabel="Hapus"
                                        autoOk
                                        views={['year', 'month', 'date']}
                                        placeholder="Tanggal Lahir"
                                        value={data.tanggalLahir}
                                        onChange={(newValue) => {
                                            handleChange('saudaraTanggal', newValue, i);
                                        }}
                                        animateYearScrolling
                                        disableFuture
                                        okLabel=""
                                        cancelLabel=""
                                        helperText=""
                                        format="dd/MM/yyyy"
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            paddingLeft: '19px',
                                            border: '0px',
                                            zIndex: '9',
                                            height: '44px',
                                            borderRadius: '4px',
                                        }}
                                        maxDate={new Date()}
                                    />
                                    <FontAwesomeIcon
                                        icon={faCalendarAlt}
                                        style={{
                                            color: "#3c98dc",
                                            fontSize: 18,
                                            position: 'absolute',
                                            right: '60',
                                        }}
                                    />
                                </div>
                            </div>

                            {/* SEKOLAH/PEKERJAAN */}
                            <span className="data-pribadi-font-mobile title-input-mobile2row">
                                Sekolah/Pekerjaan
                            </span>
                            <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                                <span className="data-pribadi-font title-input-desktop">
                                    Sekolah/Pekerjaan
                                </span>
                                <input
                                    disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                    onChange={e => handleChange('saudaraSekolahPekerjaan', e.target.value, i)}
                                    value={data.sekolahPekerjaan}
                                    type="text"
                                    placeholder="Sekolah/Pekerjaan"
                                    className="data-pribadi-input"
                                />
                            </div>
                            {/* ACTIONS */}
                            {
                                !((options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)) &&
                                ((Number(sendData.dariAnak) > 1)) &&
                                <div className="data-pribadi-wrapper" style={{ marginTop: '20px' }} >
                                    <span className="data-pribadi-font title-input-desktop" />
                                    <div className="btn-saudara-wrap" >
                                        {
                                            sendData.saudara.length - 1 === i &&
                                            <AddSaudaraButton
                                                onClick={() => handleAddSaudara()}
                                                className="btn-add-saudara"
                                            >
                                                Tambah Saudara
                                            </AddSaudaraButton>
                                        }
                                        {
                                            sendData.saudara.length > 1 &&
                                            <AddSaudaraButton
                                                onClick={() => handleRemoveSaudara(i)}
                                                className="btn-delete-saudara"
                                            >
                                                Hapus
                                            </AddSaudaraButton>
                                        }
                                    </div>
                                </div>
                            }
                        </Fragment>
                    ))
                }

                {/* BREAK */}
                <div
                    style={{
                        borderTop: '5px solid #fafafa',
                        margin: '30px 0px'
                    }}
                />

                {/* ALUMNI PERTUKARAN PELAJAR */}
                <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile" >
                    <span className="data-pribadi-font title-input-desktop" />
                    <div className="info-keluarga-font-title">
                        Adakah di antara keluarga besar adik yang pernah mengikuti program pertukaran yang diselenggarakan oleh Bina Antarbudaya?
                    </div>
                </div>

                {/* NAMA */}
                <span className="data-pribadi-font-mobile title-input-mobile">
                    Nama
                </span>
                <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile" >
                    <span className="data-pribadi-font title-input-desktop">
                        Nama
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('alumniNama', e.target.value)}
                        value={sendData.alumniNama}
                        type="text"
                        placeholder="Nama"
                        className="data-pribadi-input"
                    />
                </div>

                {/* HUBUNGAN */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Hubungan dengan Peserta
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Hubungan dengan Peserta
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('alumniHubungan', e.target.value)}
                        value={sendData.alumniHubungan}
                        type="text"
                        placeholder="Hubungan dengan Peserta"
                        className="data-pribadi-input"
                    />
                </div>

                {/* PROGRAM */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Program
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Program
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('alumniProgram', e.target.value)}
                        value={sendData.alumniProgram}
                        type="text"
                        placeholder="Program yang Diikuti"
                        className="data-pribadi-input"
                    />
                </div>

                {/* TUJUAN (SENDING) ATAU ASAL (HOSTING) */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Tujuan (Sending) atau Asal (Hosting)
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Tujuan (Sending) atau Asal (Hosting)
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('alumniTujuanAsal', e.target.value)}
                        value={sendData.alumniTujuanAsal}
                        type="text"
                        placeholder="Tujuan (Sending) atau Asal (Hosting)"
                        className="data-pribadi-input"
                    />
                </div>

                {/* ALAMAT */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Alamat Sekarang
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Alamat Sekarang
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('alumniAlamat', e.target.value)}
                        value={sendData.alumniAlamat}
                        type="text"
                        placeholder="Alamat Sekarang"
                        className="data-pribadi-input"
                    />
                </div>

                {/* BREAK */}
                <div
                    style={{
                        borderTop: '5px solid #fafafa',
                        margin: '30px 0px'
                    }}
                />

                {/* ALUMNI PERTUKARAN PELAJAR */}
                <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile" >
                    <span className="data-pribadi-font title-input-desktop" />
                    <div className="info-keluarga-font-title">
                        Pernahkan adik atau Keluarga adik Berpartisipasi Dalam Kegiatan Bina Antarbudaya Sebelumnya?
                    </div>
                </div>

                {/* KEGIATAN */}
                <span className="data-pribadi-font-mobile title-input-mobile">
                    Kegiatan
                </span>
                <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile" >
                    <span className="data-pribadi-font title-input-desktop">
                        Kegiatan
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('alumniKegiatan', e.target.value)}
                        value={sendData.alumniKegiatan}
                        type="text"
                        placeholder="Kegiatan"
                        className="data-pribadi-input"
                    />
                </div>

                {/* TAHUN */}
                <span className="data-pribadi-font-mobile title-input-mobile2row">
                    Tahun
                </span>
                <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                    <span className="data-pribadi-font title-input-desktop">
                        Tahun
                    </span>
                    <input
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                        onChange={e => handleChange('alumniTahun', e.target.value)}
                        value={sendData.alumniTahun}
                        type="text"
                        placeholder="Tahun"
                        className="data-pribadi-input"
                    />
                </div>

                {/* ACTION */}
                <div className="data-pribadi-wrapper action-wrapper-2021-margintop" >
                    {
                        (options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true) ?
                            <div className="data-pribadi-button-wripper">
                                <SaveAndOutButton className="btn-save-input2021-out" onClick={e => goToTab(1)} > Lihat Data Sebelumnya </SaveAndOutButton>
                                <SaveAndNextButton className="btn-save-input2021-next" onClick={e => goToTab(3)} > Lihat Data Selanjutnya </SaveAndNextButton>
                            </div>
                            :
                            <div className="data-pribadi-button-wripper">
                                <SaveAndOutButton className="btn-save-input2021-out" onClick={e => saving('out')} > Simpan & Keluar </SaveAndOutButton>
                                <SaveAndNextButton className="btn-save-input2021-next" onClick={e => saving('next')} > Simpan & Lanjutkan </SaveAndNextButton>
                            </div>
                    }
                </div>
            </form>
        </Fragment>
    )
})

export default InfoKeluarga