import React, { useState } from 'react'
import './styles.scss'
import BinabudayaLogo from '../../images/binabudaya.svg'
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { checkingPIN } from '../../store/actions/pin'
import Swal from 'sweetalert2'

const ActivationButton = withStyles((theme) => ({
    root: {
        marginTop: '50px',
        width: '100%',
        height: '44px',
        color: '#ffffff',
        backgroundColor: '#3598DC',
        fontFamily: ['Roboto', 'sans-serif'],
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '19px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#2f88c6',
            boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)',
        },
    },
}))(Button);

function ActivasionPin() {
    const History = useHistory()
    const Dispatch = useDispatch()
    const [pinValue, setPinValue] = useState('')
    const [click, setClick] = useState(false)

    const handleChangeValue = (key,value) => {
        switch (key) {
            case 'pin':
                setPinValue((value).toUpperCase())
                break;
            default:
                break;
        }
    }

    const handleCheck = () => {
        const ToastMid = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        setClick(true)
        if (pinValue) {
            if (pinValue.length === 16) {
                Dispatch(checkingPIN(pinValue, History, Swal))
            } else {
                ToastMid.fire({
                    icon: 'warning',
                    title: 'PIN salah'
                })
            }
        } else {
            ToastMid.fire({
                icon: 'warning',
                title: 'PIN Tidak Boleh Kosong'
            })
        }
    }

    return(
        <div className="activation-container-wrapper">
            <div className="activation-binabud-logo" >
                <img src={BinabudayaLogo} alt="binabudaya" className="logo-binabud-activation" />
            </div>
            <div className="activation-contain-text" >
                <span className="activation-font-top" >
                    Silahkan Masukan Kode PIN di Bawah ini
                </span>
                <div className="activation-font-middle-container" >
                    <span className="black-color" >
                        Belum punya PIN?
                    </span>
                    <span className="blue-color-1239888" onClick={e=>History.push('/',{ chapter: true })}>
                        Hubungi Chapter Terdekat
                    </span>
                </div>
                <div className="activation-container-card" >
                    <span className="card-font-text" >Kode PIN</span>
                    <input
                        onChange={e=>handleChangeValue('pin',e.target.value)}
                        type="text"
                        value={pinValue}
                        placeholder="Tulis Kode PIN di sini"
                        className="activation-input-theme"
                        style={{ border: (click && !pinValue) && '1px solid red' }}
                    />
                    <ActivationButton onClick={()=>handleCheck()} >Aktivasi PIN</ActivationButton>
                </div>
            </div>
        </div>
    )
}

export default ActivasionPin