import {
    SET_DATAPRIBADI,
    GET_PROFILE,
    IS_LOGOUT,
    SET_SAVE,
    SET_PASFOTO,
    SET_INFOKELUARGA,
    SET_PAGE,
    SET_RIWAYATPENDIDIKAN,
    SET_RIWAYATKEGIATAN,
    SET_KEPRIBADIAN,
    SET_PILIHANPROGRAM,
    SET_PILIHANNEGARA,
    SET_RIWAYATPERJALANAN,
    SET_MOTIVASI,
    SET_ESSAY,
    SET_REKOMENDASI,
    SET_FINALISASI,
    BERKAS_PERNYATAANSEKOLAH,
    BERKAS_IZINORANGTUA,
    BERKAS_REKOMENDASIKELUARGA,
    BERKAS_REKOMENDASISEKOLAH,
    BERKAS_REKOMENDASITEMANDEKAT,
    BERKAS_RAPORT,
    BERKAS_AKTAKELAHIRAN,
    BERKAS_SKHUNIJAZAH,
    BERKAS_SERTIFIKATDLL,
    SET_OPTIONS,
    SET_FAILSELECTION,
    SET_HASIL_SELEKSI,
    SET_INFORMATION_STATUS
} from '../constanta'

const intialState = {
    profile: null,
    checkerSave: false,
    options: null,
    gagalSeleksi: null,
    hasilSeleksi: null,
    informationStatus: ''
}

function userState(state = intialState, action) {
    switch (action.type) {
        case SET_SAVE:
            return {
                ...state, 
                checkerSave : action.action,
            }
        case SET_INFORMATION_STATUS:
            return {
                ...state, 
                informationStatus : action.information,
            }
        case SET_OPTIONS:
            return {
                ...state, 
                options : action.data,
            }
        case SET_HASIL_SELEKSI:
            return {
                ...state, 
                hasilSeleksi : action.data,
            }
        case GET_PROFILE:
            return {
                ...state, 
                profile : action.profile,
                checkerSave : false
            }
        case SET_DATAPRIBADI:
            return {
                ...state, 
                profile : { ...state.profile, dataPribadi: action.data.data, page: action.data.page },
                checkerSave : false
            }
        case SET_PASFOTO:
            return {
                ...state, 
                profile : { ...state.profile, pasFoto: action.data.data, page: action.data.page },
                checkerSave : false
            }
        case SET_INFOKELUARGA:
            return {
                ...state, 
                profile : { ...state.profile, infoKeluarga: action.data.data, page: action.data.page },
                checkerSave : false
            }
        case SET_RIWAYATPENDIDIKAN:
            return {
                ...state, 
                profile : { ...state.profile, riwayatPendidikan: action.data.data, page: action.data.page },
                checkerSave : false
            }
        case SET_RIWAYATKEGIATAN:
            return {
                ...state, 
                profile : { ...state.profile, riwayatKegiatan: action.data.data, page: action.data.page },
                checkerSave : false
            }
        case SET_KEPRIBADIAN:
            return {
                ...state, 
                profile : { ...state.profile, kepribadian: action.data.data, page: action.data.page },
                checkerSave : false
            }
        case SET_PILIHANPROGRAM:
            return {
                ...state, 
                profile : { ...state.profile, pilihanProgram: action.data.data, page: action.data.page },
                checkerSave : false
            }
        case SET_PILIHANNEGARA:
            return {
                ...state, 
                profile : { ...state.profile, pilihanNegara: action.data.data, page: action.data.page },
                checkerSave : false
            }
        case SET_RIWAYATPERJALANAN:
            return {
                ...state, 
                profile : { ...state.profile, riwayatPerjalanan: action.data.data, page: action.data.page },
                checkerSave : false
            }
        case SET_MOTIVASI:
            return {
                ...state, 
                profile : { ...state.profile, motivasi: action.data.data, page: action.data.page },
                checkerSave : false
            }
        case SET_ESSAY:
            return {
                ...state, 
                profile : { ...state.profile, essay: action.data.data, page: action.data.page },
                checkerSave : false
            }
        case SET_REKOMENDASI:
            return {
                ...state, 
                profile : { ...state.profile, rekomendasi: action.data.data, page: action.data.page },
                checkerSave : false
            }
        case SET_FINALISASI:
            return {
                ...state, 
                profile : { ...state.profile, finalisasi: action.data.data },
                checkerSave : false
            }
        case BERKAS_PERNYATAANSEKOLAH:
            return {
                ...state, 
                profile : { ...state.profile, suratPernyataanSekolah: action.data.data },
            }
        case BERKAS_IZINORANGTUA:
            return {
                ...state, 
                profile : { ...state.profile, suratIzinOrangTua: action.data.data },
            }
        case BERKAS_REKOMENDASIKELUARGA:
            return {
                ...state, 
                profile : { ...state.profile, suratRekomendasiKeluarga: action.data.data },
            }
        case BERKAS_REKOMENDASISEKOLAH:
            return {
                ...state, 
                profile : { ...state.profile, suratRekomendasiSekolah: action.data.data },
            }
        case BERKAS_REKOMENDASITEMANDEKAT:
            return {
                ...state, 
                profile : { ...state.profile, suratRekomendasiTemanDekat: action.data.data },
            }
        case BERKAS_RAPORT:
            return {
                ...state, 
                profile : { ...state.profile, raport: action.data.data },
            }
        case BERKAS_AKTAKELAHIRAN:
            return {
                ...state, 
                profile : { ...state.profile, aktaKelahiran: action.data.data },
            }
        case BERKAS_SKHUNIJAZAH:
            return {
                ...state, 
                profile : { ...state.profile, skhunIjazah: action.data.data },
            }
        case BERKAS_SERTIFIKATDLL:
            return {
                ...state, 
                profile : { ...state.profile, sertifikatDll: action.data.data },
            }
        case SET_PAGE:
            return {
                ...state, 
                profile : { ...state.profile, page: action.action },
                checkerSave : false
            }
        case SET_FAILSELECTION:
            return {
                ...state, 
                gagalSeleksi : action.action,
            }
        case IS_LOGOUT:
            return {
                ...state, 
                profile : null,
                dataPribadi: null,
                checkerSave: false,
                gagalSeleksi: null
            }
        default: {
            return state
        }
    }
}

export default userState

