import {
    GET_CHAPTER,
} from '../constanta'

const intialState = {
    chapter: [],
}

function userState(state = intialState, action) {
    switch (action.type) {
        case GET_CHAPTER:
            return {
                ...state, 
                chapter : action.data,
            }
        default: {
            return state
        }
    }
}

export default userState

