import axios from "axios";
import {} from "../constanta";
import { generateToken } from "../../helpers/bearer";

export const downloadFile = (uri, fileName, Swal) => async (dispatch) => {
  const ToastMid = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  try {
    Swal.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    const { data } = await axios({
      method: "GET",
      url: uri,
      responseType: "blob",
      headers: {
        "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
      },
      withCredentials: true
    });
    if (data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      Swal.close();
      ToastMid.fire({
        icon: "success",
        title: "Berhasil Download Berkas",
      });
    }
  } catch (err) {
    Swal.close();
  }
};
