import React, {
  useState,
  useEffect,
  forwardRef,
  Fragment,
  useImperativeHandle,
} from "react";
import "./styles.scss";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import {
  setSaveAction,
  savePilihanNegara,
} from "../../../../store/actions/peserta";

const SaveAndOutButton = withStyles((theme) => ({
  root: {
    border: "1px solid #3598DC",
    // marginTop: '50px',
    width: "200px",
    height: "44px",
    color: "#3598DC",
    backgroundColor: "#FFFFFF",
    fontFamily: ["Roboto", "sans-serif"],
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "19px",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#e5e5e5",
      boxShadow:
        "0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)",
    },
  },
}))(Button);

const SaveAndNextButton = withStyles((theme) => ({
  root: {
    // marginTop: '50px',
    width: "200px",
    height: "44px",
    color: "#ffffff",
    backgroundColor: "#3598DC",
    fontFamily: ["Roboto", "sans-serif"],
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "19px",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#2f88c6",
      boxShadow:
        "0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)",
    },
    marginLeft: 20,
  },
}))(Button);

const AddButton = withStyles((theme) => ({
  root: {
    border: "1px solid #3598DC",
    height: "44px",
    color: "#3598DC",
    backgroundColor: "#FFFFFF",
    fontFamily: ["Roboto", "sans-serif"],
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "19px",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {},
  },
}))(Button);

const PilihanNegara = forwardRef((props, ref) => {
  const { NextPage, handleLogout, goTop } = props;
  const Dispatch = useDispatch();
  const options = useSelector((state) => state.pesertaState.options);
  const profile = useSelector((state) => state.pesertaState.profile);
  const save = useSelector((state) => state.pesertaState.checkerSave);
  const negara = useSelector((state) => state.filterState.negara);
  const [click, setClick] = useState(false);
  const [max] = useState(3);
  const [sendData, setSendData] = useState([""]);

  useEffect(() => {
    if (profile) {
      if (profile.pilihanNegara || profile.program) {
        if (profile.program === "YES") {
          setSendData(["amerika serikat"]);
        } else if (profile.program === "KAKEHASHI") {
          setSendData(["jepang"]);
        } else if (profile.pilihanNegara) {
          setSendData(profile.pilihanNegara);
        }
      }

      if (!profile.pilihanNegara) {
        if (profile.program === "YES") {
          setSendData(["amerika serikat"]);
        } else if (profile.program === "KAKEHASHI") {
          setSendData(["jepang"]);
        }
      }
    }
  }, [profile && (profile.pilihanNegara || profile.program)]);

  const handleChange = (key, value, i) => {
    if (!save) Dispatch(setSaveAction(true));
    switch (key) {
      case "negara":
        let temp = [...sendData];
        temp[i] = value;
        setSendData(temp);
        break;
      default:
        break;
    }
  };

  const handleAddNegara = () => {
    if (!save) Dispatch(setSaveAction(true));
    let temp = [...sendData];
    temp.push("");
    setSendData(temp);
  };

  const handleRemoveNegara = (i) => {
    if (!save) Dispatch(setSaveAction(true));
    let temp = [...sendData];
    temp.splice(i, 1);
    setSendData(temp);
  };

  const handleFilter = (key, program, data, sendData, i, max) => {
    switch (key) {
      case "negara":
        if (program === "YES") {
          return "amerika serikat";
        } else if (program === "KAKEHASHI") {
          return "jepang";
        }
        break;
      case "pilihanNegara":
        let result = [];
        if (data) {
          data.map((a) => {
            if (sendData.indexOf(a) === i) {
              result.push(a);
            }
            if (!sendData.includes(a)) {
              result.push(a);
            }
          });
        }
        return result;
        break;
      case "addNegara":
        if (program === "AFS") {
          if (sendData.length - 1 === i && sendData.length !== max) {
            return true;
          }
        }
        break;
      default:
        break;
    }
  };

  const goToTab = (page) => {
    NextPage(page);
    goTop();
  };

  const saving = (option) => {
    const ToastMid = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    const SwalBootstrap2 = Swal.mixin({
      customClass: {
        confirmButton: "btn-finalisasi-yakin-ok",
        cancelButton: "btn-finalisasi-tidak",
      },
      buttonsStyling: false,
    });
    if (options?.[profile?.program]?.finalisasi) {
      setClick(true);
      let flag = false;
      if (sendData) {
        sendData.map((data) => {
          if (!data) {
            flag = true;
            ToastMid.fire({
              icon: "warning",
              title: `Silahkan Lengkapi Data`,
            });
            goTop();
            return;
          }
          if (flag) return;
        });
      }
      let page = profile.page;
      if (profile.page < 8) {
        page = 8;
      }
      if (!flag) {
        Dispatch(
          savePilihanNegara(
            { data: sendData, page },
            Swal,
            option,
            NextPage,
            handleLogout,
            8,
            goTop
          )
        );
      }
    } else {
      SwalBootstrap2.fire({
        title: "Pengisian Data Telah Ditutup",
        text: "",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        cancelButtonText: "",
        reverseButtons: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.value) {
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      });
    }
  };

  useImperativeHandle(ref, () => ({
    saving(option, num) {
      const ToastMid = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      const SwalBootstrap2 = Swal.mixin({
        customClass: {
          confirmButton: "btn-finalisasi-yakin-ok",
          cancelButton: "btn-finalisasi-tidak",
        },
        buttonsStyling: false,
      });
      if (options?.[profile?.program]?.finalisasi) {
        setClick(true);
        let flag = false;
        if (sendData) {
          sendData.map((data) => {
            if (!data) {
              flag = true;
              ToastMid.fire({
                icon: "warning",
                title: `Silahkan Lengkapi Data`,
              });
              goTop();
              return;
            }
            if (flag) return;
          });
        }
        let page = profile.page;
        if (profile.page < 8) {
          page = 8;
        }
        if (!flag) {
          Dispatch(
            savePilihanNegara(
              { data: sendData, page },
              Swal,
              option,
              NextPage,
              handleLogout,
              num,
              goTop
            )
          );
        }
      } else {
        SwalBootstrap2.fire({
          title: "Pengisian Data Telah Ditutup",
          text: "",
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "OK",
          cancelButtonText: "",
          reverseButtons: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.value) {
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
          }
        });
      }
    },
  }));

  return (
    <Fragment>
      {sendData?.map((data, i) => (
        <Fragment key={i}>
          {/* BREAK */}
          {i > 0 && (
            <div
              style={{
                borderTop: "2px solid #f0f0f0",
                margin: "15px auto",
                width: "70%",
              }}
            />
          )}

          {/* BAHASA YANG DIKUASAI */}
          <div className="data-pribadi-font-mobile title-input-mobile">
            {i === 0 && (
              <span>
                Tujuan Negara <b style={{ color: "red" }}> * </b>
              </span>
            )}
          </div>
          <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
            <div
              className="data-pribadi-font title-input-desktop"
              style={{
                minWidth: 150,
              }}
            >
              {i === 0 && (
                <span>
                  Tujuan Negara <b style={{ color: "red" }}> * </b>
                </span>
              )}
            </div>
            <select
              disabled={
                options?.[profile?.program]?.finalisasi === false ||
                profile?.finalisasi === true
              }
              onChange={(e) => handleChange("negara", e.target.value, i)}
              value={data}
              className="data-pribadi-input"
              style={{
                color: data ? "#666666" : "#C9C9C9",
                border: click && !data && "1px solid red",
                textTransform: "capitalize",
              }}
            >
              <option
                value=""
                style={{ color: data ? "" : "#d3d5d7" }}
                selected
              >
                Pilih Negara
              </option>
              {negara &&
                handleFilter("pilihanNegara", null, negara, sendData, i)
                  .sort()
                  .filter((a) =>
                    handleFilter("negara", profile && profile.program)
                      ? a === handleFilter("negara", profile && profile.program)
                      : a
                  )
                  .map((negaraVal, index) => (
                    <option
                      key={index}
                      selected={data === negaraVal ? true : false}
                      style={{
                        color: data === negaraVal ? "#3c98dc" : "black",
                      }}
                      value={negaraVal}
                    >
                      {negaraVal}
                    </option>
                  ))}
            </select>
          </div>

          {/* ACTION */}
          {!(
            options?.[profile?.program]?.finalisasi === false &&
            profile?.finalisasi === true
          ) && (
            <div
              className="data-pribadi-wrapper"
              style={{
                marginTop: i === 0 ? sendData.length <= 1 && "20px" : "20px",
              }}
            >
              <span
                className="data-pribadi-font title-input-desktop"
                style={{
                  minWidth: 150,
                }}
              />
              <div className="btn-saudara-wrap">
                {handleFilter(
                  "addNegara",
                  profile && profile.program,
                  null,
                  sendData,
                  i,
                  max
                ) && (
                  <AddButton
                    onClick={() => handleAddNegara()}
                    className="btn-add-sma"
                  >
                    <span className="btn-add-sma-txt">Tambah Negara</span>
                  </AddButton>
                )}
                {sendData.length > 1 && i !== 0 && (
                  <AddButton
                    onClick={() => handleRemoveNegara(i)}
                    className="btn-delete-saudara"
                  >
                    Hapus
                  </AddButton>
                )}
              </div>
            </div>
          )}
        </Fragment>
      ))}

      <div className="data-pribadi-wrapper action-wrapper-2021-margintop">
        {options?.[profile?.program]?.finalisasi === false ||
        profile?.finalisasi === true ? (
          <div className="data-pribadi-button-wripper">
            <SaveAndOutButton
              className="btn-save-input2021-out"
              onClick={(e) => goToTab(6)}
            >
              {" "}
              Lihat Data Sebelumnya{" "}
            </SaveAndOutButton>
            <SaveAndNextButton
              className="btn-save-input2021-next"
              onClick={(e) => goToTab(8)}
            >
              {" "}
              Lihat Data Selanjutnya{" "}
            </SaveAndNextButton>
          </div>
        ) : (
          <div className="data-pribadi-button-wripper">
            <SaveAndOutButton
              className="btn-save-input2021-out"
              onClick={(e) => saving("out")}
            >
              {" "}
              Simpan & Keluar{" "}
            </SaveAndOutButton>
            <SaveAndNextButton
              className="btn-save-input2021-next"
              onClick={(e) => saving("next")}
            >
              {" "}
              Simpan & Lanjutkan{" "}
            </SaveAndNextButton>
          </div>
        )}
      </div>
    </Fragment>
  );
});

export default PilihanNegara;
