import {} from "../constanta";

const intialState = {
  score: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78,
    79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97,
    98, 99, 100,
  ],
  negara: [
    "Argentina",
    "belgia flanders",
    "belgia prancis",
    "brazil",
    "republik tiongkok (china)",
    "republic ceko",
    "denmark",
    "spanyol",
    "finlandia",
    "jerman",
    "hongaria",
    "india",
    "italia",
    "jepang",
    "meksiko",
    "belanda",
    "norwegia",
    "filipina",
    "polandia",
    "rusia",
    "swiss",
    "thailand",
    "turki",
    "amerika serikat",
  ],
  optionsProgram: ["Website", "Media Sosial", "Teman", "Kerabat", "Lainnya"],
  essay: {
    afs: [
      "Jika saat ini Anda punya satu kesempatan untuk berbincang dengan satu orang tokoh yang terkenal, baik masih hidup atau sudah meninggal dunia, dengan siapa Anda ingin berbincang? Apa yang ingin dibicarakan dan didiskusikan?",
      "Describe the present-day Earth from an alien’s point of view.",
    ],
    yes: [
      "Jika saat ini Anda punya satu kesempatan untuk membuat aturan dan peraturan untuk koloni manusia pertama di Planet Mars, aturan dan peraturan apa saja yang akan Anda buat tetapkan? Kenapa aturan dan peraturan tersebut Anda tetapkan?",
      "Choose a fictional character and explain why they represents you the best.",
    ],
    kakehashi: [""],
  },
  berkas: {
    YES: {
      SuratPenyataanSekolah: {
        link: "/public/document/Surat%20Pernyataan%20Sekolah%20(YES).pdf",
        fileName: "Surat Pernyataan Sekolah (YES).pdf",
      },
      SuratIzinOrangTua: {
        link: "/public/document/Surat%20Pernyataan%20Orang%20Tua%20(YES).pdf",
        fileName: "Surat Pernyataan Orang Tua (YES).pdf",
      },
      SuratRekomendasiLingkunganRumah: {
        link: "/public/document/Surat%20Rekomendasi%20Lingkungan%20Rumah%20(YES).pdf",
        fileName: "Surat Rekomendasi Lingkungan Rumah (YES).pdf",
      },
      SuratRekomendasiLingkunganSekolah: {
        link: "/public/document/Surat%20Rekomendasi%20Lingkungan%20Sekolah%20(YES).pdf",
        fileName: "Surat Rekomendasi Lingkungan Sekolah (YES).pdf",
      },
      SuratRekomendasiTemanDekat: {
        link: "/public/document/Surat%20Rekomendasi%20Teman%20Dekat%20(YES).pdf",
        fileName: "Surat Rekomendasi Teman Dekat (YES).pdf",
      },
      Kepribadian: {
        link: "/public/document/KEPRIBADIAN%20(YES).pdf",
        fileName: "KEPRIBADIAN (YES).pdf",
      },
      Motivasi: {
        link: "/public/document/MOTIVASI%20(YES).pdf",
        fileName: "MOTIVASI (YES).pdf",
      },
      Essay: {
        link: "/public/document/ESSAY%20(YES).pdf",
        fileName: "ESSAY (YES).pdf",
      },
    },
    KAKEHASHI: {
      SuratPenyataanSekolah: {
        link: "/public/document/Surat%20Pernyataan%20Sekolah%20(Kakehashi).pdf",
        fileName: "Surat Pernyataan Sekolah (KAKEHASHI).pdf",
      },
      SuratIzinOrangTua: {
        link: "/public/document/Surat%20Izin%20Orang%20Tua%20(Kakehashi).pdf",
        fileName: "Surat Izin Orang Tua (KAKEHASHI).pdf",
      },
      SuratRekomendasiLingkunganRumah: {
        link: "/public/document/Surat%20Rekomendasi%20Lingkungan%20Rumah%20(Kakehashi).pdf",
        fileName: "Surat Rekomendasi Lingkungan Rumah (KAKEHASHI).pdf",
      },
      SuratRekomendasiLingkunganSekolah: {
        link: "/public/document/Surat%20Rekomendasi%20Lingkungan%20Sekolah%20(Kakehashi).pdf",
        fileName: "Surat Rekomendasi Lingkungan Sekolah (KAKEHASHI).pdf",
      },
      SuratRekomendasiTemanDekat: {
        link: "/public/document/Surat%20Rekomendasi%20Teman%20Dekat%20(Kakehashi).pdf",
        fileName: "Surat Rekomendasi Teman Dekat (KAKEHASHI).pdf",
      },
      Kepribadian: {
        link: "/public/document/KEPRIBADIAN%20(KAKEHASHI).pdf",
        fileName: "KEPRIBADIAN (KAKEHASHI).pdf",
      },
      Motivasi: {
        link: "/public/document/MOTIVASI%20(KAKEHASHI).pdf",
        fileName: "MOTIVASI (KAKEHASHI).pdf",
      },
      Essay: {
        link: "/public/document/ESSAY%20(KAKEHASHI).pdf",
        fileName: "ESSAY (KAKEHASHI).pdf",
      },
    },
    AFS: {
      SuratPenyataanSekolah: {
        link: "/public/document/Surat%20Pernyataan%20Sekolah%20(AFS).pdf",
        fileName: "Surat Pernyataan Sekolah (AFS).pdf",
      },
      SuratIzinOrangTua: {
        link: "/public/document/Surat%20Pernyataan%20Orang%20Tua%20(AFS).pdf",
        fileName: "Surat Pernyataan Orang Tua (AFS).pdf",
      },
      SuratRekomendasiLingkunganRumah: {
        link: "/public/document/Surat%20Rekomendasi%20Lingkungan%20Rumah%20(AFS).pdf",
        fileName: "Surat Rekomendasi Lingkungan Rumah (AFS).pdf",
      },
      SuratRekomendasiLingkunganSekolah: {
        link: "/public/document/Surat%20Rekomendasi%20Lingkungan%20Sekolah%20(AFS).pdf",
        fileName: "Surat Rekomendasi Lingkungan Sekolah (AFS).pdf",
      },
      SuratRekomendasiTemanDekat: {
        link: "/public/document/Surat%20Rekomendasi%20Teman%20Dekat%20(AFS).pdf",
        fileName: "Surat Rekomendasi Teman Dekat (AFS).pdf",
      },
      Kepribadian: {
        link: "/public/document/KEPRIBADIAN%20(AFS).pdf",
        fileName: "KEPRIBADIAN (AFS).pdf",
      },
      Motivasi: {
        link: "/public/document/MOTIVASI%20(AFS).pdf",
        fileName: "MOTIVASI (AFS).pdf",
      },
      Essay: {
        link: "/public/document/ESSAY%20(AFS).pdf",
        fileName: "ESSAY (AFS).pdf",
      },
    },
  },
  information: [
    {
      title: "Input Data",
      description: [
        "Data Pribadi",
        "Pas Foto",
        "Info Keluarga",
        "Riwayat Pendidikan",
        "Riwayat Kegiatan & Prestasi",
        "Kepribadian",
        "Pilihan Program",
        "Pilihan Negara",
        "Riwayat Perjalanan",
        "Motivasi",
        "Essay",
        "Rekomendasi",
      ],
    },
    {
      title: "Upload Berkas",
      description: [
        "Surat Pernyataan Sekolah",
        "Surat Pernyataan Orang Tua",
        "Surat Rekomendasi Lingkungan Rumah",
        "Surat Rekomendasi Sekolah",
        "Surat Rekomendasi Teman Dekat",
        "Raport",
        "Akta Kelahiran",
        "SKHUN & Ijazah SMP",
        "Sertifikat dan lain-lain",
      ],
    },
    {
      title: "Finalisasi",
      description: [
        "Input Data dikirim untuk diperiksa dan diverifikasi oleh Chapter yang adik pilih",
      ],
    },
    {
      title: "Verifikasi Berkas",
      description: [
        "Berkas Telah Diperiksa Oleh Admin Chapter",
        "Nomor Peserta diberikan",
      ],
    },
    {
      title: "Peserta Tahap 1",
      description: ["Berkas Diseleksi Kembali untuk ditentukan Lulus/Tidak"],
    },
    {
      title: "Peserta Tahap 2",
      description: ["Tahap Wawancara Kepribadian dan Bahasa Inggris"],
    },
    {
      title: "Peserta Tahap 3",
      description: ["Tahap Interaksi Kelompok"],
    },
    {
      title: "Lulus Seleksi",
      description: ["Dinyatakan Lulus oleh Chapter"],
    },
  ],
};

function FilterState(state = intialState, action) {
  switch (action.type) {
    default: {
      return state;
    }
  }
}

export default FilterState;
