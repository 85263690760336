import React, { Fragment, useEffect } from "react";
import "./styles.scss";
import { withStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import UploadIcon from "../../../images/berkas/upload.svg";
import PDFIcon from "../../../images/pdf.svg";
import Swal from "sweetalert2";
import { berkasAction } from "../../../store/actions/peserta";
import {
  BERKAS_PERNYATAANSEKOLAH,
  BERKAS_IZINORANGTUA,
  BERKAS_REKOMENDASIKELUARGA,
  BERKAS_REKOMENDASISEKOLAH,
  BERKAS_REKOMENDASITEMANDEKAT,
  BERKAS_RAPORT,
  BERKAS_AKTAKELAHIRAN,
  BERKAS_SKHUNIJAZAH,
  BERKAS_SERTIFIKATDLL,
} from "../../../store/constanta";
import { useHistory, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const HapusButton = withStyles((theme) => ({
  root: {
    marginLeft: 10,
    zIndex: 99,
    width: "120px",
    height: "100%",
    border: "1px solid #3598DC",
    color: "#3598DC",
    backgroundColor: "white",
    fontFamily: ["Roboto", "sans-serif"],
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "19px",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {},
  },
}))(Button);

const DownloadButton = withStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    color: "#FFFFFF",
    backgroundColor: "#3598DC",
    fontFamily: ["Roboto", "sans-serif"],
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "19px",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#2f88c6",
      // boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)',
    },
  },
}))(Button);

function UploadBerkas() {
  const Dispatch = useDispatch();
  const History = useHistory();
  const options = useSelector((state) => state.pesertaState.options);
  const profile = useSelector((state) => state.pesertaState.profile);

  const handleUpload = (key, file, method, type) => {
    let value;
    if (file) value = file.target.files[0];
    let data = new FormData();
    data.append("file", value);
    data.set("tanggal", new Date());
    Dispatch(berkasAction(data, Swal, key, method, type));
    if (document.getElementById(key)) {
      document.getElementById(key).value = null;
    }
  };

  const getNameFile = (file) => {
    let name = "-";
    if (file) {
      name = file.split("-FILE-")[1];
    }
    return name;
  };

  const getSizeFile = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
  };

  useEffect(() => {
    const SwalBootstrap1 = Swal.mixin({
      customClass: {
        confirmButton: "btn-finalisasi-yakin",
        cancelButton: "btn-finalisasi-tidak",
      },
      buttonsStyling: false,
    });
    if (options?.[profile?.program]?.finalisasi === false) {
      SwalBootstrap1.fire({
        title: "Upload Berkas Telah Ditutup",
        text: "",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        cancelButtonText: "",
        reverseButtons: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.value) {
          History.replace(`/informasi`);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
    }
  }, [options]);

  return (
    <div className="input-data-container">
      <span className="input-data-header">Upload Berkas</span>
      <div className="bread-cab-container">
        <span className="bread-grey">Upload Berkas /</span>
      </div>
      <div className="input-container-2021">
        <div className="data-pribadi-header">
          Upload Berkas ( BERFORMAT PDF )
        </div>
        <div className="document-wrapper-2021">
          {/* Surat Pernyataan Sekolah */}
          <div className="box-white-upload-berkas">
            <div className="box-white-blue-upload-berkas">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: profile?.suratPernyataanSekolah?.file && "100%",
                }}
              >
                <img
                  src={
                    profile?.suratPernyataanSekolah?.file ? PDFIcon : UploadIcon
                  }
                  alt="icon"
                  className="icon-download"
                  draggable={false}
                />
                {profile?.suratPernyataanSekolah?.file && (
                  <Fragment>
                    <div className="box-white-file-name">
                      {getNameFile(profile?.suratPernyataanSekolah?.file)}
                    </div>
                    <div className="box-white-size-file">
                      {getSizeFile(profile?.suratPernyataanSekolah?.size)}
                    </div>
                  </Fragment>
                )}
              </div>
              <span
                className="box-white-blue-download-berkas-text-dwd"
                style={{
                  fontSize: profile?.suratPernyataanSekolah?.file && 15,
                  width: "100%",
                  marginTop: profile?.suratPernyataanSekolah?.file && "auto",
                }}
              >
                Surat Pernyataan Sekolah
              </span>
            </div>
            {!(
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            ) && (
              <Fragment>
                <div
                  className="upload-image"
                  style={{
                    width: "100%",
                    height: "50px",
                    border: "none",
                    backgroundColor: "white",
                    marginTop: "auto",
                    display: "flex",
                  }}
                >
                  <input
                    type="file"
                    id="pernyataanSekolah"
                    className="upload-image__file"
                    style={{
                      marginLeft: 0,
                    }}
                    accept=".pdf"
                    onChange={(e) =>
                      handleUpload(
                        "pernyataanSekolah",
                        e,
                        "PATCH",
                        BERKAS_PERNYATAANSEKOLAH
                      )
                    }
                  />
                  <div
                    className="image-upload-content"
                    style={{ width: "100%" }}
                  >
                    <DownloadButton>
                      {profile?.suratPernyataanSekolah?.file
                        ? "Ganti File"
                        : "Pilih File"}
                    </DownloadButton>
                  </div>
                  {profile?.suratPernyataanSekolah?.file && (
                    <HapusButton
                      onClick={(e) =>
                        handleUpload(
                          "pernyataanSekolah",
                          null,
                          "DELETE",
                          BERKAS_PERNYATAANSEKOLAH
                        )
                      }
                    >
                      Hapus
                    </HapusButton>
                  )}
                </div>
                {profile?.suratPernyataanSekolah?.file && (
                  <div
                    className="upload-image"
                    style={{
                      width: "100%",
                      height: "50px",
                      border: "none",
                      backgroundColor: "white",
                      marginTop: "10px",
                      display: "flex",
                    }}
                  >
                    <Link
                      to={{ pathname: profile?.suratPernyataanSekolah?.file }}
                      target="_BLANK"
                      style={{
                        textDecoration: "none",
                        marginLeft: "auto",
                        cursor: "pointer",
                        width: "100%",
                      }}
                    >
                      <DownloadButton>Lihat</DownloadButton>
                    </Link>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ color: "#f44336", fontSize: 17, marginRight: 5 }}
                  />
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Maksimal 2MB
                  </span>
                </div>
              </Fragment>
            )}
            {profile?.finalisasi === true && (
              <div
                className="upload-image"
                style={{
                  width: "100%",
                  height: "50px",
                  border: "none",
                  backgroundColor: "white",
                  marginTop: "auto",
                  display: "flex",
                }}
              >
                <Link
                  to={{ pathname: profile?.suratPernyataanSekolah?.file }}
                  target="_BLANK"
                  style={{
                    textDecoration: "none",
                    marginLeft: "auto",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  <DownloadButton>Lihat</DownloadButton>
                </Link>
              </div>
            )}
          </div>

          {/* Surat Izin Orang Tua */}
          <div className="box-white-upload-berkas">
            <div className="box-white-blue-upload-berkas">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: profile?.suratIzinOrangTua?.file && "100%",
                }}
              >
                <img
                  src={profile?.suratIzinOrangTua?.file ? PDFIcon : UploadIcon}
                  alt="icon"
                  className="icon-download"
                  draggable={false}
                />
                {profile?.suratIzinOrangTua?.file && (
                  <Fragment>
                    <div className="box-white-file-name">
                      {getNameFile(profile?.suratIzinOrangTua?.file)}
                    </div>
                    <div className="box-white-size-file">
                      {getSizeFile(profile?.suratIzinOrangTua?.size)}
                    </div>
                  </Fragment>
                )}
              </div>
              <span
                className="box-white-blue-download-berkas-text-dwd"
                style={{
                  fontSize: profile?.suratIzinOrangTua?.file && 15,
                  width: "100%",
                  marginTop: profile?.suratIzinOrangTua?.file && "auto",
                }}
              >
                Surat Izin Orang Tua
              </span>
            </div>
            {!(
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            ) && (
              <Fragment>
                <div
                  className="upload-image"
                  style={{
                    width: "100%",
                    height: "50px",
                    border: "none",
                    backgroundColor: "white",
                    marginTop: "auto",
                    display: "flex",
                  }}
                >
                  <input
                    id="izinOrangTua"
                    type="file"
                    className="upload-image__file"
                    style={{
                      marginLeft: 0,
                    }}
                    accept=".pdf"
                    onChange={(e) =>
                      handleUpload(
                        "izinOrangTua",
                        e,
                        "PATCH",
                        BERKAS_IZINORANGTUA
                      )
                    }
                  />
                  <div
                    className="image-upload-content"
                    style={{ width: "100%" }}
                  >
                    <DownloadButton>
                      {profile?.suratIzinOrangTua?.file
                        ? "Ganti File"
                        : "Pilih File"}
                    </DownloadButton>
                  </div>
                  {profile?.suratIzinOrangTua?.file && (
                    <HapusButton
                      onClick={(e) =>
                        handleUpload(
                          "izinOrangTua",
                          null,
                          "DELETE",
                          BERKAS_IZINORANGTUA
                        )
                      }
                    >
                      Hapus
                    </HapusButton>
                  )}
                </div>
                {profile?.suratIzinOrangTua?.file && (
                  <div
                    className="upload-image"
                    style={{
                      width: "100%",
                      height: "50px",
                      border: "none",
                      backgroundColor: "white",
                      marginTop: "10px",
                      display: "flex",
                    }}
                  >
                    <Link
                      to={{ pathname: profile?.suratIzinOrangTua?.file }}
                      target="_BLANK"
                      style={{
                        textDecoration: "none",
                        marginLeft: "auto",
                        cursor: "pointer",
                        width: "100%",
                      }}
                    >
                      <DownloadButton>Lihat</DownloadButton>
                    </Link>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ color: "#f44336", fontSize: 17, marginRight: 5 }}
                  />
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Maksimal 2MB
                  </span>
                </div>
              </Fragment>
            )}
            {profile?.finalisasi === true && (
              <div
                className="upload-image"
                style={{
                  width: "100%",
                  height: "50px",
                  border: "none",
                  backgroundColor: "white",
                  marginTop: "auto",
                  display: "flex",
                }}
              >
                <Link
                  to={{ pathname: profile?.suratIzinOrangTua?.file }}
                  target="_BLANK"
                  style={{
                    textDecoration: "none",
                    marginLeft: "auto",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  <DownloadButton>Lihat</DownloadButton>
                </Link>
              </div>
            )}
          </div>

          {/* Surat Rekomendasi Keluarga */}
          <div className="box-white-upload-berkas">
            <div className="box-white-blue-upload-berkas">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: profile?.suratRekomendasiKeluarga?.file && "100%",
                }}
              >
                <img
                  src={
                    profile?.suratRekomendasiKeluarga?.file
                      ? PDFIcon
                      : UploadIcon
                  }
                  alt="icon"
                  className="icon-download"
                  draggable={false}
                />
                {profile?.suratRekomendasiKeluarga?.file && (
                  <Fragment>
                    <div className="box-white-file-name">
                      {getNameFile(profile?.suratRekomendasiKeluarga?.file)}
                    </div>
                    <div className="box-white-size-file">
                      {getSizeFile(profile?.suratRekomendasiKeluarga?.size)}
                    </div>
                  </Fragment>
                )}
              </div>
              <span
                className="box-white-blue-download-berkas-text-dwd"
                style={{
                  fontSize: profile?.suratRekomendasiKeluarga?.file && 15,
                  width: "100%",
                  marginTop: profile?.suratRekomendasiKeluarga?.file && "auto",
                }}
              >
                Surat Rekomendasi Lingkungan Rumah
              </span>
            </div>
            {!(
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            ) && (
              <Fragment>
                <div
                  className="upload-image"
                  style={{
                    width: "100%",
                    height: "50px",
                    border: "none",
                    backgroundColor: "white",
                    marginTop: "auto",
                    display: "flex",
                  }}
                >
                  <input
                    id="rekomendasiKeluarga"
                    type="file"
                    className="upload-image__file"
                    style={{
                      marginLeft: 0,
                    }}
                    accept=".pdf"
                    onChange={(e) =>
                      handleUpload(
                        "rekomendasiKeluarga",
                        e,
                        "PATCH",
                        BERKAS_REKOMENDASIKELUARGA
                      )
                    }
                  />
                  <div
                    className="image-upload-content"
                    style={{ width: "100%" }}
                  >
                    <DownloadButton>
                      {profile?.suratRekomendasiKeluarga?.file
                        ? "Ganti File"
                        : "Pilih File"}
                    </DownloadButton>
                  </div>
                  {profile?.suratRekomendasiKeluarga?.file && (
                    <HapusButton
                      onClick={(e) =>
                        handleUpload(
                          "rekomendasiKeluarga",
                          null,
                          "DELETE",
                          BERKAS_REKOMENDASIKELUARGA
                        )
                      }
                    >
                      Hapus
                    </HapusButton>
                  )}
                </div>
                {profile?.suratRekomendasiKeluarga?.file && (
                  <div
                    className="upload-image"
                    style={{
                      width: "100%",
                      height: "50px",
                      border: "none",
                      backgroundColor: "white",
                      marginTop: "10px",
                      display: "flex",
                    }}
                  >
                    <Link
                      to={{ pathname: profile?.suratRekomendasiKeluarga?.file }}
                      target="_BLANK"
                      style={{
                        textDecoration: "none",
                        marginLeft: "auto",
                        cursor: "pointer",
                        width: "100%",
                      }}
                    >
                      <DownloadButton>Lihat</DownloadButton>
                    </Link>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ color: "#f44336", fontSize: 17, marginRight: 5 }}
                  />
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Maksimal 2MB
                  </span>
                </div>
              </Fragment>
            )}
            {profile?.finalisasi === true && (
              <div
                className="upload-image"
                style={{
                  width: "100%",
                  height: "50px",
                  border: "none",
                  backgroundColor: "white",
                  marginTop: "auto",
                  display: "flex",
                }}
              >
                <Link
                  to={{ pathname: profile?.suratRekomendasiKeluarga?.file }}
                  target="_BLANK"
                  style={{
                    textDecoration: "none",
                    marginLeft: "auto",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  <DownloadButton>Lihat</DownloadButton>
                </Link>
              </div>
            )}
          </div>

          {/* Surat Rekomendasi Sekolah */}
          <div className="box-white-upload-berkas">
            <div className="box-white-blue-upload-berkas">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: profile?.suratRekomendasiSekolah?.file && "100%",
                }}
              >
                <img
                  src={
                    profile?.suratRekomendasiSekolah?.file
                      ? PDFIcon
                      : UploadIcon
                  }
                  alt="icon"
                  className="icon-download"
                  draggable={false}
                />
                {profile?.suratRekomendasiSekolah?.file && (
                  <Fragment>
                    <div className="box-white-file-name">
                      {getNameFile(profile?.suratRekomendasiSekolah?.file)}
                    </div>
                    <div className="box-white-size-file">
                      {getSizeFile(profile?.suratRekomendasiSekolah?.size)}
                    </div>
                  </Fragment>
                )}
              </div>
              <span
                className="box-white-blue-download-berkas-text-dwd"
                style={{
                  fontSize: profile?.suratRekomendasiSekolah?.file && 15,
                  width: "100%",
                  marginTop: profile?.suratRekomendasiSekolah?.file && "auto",
                }}
              >
                Surat Rekomendasi Lingkungan Sekolah
              </span>
            </div>
            {!(
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            ) && (
              <Fragment>
                <div
                  className="upload-image"
                  style={{
                    width: "100%",
                    height: "50px",
                    border: "none",
                    backgroundColor: "white",
                    marginTop: "auto",
                    display: "flex",
                  }}
                >
                  <input
                    id="rekomendasiSekolah"
                    type="file"
                    className="upload-image__file"
                    style={{
                      marginLeft: 0,
                    }}
                    accept=".pdf"
                    onChange={(e) =>
                      handleUpload(
                        "rekomendasiSekolah",
                        e,
                        "PATCH",
                        BERKAS_REKOMENDASISEKOLAH
                      )
                    }
                  />
                  <div
                    className="image-upload-content"
                    style={{ width: "100%" }}
                  >
                    <DownloadButton>
                      {profile?.suratRekomendasiSekolah?.file
                        ? "Ganti File"
                        : "Pilih File"}
                    </DownloadButton>
                  </div>
                  {profile?.suratRekomendasiSekolah?.file && (
                    <HapusButton
                      onClick={(e) =>
                        handleUpload(
                          "rekomendasiSekolah",
                          null,
                          "DELETE",
                          BERKAS_REKOMENDASISEKOLAH
                        )
                      }
                    >
                      Hapus
                    </HapusButton>
                  )}
                </div>
                {profile?.suratRekomendasiSekolah?.file && (
                  <div
                    className="upload-image"
                    style={{
                      width: "100%",
                      height: "50px",
                      border: "none",
                      backgroundColor: "white",
                      marginTop: "10px",
                      display: "flex",
                    }}
                  >
                    <Link
                      to={{ pathname: profile?.suratRekomendasiSekolah?.file }}
                      target="_BLANK"
                      style={{
                        textDecoration: "none",
                        marginLeft: "auto",
                        cursor: "pointer",
                        width: "100%",
                      }}
                    >
                      <DownloadButton>Lihat</DownloadButton>
                    </Link>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ color: "#f44336", fontSize: 17, marginRight: 5 }}
                  />
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Maksimal 2MB
                  </span>
                </div>
              </Fragment>
            )}
            {profile?.finalisasi === true && (
              <div
                className="upload-image"
                style={{
                  width: "100%",
                  height: "50px",
                  border: "none",
                  backgroundColor: "white",
                  marginTop: "auto",
                  display: "flex",
                }}
              >
                <Link
                  to={{ pathname: profile?.suratRekomendasiSekolah?.file }}
                  target="_BLANK"
                  style={{
                    textDecoration: "none",
                    marginLeft: "auto",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  <DownloadButton>Lihat</DownloadButton>
                </Link>
              </div>
            )}
          </div>

          {/* Surat Rekomendasi Teman Dekat */}
          <div className="box-white-upload-berkas">
            <div className="box-white-blue-upload-berkas">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: profile?.suratRekomendasiTemanDekat?.file && "100%",
                }}
              >
                <img
                  src={
                    profile?.suratRekomendasiTemanDekat?.file
                      ? PDFIcon
                      : UploadIcon
                  }
                  alt="icon"
                  className="icon-download"
                  draggable={false}
                />
                {profile?.suratRekomendasiTemanDekat?.file && (
                  <Fragment>
                    <div className="box-white-file-name">
                      {getNameFile(profile?.suratRekomendasiTemanDekat?.file)}
                    </div>
                    <div className="box-white-size-file">
                      {getSizeFile(profile?.suratRekomendasiTemanDekat?.size)}
                    </div>
                  </Fragment>
                )}
              </div>
              <span
                className="box-white-blue-download-berkas-text-dwd"
                style={{
                  fontSize: profile?.suratRekomendasiTemanDekat?.file && 15,
                  width: "100%",
                  marginTop:
                    profile?.suratRekomendasiTemanDekat?.file && "auto",
                }}
              >
                Surat Rekomendasi Teman Dekat
              </span>
            </div>
            {!(
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            ) && (
              <Fragment>
                <div
                  className="upload-image"
                  style={{
                    width: "100%",
                    height: "50px",
                    border: "none",
                    backgroundColor: "white",
                    marginTop: "auto",
                    display: "flex",
                  }}
                >
                  <input
                    id="rekomendasiTemanDekat"
                    type="file"
                    className="upload-image__file"
                    style={{
                      marginLeft: 0,
                    }}
                    accept=".pdf"
                    onChange={(e) =>
                      handleUpload(
                        "rekomendasiTemanDekat",
                        e,
                        "PATCH",
                        BERKAS_REKOMENDASITEMANDEKAT
                      )
                    }
                  />
                  <div
                    className="image-upload-content"
                    style={{ width: "100%" }}
                  >
                    <DownloadButton>
                      {profile?.suratRekomendasiTemanDekat?.file
                        ? "Ganti File"
                        : "Pilih File"}
                    </DownloadButton>
                  </div>
                  {profile?.suratRekomendasiTemanDekat?.file && (
                    <HapusButton
                      onClick={(e) =>
                        handleUpload(
                          "rekomendasiTemanDekat",
                          null,
                          "DELETE",
                          BERKAS_REKOMENDASITEMANDEKAT
                        )
                      }
                    >
                      Hapus
                    </HapusButton>
                  )}
                </div>
                {profile?.suratRekomendasiTemanDekat?.file && (
                  <div
                    className="upload-image"
                    style={{
                      width: "100%",
                      height: "50px",
                      border: "none",
                      backgroundColor: "white",
                      marginTop: "10px",
                      display: "flex",
                    }}
                  >
                    <Link
                      to={{
                        pathname: profile?.suratRekomendasiTemanDekat?.file,
                      }}
                      target="_BLANK"
                      style={{
                        textDecoration: "none",
                        marginLeft: "auto",
                        cursor: "pointer",
                        width: "100%",
                      }}
                    >
                      <DownloadButton>Lihat</DownloadButton>
                    </Link>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ color: "#f44336", fontSize: 17, marginRight: 5 }}
                  />
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Maksimal 2MB
                  </span>
                </div>
              </Fragment>
            )}
            {profile?.finalisasi === true && (
              <div
                className="upload-image"
                style={{
                  width: "100%",
                  height: "50px",
                  border: "none",
                  backgroundColor: "white",
                  marginTop: "auto",
                  display: "flex",
                }}
              >
                <Link
                  to={{ pathname: profile?.suratRekomendasiTemanDekat?.file }}
                  target="_BLANK"
                  style={{
                    textDecoration: "none",
                    marginLeft: "auto",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  <DownloadButton>Lihat</DownloadButton>
                </Link>
              </div>
            )}
          </div>

          {/* Raport */}
          <div className="box-white-upload-berkas">
            <div className="box-white-blue-upload-berkas">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: profile?.raport?.file && "100%",
                }}
              >
                <img
                  src={profile?.raport?.file ? PDFIcon : UploadIcon}
                  alt="icon"
                  className="icon-download"
                  draggable={false}
                />
                {profile?.raport?.file && (
                  <Fragment>
                    <div className="box-white-file-name">
                      {getNameFile(profile?.raport?.file)}
                    </div>
                    <div className="box-white-size-file">
                      {getSizeFile(profile?.raport?.size)}
                    </div>
                  </Fragment>
                )}
              </div>
              <span
                className="box-white-blue-download-berkas-text-dwd"
                style={{
                  fontSize: profile?.raport?.file && 15,
                  width: "100%",
                  marginTop: profile?.raport?.file && "auto",
                }}
              >
                Raport
              </span>
            </div>
            {!(
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            ) && (
              <Fragment>
                <div
                  className="upload-image"
                  style={{
                    width: "100%",
                    height: "50px",
                    border: "none",
                    backgroundColor: "white",
                    marginTop: "auto",
                    display: "flex",
                  }}
                >
                  <input
                    id="raport"
                    type="file"
                    className="upload-image__file"
                    style={{
                      marginLeft: 0,
                    }}
                    accept=".pdf"
                    onChange={(e) =>
                      handleUpload("raport", e, "PATCH", BERKAS_RAPORT)
                    }
                  />
                  <div
                    className="image-upload-content"
                    style={{ width: "100%" }}
                  >
                    <DownloadButton>
                      {profile?.raport?.file ? "Ganti File" : "Pilih File"}
                    </DownloadButton>
                  </div>
                  {profile?.raport?.file && (
                    <HapusButton
                      onClick={(e) =>
                        handleUpload("raport", null, "DELETE", BERKAS_RAPORT)
                      }
                    >
                      Hapus
                    </HapusButton>
                  )}
                </div>
                {profile?.raport?.file && (
                  <div
                    className="upload-image"
                    style={{
                      width: "100%",
                      height: "50px",
                      border: "none",
                      backgroundColor: "white",
                      marginTop: "10px",
                      display: "flex",
                    }}
                  >
                    <Link
                      to={{ pathname: profile?.raport?.file }}
                      target="_BLANK"
                      style={{
                        textDecoration: "none",
                        marginLeft: "auto",
                        cursor: "pointer",
                        width: "100%",
                      }}
                    >
                      <DownloadButton>Lihat</DownloadButton>
                    </Link>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ color: "#f44336", fontSize: 17, marginRight: 5 }}
                  />
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Maksimal 10MB
                  </span>
                </div>
              </Fragment>
            )}
            {profile?.finalisasi === true && (
              <div
                className="upload-image"
                style={{
                  width: "100%",
                  height: "50px",
                  border: "none",
                  backgroundColor: "white",
                  marginTop: "auto",
                  display: "flex",
                }}
              >
                <Link
                  to={{ pathname: profile?.raport?.file }}
                  target="_BLANK"
                  style={{
                    textDecoration: "none",
                    marginLeft: "auto",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  <DownloadButton>Lihat</DownloadButton>
                </Link>
              </div>
            )}
          </div>

          {/* Akta Kelahiran */}
          <div className="box-white-upload-berkas">
            <div className="box-white-blue-upload-berkas">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: profile?.aktaKelahiran?.file && "100%",
                }}
              >
                <img
                  src={profile?.aktaKelahiran?.file ? PDFIcon : UploadIcon}
                  alt="icon"
                  className="icon-download"
                  draggable={false}
                />
                {profile?.aktaKelahiran?.file && (
                  <Fragment>
                    <div className="box-white-file-name">
                      {getNameFile(profile?.aktaKelahiran?.file)}
                    </div>
                    <div className="box-white-size-file">
                      {getSizeFile(profile?.aktaKelahiran?.size)}
                    </div>
                  </Fragment>
                )}
              </div>
              <span
                className="box-white-blue-download-berkas-text-dwd"
                style={{
                  fontSize: profile?.aktaKelahiran?.file && 15,
                  width: "100%",
                  marginTop: profile?.aktaKelahiran?.file && "auto",
                }}
              >
                Akta Kelahiran
              </span>
            </div>
            {!(
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            ) && (
              <Fragment>
                <div
                  className="upload-image"
                  style={{
                    width: "100%",
                    height: "50px",
                    border: "none",
                    backgroundColor: "white",
                    marginTop: "auto",
                    display: "flex",
                  }}
                >
                  <input
                    id="aktaKelahiran"
                    type="file"
                    className="upload-image__file"
                    style={{
                      marginLeft: 0,
                    }}
                    accept=".pdf"
                    onChange={(e) =>
                      handleUpload(
                        "aktaKelahiran",
                        e,
                        "PATCH",
                        BERKAS_AKTAKELAHIRAN
                      )
                    }
                  />
                  <div
                    className="image-upload-content"
                    style={{ width: "100%" }}
                  >
                    <DownloadButton>
                      {profile?.aktaKelahiran?.file
                        ? "Ganti File"
                        : "Pilih File"}
                    </DownloadButton>
                  </div>
                  {profile?.aktaKelahiran?.file && (
                    <HapusButton
                      onClick={(e) =>
                        handleUpload(
                          "aktaKelahiran",
                          null,
                          "DELETE",
                          BERKAS_AKTAKELAHIRAN
                        )
                      }
                    >
                      Hapus
                    </HapusButton>
                  )}
                </div>
                {profile?.aktaKelahiran?.file && (
                  <div
                    className="upload-image"
                    style={{
                      width: "100%",
                      height: "50px",
                      border: "none",
                      backgroundColor: "white",
                      marginTop: "10px",
                      display: "flex",
                    }}
                  >
                    <Link
                      to={{ pathname: profile?.aktaKelahiran?.file }}
                      target="_BLANK"
                      style={{
                        textDecoration: "none",
                        marginLeft: "auto",
                        cursor: "pointer",
                        width: "100%",
                      }}
                    >
                      <DownloadButton>Lihat</DownloadButton>
                    </Link>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ color: "#f44336", fontSize: 17, marginRight: 5 }}
                  />
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Maksimal 2MB
                  </span>
                </div>
              </Fragment>
            )}
            {profile?.finalisasi === true && (
              <div
                className="upload-image"
                style={{
                  width: "100%",
                  height: "50px",
                  border: "none",
                  backgroundColor: "white",
                  marginTop: "auto",
                  display: "flex",
                }}
              >
                <Link
                  to={{ pathname: profile?.aktaKelahiran?.file }}
                  target="_BLANK"
                  style={{
                    textDecoration: "none",
                    marginLeft: "auto",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  <DownloadButton>Lihat</DownloadButton>
                </Link>
              </div>
            )}
          </div>

          {/* SKHUN & Ijazah SMP */}
          <div className="box-white-upload-berkas">
            <div className="box-white-blue-upload-berkas">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: profile?.skhunIjazah?.file && "100%",
                }}
              >
                <img
                  src={profile?.skhunIjazah?.file ? PDFIcon : UploadIcon}
                  alt="icon"
                  className="icon-download"
                  draggable={false}
                />
                {profile?.skhunIjazah?.file && (
                  <Fragment>
                    <div className="box-white-file-name">
                      {getNameFile(profile?.skhunIjazah?.file)}
                    </div>
                    <div className="box-white-size-file">
                      {getSizeFile(profile?.skhunIjazah?.size)}
                    </div>
                  </Fragment>
                )}
              </div>
              <span
                className="box-white-blue-download-berkas-text-dwd"
                style={{
                  fontSize: profile?.skhunIjazah?.file && 15,
                  width: "100%",
                  marginTop: profile?.skhunIjazah?.file && "auto",
                }}
              >
                SKHUN & Ijazah SMP
              </span>
            </div>
            {!(
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            ) && (
              <Fragment>
                <div
                  className="upload-image"
                  style={{
                    width: "100%",
                    height: "50px",
                    border: "none",
                    backgroundColor: "white",
                    marginTop: "auto",
                    display: "flex",
                  }}
                >
                  <input
                    id="skhunIjazah"
                    type="file"
                    className="upload-image__file"
                    style={{
                      marginLeft: 0,
                    }}
                    accept=".pdf"
                    onChange={(e) =>
                      handleUpload(
                        "skhunIjazah",
                        e,
                        "PATCH",
                        BERKAS_SKHUNIJAZAH
                      )
                    }
                  />
                  <div
                    className="image-upload-content"
                    style={{ width: "100%" }}
                  >
                    <DownloadButton>
                      {profile?.skhunIjazah?.file ? "Ganti File" : "Pilih File"}
                    </DownloadButton>
                  </div>
                  {profile?.skhunIjazah?.file && (
                    <HapusButton
                      onClick={(e) =>
                        handleUpload(
                          "skhunIjazah",
                          null,
                          "DELETE",
                          BERKAS_SKHUNIJAZAH
                        )
                      }
                    >
                      Hapus
                    </HapusButton>
                  )}
                </div>
                {profile?.skhunIjazah?.file && (
                  <div
                    className="upload-image"
                    style={{
                      width: "100%",
                      height: "50px",
                      border: "none",
                      backgroundColor: "white",
                      marginTop: "10px",
                      display: "flex",
                    }}
                  >
                    <Link
                      to={{ pathname: profile?.skhunIjazah?.file }}
                      target="_BLANK"
                      style={{
                        textDecoration: "none",
                        marginLeft: "auto",
                        cursor: "pointer",
                        width: "100%",
                      }}
                    >
                      <DownloadButton>Lihat</DownloadButton>
                    </Link>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ color: "#f44336", fontSize: 17, marginRight: 5 }}
                  />
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Maksimal 2MB
                  </span>
                </div>
              </Fragment>
            )}
            {profile?.finalisasi === true && (
              <div
                className="upload-image"
                style={{
                  width: "100%",
                  height: "50px",
                  border: "none",
                  backgroundColor: "white",
                  marginTop: "auto",
                  display: "flex",
                }}
              >
                <Link
                  to={{ pathname: profile?.skhunIjazah?.file }}
                  target="_BLANK"
                  style={{
                    textDecoration: "none",
                    marginLeft: "auto",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  <DownloadButton>Lihat</DownloadButton>
                </Link>
              </div>
            )}
          </div>

          {/* Sertifikat dan lain-lain */}
          <div className="box-white-upload-berkas">
            <div className="box-white-blue-upload-berkas">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: profile?.sertifikatDll?.file && "100%",
                }}
              >
                <img
                  src={profile?.sertifikatDll?.file ? PDFIcon : UploadIcon}
                  alt="icon"
                  className="icon-download"
                  draggable={false}
                />
                {profile?.sertifikatDll?.file && (
                  <Fragment>
                    <div className="box-white-file-name">
                      {getNameFile(profile?.sertifikatDll?.file)}
                    </div>
                    <div className="box-white-size-file">
                      {getSizeFile(profile?.sertifikatDll?.size)}
                    </div>
                  </Fragment>
                )}
              </div>
              <span
                className="box-white-blue-download-berkas-text-dwd"
                style={{
                  fontSize: profile?.sertifikatDll?.file && 15,
                  width: "100%",
                  marginTop: profile?.sertifikatDll?.file && "auto",
                }}
              >
                Sertifikat dan lain-lain
              </span>
            </div>
            {!(
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            ) && (
              <Fragment>
                <div
                  className="upload-image"
                  style={{
                    width: "100%",
                    height: "50px",
                    border: "none",
                    backgroundColor: "white",
                    marginTop: "auto",
                    display: "flex",
                  }}
                >
                  <input
                    id="sertifikatDll"
                    type="file"
                    className="upload-image__file"
                    style={{
                      marginLeft: 0,
                    }}
                    accept=".pdf"
                    onChange={(e) =>
                      handleUpload(
                        "sertifikatDll",
                        e,
                        "PATCH",
                        BERKAS_SERTIFIKATDLL
                      )
                    }
                  />
                  <div
                    className="image-upload-content"
                    style={{ width: "100%" }}
                  >
                    <DownloadButton>
                      {profile?.sertifikatDll?.file
                        ? "Ganti File"
                        : "Pilih File"}
                    </DownloadButton>
                  </div>
                  {profile?.sertifikatDll?.file && (
                    <HapusButton
                      onClick={(e) =>
                        handleUpload(
                          "sertifikatDll",
                          null,
                          "DELETE",
                          BERKAS_SERTIFIKATDLL
                        )
                      }
                    >
                      Hapus
                    </HapusButton>
                  )}
                </div>
                {profile?.sertifikatDll?.file && (
                  <div
                    className="upload-image"
                    style={{
                      width: "100%",
                      height: "50px",
                      border: "none",
                      backgroundColor: "white",
                      marginTop: "10px",
                      display: "flex",
                    }}
                  >
                    <Link
                      to={{ pathname: profile?.sertifikatDll?.file }}
                      target="_BLANK"
                      style={{
                        textDecoration: "none",
                        marginLeft: "auto",
                        cursor: "pointer",
                        width: "100%",
                      }}
                    >
                      <DownloadButton>Lihat</DownloadButton>
                    </Link>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ color: "#f44336", fontSize: 17, marginRight: 5 }}
                  />
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Maksimal 10MB
                  </span>
                </div>
              </Fragment>
            )}
            {profile?.finalisasi === true && (
              <div
                className="upload-image"
                style={{
                  width: "100%",
                  height: "50px",
                  border: "none",
                  backgroundColor: "white",
                  marginTop: "auto",
                  display: "flex",
                }}
              >
                <Link
                  to={{ pathname: profile?.sertifikatDll?.file }}
                  target="_BLANK"
                  style={{
                    textDecoration: "none",
                    marginLeft: "auto",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  <DownloadButton>Lihat</DownloadButton>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadBerkas;
