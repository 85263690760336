import React, {
  useState,
  useEffect,
  Fragment,
  forwardRef,
  useImperativeHandle,
} from "react";
import "./styles.scss";
import { DatePicker } from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import {
  setSaveAction,
  saveRiwayatPerjalanan,
} from "../../../../store/actions/peserta";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

const SaveAndOutButton = withStyles((theme) => ({
  root: {
    border: "1px solid #3598DC",
    // marginTop: '50px',
    width: "200px",
    height: "44px",
    color: "#3598DC",
    backgroundColor: "#FFFFFF",
    fontFamily: ["Roboto", "sans-serif"],
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "19px",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#e5e5e5",
      boxShadow:
        "0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)",
    },
  },
}))(Button);

const SaveAndNextButton = withStyles((theme) => ({
  root: {
    // marginTop: '50px',
    width: "200px",
    height: "44px",
    color: "#ffffff",
    backgroundColor: "#3598DC",
    fontFamily: ["Roboto", "sans-serif"],
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "19px",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#2f88c6",
      boxShadow:
        "0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)",
    },
    marginLeft: 20,
  },
}))(Button);

const AddButton = withStyles((theme) => ({
  root: {
    border: "1px solid #3598DC",
    height: "44px",
    color: "#3598DC",
    backgroundColor: "#FFFFFF",
    fontFamily: ["Roboto", "sans-serif"],
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "19px",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {},
  },
}))(Button);

const SkyRadio = withStyles({
  root: {
    color: "#999999",
    "&$checked": {
      color: "#3598DC",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const RedRadio = withStyles({
  root: {
    color: "red",
    "&$checked": {
      color: "red",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const RiwayatPerjalanan = forwardRef((props, ref) => {
  const { NextPage, handleLogout, goTop } = props;
  const Dispatch = useDispatch();
  const options = useSelector((state) => state.pesertaState.options);
  const profile = useSelector((state) => state.pesertaState.profile);
  const save = useSelector((state) => state.pesertaState.checkerSave);
  const [click, setClick] = useState(false);
  const [radio] = useState(["true", "false"]);
  const [sendData, setSendData] = useState({
    kunjungan: [
      {
        negara: "",
        tahun: null,
        rangka: "",
      },
    ],
    tinggalDiluarNegeri: null,
    tinggal: [
      {
        negara: "",
        dariTahun: null,
        sampaiTahun: null,
      },
    ],
  });

  useEffect(() => {
    if (profile) {
      if (profile.riwayatPerjalanan) {
        setSendData(profile.riwayatPerjalanan);
      }
    }
  }, [profile && profile.riwayatPerjalanan]);

  const handleChange = (key, value, i) => {
    const SwalBootstrap = Swal.mixin({
      customClass: {
        confirmButton: "btn-finalisasi-yakin",
        cancelButton: "btn-finalisasi-tidak",
      },
      buttonsStyling: false,
    });
    if (!save) Dispatch(setSaveAction(true));
    switch (key) {
      case "kunjunganNegara":
        let temp = [...sendData.kunjungan];
        temp[i].negara = value;
        setSendData({
          ...sendData,
          kunjungan: temp,
        });
        break;
      case "kunjunganTahun":
        let temp1 = [...sendData.kunjungan];
        temp1[i].tahun = value;
        setSendData({
          ...sendData,
          kunjungan: temp1,
        });
        break;
      case "kunjunganRangka":
        let temp2 = [...sendData.kunjungan];
        temp2[i].rangka = value;
        setSendData({
          ...sendData,
          kunjungan: temp2,
        });
        break;
      case "tinggalDiluarNegeri":
        if (value === "true") {
          setSendData({
            ...sendData,
            tinggalDiluarNegeri: value,
          });
        } else if (value === "false") {
          let flag = false;
          if (sendData) {
            sendData.tinggal.map((data) => {
              if (data.negara || data.dariTahun || data.sampaiTahun) {
                flag = true;
                return;
              }
              if (flag) return;
            });
          }
          if (flag) {
            SwalBootstrap.fire({
              title: "Peringatan",
              text: "Mengganti Pilihan akan menghapus data yg telah dimasukkan, Lanjutkan?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Ganti",
              cancelButtonText: "Batal",
              reverseButtons: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((result) => {
              if (result.value) {
                setSendData({
                  ...sendData,
                  tinggalDiluarNegeri: value,
                  tinggal: [
                    {
                      negara: "",
                      dariTahun: null,
                      sampaiTahun: null,
                    },
                  ],
                });
              } else if (result.dismiss === Swal.DismissReason.cancel) {
              }
            });
          } else {
            setSendData({
              ...sendData,
              tinggalDiluarNegeri: value,
            });
          }
        }
        break;
      case "tinggalNegara":
        let temp3 = [...sendData.tinggal];
        temp3[i].negara = value;
        setSendData({
          ...sendData,
          tinggal: temp3,
        });
        break;
      case "tinggalDariTahun":
        let temp4 = [...sendData.tinggal];
        temp4[i].dariTahun = value;
        setSendData({
          ...sendData,
          tinggal: temp4,
        });
        break;
      case "tinggalSampaiTahun":
        let temp5 = [...sendData.tinggal];
        temp5[i].sampaiTahun = value;
        setSendData({
          ...sendData,
          tinggal: temp5,
        });
        break;
      default:
        break;
    }
  };

  const handleAddKunjungan = () => {
    if (!save) Dispatch(setSaveAction(true));
    let temp = [...sendData.kunjungan];
    temp.push({
      negara: "",
      tahun: null,
      penjelasan: "",
    });
    setSendData({
      ...sendData,
      kunjungan: temp,
    });
  };

  const handleRemoveKunjungan = (i) => {
    if (!save) Dispatch(setSaveAction(true));
    let temp = [...sendData.kunjungan];
    temp.splice(i, 1);
    setSendData({
      ...sendData,
      kunjungan: temp,
    });
  };

  const handleAddTinggal = () => {
    if (!save) Dispatch(setSaveAction(true));
    let temp = [...sendData.tinggal];
    temp.push({
      negara: "",
      dariTahun: null,
      sampaiTahun: null,
    });
    setSendData({
      ...sendData,
      tinggal: temp,
    });
  };

  const handleRemoveTinggal = (i) => {
    if (!save) Dispatch(setSaveAction(true));
    let temp = [...sendData.tinggal];
    temp.splice(i, 1);
    setSendData({
      ...sendData,
      tinggal: temp,
    });
  };

  const goToTab = (page) => {
    NextPage(page);
    goTop();
  };

  const saving = (option) => {
    const ToastMid = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    const SwalBootstrap2 = Swal.mixin({
      customClass: {
        confirmButton: "btn-finalisasi-yakin-ok",
        cancelButton: "btn-finalisasi-tidak",
      },
      buttonsStyling: false,
    });
    if (options?.[profile?.program]?.finalisasi) {
      setClick(true);
      let flag = false;
      for (let key in sendData) {
        if (key === "tinggalDiluarNegeri") {
          if (!sendData[key]) {
            flag = true;
            ToastMid.fire({
              icon: "warning",
              title: `Silahkan Lengkapi Data`,
            });
            goTop();
            return;
          }
          if (flag) return;
        }

        if (key === "tinggal" && sendData.tinggalDiluarNegeri === "true") {
          if (sendData[key]) {
            sendData[key].map((a) => {
              for (const keyA in a) {
                if (!a[keyA]) {
                  flag = true;
                  ToastMid.fire({
                    icon: "warning",
                    title: `Silahkan Lengkapi Data`,
                  });
                  goTop();
                  return;
                }
                if (flag) return;
              }
              if (flag) return;
            });
          }
        }
        if (flag) return;
      }
      let page = profile.page;
      if (profile.page < 9) {
        page = 9;
      }
      if (!flag) {
        Dispatch(
          saveRiwayatPerjalanan(
            { data: sendData, page },
            Swal,
            option,
            NextPage,
            handleLogout,
            9,
            goTop
          )
        );
      }
    } else {
      SwalBootstrap2.fire({
        title: "Pengisian Data Telah Ditutup",
        text: "",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        cancelButtonText: "",
        reverseButtons: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.value) {
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      });
    }
  };

  useImperativeHandle(ref, () => ({
    saving(option, num) {
      const ToastMid = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      const SwalBootstrap2 = Swal.mixin({
        customClass: {
          confirmButton: "btn-finalisasi-yakin-ok",
          cancelButton: "btn-finalisasi-tidak",
        },
        buttonsStyling: false,
      });
      if (options?.[profile?.program]?.finalisasi) {
        setClick(true);
        let flag = false;
        for (let key in sendData) {
          if (key === "tinggalDiluarNegeri") {
            if (!sendData[key]) {
              flag = true;
              ToastMid.fire({
                icon: "warning",
                title: `Silahkan Lengkapi Data`,
              });
              goTop();
              return;
            }
            if (flag) return;
          }

          if (key === "tinggal" && sendData.tinggalDiluarNegeri === "true") {
            if (sendData[key]) {
              sendData[key].map((a) => {
                for (const keyA in a) {
                  if (!a[keyA]) {
                    flag = true;
                    ToastMid.fire({
                      icon: "warning",
                      title: `Silahkan Lengkapi Data`,
                    });
                    goTop();
                    return;
                  }
                  if (flag) return;
                }
                if (flag) return;
              });
            }
          }
          if (flag) return;
        }
        let page = profile.page;
        if (profile.page < 9) {
          page = 9;
        }
        if (!flag) {
          Dispatch(
            saveRiwayatPerjalanan(
              { data: sendData, page },
              Swal,
              option,
              NextPage,
              handleLogout,
              num,
              goTop
            )
          );
        }
      } else {
        SwalBootstrap2.fire({
          title: "Pengisian Data Telah Ditutup",
          text: "",
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "OK",
          cancelButtonText: "",
          reverseButtons: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.value) {
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
          }
        });
      }
    },
  }));

  return (
    <Fragment>
      <form autoComplete="off">
        {/* KEGIATAN YANG PERNAH DIIKUTI */}
        <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
          <span
            className="data-pribadi-font title-input-desktop"
            style={{
              minWidth: 180,
            }}
          />
          <div className="info-keluarga-font-title">
            Pernahkan adik Melawat/Berpergian Dalam Jangka Pendek ke Luar
            Negeri?
          </div>
        </div>

        {sendData?.kunjungan?.map((data, i) => (
          <Fragment key={i}>
            {/* BREAK */}
            {i > 0 && (
              <div
                style={{
                  borderTop: "2px solid #f0f0f0",
                  margin: "15px auto",
                  width: "70%",
                }}
              />
            )}

            {/* KEMANA & KAPAN */}
            <span className="data-pribadi-font-mobile title-input-mobile">
              Ke Mana & Kapan?
            </span>
            <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
              <span
                className="data-pribadi-font title-input-desktop"
                style={{
                  minWidth: 180,
                }}
              >
                Ke Mana & Kapan?
              </span>
              <div className="input-wrapper-data-pribadi">
                <input
                  disabled={
                    options?.[profile?.program]?.finalisasi === false ||
                    profile?.finalisasi === true
                  }
                  onChange={(e) =>
                    handleChange("kunjunganNegara", e.target.value, i)
                  }
                  value={data.negara}
                  type="text"
                  placeholder="Nama Negara"
                  className="data-pribadi-input2"
                />
                <div
                  className="data-pribadi-input2 data-pribadi-input-margin-top-mobile"
                  style={{
                    padding: 0,
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <DatePicker
                    disabled={
                      options?.[profile?.program]?.finalisasi === false ||
                      profile?.finalisasi === true
                    }
                    autoOk
                    views={["year"]}
                    placeholder="Tahun Mengunjungi"
                    value={data.tahun}
                    onChange={(newValue) => {
                      handleChange("kunjunganTahun", newValue, i);
                    }}
                    clearable
                    clearLabel="Hapus"
                    animateYearScrolling
                    disableFuture
                    okLabel=""
                    cancelLabel=""
                    helperText=""
                    format="yyyy"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      paddingLeft: "19px",
                      border: "0px",
                      zIndex: "9",
                      height: "44px",
                      borderRadius: "4px",
                    }}
                    maxDate={new Date()}
                    minDate={new Date("2000")}
                  />
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    style={{
                      color: "#3c98dc",
                      fontSize: 18,
                      position: "absolute",
                      right: "60",
                    }}
                  />
                </div>
              </div>
            </div>

            {/* DALAM RANGKA */}
            <span className="data-pribadi-font-mobile title-input-mobile2row">
              Dalam Rangka Apa?
            </span>
            <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
              <span
                className="data-pribadi-font title-input-desktop"
                style={{
                  minWidth: 180,
                }}
              >
                Dalam Rangka Apa?
              </span>
              <div className="input-wrapper-data-pribadi">
                <input
                  disabled={
                    options?.[profile?.program]?.finalisasi === false ||
                    profile?.finalisasi === true
                  }
                  onChange={(e) =>
                    handleChange("kunjunganRangka", e.target.value, i)
                  }
                  value={data.rangka}
                  type="text"
                  placeholder="Dalam Rangka Apa Mengunjungi Negara Tersebut"
                  className="data-pribadi-input"
                />
              </div>
            </div>

            {/* ACTION */}
            {!(
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            ) && (
              <div
                className="data-pribadi-wrapper"
                style={{ marginTop: "20px" }}
              >
                <span
                  className="data-pribadi-font title-input-desktop"
                  style={{
                    minWidth: 180,
                  }}
                />
                <div className="btn-saudara-wrap">
                  {sendData.kunjungan.length - 1 === i && (
                    <AddButton
                      onClick={() => handleAddKunjungan()}
                      className="btn-add-sma"
                    >
                      <span className="btn-add-sma-txt">Tambah Kunjungan</span>
                    </AddButton>
                  )}
                  {sendData.kunjungan.length > 1 && (
                    <AddButton
                      onClick={() => handleRemoveKunjungan(i)}
                      className="btn-delete-saudara"
                    >
                      Hapus
                    </AddButton>
                  )}
                </div>
              </div>
            )}
          </Fragment>
        ))}

        {/* BREAK */}
        <div
          style={{
            borderTop: "5px solid #fafafa",
            margin: "30px 0px",
          }}
        />

        {/* TINGGAL DILUAR NEGERI */}
        <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
          <span
            className="data-pribadi-font title-input-desktop"
            style={{
              minWidth: 180,
            }}
          />
          <div className="info-keluarga-font-title">
            Pernahkah adik tinggal di luar negeri?
          </div>
        </div>

        {/* RADIO BUTTON */}
        <span className="data-pribadi-font-mobile title-input-mobile">
          Pernah Tinggal di Luar Negeri <b style={{ color: "red" }}> * </b>
        </span>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span
            className="data-pribadi-font title-input-desktop"
            style={{
              minWidth: 250,
            }}
          >
            Pernah Tinggal di Luar Negeri <b style={{ color: "red" }}> * </b>
          </span>
          <div className="input-wrapper-data-pribadi-radio">
            {radio?.map((data, i) => (
              <FormControlLabel
                key={i}
                value={data}
                style={{
                  marginRight: i === 0 && 30,
                }}
                disabled={
                  options?.[profile?.program]?.finalisasi === false ||
                  profile?.finalisasi === true
                }
                control={
                  click && !sendData.tinggalDiluarNegeri ? (
                    <RedRadio
                      checked={sendData.tinggalDiluarNegeri === data}
                      onChange={(e) =>
                        handleChange("tinggalDiluarNegeri", e.target.value)
                      }
                      value={data}
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "pernah" }}
                    />
                  ) : (
                    <SkyRadio
                      checked={sendData.tinggalDiluarNegeri === data}
                      onChange={(e) =>
                        handleChange("tinggalDiluarNegeri", e.target.value)
                      }
                      value={data}
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "pernah" }}
                    />
                  )
                }
                label={data === "true" ? "Pernah" : "Belum Pernah"}
              />
            ))}
          </div>
        </div>

        {sendData?.tinggalDiluarNegeri === "true" &&
          sendData?.tinggal.map((data, i) => (
            <Fragment key={i}>
              {/* BREAK */}
              {i > 0 && (
                <div
                  style={{
                    borderTop: "2px solid #f0f0f0",
                    margin: "15px auto",
                    width: "70%",
                  }}
                />
              )}

              {/* NEGARA */}
              <span className="data-pribadi-font-mobile title-input-mobile2row">
                Di Negara Apa?
              </span>
              <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
                <span
                  className="data-pribadi-font title-input-desktop"
                  style={{
                    minWidth: 180,
                  }}
                >
                  Di Negara Apa?
                </span>
                <div className="input-wrapper-data-pribadi">
                  <input
                    disabled={
                      options?.[profile?.program]?.finalisasi === false ||
                      profile?.finalisasi === true
                    }
                    onChange={(e) =>
                      handleChange("tinggalNegara", e.target.value, i)
                    }
                    value={data.negara}
                    type="text"
                    placeholder="Dalam Rangka Apa Mengunjungi Negara Tersebut"
                    className="data-pribadi-input"
                    style={{ border: click && !data.negara && "1px solid red" }}
                  />
                </div>
              </div>

              {/* TAHUN DARI & SAMPAI */}
              <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
                <span
                  className="data-pribadi-font title-input-desktop"
                  style={{
                    minWidth: 180,
                  }}
                ></span>
                <div className="input-wrapper-data-pribadi">
                  <div
                    className="data-pribadi-input2 data-pribadi-input-margin-top-mobile"
                    style={{
                      padding: 0,
                      boxSizing: "border-box",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      border: click && !data.dariTahun && "1px solid red",
                    }}
                  >
                    <DatePicker
                      disabled={
                        options?.[profile?.program]?.finalisasi === false ||
                        profile?.finalisasi === true
                      }
                      autoOk
                      views={["year"]}
                      placeholder="Dari Tahun"
                      value={data.dariTahun}
                      onChange={(newValue) => {
                        handleChange("tinggalDariTahun", newValue, i);
                      }}
                      clearable
                      clearLabel="Hapus"
                      animateYearScrolling
                      disableFuture
                      okLabel=""
                      cancelLabel=""
                      helperText=""
                      format="yyyy"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        paddingLeft: "19px",
                        border: "0px",
                        zIndex: "9",
                        height: "44px",
                        borderRadius: "4px",
                      }}
                      maxDate={new Date()}
                      minDate={new Date("1990")}
                    />
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      style={{
                        color: "#3c98dc",
                        fontSize: 18,
                        marginLeft: -35,
                      }}
                    />
                  </div>
                  <div
                    className="data-pribadi-input2 data-pribadi-input-margin-top-mobile"
                    style={{
                      padding: 0,
                      boxSizing: "border-box",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      border: click && !data.dariTahun && "1px solid red",
                    }}
                  >
                    <DatePicker
                      disabled={
                        options?.[profile?.program]?.finalisasi === false ||
                        profile?.finalisasi === true
                      }
                      autoOk
                      views={["year"]}
                      placeholder="Sampai Tahun"
                      value={data.sampaiTahun}
                      onChange={(newValue) => {
                        handleChange("tinggalSampaiTahun", newValue, i);
                      }}
                      clearable
                      clearLabel="Hapus"
                      animateYearScrolling
                      disableFuture
                      okLabel=""
                      cancelLabel=""
                      helperText=""
                      format="yyyy"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        paddingLeft: "19px",
                        border: "0px",
                        zIndex: "9",
                        height: "44px",
                        borderRadius: "4px",
                      }}
                      maxDate={new Date()}
                      minDate={new Date("1990")}
                    />
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      style={{
                        color: "#3c98dc",
                        fontSize: 18,
                        position: "absolute",
                        right: "60",
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* ACTION */}
              {!(
                options?.[profile?.program]?.finalisasi === false ||
                profile?.finalisasi === true
              ) && (
                <div
                  className="data-pribadi-wrapper"
                  style={{ marginTop: "20px" }}
                >
                  <span
                    className="data-pribadi-font title-input-desktop"
                    style={{
                      minWidth: 180,
                    }}
                  />
                  <div className="btn-saudara-wrap">
                    {sendData.tinggal.length - 1 === i && (
                      <AddButton
                        onClick={() => handleAddTinggal()}
                        className="btn-add-sma"
                      >
                        <span className="btn-add-sma-txt">
                          Tambah Kunjungan
                        </span>
                      </AddButton>
                    )}
                    {sendData.tinggal.length > 1 && (
                      <AddButton
                        onClick={() => handleRemoveTinggal(i)}
                        className="btn-delete-saudara"
                      >
                        Hapus
                      </AddButton>
                    )}
                  </div>
                </div>
              )}
            </Fragment>
          ))}

        <div className="data-pribadi-wrapper action-wrapper-2021-margintop">
          {options?.[profile?.program]?.finalisasi === false ||
          profile?.finalisasi === true ? (
            <div className="data-pribadi-button-wripper">
              <SaveAndOutButton
                className="btn-save-input2021-out"
                onClick={(e) => goToTab(7)}
              >
                {" "}
                Lihat Data Sebelumnya{" "}
              </SaveAndOutButton>
              <SaveAndNextButton
                className="btn-save-input2021-next"
                onClick={(e) => goToTab(9)}
              >
                {" "}
                Lihat Data Selanjutnya{" "}
              </SaveAndNextButton>
            </div>
          ) : (
            <div className="data-pribadi-button-wripper">
              <SaveAndOutButton
                className="btn-save-input2021-out"
                onClick={(e) => saving("out")}
              >
                {" "}
                Simpan & Keluar{" "}
              </SaveAndOutButton>
              <SaveAndNextButton
                className="btn-save-input2021-next"
                onClick={(e) => saving("next")}
              >
                {" "}
                Simpan & Lanjutkan{" "}
              </SaveAndNextButton>
            </div>
          )}
        </div>
      </form>
    </Fragment>
  );
});

export default RiwayatPerjalanan;
