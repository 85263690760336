import React, { useState } from 'react'
import './styles.scss'
import BinabudayaLogo from '../../images/homepage/binabud.svg'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'

const ActivationButton = withStyles((theme) => ({
    root: {
        marginTop: '50px',
        width: '400px',
        height: '44px',
        color: '#ffffff',
        backgroundColor: '#3598DC',
        fontFamily: ['Roboto', 'sans-serif'],
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '19px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#2f88c6',
            boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)',
        },
    },
}))(Button);

export default function Login() {
    const History = useHistory()
    const [showPasswordNow, setShowPasswordNow] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)

    const handleShowPassword = () => {
        showPassword ? setShowPassword(false) : setShowPassword(true)
    }

    const handleShowPasswordConfirmation = () => {
        showPasswordConfirmation ? setShowPasswordConfirmation(false) : setShowPasswordConfirmation(true)
    }

    const handleShowPasswordNow = () => {
        showPasswordNow ? setShowPasswordNow(false) : setShowPasswordNow(true)
    }

    const SignIn = () => {
    }

    return (
        <div className="activation-container-wrapper">
            <div className="activation-binabud-logo" >
                <img src={BinabudayaLogo} alt="binabudaya" className="logo-binabud-activation" />
            </div>
            <div className="activation-contain-text" >
                <span className="activation-font-top" >
                    Ganti Password
                </span>
                <span className="change-pass-text">
                    Pastikan adik ingat password lama sebelum menggantinya
                </span>
                <div className="activation-container-card" style={{ marginTop: 29, paddingBottom: 29 }} >
                    <div className="new-password-container" >
                        <span className="card-font-text-password" style={{ marginTop: 0 }}>Password Lama</span>
                        <div className="password-wraper" >
                            <input type={!showPasswordNow ? 'password' : 'text'} placeholder="Password yang Saat ini Digunakan" className="activation-input-theme" />
                            {
                                showPasswordNow ?
                                <FontAwesomeIcon
                                    icon={faEye}
                                    className="eye-custom"
                                    style={{ color: '#3c98dc' }}
                                    onClick={() => handleShowPasswordNow()}
                                />
                                :
                                <FontAwesomeIcon
                                    icon={faEye}
                                    className="eye-custom"
                                    onClick={() => handleShowPasswordNow()}
                                />
                            }
                        </div>
                        <span className="label-lupa-password" onClick={()=>History.push('/forget-password')}>Lupa Password?</span>
                    </div>
                    <div className="new-password-container" style={{ marginTop: 28 }}>
                        <span className="card-font-text-password" style={{ marginTop: 0 }}>Password</span>
                        <div className="password-wraper" >
                            <input type={!showPassword ? 'password' : 'text'} placeholder="Password Baru" className="activation-input-theme" />
                            {
                                showPassword ?
                                <FontAwesomeIcon
                                    icon={faEye}
                                    className="eye-custom"
                                    style={{ color: '#3c98dc' }}
                                    onClick={() => handleShowPassword()}
                                />
                                :
                                <FontAwesomeIcon
                                    icon={faEye}
                                    className="eye-custom"
                                    onClick={() => handleShowPassword()}
                                />
                            }
                        </div>
                    </div>
                    <div className="new-password-container" style={{ marginTop: 21 }}>
                        <span className="card-font-text-password" style={{ marginTop: 0 }}>Konfirmasi Password</span>
                        <div className="password-wraper" >
                            <input type={!showPasswordConfirmation ? 'password' : 'text'} placeholder="Konfirmasi Password Baru" className="activation-input-theme" />
                            {
                                showPasswordConfirmation ?
                                <FontAwesomeIcon
                                    icon={faEye}
                                    className="eye-custom"
                                    style={{ color: '#3c98dc' }}
                                    onClick={() => handleShowPasswordConfirmation()}
                                />
                                :
                                <FontAwesomeIcon
                                    icon={faEye}
                                    className="eye-custom"
                                    onClick={() => handleShowPasswordConfirmation()}
                                />
                            }
                        </div>
                    </div>
                    <ActivationButton onClick={()=>SignIn()} >Buat Password Baru</ActivationButton>
                </div>
            </div>
        </div>
    )
}