import React, {
  useState,
  useEffect,
  forwardRef,
  Fragment,
  useImperativeHandle,
} from "react";
import "./styles.scss";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import { savePilihanProgram, setSaveAction } from "../../../../store/actions/peserta";

const SaveAndOutButton = withStyles((theme) => ({
  root: {
    border: "1px solid #3598DC",
    // marginTop: '50px',
    width: "200px",
    height: "44px",
    color: "#3598DC",
    backgroundColor: "#FFFFFF",
    fontFamily: ["Roboto", "sans-serif"],
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "19px",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#e5e5e5",
      // boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)',
    },
  },
}))(Button);

const SaveAndNextButton = withStyles((theme) => ({
  root: {
    // marginTop: '50px',
    width: "200px",
    height: "44px",
    color: "#ffffff",
    backgroundColor: "#3598DC",
    fontFamily: ["Roboto", "sans-serif"],
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "19px",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#2f88c6",
      boxShadow:
        "0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)",
    },
    marginLeft: 20,
  },
}))(Button);

const PilihanProgram = forwardRef((props, ref) => {
  const { NextPage, handleLogout, goTop } = props;
  const Dispatch = useDispatch();
  const options = useSelector((state) => state.pesertaState.options);
  const optionsProgram = useSelector((state) => state.filterState.optionsProgram);
  const profile = useSelector((state) => state.pesertaState.profile);
  const save = useSelector((state) => state.pesertaState.checkerSave);
  const [click, setClick] = useState(false);
  const [sendData, setSendData] = useState({
    program: "",
    optionProgram: "",
    optionProgramLainnya: "",
  });

  useEffect(() => {
    if (profile?.pilihanProgram) {
      setSendData(profile?.pilihanProgram);
    } else {
      setSendData({
        ...sendData,
        program: profile.program,
      });
    }
  }, [profile?.program, profile?.pilihanProgram]);

  const saving = (option) => {
    const ToastMid = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    const SwalBootstrap2 = Swal.mixin({
      customClass: {
        confirmButton: "btn-finalisasi-yakin-ok",
        cancelButton: "btn-finalisasi-tidak",
      },
      buttonsStyling: false,
    });
    if (options?.[profile?.program]?.finalisasi) {
      setClick(true);
      let flag = false;
      for (let key in sendData) {
        if (
          key === "optionProgram"
        ) {
          if (!sendData[key]) {
            flag = true;
            ToastMid.fire({
              icon: "warning",
              title: `Silahkan Lengkapi Data`,
            });
            goTop();
            return;
          }

          if (sendData[key] === 'Lainnya') {
            if (!sendData['optionProgramLainnya']) {
              flag = true;
              ToastMid.fire({
                icon: "warning",
                title: `Silahkan Lengkapi Data`,
              });
              goTop();
              return;
            }
          }

          if (flag) return;
        }
        if (flag) return;
      }
      let page = profile.page;
      if (profile.page < 7) {
        page = 7;
      }
      if (!flag) {
        Dispatch(
          savePilihanProgram(
            { data: sendData, page },
            Swal,
            option,
            NextPage,
            handleLogout,
            7,
            goTop
          )
        );
      }
    } else {
      SwalBootstrap2.fire({
        title: "Pengisian Data Telah Ditutup",
        text: "",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        cancelButtonText: "",
        reverseButtons: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.value) {
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      });
    }
  };

  useImperativeHandle(ref, () => ({
    saving(option, num) {
      const ToastMid = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      const SwalBootstrap2 = Swal.mixin({
        customClass: {
          confirmButton: "btn-finalisasi-yakin-ok",
          cancelButton: "btn-finalisasi-tidak",
        },
        buttonsStyling: false,
      });
      if (options?.[profile?.program]?.finalisasi) {
        setClick(true);
        let flag = false;
        for (let key in sendData) {
          if (
            key === "optionProgram"
          ) {
            if (!sendData[key]) {
              flag = true;
              ToastMid.fire({
                icon: "warning",
                title: `Silahkan Lengkapi Data`,
              });
              goTop();
              return;
            }
            if (flag) return;
          }
          if (flag) return;
        }
        let page = profile.page;
        if (profile.page < 7) {
          page = 7;
        }
        if (!flag) {
          Dispatch(
            savePilihanProgram(
              { data: sendData, page },
              Swal,
              option,
              NextPage,
              handleLogout,
              7,
              goTop
            )
          );
        }
      } else {
        SwalBootstrap2.fire({
          title: "Pengisian Data Telah Ditutup",
          text: "",
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "OK",
          cancelButtonText: "",
          reverseButtons: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.value) {
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
          }
        });
      }
    },
  }));

  const goToTab = (page) => {
    NextPage(page);
    goTop();
  };

  const handleFilter = (key, program, data) => {
    switch (key) {
      case "optionProgram":
        return data || [];
      default:
        break;
    }
  };

  const handleChange = (key, value) => {
    if (!save) Dispatch(setSaveAction(true));
    switch (key) {
      case "optionProgram":
        setSendData({
          ...sendData,
          optionProgram: value,
          optionProgramLainnya: '',
        });
        break;
      case "optionProgramLainnya":
        setSendData({
          ...sendData,
          optionProgramLainnya: value,
        });
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      {/* PROGRAM */}
      <span className="data-pribadi-font-mobile title-input-mobile">
        Pilihan Program yang Diikuti <b style={{ color: "red" }}> * </b>
      </span>
      <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
        <span className="data-pribadi-font title-input-desktop">
          Pilihan Program yang Diikuti <b style={{ color: "red" }}> * </b>
        </span>
        <input
          disabled
          value={
            sendData.program === "KAKEHASHI"
              ? "Asia Kakehashi Project"
              : sendData.program
              ? sendData.program.toUpperCase()
              : "-"
          }
          type="text"
          placeholder="Pilihan Program"
          className="data-pribadi-input"
          style={{
            border: "none",
            background: "none",
            fontWeight: "bold",
          }}
        />
      </div>

      {/* PROGRAM */}
      <span className="data-pribadi-font-mobile title-input-mobile">
        Dimana Adik Mengetahui tentang Program <b>{
          sendData.program === "KAKEHASHI" ?
            "Asia Kakehashi Project" :
            sendData.program === 'YES' ?
              `KL-YES` :
            sendData.program ?
              sendData.program.toUpperCase() :
              "-"
        }</b> <b style={{ color: "red" }}> * </b>
      </span>
      <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile"
        style={{
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        <span className="data-pribadi-font title-input-desktop">
          Dimana Adik Mengetahui tentang Program <b>{
            sendData.program === "KAKEHASHI" ?
              "Asia Kakehashi Project" :
              sendData.program === 'YES' ?
                `KL-YES` :
              sendData.program ?
                sendData.program.toUpperCase() :
                "-"
          }</b> <b style={{ color: "red" }}> * </b>
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            width: '100%'
          }}
        >
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            onChange={(e) => handleChange("optionProgram", e.target.value)}
            value={sendData.optionProgram}
            className="data-pribadi-input"
            style={{
              color: sendData.optionProgram ? "#666666" : "#C9C9C9",
              border: click && !sendData.optionProgram && "1px solid red",
              textTransform: "capitalize",
            }}
          >
            <option
              value=""
              style={{ color: sendData.optionProgram ? "" : "#d3d5d7" }}
              selected
            >
              Pilih
            </option>
            {
              handleFilter("optionProgram", sendData?.program, optionsProgram)
                .map((option, index) => (
                  <option
                    key={index}
                    selected={sendData.optionProgram === option ? true : false}
                    style={{
                      color: sendData.optionProgram === option ? "#3c98dc" : "black",
                    }}
                    value={option}
                  >
                    {option}
                  </option>
                ))
              }
            </select>
            {
              sendData.optionProgram === 'Lainnya' &&
              <input
                disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                onChange={e => handleChange('optionProgramLainnya', e.target.value)}
                value={sendData.optionProgramLainnya}
                type="text"
                placeholder="Ketik disini"
                className="data-pribadi-input"
                style={{ border: (click && !sendData.optionProgramLainnya) && '1px solid red' }}
              />
            }
        </div>
      </div>

      <div className="data-pribadi-wrapper action-wrapper-2021-margintop">
        {options?.[profile?.program]?.finalisasi === false ||
        profile?.finalisasi === true ? (
          <div className="data-pribadi-button-wripper">
            <SaveAndOutButton
              className="btn-save-input2021-out"
              onClick={(e) => goToTab(5)}
            >
              {" "}
              Lihat Data Sebelumnya{" "}
            </SaveAndOutButton>
            <SaveAndNextButton
              className="btn-save-input2021-next"
              onClick={(e) => goToTab(7)}
            >
              {" "}
              Lihat Data Selanjutnya{" "}
            </SaveAndNextButton>
          </div>
        ) : (
          <div className="data-pribadi-button-wripper">
            <SaveAndOutButton
              className="btn-save-input2021-out"
              onClick={(e) => saving("out")}
            >
              {" "}
              Simpan & Keluar{" "}
            </SaveAndOutButton>
            <SaveAndNextButton
              className="btn-save-input2021-next"
              onClick={(e) => saving("next")}
            >
              {" "}
              Simpan & Lanjutkan{" "}
            </SaveAndNextButton>
          </div>
        )}
      </div>
    </Fragment>
  );
});

export default PilihanProgram;
