import React, { useEffect, useRef, useState } from 'react'
import './styles.scss'
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// COMPONENT
import DataPribadi from './dataPribadi'
import PassFoto from './PassFoto'
import InfoKeluarga from './infoKeluarga'
import RiwayatPendidikan from './RiwayatPendidikan'
import RiwayatKegiatan from './RiwayatKegiatan'
import Kepribadian from './Kepribadian'
import PilihanProgram from './pilihanProgram'
import PilihanNegara from './pilihanNegara'
import RiwayatPerjalanan from './RiwayatPerjalanan'
import Motivational from './motivasi'
import Essay from './Essay'
import Rekomendasi from './Rekomendasi'
// import Lanjutkan from './Lanjutkan'
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { setSaveAction } from '../../../store/actions/peserta'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box style={{ paddingTop: 20 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

function InputData(props) {
    let {
        handleLogout
    } = props
    const Dispatch = useDispatch()
    const options = useSelector(state=> state.pesertaState.options )
    const profile = useSelector(state=>state.pesertaState.profile);
    const save = useSelector(state=>state.pesertaState.checkerSave);
    const [value, setValue] = useState(0);
    const [page, setPage] = useState(0);
    const DataPribadiRef = useRef();
    const PasFotoRef = useRef();
    const InfoKeluargaRef = useRef();
    const RiwayatPendidikanRef = useRef();
    const RiwayatKegiatanRef = useRef();
    const KepribadianRef = useRef();
    const PilihanProgramRef = useRef();
    const PilihanNegaraRef = useRef();
    const RiwayatPerjalananRef = useRef();
    const MotivasiRef = useRef();
    const EssayRef = useRef();
    const RekomendasiRef = useRef();
    // const LanjutkanRef = useRef();

    const goTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    const handleChange = (event, newValue) => {
        const SwalBootstrap = Swal.mixin({
            customClass: {
                confirmButton: 'btn-finalisasi-yakin',
                cancelButton: 'btn-finalisasi-tidak'
            },
            buttonsStyling: false
        })
        if (!save) {
            setValue(newValue);
            setPage(newValue)
        } else if(save) {
            SwalBootstrap.fire({
                title: 'Ada Perubahan Data',
                text: "Ingin Menyimpan?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Simpan',
                cancelButtonText: 'Tidak',
                reverseButtons: true,
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                Dispatch(setSaveAction(false))
                if (result.value) {
                    if (page === 0) {
                        DataPribadiRef.current.saving('next', newValue)
                    } else if (page === 1) {
                        PasFotoRef.current.saving('next', newValue)
                    } else if (page === 2){
                        InfoKeluargaRef.current.saving('next', newValue)
                    } else if (page === 3){
                        RiwayatPendidikanRef.current.saving('next', newValue)
                    } else if (page === 4){
                        RiwayatKegiatanRef.current.saving('next', newValue)
                    } else if (page === 5){
                        KepribadianRef.current.saving('next', newValue)
                    } else if (page === 6){
                        PilihanProgramRef.current.saving('next', newValue)
                    } else if (page === 7){
                        PilihanNegaraRef.current.saving('next', newValue)
                    } else if (page === 8){
                        RiwayatPerjalananRef.current.saving('next', newValue)
                    } else if (page === 9){
                        MotivasiRef.current.saving('next', newValue)
                    } else if (page === 10){
                        EssayRef.current.saving('next', newValue)
                    } else if (page === 11){
                        RekomendasiRef.current.saving('next', newValue)
                    }
                    // else if (page === 12){
                        // LanjutkanRef.current.saving('next', newValue)
                    // }
                } else if ( result.dismiss === Swal.DismissReason.cancel) {
                    setValue(newValue)
                    setPage(newValue)
                }
            })
        }
    };

    const NextPage = (page) => {
        setValue(page)
        setPage(page)
    }

    const BackPage = (page) => {
        setValue(page)
        setPage(page)
    }

    useEffect(()=>{
        if (profile && options) {
            if (!((options?.[profile?.program]?.finalisasi === false) && (profile?.finalisasi === false))) {
                if (profile.page) {
                    setValue(Number(profile.page) >= 12 ? 0 : Number(profile.page))
                    setPage(Number(profile.page) >= 12 ? 0 : Number(profile.page))
                }
            } 
        }
    },[profile?.page, options?.[profile?.program]?.finalisasi])

    const machineLearn = (pageNow) => {
        if (profile) {
            if ((page <= profile.page) && (profile.page >= pageNow)) {
                return false
            } else {
                return true
            }
        } else {
            return true
        }
    }

    return (
        <div className="input-data-container" >
            <span className="input-data-header">
                Input Data
            </span>
            <div className="bread-cab-container" >
                <span className="bread-grey">
                    Input Data /
                </span>
            </div>
            <div style={{ width: '100%', marginTop: 15 }} >
                <Tabs
                    style={{
                        backgroundColor: 'white',
                        borderRadius: 4,
                    }}
                    className="appbar-menu-input-2021"
                    value={page}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    indicatorColor="secondary"
                    textColor="secondary"
                    aria-label="scrollable force tabs example"
                    TabIndicatorProps={{
                        style:{
                            backgroundColor: '#3598DC',
                        }
                    }}
                    ScrollButtonComponent={(props) => {
                        if (
                            props.direction === "left" &&
                            !props.disabled
                        ) {
                            return (
                                <div className="btn-input-appbar">
                                    <ChevronLeftIcon {...props}
                                        style={{
                                            color: 'white',
                                            height: '100%',
                                        }}
                                    />
                                </div>
                            );
                        } else if (
                            props.direction === "right" &&
                            !props.disabled
                        ) {
                            return (
                                <div className="btn-input-appbar">
                                    <ChevronRightIcon {...props}
                                        style={{
                                            height: '100%',
                                            color: 'white'
                                        }}
                                    />
                                </div>
                            );
                        } else {
                            return null;
                        }
                    }}
                >
                    <Tab
                        disabled={machineLearn(0)}
                        label="Data Pribadi"
                        className={"tabs"}
                        style={{
                            backgroundColor: 
                                profile ?
                                    profile.page >= 0 ?
                                        page !== 0 ? 
                                            '#76b6e6'
                                            :
                                            '#3c98dc'
                                        :
                                    'white'
                                :
                                '#3c98dc',
                            color: 
                                profile ?
                                    profile.page >= 0 ?
                                        page !== 0 ? 
                                            'white'
                                            :
                                            'white'
                                        :
                                    '#BBBBBB'
                                :
                                'white',
                            textTransform: 'none',
                            fontSize: '13px',
                            lineHeight: 'normal'
                        }}
                        {...a11yProps(0)}
                    />
                    <Tab
                        disabled={machineLearn(1)}
                        label="Pas Foto"
                        className={"tabs"}
                        style={{
                            backgroundColor: 
                                profile ?
                                    profile.page >= 1 ?
                                        page !== 1 ? 
                                            '#76b6e6'
                                            :
                                            '#3c98dc'
                                        :
                                    'white'
                                :
                                'white',
                            color: 
                                profile ?
                                    profile.page >= 1 ?
                                        page !== 1 ? 
                                            'white'
                                            :
                                            'white'
                                        :
                                    '#BBBBBB'
                                :
                                '#BBBBBB',
                            textTransform: 'none',
                            fontSize: '13px',
                            lineHeight: 'normal'
                        }}
                        {...a11yProps(1)}
                    />
                    <Tab
                        disabled={machineLearn(2)}
                        label="Info Keluarga"
                        className={"tabs"}
                        style={{
                            backgroundColor: 
                                profile ?
                                    profile.page >= 2 ?
                                        page !== 2 ? 
                                            '#76b6e6'
                                            :
                                            '#3c98dc'
                                        :
                                    'white'
                                :
                                'white',
                            color: 
                                profile ?
                                    profile.page >= 2 ?
                                        page !== 2 ? 
                                            'white'
                                            :
                                            'white'
                                        :
                                    '#BBBBBB'
                                :
                                '#BBBBBB',
                            textTransform: 'none',
                            fontSize: '13px',
                            lineHeight: 'normal'
                        }}
                        {...a11yProps(2)}
                    />
                    <Tab
                        disabled={machineLearn(3)}
                        label="Riwayat Pendidikan"
                        className={"tabs"}
                        style={{
                            backgroundColor: 
                                profile ?
                                    profile.page >= 3 ?
                                        page !== 3 ? 
                                            '#76b6e6'
                                            :
                                            '#3c98dc'
                                        :
                                    'white'
                                :
                                'white',
                            color: 
                                profile ?
                                    profile.page >= 3 ?
                                        page !== 3 ? 
                                            'white'
                                            :
                                            'white'
                                        :
                                    '#BBBBBB'
                                :
                                '#BBBBBB',
                            textTransform: 'none',
                            fontSize: '13px',
                            lineHeight: 'normal'
                        }}
                        {...a11yProps(3)}
                    />
                    <Tab
                        disabled={machineLearn(4)}
                        label="Riwayat Kegiatan & Prestasi"
                        className={"tabs"}
                        style={{
                            backgroundColor: 
                                profile ?
                                    profile.page >= 4 ?
                                        page !== 4 ? 
                                            '#76b6e6'
                                            :
                                            '#3c98dc'
                                        :
                                    'white'
                                :
                                'white',
                            color: 
                                profile ?
                                    profile.page >= 4 ?
                                        page !== 4 ? 
                                            'white'
                                            :
                                            'white'
                                        :
                                    '#BBBBBB'
                                :
                                '#BBBBBB',
                            textTransform: 'none',
                            fontSize: '13px',
                            lineHeight: 'normal'
                        }}
                        {...a11yProps(4)}
                    />
                    <Tab
                        disabled={machineLearn(5)}
                        label="Kepribadian"
                        className={"tabs"}
                        style={{
                            backgroundColor: 
                                profile ?
                                    profile.page >= 5 ?
                                        page !== 5 ? 
                                            '#76b6e6'
                                            :
                                            '#3c98dc'
                                        :
                                    'white'
                                :
                                'white',
                            color: 
                                profile ?
                                    profile.page >= 5 ?
                                        page !== 5 ? 
                                            'white'
                                            :
                                            'white'
                                        :
                                    '#BBBBBB'
                                :
                                '#BBBBBB',
                            textTransform: 'none',
                            fontSize: '13px',
                            lineHeight: 'normal'
                        }}
                        {...a11yProps(5)}
                    />
                    <Tab
                        disabled={machineLearn(6)}
                        label="Pilihan Program"
                        className={"tabs"}
                        style={{
                            backgroundColor: 
                                profile ?
                                    profile.page >= 6 ?
                                        page !== 6 ? 
                                            '#76b6e6'
                                            :
                                            '#3c98dc'
                                        :
                                    'white'
                                :
                                'white',
                            color: 
                                profile ?
                                    profile.page >= 6 ?
                                        page !== 6 ? 
                                            'white'
                                            :
                                            'white'
                                        :
                                    '#BBBBBB'
                                :
                                '#BBBBBB',
                            textTransform: 'none',
                            fontSize: '13px',
                            lineHeight: 'normal'
                        }}
                        {...a11yProps(6)}
                    />
                    <Tab
                        disabled={machineLearn(7)}
                        label="Pilihan Negara"
                        className={"tabs"}
                        style={{
                            backgroundColor: 
                                profile ?
                                    profile.page >= 7 ?
                                        page !== 7 ? 
                                            '#76b6e6'
                                            :
                                            '#3c98dc'
                                        :
                                    'white'
                                :
                                'white',
                            color: 
                                profile ?
                                    profile.page >= 7 ?
                                        page !== 7 ? 
                                            'white'
                                            :
                                            'white'
                                        :
                                    '#BBBBBB'
                                :
                                '#BBBBBB',
                            textTransform: 'none',
                            fontSize: '13px',
                            lineHeight: 'normal'
                        }}
                        {...a11yProps(7)}
                    />
                    <Tab
                        disabled={machineLearn(8)}
                        label="Riwayat Perjalanan"
                        className={"tabs"}
                        style={{
                            backgroundColor: 
                                profile ?
                                    profile.page >= 8 ?
                                        page !== 8 ? 
                                            '#76b6e6'
                                            :
                                            '#3c98dc'
                                        :
                                    'white'
                                :
                                'white',
                            color: 
                                profile ?
                                    profile.page >= 8 ?
                                        page !== 8 ? 
                                            'white'
                                            :
                                            'white'
                                        :
                                    '#BBBBBB'
                                :
                                '#BBBBBB',
                            textTransform: 'none',
                            fontSize: '13px',
                            lineHeight: 'normal'
                        }}
                        {...a11yProps(8)}
                    />
                    <Tab
                        disabled={machineLearn(9)}
                        label="Motivasi" 
                        className={"tabs"}
                        style={{
                            backgroundColor: 
                                profile ?
                                    profile.page >= 9 ?
                                        page !== 9 ? 
                                            '#76b6e6'
                                            :
                                            '#3c98dc'
                                        :
                                    'white'
                                :
                                'white',
                            color: 
                                profile ?
                                    profile.page >= 9 ?
                                        page !== 9 ? 
                                            'white'
                                            :
                                            'white'
                                        :
                                    '#BBBBBB'
                                :
                                '#BBBBBB',
                            textTransform: 'none',
                            fontSize: '13px',
                            lineHeight: 'normal'
                        }}
                        {...a11yProps(9)}
                    />
                    <Tab
                        disabled={machineLearn(10)}
                        label="Essay"
                        className={"tabs"}
                        style={{
                            backgroundColor: 
                                profile ?
                                    profile.page >= 10 ?
                                        page !== 10 ? 
                                            '#76b6e6'
                                            :
                                            '#3c98dc'
                                        :
                                    'white'
                                :
                                'white',
                            color: 
                                profile ?
                                    profile.page >= 10 ?
                                        page !== 10 ? 
                                            'white'
                                            :
                                            'white'
                                        :
                                    '#BBBBBB'
                                :
                                '#BBBBBB',
                            textTransform: 'none',
                            fontSize: '13px',
                            lineHeight: 'normal'
                        }}
                        {...a11yProps(10)}
                    />
                    <Tab
                        disabled={machineLearn(11)}
                        label="Rekomendasi"
                        className={"tabs"}
                        style={{
                            backgroundColor: 
                                profile ?
                                    profile.page >= 11 ?
                                        page !== 11 ? 
                                            '#76b6e6'
                                            :
                                            '#3c98dc'
                                        :
                                    'white'
                                :
                                'white',
                            color: 
                                profile ?
                                    profile.page >= 11 ?
                                        page !== 11 ? 
                                            'white'
                                            :
                                            'white'
                                        :
                                    '#BBBBBB'
                                :
                                '#BBBBBB',
                            textTransform: 'none',
                            fontSize: '13px',
                            lineHeight: 'normal'
                        }} 
                        {...a11yProps(11)}
                    />
                    {/* {
                        !((options && options.finalisasi === false) && (profile && profile.finalisasi === false)) &&
                        <Tab
                            disabled={machineLearn(12)}
                            label="Lanjutkan"
                            className={"tabs"}
                            style={{
                                backgroundColor: 
                                    profile ?
                                        profile.page >= 12 ?
                                            page !== 12 ? 
                                                '#76b6e6'
                                                :
                                                '#3c98dc'
                                            :
                                        'white'
                                    :
                                    'white',
                                color: 
                                    profile ?
                                        profile.page >= 12 ?
                                            page !== 12 ? 
                                                'white'
                                                :
                                                'white'
                                            :
                                        '#BBBBBB'
                                    :
                                    '#BBBBBB',
                                textTransform: 'none',
                                fontSize: '13px',
                                lineHeight: 'normal'
                            }} 
                            {...a11yProps(12)}
                        />
                    } */}
                </Tabs>
            </div>
            <div className="input-container-2021" >
                <div className="data-pribadi-header">
                    {
                        value === 0 ?
                        'Data Pribadi'
                        :
                        value === 1 ?
                        'Pas Foto'
                        :
                        value === 2 ?
                        'Info Keluarga'
                        :
                        value === 3 ?
                        'Riwayat Pendidikan'
                        :
                        value === 4 ?
                        'Riwayat Kegiatan & Prestasi'
                        :
                        value === 5 ?
                        'Kepribadian'
                        :
                        value === 6 ?
                        'Pilihan Program'
                        :
                        value === 7 ?
                        'Pilihan Negara'
                        :
                        value === 8 ?
                        'Riwayat Perjalanan ke Luar Negeri'
                        :
                        value === 9 ?
                        'Motivational Statements'
                        :
                        value === 10 ?
                        'Essay'
                        :
                        value === 11 &&
                        'Rekomendasi'
                    }
                </div>
                <div className="input-wrapper-2021">
                    {
                        value == 0 ?
                        <DataPribadi
                            ref={DataPribadiRef}
                            NextPage={NextPage}
                            BackPage={BackPage}
                            handleLogout={handleLogout}
                            goTop={goTop}
                        />
                        :
                        value == 1 ?
                        <PassFoto
                            ref={PasFotoRef}
                            NextPage={NextPage}
                            BackPage={BackPage}
                            handleLogout={handleLogout}
                            goTop={goTop}
                        />
                        :
                        value === 2 ?
                        <InfoKeluarga
                            ref={InfoKeluargaRef}
                            NextPage={NextPage}
                            BackPage={BackPage}
                            handleLogout={handleLogout}
                            goTop={goTop}
                        />
                        :
                        value === 3 ?
                        <RiwayatPendidikan
                            ref={RiwayatPendidikanRef}
                            NextPage={NextPage}
                            BackPage={BackPage}
                            handleLogout={handleLogout}
                            goTop={goTop}
                        />
                        :
                        value === 4 ?
                        <RiwayatKegiatan
                            ref={RiwayatKegiatanRef}
                            NextPage={NextPage}
                            BackPage={BackPage}
                            handleLogout={handleLogout}
                            goTop={goTop}
                        />
                        :
                        value === 5 ?
                        <Kepribadian
                            ref={KepribadianRef}
                            NextPage={NextPage}
                            BackPage={BackPage}
                            handleLogout={handleLogout}
                            goTop={goTop}
                        />
                        :
                        value === 6 ?
                        <PilihanProgram
                            ref={PilihanProgramRef}
                            NextPage={NextPage}
                            BackPage={BackPage}
                            handleLogout={handleLogout}
                            goTop={goTop}
                        />
                        :
                        value === 7 ?
                        <PilihanNegara
                            ref={PilihanNegaraRef}
                            NextPage={NextPage}
                            BackPage={BackPage}
                            handleLogout={handleLogout}
                            goTop={goTop}
                        />
                        :
                        value === 8 ?
                        <RiwayatPerjalanan
                            ref={RiwayatPerjalananRef}
                            NextPage={NextPage}
                            BackPage={BackPage}
                            handleLogout={handleLogout}
                            goTop={goTop}
                        />
                        :
                        value === 9 ?
                        <Motivational
                            ref={MotivasiRef}
                            NextPage={NextPage}
                            BackPage={BackPage}
                            handleLogout={handleLogout}
                            goTop={goTop}
                        />
                        :
                        value === 10 ?
                        <Essay
                            ref={EssayRef}
                            NextPage={NextPage}
                            BackPage={BackPage}
                            handleLogout={handleLogout}
                            goTop={goTop}
                        />
                        :
                        value === 11 &&
                        <Rekomendasi
                            ref={RekomendasiRef}
                            NextPage={NextPage}
                            BackPage={BackPage}
                            handleLogout={handleLogout}
                            goTop={goTop}
                        />
                        // value === 12 ?
                        // <Lanjutkan
                        //     ref={LanjutkanRef}
                        //     NextPage={NextPage}
                        //     BackPage={BackPage}
                        //     handleLogout={handleLogout}
                        //     goTop={goTop}
                        // />
                        // :
                    }
                </div>
            </div>
        </div>
    )
}

export default InputData