import React, { useState, useEffect, Fragment, forwardRef, useImperativeHandle } from 'react'
import './styles.scss'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import UploadImage from '../../../../images/inputData/upload.png'
import Swal from 'sweetalert2'
import { useSelector, useDispatch } from 'react-redux'
import { savePasFoto, setSaveAction, setPageAction } from '../../../../store/actions/peserta'

const SaveAndOutButton = withStyles((theme) => ({
    root: {
        border: '1px solid #3598DC',
        // marginTop: '50px',
        width: '200px',
        height: '44px',
        color: '#3598DC',
        backgroundColor: '#FFFFFF',
        fontFamily: ['Roboto', 'sans-serif'],
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '19px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
            // backgroundColor: '#e5e5e5',
            // boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)',
        },
    },
}))(Button);

const SaveAndNextButton = withStyles((theme) => ({
    root: {
        // marginTop: '50px',
        width: '200px',
        height: '44px',
        color: '#ffffff',
        backgroundColor: '#3598DC',
        fontFamily: ['Roboto', 'sans-serif'],
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '19px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#2f88c6',
            boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)',
        },
        marginLeft: 20
    },
}))(Button);

const PasFoto = forwardRef((props, ref) => {
    const {
        NextPage,
        handleLogout,
        goTop
    } = props
    const Dispatch = useDispatch()
    const options = useSelector(state=> state.pesertaState.options )
    const profile = useSelector(state=>state.pesertaState.profile);
    const save = useSelector(state=>state.pesertaState.checkerSave);
    const [click, setClick] = useState(false)
    const [photo, setPhoto] = useState(null)
    const [photoSelect, setPhotoSelect] = useState(null)

    useEffect(()=>{
        if (profile) {
            if (profile.pasFoto) {
                setPhotoSelect(profile.pasFoto)
            }
        }
    },[profile && profile.pasFoto])

    const onChangeHandler = (file) => {
        if (!save) Dispatch(setSaveAction(true))
        let image = file.target.files[0]
        setPhoto(file.target.files[0])
        let reader = new FileReader()
        if (image && image.type.match('image.*')) {
            reader.readAsDataURL(image)
            reader.onloadend = () => {
                setPhotoSelect(reader.result)
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            }
        }
        
    }

    const goToTab = (page) => {
        NextPage(page)
        goTop()
    }

    const saving = (option) => {
        const ToastMid = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        const SwalBootstrap2 = Swal.mixin({
            customClass: {
                confirmButton: 'btn-finalisasi-yakin-ok',
                cancelButton: 'btn-finalisasi-tidak'
            },
            buttonsStyling: false
        })
        if (options?.[profile?.program]?.finalisasi) {
            setClick(true)
            if (photo) {
                let page = profile.page
                if (profile.page < 2) {
                    page = 2
                }
                const data = new FormData()
                data.append('photo', photo)
                data.set('page', page)
                Dispatch(savePasFoto(data, Swal, option, NextPage, handleLogout, 2, photoSelect, goTop, page))
            } else if ((save === false) && (profile.pasFoto)) {
                ToastMid.fire({
                    icon: 'success',
                    title: `Pas Foto Berhasil Disimpan`,
                })
                let page = profile.page
                if (profile.page < 2) {
                    page = 2
                }
                Dispatch(setPageAction(page))
                NextPage(2)
                goTop()
            } else {
                ToastMid.fire({
                    icon: 'warning',
                    title: `Silahkan Kirimkan Pas Foto`
                })
                goTop()
            }
        } else {
            SwalBootstrap2.fire({
                title: 'Pengisian Data Telah Ditutup',
                text: "",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'OK',
                cancelButtonText: '',
                reverseButtons: true,
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.value) {
                } else if (
                    /* Read more about handling dismissals below */
                        result.dismiss === Swal.DismissReason.cancel
                ) {}
            })
        }
    }

    useImperativeHandle(ref, () => ({
        saving(option, num){
            const ToastMid = Swal.mixin({
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                onOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            const SwalBootstrap2 = Swal.mixin({
                customClass: {
                    confirmButton: 'btn-finalisasi-yakin-ok',
                    cancelButton: 'btn-finalisasi-tidak'
                },
                buttonsStyling: false
            })
            if (options?.[profile?.program]?.finalisasi) {
                setClick(true)
                if (photo) {
                    let page = profile.page
                    if (profile.page < 2) {
                        page = 2
                    }
                    const data = new FormData()
                    data.append('photo', photo)
                    data.set('page', page)
                    Dispatch(savePasFoto(data, Swal, option, NextPage, handleLogout, num, photoSelect, goTop, page))
                } else if ((save === false) && (profile.pasFoto)) {
                    let page = profile.page
                    if (profile.page < 2) {
                        page = 2
                    }
                    Dispatch(setPageAction(page))
                    ToastMid.fire({
                        icon: 'success',
                        title: `Pas Foto Berhasil Disimpan`,
                    })
                    NextPage(2)
                    goTop()
                } else {
                    ToastMid.fire({
                        icon: 'warning',
                        title: `Silahkan Kirimkan Pas Foto`
                    })
                    goTop()
                }
            } else {
                SwalBootstrap2.fire({
                    title: 'Pengisian Data Telah Ditutup',
                    text: "",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'OK',
                    cancelButtonText: '',
                    reverseButtons: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then((result) => {
                    if (result.value) {
                    } else if (
                        /* Read more about handling dismissals below */
                            result.dismiss === Swal.DismissReason.cancel
                    ) {}
                })
            }
        }
    }));

    return(
        <Fragment>
            <div className="data-pribadi-input-area">
                <div className="upload-image" style={{ border: (click && !photoSelect) && '2px dashed red' }}>
                    <input
                        type="file"
                        className="upload-image__file"
                        accept=".jpg, .jpeg"
                        onChange={e=>onChangeHandler(e)}
                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                    />
                    {
                        photoSelect ?
                        <img
                            src={photoSelect}
                            alt="images"
                            className="image-upload-content"
                            style={{
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                width: photoSelect ? '100%' : '',
                            }}
                        />
                        :
                        <div
                            className="image-upload-content"
                            style={{
                                width: photoSelect ? '100%' : '',
                            }}
                        >
                            <img src={UploadImage} alt="icon"/>
                            <span className="image-upload-content-text" >Klik di sini untuk upload foto</span>
                        </div>
                    }
                </div>
                <div className="upload-image-alert-box" >
                    <span className="upload-image-alert-text">
                        Gunakan Foto Berformat JPG/JPEG,<br/>Ukuran foto 4 x 6 dan maksimal 200 KB,<br/>foto berwarna, menghadap ke depan, latar polos, boleh tersenyum kelihatan gigi, foto hanya seorang diri dan tidak boleh selfie.
                    </span>
                </div>
            </div>
            <div className="data-pribadi-wrapper action-wrapper-2021-margintop" >
                {
                    ((options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)) ?
                    <div className="data-pribadi-button-wripper">
                        <SaveAndOutButton className="btn-save-input2021-out" onClick={e=>goToTab(0)} > Lihat Data Sebelumnya </SaveAndOutButton>
                        <SaveAndNextButton className="btn-save-input2021-next" onClick={e=>goToTab(2)} > Lihat Data Selanjutnya </SaveAndNextButton>
                    </div>
                    :
                    <div className="data-pribadi-button-wripper">
                        <SaveAndOutButton className="btn-save-input2021-out" onClick={e=>saving('out')} > Simpan & Keluar </SaveAndOutButton>
                        <SaveAndNextButton className="btn-save-input2021-next" onClick={e=>saving('next')} > Simpan & Lanjutkan </SaveAndNextButton>
                    </div>
                }
            </div>
        </Fragment>
    )
})

export default PasFoto