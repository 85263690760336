import React, { useState , useEffect, useRef } from 'react'
import './styles.scss'
import BinabudayaLogo from '../../images/binabudaya-white.svg'
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux';
import { verifikasiEmail } from '../../store/actions/peserta'

const Loading = withStyles({
    root:{
        marginTop: 5,
        margin: 'auto auto'
    },
    colorPrimary: {
        backgroundColor: '#b1d5f1',
    },
    barColorPrimary: {
        backgroundColor: '#edf5fb',
    }
})((props) => <LinearProgress variant="determinate" {...props} />);

function Verification({match}) {
    const progressRef = useRef(() => {});
    const History = useHistory()
    const Dispatch = useDispatch()
    const { program, id } = useParams()
    const [progress, setProgress] = useState(0);
    const [success, setSuccess] = useState(false)

    useEffect(()=>{
        progressRef.current = (ToastMid, timer) => {
            if (progress >= 100) {
                clearInterval(timer)
                setProgress(100);
                if (success) {
                    if (success.message) {
                        ToastMid.fire({
                            icon: 'error',
                            title: 'Email sudah terverifikasi'
                        })
                        History.replace(`/masuk?program=${program}`)
                    } else {
                        ToastMid.fire({
                            icon: 'success',
                            title: 'Verifikasi email berhasil'
                        })
                        History.replace(`/masuk?program=${program}`)
                    }
                }
            } else {
                const diff = Math.random() * 30;
                setProgress(progress + diff);
            }
        };
    })

    useEffect(() => {
        Dispatch(verifikasiEmail(History,{ id, setSuccess }, Swal))
        const ToastMid = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        
        const timer = setInterval(() => {
            progressRef.current(ToastMid, timer);
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div style={{
            backgroundColor: '#3c98dc',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column'
        }} >
            <div className="box-verification-124" >
                <img src={BinabudayaLogo} alt="logo" draggable={false}
                    className="logo-verification-binabudaya"
                />
                <span className="loading-text-verification" >
                    mohon tunggu ...
                </span>
                <Loading value={progress} />
            </div>
        </div>
    )
}

export default Verification