import React, { useState, useEffect } from 'react'
import './styles.scss'
import BinabudayaLogo from '../../images/homepage/binabud.svg'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { createPesertaYK, createPesertaAFS } from '../../store/actions/peserta'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'

const ActivationButton = withStyles((theme) => ({
    root: {
        marginTop: '50px',
        width: '100%',
        height: '44px',
        color: '#ffffff',
        backgroundColor: '#3598DC',
        fontFamily: ['Roboto', 'sans-serif'],
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '19px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#2f88c6',
            boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)',
        },
    },
}))(Button);

const useQuery = () => {
    return new URLSearchParams(useLocation().search)
}

export default function Login() {
    const History = useHistory()
    const Dispath = useDispatch()
    const query = useQuery()
    const chapterList = useSelector(state=> state.chapterState.chapter )
    const options = useSelector(state=> state.pesertaState.options )
    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordVerification, setShowPasswordVerification] = useState(false)
    const [passwordVerification, setPasswordVerification] = useState('')
    const [click, setClick] = useState(false)
    const [sendData, setSendData] = useState({
        username: '',
        email: '',
        chapter: '',
        phone: '',
        password: '',
        program : ''
    })

    useEffect(()=>{
        const SwalBootstrap1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn-finalisasi-yakin',
                cancelButton: 'btn-finalisasi-tidak'
            },
            buttonsStyling: false
        })
        if (query.get('program')) {
            if (
                query.get('program') !== 'afs' &&
                query.get('program') !== 'yes'
                // query.get('program') !== 'kakehashi'
            ) {
                History.replace('/')
            }
            if (options) {
                if (query.get('program') === 'yes') {
                    if (options?.YES?.pendaftaran === false) {
                        SwalBootstrap1.fire({
                            title: "Pendaftaran Telah Ditutup",
                            text: "",
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonText: 'Kembali',
                            cancelButtonText: '',
                            reverseButtons: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false
                        }).then((result) => {
                            if (result.value) {
                                if (query.get('program') === 'afs') {
                                    History.replace(`/aktivasi-pin`)
                                } else {
                                    History.replace(`/masuk?program=${query.get('program')}`)
                                }
                            } else if ( result.dismiss === Swal.DismissReason.cancel) {
                            }
                        })
                    }
                }
                if (query.get('program') === 'afs') {
                    if (options?.AFS?.pendaftaran === false) {
                        SwalBootstrap1.fire({
                            title: "Pendaftaran Telah Ditutup",
                            text: "",
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonText: 'Kembali',
                            cancelButtonText: '',
                            reverseButtons: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false
                        }).then((result) => {
                            if (result.value) {
                                if (query.get('program') === 'afs') {
                                    History.replace(`/aktivasi-pin`)
                                } else {
                                    History.replace(`/masuk?program=${query.get('program')}`)
                                }
                            } else if ( result.dismiss === Swal.DismissReason.cancel) {
                            }
                        })
                    }
                }
            }
            setSendData({
                ...sendData,
                program: (query.get('program')).toUpperCase()
            })
            if ((query.get('program')).toUpperCase() === 'AFS') {
                if (!sessionStorage.getItem('secret')) {
                    History.replace('/')
                } else {
                    const dataSession = JSON.parse(sessionStorage.getItem('secret'))
                    setSendData({
                        username: '',
                        email: '',
                        chapter: dataSession.chapter,
                        phone: '',
                        password: '',
                        program : 'AFS',
                        pinId: dataSession.id,
                        date: new Date(),
                    })
                }
            }
        } else if (
                query.get('program') !== 'afs' ||
                query.get('program') !== 'yes'
                // query.get('program') !== 'kakehashi'
            ) {
            History.replace('/')
        }

    },[query.get('program'), options])

    const handleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleShowPasswordVerification = () => {
        setShowPasswordVerification(!showPasswordVerification)
    }

    const Register = () => {
        setClick(true)

        const ToastMid = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        if (sendData.password === passwordVerification) {
            if (
                sendData.username &&
                sendData.email &&
                sendData.chapter &&
                sendData.phone &&
                sendData.password &&
                passwordVerification
            ) {
                if (sendData.program === 'YES' || sendData.program === 'KAKEHASHI') {
                    Dispath(createPesertaYK(History, sendData, Swal))
                } else if (sendData.program === 'AFS'){
                    Dispath(createPesertaAFS(History, sendData, Swal))
                }
            } else {
                ToastMid.fire({
                    icon: 'warning',
                    title: `Isi Seluruh Syarat Pendaftaran Peserta`,
                })
            }
        } else {
            ToastMid.fire({
                icon: 'warning',
                title: `Pastikan Password Sama`,
            })
        }
    }

    const handleChange = (key, value) => {
        switch (key) {
            case 'username':
                setSendData({
                    ...sendData,
                    username: value
                })
                break;
            case 'email':
                setSendData({
                    ...sendData,
                    email: value
                })
                break;
            case 'chapter':
                    setSendData({
                        ...sendData,
                        chapter: value
                    })
                break;
            case 'phone':
                setSendData({
                    ...sendData,
                    phone: value
                })
                break;
            case 'password':
                setSendData({
                    ...sendData,
                    password: value
                })
                break;
            case 'password2nd':
                setPasswordVerification(value)
                break;
            default:
                break;
        }
    }

    return (
        <div className="register-container-wrapper">
            <img src={BinabudayaLogo} alt="binabudaya" className="logo-binabud-register" />
            <div className="register-contain-text" >
                <span className="activation-font-top" >
                    Silahkan Buat Akun
                </span>

                <div className="register-container-card" >

                    <div className="component-input-register" style={{ marginTop: 0 }}>
                        <span className="card-font-text" >Program</span>
                        <input
                            disabled
                            type="text"
                            placeholder="Program"
                            value={
                                sendData.program === 'KAKEHASHI' ?
                                'ASIA KAKEHASHI PROJECT' :
                                sendData.program
                            }
                            className="login-input-theme"
                            style={{ border: (click && !sendData.program) && '1px solid red' }}
                        />
                    </div>

                    <div className="component-input-register" >
                        <span className="card-font-text" >Username</span>
                        <input
                            onChange={e=>handleChange('username', e.target.value)}
                            type="text"
                            placeholder="Username"
                            className="login-input-theme"
                            style={{ border: (click && !sendData.username) && '1px solid red' }}
                        />
                    </div>

                    <div className="component-input-register" >
                        <span className="card-font-text" >Email</span>
                        <input
                            onChange={e=>handleChange('email', e.target.value)}
                            type="text"
                            placeholder="example@gmail.com"
                            className="login-input-theme"
                            style={{ textTransform: 'lowercase', border: (click && !sendData.email) && '1px solid red' }}
                        />
                        <span className="label-card" >Pastikan email yang digunakan aktif</span>
                    </div>

                    <div className="component-input-register" >
                        <span className="card-font-text" >Chapter</span>
                        <select  className="login-input-theme"
                            disabled={(query.get('program')).toUpperCase() === 'AFS' ? true : false}
                            onChange={e=>handleChange('chapter', e.target.value)}
                            style={{ border:
                                (click && !sendData.chapter) && '1px solid red',
                                color: sendData.chapter ? 'black' : '#d3d5d7',
                                textTransform: 'capitalize'
                            }}
                            value={ sendData.chapter }
                        >
                            <option value="" style={{ color: sendData.chapter ? '' : '#d3d5d7' }} disabled selected>Pilih Penerbit/Chapter</option>
                            {
                                chapterList &&
                                chapterList
                                .filter(chap => chap.status === true)
                                .sort((a,b) =>  a.chapter < b.chapter ? -1 : a.chapter > b.chapter ? 1 : 0)
                                .map((chapter,i)=>(
                                <option
                                    style={{ color: sendData.chapter === chapter._id ? '#3c98dc' : 'black' }}
                                    key={i}
                                    value={chapter._id}
                                >
                                        {chapter.chapter}
                                    </option>
                                ))
                            }
                        </select>
                    </div>

                    <div className="component-input-register" >
                        <span className="card-font-text" >No. HP</span>
                        <input 
                            onChange={e=>handleChange('phone', e.target.value)}
                            type="text"
                            placeholder="08123456***"
                            className="nohp-input-wrapper login-input-theme"
                            style={{ border: (click && !sendData.phone) && '1px solid red' }}
                        />
                    </div>

                    <div className="component-input-register" >
                        <span className="card-font-text" >Password</span>
                        <div className="password-wraper" >
                            <input 
                                onChange={e=>handleChange('password', e.target.value)}
                                type={!showPassword ? 'password' : 'text'}
                                placeholder="Password"
                                className="login-input-theme"
                                style={{ border: (click && !sendData.password) && '1px solid red' }}
                            />
                            {
                                showPassword ?
                                <FontAwesomeIcon
                                    icon={faEye}
                                    className="eye-custom"
                                    style={{ color: '#3c98dc' }}
                                    onClick={() => handleShowPassword()}
                                />
                                    :
                                <FontAwesomeIcon
                                    icon={faEye}
                                    className="eye-custom"
                                    onClick={() => handleShowPassword()}
                                />
                            }
                        </div>
                    </div>

                    <div className="component-input-register" >
                        <span className="card-font-text" >Konfirmasi Password</span>
                        <div className="password-wraper" >
                            <input 
                                onChange={e=>handleChange('password2nd', e.target.value)}
                                type={!showPasswordVerification ? 'password' : 'text'}
                                placeholder="Konfirmasi Password"
                                className="login-input-theme"
                                style={{ border: (click && !passwordVerification) && '1px solid red' }}
                            />
                            {
                                showPasswordVerification ?
                                <FontAwesomeIcon
                                    icon={faEye}
                                    className="eye-custom"
                                    style={{ color: '#3c98dc' }}
                                    onClick={() => handleShowPasswordVerification()}
                                />
                                    :
                                <FontAwesomeIcon
                                    icon={faEye}
                                    className="eye-custom"
                                    onClick={() => handleShowPasswordVerification()}
                                />
                            }
                        </div>
                    </div>

                    <ActivationButton onClick={()=>Register()} >Daftar</ActivationButton>
                </div>
            </div>
        </div>
    )
}