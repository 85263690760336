import { combineReducers } from 'redux'
import PesertaReducer from './peserta'
import ChapterReducer from './chapter'
import DaerahReducer from './daerah'
import FilterReducer from './filter'

const reducers = combineReducers({
    pesertaState : PesertaReducer,
    chapterState : ChapterReducer,
    daerahState : DaerahReducer,
    filterState : FilterReducer
})

export default reducers