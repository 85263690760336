import React, { useEffect, useState, Fragment } from "react";
import "./styles.scss";

// EDITOR
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw } from "draft-js";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

function Essay() {
  const profile = useSelector((state) => state.pesertaState.profile);
  const essay = useSelector((state) => state.filterState.essay);

  useEffect(() => {
    if (profile?.dataPribadi && essay) {
      document.title = `Berkas Essay ${getMyName(
        profile?.dataPribadi
      ).toUpperCase()}.pdf`;
      Swal.close();
      setTimeout(() => {
        window.print();
        window.close();
      }, 2000);
    }
  }, [profile?.dataPribadi, essay]);

  useEffect(() => {
    Swal.showLoading();
  }, []);

  const getMyName = (dataPribadi) => {
    let namaDepan;
    let namaTengah;
    let namaBelakang;
    if (dataPribadi) {
      namaDepan = dataPribadi.namaDepan;
      namaTengah = dataPribadi.namaTengah;
      namaBelakang = dataPribadi.namaBelakang;
    }
    return `${namaDepan ? namaDepan + " " : ""}${
      namaTengah ? namaTengah + " " : ""
    }${namaBelakang ? namaBelakang + " " : ""}`;
  };

  return (
    <div className="container-print" style={{ boxSizing: "border-box" }}>
      <div className="container-page" style={{ boxSizing: "border-box" }}>
        <div className="divider-page2-pdf" style={{ marginBottom: 15 }} />
        <div className="page2-header-wrapper">
          <span className="font-header-page2">ESSAY</span>
        </div>
        <div className="page2-content-wrapper" style={{ marginTop: 5 }}>
          {profile?.program === "YES"
            ? essay?.yes?.map((data, i) => (
                <Fragment>
                  <div
                    style={{
                      marginTop: "20px",
                    }}
                  />
                  <span
                    className="page2-row-left"
                    style={{
                      maxWidth: "100%",
                      minWidth: "100%",
                      textAlign: "left",
                    }}
                  >
                    {i + 1}. {data}
                  </span>
                  {profile?.essay[`essay${i + 1}`]?.text ? (
                    <Editor
                      editorState={EditorState.createWithContent(
                        convertFromRaw(
                          JSON.parse(profile.essay[`essay${i + 1}`]?.text || '')
                        )
                      )}
                      toolbarHidden={true}
                      readOnly={true}
                      wrapperClassName="pdf-text-editor"
                      editorClassName="pdf-text-editor-font"
                    />
                  ) : (
                    "-"
                  )}
                </Fragment>
              ))
            : profile?.program === "AFS" &&
              essay?.afs?.map((data, i) => (
                <Fragment>
                  <div
                    style={{
                      marginTop: "20px",
                    }}
                  />
                  <span
                    className="page2-row-left"
                    style={{
                      maxWidth: "100%",
                      minWidth: "100%",
                      textAlign: "left",
                    }}
                  >
                    {i + 1}. {data}
                  </span>
                  {profile?.essay[`essay${i + 1}`]?.text ? (
                    <Editor
                      editorState={EditorState.createWithContent(
                        convertFromRaw(
                          JSON.parse(profile.essay[`essay${i + 1}`]?.text || '')
                        )
                      )}
                      toolbarHidden={true}
                      readOnly={true}
                      wrapperClassName="pdf-text-editor"
                      editorClassName="pdf-text-editor-font"
                    />
                  ) : (
                    "-"
                  )}
                </Fragment>
              ))}
        </div>
      </div>
    </div>
  );
}

export default Essay;
