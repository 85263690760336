import React, {
  useState,
  useEffect,
  Fragment,
  forwardRef,
  useImperativeHandle,
} from "react";
import "./styles.scss";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import server from '../../../../endpoint'
import UploadIcon from "../../../../images/berkas/upload.svg";
import PDFIcon from "../../../../images/pdf.svg";
import { downloadFile } from '../../../../store/actions/document'
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import {
  saveKepribadian,
  setSaveAction,
} from "../../../../store/actions/peserta";
import { Link } from 'react-router-dom';
import DownloadIcon from "../../../../images/berkas/download.svg";

const DownloadButton = withStyles(() => ({
  root: {
    marginTop: "auto",
    width: "100%",
    height: "44px",
    color: "#FFFFFF",
    backgroundColor: "#3598DC",
    fontFamily: ["Roboto", "sans-serif"],
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "19px",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#2f88c6",
    },
  },
}))(Button);

const UploadButton = withStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    color: "#FFFFFF",
    backgroundColor: "#3598DC",
    fontFamily: ["Roboto", "sans-serif"],
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "19px",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#2f88c6",
      // boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)',
    },
  },
}))(Button);

const SaveAndOutButton = withStyles((theme) => ({
  root: {
    border: "1px solid #3598DC",
    // marginTop: '50px',
    width: "200px",
    height: "44px",
    color: "#3598DC",
    backgroundColor: "#FFFFFF",
    fontFamily: ["Roboto", "sans-serif"],
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "19px",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      // backgroundColor: '#e5e5e5',
      // boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)',
    },
  },
}))(Button);

const SaveAndNextButton = withStyles((theme) => ({
  root: {
    // marginTop: '50px',
    width: "200px",
    height: "44px",
    color: "#ffffff",
    backgroundColor: "#3598DC",
    fontFamily: ["Roboto", "sans-serif"],
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "19px",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#2f88c6",
      boxShadow:
        "0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)",
    },
    marginLeft: 20,
  },
}))(Button);

const Kepribadian = forwardRef((props, ref) => {
  const { NextPage, handleLogout, goTop } = props;
  const Dispatch = useDispatch();
  const options = useSelector((state) => state.pesertaState.options);
  const profile = useSelector((state) => state.pesertaState.profile);
  const berkas = useSelector(state=> state.filterState.berkas);
  const save = useSelector((state) => state.pesertaState.checkerSave);
  const [click, setClick] = useState(false);
  const [sendData, setSendData] = useState({
    file: "",
    size: null
  });

  useEffect(() => {
    if (profile?.kepribadian) {
      let dataParse = {
        file: ""
      };
      for (let key in profile.kepribadian) {
        if (
          key === "file" ||
          key === "size"
        ) {
          dataParse[key] = profile.kepribadian[key];
        }
      }
      setSendData(dataParse);
    }
  }, [profile?.kepribadian]);

  const getNameFile = (file) => {
    if (typeof sendData?.file === 'string' && file?.includes('-FILE-')) {
      return file.split("-FILE-")[1];
    }
    return file?.name || "";;
  };

  const getSizeFile = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
  };

  const goToTab = (page) => {
    NextPage(page);
    goTop();
  };

  const handleDownload = (e, program, option) => {
    if (e) e.preventDefault()
    let fileName = berkas[program][option].fileName
    let uri = `${server}${berkas[program][option].link}`
    if (fileName && uri) {
      Dispatch(downloadFile(uri, fileName ,Swal))
    }
  }

  const handleUpload = (file) => {
    if (!save) Dispatch(setSaveAction(true));
    let value
    if (file) value = file.target.files[0]
    setSendData({
      ...sendData,
      file: value,
      size: value?.size,
    });
  }

  const saving = (option) => {
    const ToastMid = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    const SwalBootstrap2 = Swal.mixin({
      customClass: {
        confirmButton: "btn-finalisasi-yakin-ok",
        cancelButton: "btn-finalisasi-tidak",
      },
      buttonsStyling: false,
    });
    if (options?.[profile?.program]?.finalisasi) {
      setClick(true);
      let flag = false;
      let formData = new FormData()
      let sendFile = false;
      let dataParse = {};
      let dataSave = {};

      if (
        !sendData?.file || !sendData?.size
      ) {
        flag = true;
        ToastMid.fire({
          icon: "warning",
          title: `Silahkan Lengkapi Data`,
        });
        goTop();
        return;
      }
      let page = profile.page;
      if (profile.page < 6) {
        page = 6;
      }

      dataSave = sendData
      dataParse = sendData
      if (!(typeof sendData?.file === 'string' && (sendData?.file?.includes("http://") || sendData?.file?.includes("https://")))) {
        sendFile = true
        formData.append(`file`, sendData?.file)
        formData.append(`size`, sendData?.size)
        formData.append('data', JSON.stringify(dataParse))
        formData.append('page', page)
      }

      if (!flag) {
        Dispatch(
          saveKepribadian(
            { data: dataSave, page, formData: sendFile ? formData : {data: JSON.stringify(dataParse), page} },
            Swal,
            option,
            NextPage,
            handleLogout,
            6,
            goTop
          )
        );
      }
    } else {
      SwalBootstrap2.fire({
        title: "Pengisian Data Telah Ditutup",
        text: "",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        cancelButtonText: "",
        reverseButtons: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.value) {
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      });
    }
  };

  useImperativeHandle(ref, () => ({
    saving(option, num) {
      const ToastMid = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      const SwalBootstrap2 = Swal.mixin({
        customClass: {
          confirmButton: "btn-finalisasi-yakin-ok",
          cancelButton: "btn-finalisasi-tidak",
        },
        buttonsStyling: false,
      });
      if (options?.[profile?.program]?.finalisasi) {
        setClick(true);
        let flag = false;
        let formData = new FormData()
        let sendFile = false;
        let dataParse = {};
        let dataSave = {};
  
        if (
          !sendData?.file || !sendData?.size
        ) {
          flag = true;
          ToastMid.fire({
            icon: "warning",
            title: `Silahkan Lengkapi Data`,
          });
          goTop();
          return;
        }
        let page = profile.page;
        if (profile.page < 6) {
          page = 6;
        }
  
        dataSave = sendData
        dataParse = sendData
        if (!(typeof sendData?.file === 'string' && (sendData?.file?.includes("http://") || sendData?.file?.includes("https://")))) {
          sendFile = true
          formData.append(`file`, sendData?.file)
          formData.append(`size`, sendData?.size)
          formData.append('data', JSON.stringify(dataParse))
          formData.append('page', page)
        }
  
        if (!flag) {
          Dispatch(
            saveKepribadian(
              { data: dataSave, page, formData: sendFile ? formData : {data: JSON.stringify(dataParse), page} },
              Swal,
              option,
              NextPage,
              handleLogout,
              6,
              goTop
            )
          );
        }
      } else {
        SwalBootstrap2.fire({
          title: "Pengisian Data Telah Ditutup",
          text: "",
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "OK",
          cancelButtonText: "",
          reverseButtons: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.value) {
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
          }
        });
      }
    },
  }));

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div style={{
        display: 'flex',
        flexWrap: 'wrap'
      }}>
        <div className="box-white-download-berkas">
          <div className="box-white-blue-download-berkas">
            <img src={DownloadIcon} alt="icon" className="icon-download" />
            <span className="box-white-blue-download-berkas-text-dwd">
              Download<br/>Kepribadian
            </span>
          </div>
          <div style={{ marginTop: "auto" }}>
            <DownloadButton
              onClick={(e) => handleDownload(e, profile?.program, "Kepribadian")}
            >
              Download
            </DownloadButton>
          </div>
        </div>

        <div className="box-white-upload-berkas"
          style={{
            border: (!sendData?.file &&  click) && "1px solid red",
          }}
        >
          <div className="box-white-blue-upload-berkas">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: sendData?.file && "100%",
              }}
            >
              <img
                src={
                  sendData?.file ? PDFIcon : UploadIcon
                }
                alt="icon"
                className="icon-download"
                draggable={false}
              />
              {sendData?.file && (
                <Fragment>
                  <div className="box-white-file-name">
                    {getNameFile(sendData?.file)}
                  </div>
                  <div className="box-white-size-file">
                    {getSizeFile(sendData?.size)}
                  </div>
                </Fragment>
              )}
            </div>
            <span
              className="box-white-blue-download-berkas-text-dwd"
              style={{
                fontSize: sendData?.file && 15,
                width: "100%",
                marginTop: sendData?.file && "auto",
              }}
            >
              Upload<br/>Kepribadian
            </span>
          </div>
          
          <Fragment>
            <div
              className="upload-image"
              style={{
                width: "100%",
                height: "50px",
                border: "none",
                backgroundColor: "white",
                marginTop: "auto",
                display: "flex"
              }}
            >
              <input
                type="file"
                id="pernyataanSekolah"
                className="upload-image__file"
                style={{
                  marginLeft: 0,
                }}
                accept=".pdf"
                onChange={handleUpload}
              />
              <div
                className="image-upload-content"
                style={{ width: "100%" }}
              >
                <UploadButton>
                  {sendData?.file
                    ? "Ganti File"
                    : "Pilih File"}
                </UploadButton>
              </div>
            </div>
            {sendData?.file && (
              <div
                className="upload-image"
                style={{
                  width: "100%",
                  height: "50px",
                  border: "none",
                  backgroundColor: "white",
                  marginTop: "10px",
                  display: "flex",
                }}
              >
                {
                  (typeof sendData?.file === 'string' && (sendData?.file?.includes("http://") || sendData?.file?.includes("https://"))) ?
                  <Link
                    to={{ pathname: sendData?.file }}
                    target="_BLANK"
                    style={{
                      textDecoration: "none",
                      marginLeft: "auto",
                      cursor: "pointer",
                      width: "100%",
                    }}
                  >
                    <UploadButton>Lihat</UploadButton>
                  </Link>
                  :
                  <UploadButton
                    style={{
                      background: !sendData?.file ? click ? "red" : "grey" : "#3598DC",
                    }}
                    onClick={() => {
                      if (sendData?.file) {
                        const file = sendData?.file
                        if (file?.type) {
                          let reader = new FileReader();
                          reader.readAsArrayBuffer(file);

                          const fileType = [
                            'application/pdf',
                            'application/msword',
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                            'application/vnd.ms-excel',
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            'application/vnd.oasis.opendocument.text',
                            'text/plain',
                          ]

                          for (let type of fileType) {
                            if (file?.type === type) {
                              reader.onloadend = () => {
                                const blob = new Blob([reader.result], { type});
                                const url = URL.createObjectURL(blob);
                                window.open(url, '_blank');
                              };
                              reader.onerror = function (error) {
                                console.log('Error: ', error);
                              };
                              return
                            }
                          }
                        }
                      }
                    }}
                  >
                    Lihat
                  </UploadButton>
                }
              </div>
            )}
            <div
              style={{
                display: "flex",
                marginTop: 10,
              }}
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                style={{ color: "#f44336", fontSize: 17, marginRight: 5 }}
              />
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                Maksimal 2MB
              </span>
            </div>
          </Fragment>
          
          {profile?.finalisasi === true && (
            <div
              className="upload-image"
              style={{
                width: "100%",
                height: "50px",
                border: "none",
                backgroundColor: "white",
                marginTop: "auto",
                display: "flex",
              }}
            >
              <Link
                to={{ pathname: sendData?.file }}
                target="_BLANK"
                style={{
                  textDecoration: "none",
                  marginLeft: "auto",
                  cursor: "pointer",
                  width: "100%",
                }}
              >
                <UploadButton>Lihat</UploadButton>
              </Link>
            </div>
          )}
        </div>
      </div>

      <div className="data-pribadi-wrapper action-wrapper-2021-margintop">
          {options?.[profile?.program]?.finalisasi === false ||
          profile?.finalisasi === true ? (
            <div className="data-pribadi-button-wripper">
              <SaveAndOutButton
                className="btn-save-input2021-out"
                onClick={(e) => goToTab(4)}
              >
                {" "}
                Lihat Data Sebelumnya{" "}
              </SaveAndOutButton>
              <SaveAndNextButton
                className="btn-save-input2021-next"
                onClick={(e) => goToTab(6)}
              >
                {" "}
                Lihat Data Selanjutnya{" "}
              </SaveAndNextButton>
            </div>
          ) : (
            <div className="data-pribadi-button-wripper">
              <SaveAndOutButton
                className="btn-save-input2021-out"
                onClick={(e) => saving("out")}
              >
                {" "}
                Simpan & Keluar{" "}
              </SaveAndOutButton>
              <SaveAndNextButton
                className="btn-save-input2021-next"
                onClick={(e) => saving("next")}
              >
                {" "}
                Simpan & Lanjutkan{" "}
              </SaveAndNextButton>
            </div>
          )}
        </div>
    </div>
  );
});

export default Kepribadian;
