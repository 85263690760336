import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useSelector } from "react-redux";
import BannerAFS from "../../images/AFSpdf.png";
import BannerKAKEHASHI from "../../images/KAKEHASHIpdf.png";
import BannerYES from "../../images/YESpdf.png";
import Swal from "sweetalert2";

function KartuPeserta() {
  const profile = useSelector((state) => state.pesertaState.profile);
  const chapterList = useSelector((state) => state.chapterState.chapter);
  const propinsiDB = useSelector((state) => state.daerahState.propinsi);
  const kabupatenDB = useSelector((state) => state.daerahState.kabupaten);

  useEffect(() => {
    if (profile && chapterList) {
      document.title = `Kartu Tanda Peserta ${getMyName(
        profile?.dataPribadi
      ).toUpperCase()}.pdf`;
      Swal.close();
      setTimeout(() => {
        window.print();
        window.close();
      }, 2000);
    }
  }, [profile && chapterList]);

  useEffect(() => {
    Swal.showLoading();
  }, []);

  const getChapterCode = (chapterList, chapter) => {
    if (chapterList && chapter) {
      let temp = chapterList?.filter((a) => a._id === chapter)?.[0];
      return temp?.code || "-";
    }
  };

  const getChapterName = (chapterList, chapter) => {
    if (chapterList && chapter) {
      let temp = chapterList?.filter((a) => a._id === chapter)?.[0];
      return temp?.chapter || "-";
    }
  };

  const getMyName = (dataPribadi) => {
    let namaDepan;
    let namaTengah;
    let namaBelakang;
    if (dataPribadi) {
      namaDepan = dataPribadi.namaDepan;
      namaTengah = dataPribadi.namaTengah;
      namaBelakang = dataPribadi.namaBelakang;
    }
    return `${namaDepan ? namaDepan + " " : ""}${
      namaTengah ? namaTengah + " " : ""
    }${namaBelakang ? namaBelakang + " " : ""}`;
  };

  const getSchool = (school) => {
    return `${school ? school : "-"}`;
  };

  const getPhone = (phone) => {
    return `${phone ? phone : "-"}`;
  };

  const getAddress = (dataPribadi) => {
    let alamat;
    let kecamatan;
    let kota;
    let propinsi;
    let kodepos;
    if (dataPribadi) {
      if (dataPribadi.propinsi) {
        if (propinsiDB.filter((a) => a.id === dataPribadi.propinsi)[0]) {
          propinsi = propinsiDB.filter((a) => a.id === dataPribadi.propinsi)[0]
            .propinsi;
        }
      }
      if (kabupatenDB[dataPribadi.propinsi]) {
        if (
          kabupatenDB[dataPribadi.propinsi].filter(
            (a) => a.id === dataPribadi.kabupaten
          )[0]
        ) {
          kota = kabupatenDB[dataPribadi.propinsi].filter(
            (a) => a.id === dataPribadi.kabupaten
          )[0].kabupaten;
        }
      }
      alamat = dataPribadi.alamat;
      kecamatan = dataPribadi.kecamatan;
      kodepos = dataPribadi.kodepos;
    }
    return `${alamat ? alamat : "-"}, Kecamatan ${
      kecamatan ? kecamatan : "-"
    }, Kota/Kabupaten ${kota ? kota : "-"}, Propinsi ${
      propinsi ? propinsi : "-"
    } ${kodepos ? kodepos : "-"}`;
  };

  const getBanner = (program) => {
    let banner = "";
    if (program) {
      if (program === "AFS") {
        banner = BannerAFS;
      } else if (program === "YES") {
        banner = BannerYES;
      } else if (program === "KAKEHASHI") {
        banner = BannerKAKEHASHI;
      }
    }
    return banner;
  };

  const getClassNameBanner = (program) => {
    let className = "";
    if (program) {
      if (program === "AFS") {
        className = "container-header-print-afs-new";
      } else if (program === "YES") {
        className = "container-header-print";
      } else if (program === "KAKEHASHI") {
        className = "container-header-print";
      }
    }
    return className;
  };

  return (
    <div className="container-print" style={{ boxSizing: "border-box" }}>
      <div className="container-page-card">
        <div style={{ width: "100%" }}>
          <img
            src={getBanner(profile?.program)}
            alt="header"
            className={getClassNameBanner(profile?.program)}
          />
        </div>
        <div className="wrapper-card-wrapper-left">
          <span className="wrapper-card-wrapper-blue">Tanda Peserta</span>
          <span
            className="wrapper-card-wrapper-black"
            style={{ marginTop: 8, textTransform: "capitalize" }}
          >
            Seleksi Pertukaran Pelajar Program{" "}
            {profile?.program === "KAKEHASHI"
              ? "Asia Kakehashi Project"
              : profile?.program}
          </span>
        </div>
        <div className="wrapper-card-double" style={{ marginTop: 20 }}>
          <div className="wrapper-card-left">
            <div className="wrapper-containt" style={{ width: "100%" }}>
              <span className="wrapper-containt-blue">NOMOR PESERTA</span>
              <span
                className="wrapper-containt-black-bold"
                style={{ marginTop: 8 }}
              >
                YBA/YP25-26/
                {getChapterCode(chapterList, profile?.chapter)}/
                {profile?.program === "KAKEHASHI" ? "MXT" : profile?.program}/
                {profile?.nomorUrut !== "0000" &&
                profile?.statusVerifikasiBerkas === true
                  ? profile?.nomorUrut
                  : "-"}
              </span>
            </div>
            <div className="wrapper-containt-row" style={{ marginTop: 20 }}>
              <div className="wrapper-containt" style={{ width: "70%" }}>
                <span className="wrapper-containt-blue">NAMA PESERTA</span>
                <span
                  className="wrapper-containt-black"
                  style={{ marginTop: 8, textTransform: "uppercase" }}
                >
                  {getMyName(profile?.dataPribadi)}
                </span>
              </div>

              <div className="wrapper-containt" style={{ width: "30%" }}>
                <span className="wrapper-containt-blue">CHAPTER</span>
                <span
                  className="wrapper-containt-black"
                  style={{ marginTop: 8, textTransform: "capitalize" }}
                >
                  {getChapterName(chapterList, profile?.chapter)}
                </span>
              </div>
            </div>
            <div className="wrapper-containt-row" style={{ marginTop: 20 }}>
              <div className="wrapper-containt" style={{ width: "70%" }}>
                <span className="wrapper-containt-blue">Asal Sekolah</span>
                <span
                  className="wrapper-containt-black"
                  style={{ marginTop: 8 }}
                >
                  {getSchool(profile?.riwayatPendidikan?.namaSMA)}
                </span>
              </div>

              <div className="wrapper-containt" style={{ width: "30%" }}>
                <span className="wrapper-containt-blue">TELEPON</span>
                <span
                  className="wrapper-containt-black"
                  style={{ marginTop: 8 }}
                >
                  {getPhone(profile?.dataPribadi?.telepon)}
                </span>
              </div>
            </div>
            <div className="wrapper-containt-row" style={{ marginTop: 20 }}>
              <div className="wrapper-containt" style={{ width: "100%" }}>
                <span className="wrapper-containt-blue">ALAMAT</span>
                <span
                  className="wrapper-containt-black"
                  style={{ marginTop: 8, textAlign: "left" }}
                >
                  {getAddress(profile?.dataPribadi)}
                </span>
              </div>
            </div>
            <div className="alert-card">
              <span>
                TANDA PESERTA INI TIDAK BOLEH HILANG, RUSAK, ATAU DILAMINASI
              </span>
            </div>
          </div>
          <div className="wrapper-card-right">
            <img
              src={profile?.pasFoto}
              alt="header"
              className="container-card-photo"
            />
            <div
              className="box-card-ttd"
              style={{ marginTop: 10, paddingTop: 3 }}
            >
              <span>Pengesahan Panitia</span>
            </div>
          </div>
        </div>
      </div>
      <div className="cut-here" />
      <div
        className="container-page-card"
        style={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          paddingTop: 0,
        }}
      >
        <span className="cut-here-text">Gunting di sini</span>
        <span className="petunjuk-card" style={{ marginTop: 10 }}>
          Petunjuk:
        </span>
        <span className="petunjuk-card-point" style={{ marginTop: 10 }}>
          Cetak halaman ini secara <b>berwarna</b> pada{" "}
          <b>kertas HVS ukuran A4</b> dalam orientasi <b>portrait (berdiri)</b>.
        </span>
        <span className="petunjuk-card-point" style={{ marginTop: 5 }}>
          Gunting pada garis putus-putus yang tersedia.
        </span>
        <span className="petunjuk-card-point" style={{ marginTop: 5 }}>
          Dilarang menghilangkan, merusak, atau melaminasi tanda peserta ini.
        </span>
      </div>
    </div>
  );
}

export default KartuPeserta;
