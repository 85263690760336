import axios from 'axios'
import server from '../../endpoint'
import {
} from "../constanta";
import { generateToken } from '../../helpers/bearer'

export const checkingPIN = (pin, History, Swal) => async dispatch => {
    const ToastMid = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    try {
        const { data } = await axios({
            method: 'post',
            url: `${server}/pin/checking`,
            data: { pin },
            headers: {
                'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
            }
        })
        if (data) {
            if (data.status) {
                sessionStorage.setItem('secret', JSON.stringify({ pin, id: data.id, chapter: data.chapterId }))
                Swal.fire({
                    text: "PIN benar, Silahkan Mendaftar",
                    icon: 'success',
                    showCancelButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    confirmButtonColor: '#3598dc',
                    confirmButtonText: 'Daftar Di Sini'
                }).then((result) => {
                    localStorage.setItem('secret',data._id)
                    if (result.value) {
                        History.replace('/daftar?program=afs')
                    }
                })
            } else {
                ToastMid.fire({
                    icon: 'warning',
                    title: 'PIN salah'
                })
            }
        } else {
            ToastMid.fire({
                icon: 'warning',
                title: 'PIN salah'
            })
        }
    } catch (err) {
        Swal.close()
        if (err) {
            if (err.response) {
                if (err.response.data) {
                    if (err.response.data.message) {
                        if (err.response.data.message === "Mohon Maaf Pendaftaran Telah Ditutup") {
                            ToastMid.fire({
                                icon: 'error',
                                title: `Mohon Maaf Pendaftaran Telah Ditutup`,
                            })
                        }
                        if (err.response.data.message === "PIN Telah Kadaluarsa!") {
                            ToastMid.fire({
                                icon: 'error',
                                title: `PIN Telah Kadaluarsa!`,
                            })
                        }
                        if (err.response.data.message === "PIN Telah Digunakan!") {
                            ToastMid.fire({
                                icon: 'error',
                                title: `PIN Telah Digunakan!`,
                            })
                        }
                    }
                }
            }
        }
    }
}