import React, {
  useState,
  useEffect,
  Fragment,
  forwardRef,
  useImperativeHandle,
} from "react";
import "./styles.scss";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import {
  setSaveAction,
  saveRekomendasi,
} from "../../../../store/actions/peserta";
import { useHistory } from "react-router-dom";

const SaveAndOutButton = withStyles((theme) => ({
  root: {
    border: "1px solid #3598DC",
    // marginTop: '50px',
    width: "200px",
    height: "44px",
    color: "#3598DC",
    backgroundColor: "#FFFFFF",
    fontFamily: ["Roboto", "sans-serif"],
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "19px",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#e5e5e5",
      boxShadow:
        "0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)",
    },
  },
}))(Button);

const SaveAndNextButton = withStyles((theme) => ({
  root: {
    // marginTop: '50px',
    width: "200px",
    height: "44px",
    color: "#ffffff",
    backgroundColor: "#3598DC",
    fontFamily: ["Roboto", "sans-serif"],
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "19px",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#2f88c6",
      boxShadow:
        "0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)",
    },
    marginLeft: 20,
  },
}))(Button);

const Rekomendasi = forwardRef((props, ref) => {
  const { NextPage, handleLogout, goTop } = props;
  const History = useHistory();
  const Dispatch = useDispatch();
  const options = useSelector((state) => state.pesertaState.options);
  const propinsiDB = useSelector((state) => state.daerahState.propinsi);
  const kabupatenDB = useSelector((state) => state.daerahState.kabupaten);
  const kecamatanDB = useSelector((state) => state.daerahState.kecamatan);
  const profile = useSelector((state) => state.pesertaState.profile);
  const save = useSelector((state) => state.pesertaState.checkerSave);
  const [click, setClick] = useState(false);
  const [sendData, setSendData] = useState({
    namaLingkunganSekolah: "",
    alamatLingkunganSekolah: "",
    propinsiLingkunganSekolah: "",
    kabupatenLingkunganSekolah: "",
    kecamatanLingkunganSekolah: "",
    kelurahanLingkunganSekolah: "",
    kodeposLingkunganSekolah: "",
    teleponLingkunganSekolah: "",
    pekerjaanLingkunganSekolah: "",
    alamatKerjaLingkunganSekolah: "",
    propinsiKerjaLingkunganSekolah: "",
    kabupatenKerjaLingkunganSekolah: "",
    kecamatanKerjaLingkunganSekolah: "",
    kelurahanKerjaLingkunganSekolah: "",
    kodeposKerjaLingkunganSekolah: "",
    hubunganLingkunganSekolah: "",
    namaLingkunganRumah: "",
    alamatLingkunganRumah: "",
    propinsiLingkunganRumah: "",
    kabupatenLingkunganRumah: "",
    kecamatanLingkunganRumah: "",
    kelurahanLingkunganRumah: "",
    kodeposLingkunganRumah: "",
    teleponLingkunganRumah: "",
    pekerjaanLingkunganRumah: "",
    alamatKerjaLingkunganRumah: "",
    propinsiKerjaLingkunganRumah: "",
    kabupatenKerjaLingkunganRumah: "",
    kecamatanKerjaLingkunganRumah: "",
    kelurahanKerjaLingkunganRumah: "",
    kodeposKerjaLingkunganRumah: "",
    hubunganLingkunganRumah: "",
    namaTemanDekat: "",
    alamatTemanDekat: "",
    propinsiTemanDekat: "",
    kabupatenTemanDekat: "",
    kecamatanTemanDekat: "",
    kelurahanTemanDekat: "",
    kodeposTemanDekat: "",
    teleponTemanDekat: "",
    hubunganTemanDekat: "",
  });

  useEffect(() => {
    if (profile?.rekomendasi) {
      setSendData(profile?.rekomendasi);
    }
  }, [profile?.rekomendasi]);

  const handleChange = (key, value) => {
    if (!save) Dispatch(setSaveAction(true));
    switch (key) {
      case "namaLingkunganSekolah":
        setSendData({
          ...sendData,
          namaLingkunganSekolah: value,
        });
        break;
      case "alamatLingkunganSekolah":
        setSendData({
          ...sendData,
          alamatLingkunganSekolah: value,
        });
        break;
      case "propinsiLingkunganSekolah":
        setSendData({
          ...sendData,
          propinsiLingkunganSekolah: value,
          kabupatenLingkunganSekolah: "",
          kecamatanLingkunganSekolah: "",
          kelurahanLingkunganSekolah: "",
          kodeposLingkunganSekolah: "",
        });
        break;
      case "kabupatenLingkunganSekolah":
        setSendData({
          ...sendData,
          kabupatenLingkunganSekolah: value,
          kecamatanLingkunganSekolah: "",
          kelurahanLingkunganSekolah: "",
          kodeposLingkunganSekolah: "",
        });
        break;
      case "kecamatanLingkunganSekolah":
        setSendData({
          ...sendData,
          kecamatanLingkunganSekolah: value,
          kelurahanLingkunganSekolah: "",
          kodeposLingkunganSekolah: "",
        });
        break;
      case "kelurahanLingkunganSekolah":
        setSendData({
          ...sendData,
          kelurahanLingkunganSekolah: value,
          kodeposLingkunganSekolah: "",
        });
        break;
      case "kodeposLingkunganSekolah":
        setSendData({
          ...sendData,
          kodeposLingkunganSekolah: value,
        });
        break;
      case "teleponLingkunganSekolah":
        setSendData({
          ...sendData,
          teleponLingkunganSekolah: value,
        });
        break;
      case "pekerjaanLingkunganSekolah":
        setSendData({
          ...sendData,
          pekerjaanLingkunganSekolah: value,
        });
        break;
      case "alamatKerjaLingkunganSekolah":
        setSendData({
          ...sendData,
          alamatKerjaLingkunganSekolah: value,
        });
        break;
      case "propinsiKerjaLingkunganSekolah":
        setSendData({
          ...sendData,
          propinsiKerjaLingkunganSekolah: value,
          kabupatenKerjaLingkunganSekolah: "",
          kecamatanKerjaLingkunganSekolah: "",
          kelurahanKerjaLingkunganSekolah: "",
          kodeposKerjaLingkunganSekolah: "",
        });
        break;
      case "kabupatenKerjaLingkunganSekolah":
        setSendData({
          ...sendData,
          kabupatenKerjaLingkunganSekolah: value,
          kecamatanKerjaLingkunganSekolah: "",
          kelurahanKerjaLingkunganSekolah: "",
          kodeposKerjaLingkunganSekolah: "",
        });
        break;
      case "kecamatanKerjaLingkunganSekolah":
        setSendData({
          ...sendData,
          kecamatanKerjaLingkunganSekolah: value,
          kelurahanKerjaLingkunganSekolah: "",
          kodeposKerjaLingkunganSekolah: "",
        });
        break;
      case "kelurahanKerjaLingkunganSekolah":
        setSendData({
          ...sendData,
          kelurahanKerjaLingkunganSekolah: value,
          kodeposKerjaLingkunganSekolah: "",
        });
        break;
      case "kodeposKerjaLingkunganSekolah":
        setSendData({
          ...sendData,
          kodeposKerjaLingkunganSekolah: value,
        });
        break;
      case "hubunganLingkunganSekolah":
        setSendData({
          ...sendData,
          hubunganLingkunganSekolah: value,
        });
        break;
      case "namaLingkunganRumah":
        setSendData({
          ...sendData,
          namaLingkunganRumah: value,
        });
        break;
      case "alamatLingkunganRumah":
        setSendData({
          ...sendData,
          alamatLingkunganRumah: value,
        });
        break;
      case "propinsiLingkunganRumah":
        setSendData({
          ...sendData,
          propinsiLingkunganRumah: value,
          kabupatenLingkunganRumah: "",
          kecamatanLingkunganRumah: "",
          kelurahanLingkunganRumah: "",
          kodeposLingkunganRumah: "",
        });
        break;
      case "kabupatenLingkunganRumah":
        setSendData({
          ...sendData,
          kabupatenLingkunganRumah: value,
          kecamatanLingkunganRumah: "",
          kelurahanLingkunganRumah: "",
          kodeposLingkunganRumah: "",
        });
        break;
      case "kecamatanLingkunganRumah":
        setSendData({
          ...sendData,
          kecamatanLingkunganRumah: value,
          kelurahanLingkunganRumah: "",
          kodeposLingkunganRumah: "",
        });
        break;
      case "kelurahanLingkunganRumah":
        setSendData({
          ...sendData,
          kelurahanLingkunganRumah: value,
          kodeposLingkunganRumah: "",
        });
        break;
      case "kodeposLingkunganRumah":
        setSendData({
          ...sendData,
          kodeposLingkunganRumah: value,
        });
        break;
      case "teleponLingkunganRumah":
        setSendData({
          ...sendData,
          teleponLingkunganRumah: value,
        });
        break;
      case "pekerjaanLingkunganRumah":
        setSendData({
          ...sendData,
          pekerjaanLingkunganRumah: value,
        });
        break;
      case "alamatKerjaLingkunganRumah":
        setSendData({
          ...sendData,
          alamatKerjaLingkunganRumah: value,
        });
        break;
      case "propinsiKerjaLingkunganRumah":
        setSendData({
          ...sendData,
          propinsiKerjaLingkunganRumah: value,
          kabupatenKerjaLingkunganRumah: "",
          kecamatanKerjaLingkunganRumah: "",
          kelurahanKerjaLingkunganRumah: "",
          kodeposKerjaLingkunganRumah: "",
        });
        break;
      case "kabupatenKerjaLingkunganRumah":
        setSendData({
          ...sendData,
          kabupatenKerjaLingkunganRumah: value,
          kecamatanKerjaLingkunganRumah: "",
          kelurahanKerjaLingkunganRumah: "",
          kodeposKerjaLingkunganRumah: "",
        });
        break;
      case "kecamatanKerjaLingkunganRumah":
        setSendData({
          ...sendData,
          kecamatanKerjaLingkunganRumah: value,
          kelurahanKerjaLingkunganRumah: "",
          kodeposKerjaLingkunganRumah: "",
        });
        break;
      case "kelurahanKerjaLingkunganRumah":
        setSendData({
          ...sendData,
          kelurahanKerjaLingkunganRumah: value,
          kodeposKerjaLingkunganRumah: "",
        });
        break;
      case "kodeposKerjaLingkunganRumah":
        setSendData({
          ...sendData,
          kodeposKerjaLingkunganRumah: value,
        });
        break;
      case "hubunganLingkunganRumah":
        setSendData({
          ...sendData,
          hubunganLingkunganRumah: value,
        });
        break;
      case "namaTemanDekat":
        setSendData({
          ...sendData,
          namaTemanDekat: value,
        });
        break;
      case "alamatTemanDekat":
        setSendData({
          ...sendData,
          alamatTemanDekat: value,
        });
        break;
      case "propinsiTemanDekat":
        setSendData({
          ...sendData,
          propinsiTemanDekat: value,
          kabupatenTemanDekat: "",
          kecamatanTemanDekat: "",
          kelurahanTemanDekat: "",
          kodeposTemanDekat: "",
        });
        break;
      case "kabupatenTemanDekat":
        setSendData({
          ...sendData,
          kabupatenTemanDekat: value,
          kecamatanTemanDekat: "",
          kelurahanTemanDekat: "",
          kodeposTemanDekat: "",
        });
        break;
      case "kecamatanTemanDekat":
        setSendData({
          ...sendData,
          kecamatanTemanDekat: value,
          kelurahanTemanDekat: "",
          kodeposTemanDekat: "",
        });
        break;
      case "kelurahanTemanDekat":
        setSendData({
          ...sendData,
          kelurahanTemanDekat: value,
          kodeposTemanDekat: "",
        });
        break;
      case "kodeposTemanDekat":
        setSendData({
          ...sendData,
          kodeposTemanDekat: value,
        });
        break;
      case "teleponTemanDekat":
        setSendData({
          ...sendData,
          teleponTemanDekat: value,
        });
        break;
      case "hubunganTemanDekat":
        setSendData({
          ...sendData,
          hubunganTemanDekat: value,
        });
        break;
      default:
        break;
    }
  };

  const handleFilter = (key, data, kecamatan, kelurahan) => {
    let cloud = [];
    switch (key) {
      case "kecamatan":
        if (data) {
          data.map((props) => {
            if (!cloud.includes(props.kecamatan)) {
              cloud.push(props.kecamatan);
            }
          });
        }
        break;
      case "kelurahan":
        if (data) {
          data.map((props) => {
            if (props.kecamatan === kecamatan) {
              if (!cloud.includes(props.kelurahan)) {
                cloud.push(props.kelurahan);
              }
            }
          });
        }
        break;
      case "kodepos":
        if (data) {
          data.map((props) => {
            if (props.kecamatan === kecamatan) {
              if (props.kelurahan === kelurahan) {
                if (!cloud.includes(props.kodepos)) {
                  cloud.push(props.kodepos);
                }
              }
            }
          });
        }
        break;
      default:
        break;
    }
    return cloud;
  };

  const saving = (option) => {
    const ToastMid = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    const SwalBootstrap2 = Swal.mixin({
      customClass: {
        confirmButton: "btn-finalisasi-yakin-ok",
        cancelButton: "btn-finalisasi-tidak",
      },
      buttonsStyling: false,
    });
    if (options?.[profile?.program]?.finalisasi) {
      setClick(true);
      let flag = false;
      for (let key in sendData) {
        if (
          key === "namaLingkunganSekolah" ||
          key === "alamatLingkunganSekolah" ||
          key === "propinsiLingkunganSekolah" ||
          key === "kabupatenLingkunganSekolah" ||
          key === "kecamatanLingkunganSekolah" ||
          key === "teleponLingkunganSekolah" ||
          key === "pekerjaanLingkunganSekolah" ||
          key === "alamatKerjaLingkunganSekolah" ||
          key === "propinsiKerjaLingkunganSekolah" ||
          key === "kabupatenKerjaLingkunganSekolah" ||
          key === "kecamatanKerjaLingkunganSekolah" ||
          key === "hubunganLingkunganSekolah" ||
          key === "namaLingkunganRumah" ||
          key === "alamatLingkunganRumah" ||
          key === "propinsiLingkunganRumah" ||
          key === "kabupatenLingkunganRumah" ||
          key === "kecamatanLingkunganRumah" ||
          key === "teleponLingkunganRumah" ||
          key === "pekerjaanLingkunganRumah" ||
          key === "alamatKerjaLingkunganRumah" ||
          key === "propinsiKerjaLingkunganRumah" ||
          key === "kabupatenKerjaLingkunganRumah" ||
          key === "kecamatanKerjaLingkunganRumah" ||
          key === "hubunganLingkunganRumah" ||
          key === "namaTemanDekat" ||
          key === "alamatTemanDekat" ||
          key === "propinsiTemanDekat" ||
          key === "kabupatenTemanDekat" ||
          key === "kecamatanTemanDekat" ||
          key === "teleponTemanDekat" ||
          key === "hubunganTemanDekat"
        ) {
          if (!sendData[key]) {
            flag = true;
            ToastMid.fire({
              icon: "warning",
              title: `Silahkan Lengkapi Data`,
            });
            goTop();
            return;
          }
          if (flag) return;
        }
        if (flag) return;
      }
      let page = profile.page;
      if (profile.page < 12) {
        page = 12;
      }
      if (!flag) {
        Dispatch(
          saveRekomendasi(
            { data: sendData, page },
            Swal,
            option,
            NextPage,
            handleLogout,
            12,
            goTop,
            History,
            profile
          )
        );
      }
    } else {
      SwalBootstrap2.fire({
        title: "Pengisian Data Telah Ditutup",
        text: "",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        cancelButtonText: "",
        reverseButtons: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.value) {
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      });
    }
  };

  useImperativeHandle(ref, () => ({
    saving(option, num) {
      const ToastMid = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      const SwalBootstrap2 = Swal.mixin({
        customClass: {
          confirmButton: "btn-finalisasi-yakin-ok",
          cancelButton: "btn-finalisasi-tidak",
        },
        buttonsStyling: false,
      });
      if (options?.[profile?.program]?.finalisasi) {
        setClick(true);
        let flag = false;
        for (let key in sendData) {
          if (
            key === "namaLingkunganSekolah" ||
            key === "alamatLingkunganSekolah" ||
            key === "propinsiLingkunganSekolah" ||
            key === "kabupatenLingkunganSekolah" ||
            key === "kecamatanLingkunganSekolah" ||
            key === "teleponLingkunganSekolah" ||
            key === "pekerjaanLingkunganSekolah" ||
            key === "alamatKerjaLingkunganSekolah" ||
            key === "propinsiKerjaLingkunganSekolah" ||
            key === "kabupatenKerjaLingkunganSekolah" ||
            key === "kecamatanKerjaLingkunganSekolah" ||
            key === "hubunganLingkunganSekolah" ||
            key === "namaLingkunganRumah" ||
            key === "alamatLingkunganRumah" ||
            key === "propinsiLingkunganRumah" ||
            key === "kabupatenLingkunganRumah" ||
            key === "kecamatanLingkunganRumah" ||
            key === "teleponLingkunganRumah" ||
            key === "pekerjaanLingkunganRumah" ||
            key === "alamatKerjaLingkunganRumah" ||
            key === "propinsiKerjaLingkunganRumah" ||
            key === "kabupatenKerjaLingkunganRumah" ||
            key === "kecamatanKerjaLingkunganRumah" ||
            key === "hubunganLingkunganRumah" ||
            key === "namaTemanDekat" ||
            key === "alamatTemanDekat" ||
            key === "propinsiTemanDekat" ||
            key === "kabupatenTemanDekat" ||
            key === "kecamatanTemanDekat" ||
            key === "teleponTemanDekat" ||
            key === "hubunganTemanDekat"
          ) {
            if (!sendData[key]) {
              flag = true;
              ToastMid.fire({
                icon: "warning",
                title: `Silahkan Lengkapi Data`,
              });
              goTop();
              return;
            }
            if (flag) return;
          }
          if (flag) return;
        }
        let page = profile.page;
        if (profile.page < 12) {
          page = 12;
        }
        if (!flag) {
          Dispatch(
            saveRekomendasi(
              { data: sendData, page },
              Swal,
              option,
              NextPage,
              handleLogout,
              num,
              goTop
            )
          );
        }
      } else {
        SwalBootstrap2.fire({
          title: "Pengisian Data Telah Ditutup",
          text: "",
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "OK",
          cancelButtonText: "",
          reverseButtons: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.value) {
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
          }
        });
      }
    },
  }));

  const goToTab = (page) => {
    NextPage(page);
    goTop();
  };

  return (
    <form autoComplete="off">
      {/* LINGKUNGAN SEKOLAH */}
      <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }}
        />
        <div className="info-keluarga-font-title" style={{ marginBottom: 0 }}>
          Lingkungan Sekolah
        </div>
      </div>
      <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }}
        />
        <span className="box-white-blue-download-berkas-text-dwd-title">
          (Guru, Kepala Sekolah/Wakil Kepala Sekolah, Pembina OSIS)
        </span>
      </div>

      {/* NAMA */}
      <span className="data-pribadi-font-mobile title-input-mobile">
        Nama <b style={{ color: "red" }}> * </b>
      </span>
      <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }}
        >
          Nama <b style={{ color: "red" }}> * </b>
        </span>
        <input
          disabled={
            options?.[profile?.program]?.finalisasi === false ||
            profile?.finalisasi === true
          }
          onChange={(e) =>
            handleChange("namaLingkunganSekolah", e.target.value)
          }
          value={sendData.namaLingkunganSekolah}
          placeholder="Nama"
          className="data-pribadi-input"
          style={{
            border:
              click && !sendData.namaLingkunganSekolah && "1px solid red",
          }}
        />
      </div>

      {/* ALAMAT & TELEPON */}
      <span className="data-pribadi-font-mobile title-input-mobile2row">
        Alamat & Telepon <b style={{ color: "red" }}> * </b>
      </span>
      <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }}
        >
          Alamat & Telepon <b style={{ color: "red" }}> * </b>
        </span>
        <div className="input-wrapper-data-pribadi">
          <input
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            onChange={(e) =>
              handleChange("alamatLingkunganSekolah", e.target.value)
            }
            value={sendData.alamatLingkunganSekolah}
            placeholder="Alamat Lengkap"
            className="data-pribadi-input3"
            style={{
              border:
                click && !sendData.alamatLingkunganSekolah && "1px solid red",
            }}
          />
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
            onChange={(e) =>
              handleChange("propinsiLingkunganSekolah", e.target.value)
            }
            value={sendData.propinsiLingkunganSekolah}
            style={{
              color: sendData.propinsiLingkunganSekolah ? "" : "#C9C9C9",
              border:
                click &&
                !sendData.propinsiLingkunganSekolah &&
                "1px solid red",
            }}
          >
            <option
              value=""
              style={{
                color: sendData.propinsiLingkunganSekolah ? "" : "#d3d5d7",
              }}
              selected
            >
              Propinsi
            </option>
            {propinsiDB &&
              propinsiDB.map((data, i) => (
                <option
                  key={i}
                  selected={
                    sendData.propinsiLingkunganSekolah === data.id
                      ? true
                      : false
                  }
                  style={{
                    color:
                      sendData.propinsiLingkunganSekolah === data.id
                        ? "#3c98dc"
                        : "black",
                  }}
                  value={data.id}
                >
                  {data.propinsi}
                </option>
              ))}
          </select>
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
            onChange={(e) =>
              handleChange("kabupatenLingkunganSekolah", e.target.value)
            }
            value={sendData.kabupatenLingkunganSekolah}
            style={{
              color: sendData.kabupatenLingkunganSekolah ? "" : "#C9C9C9",
              border:
                click &&
                !sendData.kabupatenLingkunganSekolah &&
                "1px solid red",
            }}
          >
            <option
              value=""
              style={{
                color: sendData.kabupatenLingkunganSekolah ? "" : "#d3d5d7",
              }}
              selected
            >
              Kota/Kabupaten
            </option>
            {kabupatenDB[sendData.propinsiLingkunganSekolah] &&
              kabupatenDB[sendData.propinsiLingkunganSekolah].map(
                (data, i) => (
                  <option
                    key={i}
                    selected={
                      sendData.kabupatenLingkunganSekolah === data.id
                        ? true
                        : false
                    }
                    style={{
                      color:
                        sendData.kabupatenLingkunganSekolah === data.id
                          ? "#3c98dc"
                          : "black",
                    }}
                    value={data.id}
                  >
                    {data.kabupaten}
                  </option>
                )
              )}
          </select>
        </div>
      </div>
      <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }} />
        <div className="input-wrapper-data-pribadi">
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3"
            onChange={(e) =>
              handleChange("kecamatanLingkunganSekolah", e.target.value)
            }
            value={sendData.kecamatanLingkunganSekolah}
            style={{
              color: sendData.kecamatanLingkunganSekolah ? "" : "#C9C9C9",
              border:
                click &&
                !sendData.kecamatanLingkunganSekolah &&
                "1px solid red",
            }}
          >
            <option
              value=""
              style={{
                color: sendData.kecamatanLingkunganSekolah ? "" : "#d3d5d7",
              }}
              selected
            >
              Kecamatan
            </option>
            {kecamatanDB[sendData.kabupatenLingkunganSekolah] &&
              handleFilter(
                "kecamatan",
                kecamatanDB[sendData.kabupatenLingkunganSekolah],
                sendData.kecamatanLingkunganSekolah,
                sendData.kelurahanLingkunganSekolah
              ).map((data, i) => (
                <option
                  key={i}
                  selected={
                    sendData.kecamatanLingkunganSekolah === data
                      ? true
                      : false
                  }
                  style={{
                    color:
                      sendData.kecamatanLingkunganSekolah === data
                        ? "#3c98dc"
                        : "black",
                  }}
                  value={data}
                >
                  {data}
                </option>
              ))}
          </select>
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
            onChange={(e) =>
              handleChange("kelurahanLingkunganSekolah", e.target.value)
            }
            value={sendData.kelurahanLingkunganSekolah}
            style={{
              color: sendData.kelurahanLingkunganSekolah ? "" : "#C9C9C9",
            }}
          >
            <option
              value=""
              style={{
                color: sendData.kelurahanLingkunganSekolah ? "" : "#d3d5d7",
              }}
              selected
            >
              Desa/Kelurahan
            </option>
            {kecamatanDB[sendData.kabupatenLingkunganSekolah] &&
              handleFilter(
                "kelurahan",
                kecamatanDB[sendData.kabupatenLingkunganSekolah],
                sendData.kecamatanLingkunganSekolah,
                sendData.kelurahanLingkunganSekolah
              ).map((data, i) => (
                <option
                  key={i}
                  selected={
                    sendData.kelurahanLingkunganSekolah === data
                      ? true
                      : false
                  }
                  style={{
                    color:
                      sendData.kelurahanLingkunganSekolah === data
                        ? "#3c98dc"
                        : "black",
                  }}
                  value={data.id}
                >
                  {data}
                </option>
              ))}
          </select>
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
            onChange={(e) =>
              handleChange("kodeposLingkunganSekolah", e.target.value)
            }
            value={sendData.kodeposLingkunganSekolah}
            style={{
              color: sendData.kodeposLingkunganSekolah ? "" : "#C9C9C9",
            }}
          >
            <option
              value=""
              style={{
                color: sendData.kodeposLingkunganSekolah ? "" : "#d3d5d7",
              }}
              selected
            >
              Kode Pos
            </option>
            {kecamatanDB[sendData.kabupatenLingkunganSekolah] &&
              handleFilter(
                "kodepos",
                kecamatanDB[sendData.kabupatenLingkunganSekolah],
                sendData.kecamatanLingkunganSekolah,
                sendData.kelurahanLingkunganSekolah
              ).map((data, i) => (
                <option
                  key={i}
                  selected={
                    sendData.kodeposLingkunganSekolah === data ? true : false
                  }
                  style={{
                    color:
                      sendData.kodeposLingkunganSekolah === data
                        ? "#3c98dc"
                        : "black",
                  }}
                  value={data}
                >
                  {data}
                </option>
              ))}
          </select>
        </div>
      </div>

      {/* TELEPON */}
      <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }} />
        <input
          disabled={
            options?.[profile?.program]?.finalisasi === false ||
            profile?.finalisasi === true
          }
          onChange={(e) =>
            handleChange("teleponLingkunganSekolah", e.target.value)
          }
          value={sendData.teleponLingkunganSekolah}
          placeholder="Telepon"
          className="data-pribadi-input"
          style={{
            border:
              click && !sendData.teleponLingkunganSekolah && "1px solid red",
          }}
        />
      </div>
      <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }} />
        <div
          className="input-wrapper-data-pribadi"
          style={{
            justifyContent: "flex-start",
            textAlign: "left",
            alignItems: "flex-start",
            flexDirection: "row",
          }}
        >
          <b style={{ color: "red", marginRight: 5 }}> * </b> Kosongkan
          Desa/Kelurahan atau Kodepos jika tidak ditemukan, tulis alamat
          lengkap pada kolom isi
        </div>
      </div>

      {/* PEKERJAAN */}
      <span className="data-pribadi-font-mobile title-input-mobile2row">
        Pekerjaan <b style={{ color: "red" }}> * </b>
      </span>
      <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }}>
          Pekerjaan <b style={{ color: "red" }}> * </b>
        </span>
        <input
          disabled={
            options?.[profile?.program]?.finalisasi === false ||
            profile?.finalisasi === true
          }
          onChange={(e) =>
            handleChange("pekerjaanLingkunganSekolah", e.target.value)
          }
          value={sendData.pekerjaanLingkunganSekolah}
          placeholder="Pekerjaan"
          className="data-pribadi-input"
          style={{
            border:
              click &&
              !sendData.pekerjaanLingkunganSekolah &&
              "1px solid red",
          }}
        />
      </div>

      {/* ALAMAT PEKERJAAN */}
      <span className="data-pribadi-font-mobile title-input-mobile2row">
        Alamat Pekerjaan <b style={{ color: "red" }}> * </b>
      </span>
      <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }}>
          Alamat Pekerjaan <b style={{ color: "red" }}> * </b>
        </span>
        <div className="input-wrapper-data-pribadi">
          <input
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            onChange={(e) =>
              handleChange("alamatKerjaLingkunganSekolah", e.target.value)
            }
            value={sendData.alamatKerjaLingkunganSekolah}
            placeholder="Alamat Lengkap"
            className="data-pribadi-input3"
            style={{
              border:
                click &&
                !sendData.alamatKerjaLingkunganSekolah &&
                "1px solid red",
            }}
          />
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
            onChange={(e) =>
              handleChange("propinsiKerjaLingkunganSekolah", e.target.value)
            }
            value={sendData.propinsiKerjaLingkunganSekolah}
            style={{
              color: sendData.propinsiKerjaLingkunganSekolah ? "" : "#C9C9C9",
              border:
                click &&
                !sendData.propinsiKerjaLingkunganSekolah &&
                "1px solid red",
            }}
          >
            <option
              value=""
              style={{
                color: sendData.propinsiKerjaLingkunganSekolah
                  ? ""
                  : "#d3d5d7",
              }}
              selected
            >
              Propinsi
            </option>
            {propinsiDB &&
              propinsiDB.map((data, i) => (
                <option
                  key={i}
                  selected={
                    sendData.propinsiKerjaLingkunganSekolah === data.id
                      ? true
                      : false
                  }
                  style={{
                    color:
                      sendData.propinsiKerjaLingkunganSekolah === data.id
                        ? "#3c98dc"
                        : "black",
                  }}
                  value={data.id}
                >
                  {data.propinsi}
                </option>
              ))}
          </select>
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
            onChange={(e) =>
              handleChange("kabupatenKerjaLingkunganSekolah", e.target.value)
            }
            value={sendData.kabupatenKerjaLingkunganSekolah}
            style={{
              color: sendData.kabupatenKerjaLingkunganSekolah
                ? ""
                : "#C9C9C9",
              border:
                click &&
                !sendData.kabupatenKerjaLingkunganSekolah &&
                "1px solid red",
            }}
          >
            <option
              value=""
              style={{
                color: sendData.kabupatenKerjaLingkunganSekolah
                  ? ""
                  : "#d3d5d7",
              }}
              selected
            >
              Kota/Kabupaten
            </option>
            {kabupatenDB[sendData.propinsiKerjaLingkunganSekolah] &&
              kabupatenDB[sendData.propinsiKerjaLingkunganSekolah].map(
                (data, i) => (
                  <option
                    key={i}
                    selected={
                      sendData.kabupatenKerjaLingkunganSekolah === data.id
                        ? true
                        : false
                    }
                    style={{
                      color:
                        sendData.kabupatenKerjaLingkunganSekolah === data.id
                          ? "#3c98dc"
                          : "black",
                    }}
                    value={data.id}
                  >
                    {data.kabupaten}
                  </option>
                )
              )}
          </select>
        </div>
      </div>
      <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }} />
        <div className="input-wrapper-data-pribadi">
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3"
            onChange={(e) =>
              handleChange("kecamatanKerjaLingkunganSekolah", e.target.value)
            }
            value={sendData.kecamatanKerjaLingkunganSekolah}
            style={{
              color: sendData.kecamatanKerjaLingkunganSekolah
                ? ""
                : "#C9C9C9",
              border:
                click &&
                !sendData.kecamatanKerjaLingkunganSekolah &&
                "1px solid red",
            }}
          >
            <option
              value=""
              style={{
                color: sendData.kecamatanKerjaLingkunganSekolah
                  ? ""
                  : "#d3d5d7",
              }}
              selected
            >
              Kecamatan
            </option>
            {kecamatanDB[sendData.kabupatenKerjaLingkunganSekolah] &&
              handleFilter(
                "kecamatan",
                kecamatanDB[sendData.kabupatenKerjaLingkunganSekolah],
                sendData.kecamatanKerjaLingkunganSekolah,
                sendData.kelurahanLingkunganSekolah
              ).map((data, i) => (
                <option
                  key={i}
                  selected={
                    sendData.kecamatanKerjaLingkunganSekolah === data
                      ? true
                      : false
                  }
                  style={{
                    color:
                      sendData.kecamatanKerjaLingkunganSekolah === data
                        ? "#3c98dc"
                        : "black",
                  }}
                  value={data}
                >
                  {data}
                </option>
              ))}
          </select>
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
            onChange={(e) =>
              handleChange("kelurahanKerjaLingkunganSekolah", e.target.value)
            }
            value={sendData.kelurahanKerjaLingkunganSekolah}
            style={{
              color: sendData.kelurahanKerjaLingkunganSekolah
                ? ""
                : "#C9C9C9",
            }}
          >
            <option
              value=""
              style={{
                color: sendData.kelurahanKerjaLingkunganSekolah
                  ? ""
                  : "#d3d5d7",
              }}
              selected
            >
              Desa/Kelurahan
            </option>
            {kecamatanDB[sendData.kabupatenKerjaLingkunganSekolah] &&
              handleFilter(
                "kelurahan",
                kecamatanDB[sendData.kabupatenKerjaLingkunganSekolah],
                sendData.kecamatanKerjaLingkunganSekolah,
                sendData.kelurahanKerjaLingkunganSekolah
              ).map((data, i) => (
                <option
                  key={i}
                  selected={
                    sendData.kelurahanKerjaLingkunganSekolah === data
                      ? true
                      : false
                  }
                  style={{
                    color:
                      sendData.kelurahanKerjaLingkunganSekolah === data
                        ? "#3c98dc"
                        : "black",
                  }}
                  value={data.id}
                >
                  {data}
                </option>
              ))}
          </select>
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
            onChange={(e) =>
              handleChange("kodeposKerjaLingkunganSekolah", e.target.value)
            }
            value={sendData.kodeposKerjaLingkunganSekolah}
            style={{
              color: sendData.kodeposKerjaLingkunganSekolah ? "" : "#C9C9C9",
            }}
          >
            <option
              value=""
              style={{
                color: sendData.kodeposKerjaLingkunganSekolah
                  ? ""
                  : "#d3d5d7",
              }}
              selected
            >
              Kode Pos
            </option>
            {kecamatanDB[sendData.kabupatenKerjaLingkunganSekolah] &&
              handleFilter(
                "kodepos",
                kecamatanDB[sendData.kabupatenKerjaLingkunganSekolah],
                sendData.kecamatanKerjaLingkunganSekolah,
                sendData.kelurahanKerjaLingkunganSekolah
              ).map((data, i) => (
                <option
                  key={i}
                  selected={
                    sendData.kodeposKerjaLingkunganSekolah === data
                      ? true
                      : false
                  }
                  style={{
                    color:
                      sendData.kodeposKerjaLingkunganSekolah === data
                        ? "#3c98dc"
                        : "black",
                  }}
                  value={data}
                >
                  {data}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }} />
        <div
          className="input-wrapper-data-pribadi"
          style={{
            justifyContent: "flex-start",
            textAlign: "left",
            alignItems: "flex-start",
            flexDirection: "row",
          }}
        >
          <b style={{ color: "red", marginRight: 5 }}> * </b> Kosongkan
          Desa/Kelurahan atau Kodepos jika tidak ditemukan, tulis alamat
          lengkap pada kolom isi
        </div>
      </div>

      {/* HUBUNGAN */}
      <span className="data-pribadi-font-mobile title-input-mobile2row">
        Hubungan <b style={{ color: "red" }}> * </b>
      </span>
      <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }}>
          Hubungan <b style={{ color: "red" }}> * </b>
        </span>
        <input
          disabled={
            options?.[profile?.program]?.finalisasi === false ||
            profile?.finalisasi === true
          }
          onChange={(e) =>
            handleChange("hubunganLingkunganSekolah", e.target.value)
          }
          value={sendData.hubunganLingkunganSekolah}
          placeholder="Apa Hubungan dengan adik"
          className="data-pribadi-input"
          style={{
            border:
              click && !sendData.hubunganLingkunganSekolah && "1px solid red",
          }}
        />
      </div>

      {/* BREAK */}
      <div
        style={{
          borderTop: "5px solid #fafafa",
          margin: "30px 0px",
        }}
      />

      {/* LINGKUNGAN RUMAH */}
      <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }} />
        <div className="info-keluarga-font-title" style={{ marginBottom: 0 }}>
          Lingkungan Rumah/Organisasi di Luar Sekolah
        </div>
      </div>

      <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }} />
        <span className="box-white-blue-download-berkas-text-dwd-title">
          (Usia min. 18 th, berasal dari organisasi, tetangga, pembimbing
          rohani, dll. Bukan anggota keluarga atau tinggal serumah)
        </span>
      </div>

      {/* NAMA */}
      <span className="data-pribadi-font-mobile title-input-mobile">
        Nama <b style={{ color: "red" }}> * </b>
      </span>
      <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }}>
          Nama <b style={{ color: "red" }}> * </b>
        </span>
        <input
          disabled={
            options?.[profile?.program]?.finalisasi === false ||
            profile?.finalisasi === true
          }
          onChange={(e) =>
            handleChange("namaLingkunganRumah", e.target.value)
          }
          value={sendData.namaLingkunganRumah}
          placeholder="Nama"
          className="data-pribadi-input"
          style={{
            border: click && !sendData.namaLingkunganRumah && "1px solid red",
          }}
        />
      </div>

      {/* ALAMAT & TELEPON */}
      <span className="data-pribadi-font-mobile title-input-mobile2row">
        Alamat & Telepon <b style={{ color: "red" }}> * </b>
      </span>
      <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }}>
          Alamat & Telepon <b style={{ color: "red" }}> * </b>
        </span>
        <div className="input-wrapper-data-pribadi">
          <input
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            onChange={(e) =>
              handleChange("alamatLingkunganRumah", e.target.value)
            }
            value={sendData.alamatLingkunganRumah}
            placeholder="Alamat Lengkap"
            className="data-pribadi-input3"
            style={{
              border:
                click && !sendData.alamatLingkunganRumah && "1px solid red",
            }}
          />
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
            onChange={(e) =>
              handleChange("propinsiLingkunganRumah", e.target.value)
            }
            value={sendData.propinsiLingkunganRumah}
            style={{
              color: sendData.propinsiLingkunganRumah ? "" : "#C9C9C9",
              border:
                click && !sendData.propinsiLingkunganRumah && "1px solid red",
            }}
          >
            <option
              value=""
              style={{
                color: sendData.propinsiLingkunganRumah ? "" : "#d3d5d7",
              }}
              selected
            >
              Propinsi
            </option>
            {propinsiDB &&
              propinsiDB.map((data, i) => (
                <option
                  key={i}
                  selected={
                    sendData.propinsiLingkunganRumah === data.id
                      ? true
                      : false
                  }
                  style={{
                    color:
                      sendData.propinsiLingkunganRumah === data.id
                        ? "#3c98dc"
                        : "black",
                  }}
                  value={data.id}
                >
                  {data.propinsi}
                </option>
              ))}
          </select>
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
            onChange={(e) =>
              handleChange("kabupatenLingkunganRumah", e.target.value)
            }
            value={sendData.kabupatenLingkunganRumah}
            style={{
              color: sendData.kabupatenLingkunganRumah ? "" : "#C9C9C9",
              border:
                click &&
                !sendData.kabupatenLingkunganRumah &&
                "1px solid red",
            }}
          >
            <option
              value=""
              style={{
                color: sendData.kabupatenLingkunganRumah ? "" : "#d3d5d7",
              }}
              selected
            >
              Kota/Kabupaten
            </option>
            {kabupatenDB[sendData.propinsiLingkunganRumah] &&
              kabupatenDB[sendData.propinsiLingkunganRumah].map((data, i) => (
                <option
                  key={i}
                  selected={
                    sendData.kabupatenLingkunganRumah === data.id
                      ? true
                      : false
                  }
                  style={{
                    color:
                      sendData.kabupatenLingkunganRumah === data.id
                        ? "#3c98dc"
                        : "black",
                  }}
                  value={data.id}
                >
                  {data.kabupaten}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }} />
        <div className="input-wrapper-data-pribadi">
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3"
            onChange={(e) =>
              handleChange("kecamatanLingkunganRumah", e.target.value)
            }
            value={sendData.kecamatanLingkunganRumah}
            style={{
              color: sendData.kecamatanLingkunganRumah ? "" : "#C9C9C9",
              border:
                click &&
                !sendData.kecamatanLingkunganRumah &&
                "1px solid red",
            }}
          >
            <option
              value=""
              style={{
                color: sendData.kecamatanLingkunganRumah ? "" : "#d3d5d7",
              }}
              selected
            >
              Kecamatan
            </option>
            {kecamatanDB[sendData.kabupatenLingkunganRumah] &&
              handleFilter(
                "kecamatan",
                kecamatanDB[sendData.kabupatenLingkunganRumah],
                sendData.kecamatanLingkunganRumah,
                sendData.kelurahanLingkunganRumah
              ).map((data, i) => (
                <option
                  key={i}
                  selected={
                    sendData.kecamatanLingkunganRumah === data ? true : false
                  }
                  style={{
                    color:
                      sendData.kecamatanLingkunganRumah === data
                        ? "#3c98dc"
                        : "black",
                  }}
                  value={data}
                >
                  {data}
                </option>
              ))}
          </select>
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
            onChange={(e) =>
              handleChange("kelurahanLingkunganRumah", e.target.value)
            }
            value={sendData.kelurahanLingkunganRumah}
            style={{
              color: sendData.kelurahanLingkunganRumah ? "" : "#C9C9C9",
            }}
          >
            <option
              value=""
              style={{
                color: sendData.kelurahanLingkunganRumah ? "" : "#d3d5d7",
              }}
              selected
            >
              Desa/Kelurahan
            </option>
            {kecamatanDB[sendData.kabupatenLingkunganRumah] &&
              handleFilter(
                "kelurahan",
                kecamatanDB[sendData.kabupatenLingkunganRumah],
                sendData.kecamatanLingkunganRumah,
                sendData.kelurahanLingkunganRumah
              ).map((data, i) => (
                <option
                  key={i}
                  selected={
                    sendData.kelurahanLingkunganRumah === data ? true : false
                  }
                  style={{
                    color:
                      sendData.kelurahanLingkunganRumah === data
                        ? "#3c98dc"
                        : "black",
                  }}
                  value={data.id}
                >
                  {data}
                </option>
              ))}
          </select>
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
            onChange={(e) =>
              handleChange("kodeposLingkunganRumah", e.target.value)
            }
            value={sendData.kodeposLingkunganRumah}
            style={{
              color: sendData.kodeposLingkunganRumah ? "" : "#C9C9C9",
            }}
          >
            <option
              value=""
              style={{
                color: sendData.kodeposLingkunganRumah ? "" : "#d3d5d7",
              }}
              selected
            >
              Kode Pos
            </option>
            {kecamatanDB[sendData.kabupatenLingkunganRumah] &&
              handleFilter(
                "kodepos",
                kecamatanDB[sendData.kabupatenLingkunganRumah],
                sendData.kecamatanLingkunganRumah,
                sendData.kelurahanLingkunganRumah
              ).map((data, i) => (
                <option
                  key={i}
                  selected={
                    sendData.kodeposLingkunganRumah === data ? true : false
                  }
                  style={{
                    color:
                      sendData.kodeposLingkunganRumah === data
                        ? "#3c98dc"
                        : "black",
                  }}
                  value={data}
                >
                  {data}
                </option>
              ))}
          </select>
        </div>
      </div>

      {/* TELEPON */}
      <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }} />
        <input
          disabled={
            options?.[profile?.program]?.finalisasi === false ||
            profile?.finalisasi === true
          }
          onChange={(e) =>
            handleChange("teleponLingkunganRumah", e.target.value)
          }
          value={sendData.teleponLingkunganRumah}
          placeholder="Telepon"
          className="data-pribadi-input"
          style={{
            border:
              click && !sendData.teleponLingkunganRumah && "1px solid red",
          }}
        />
      </div>

      <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }} />
        <div
          className="input-wrapper-data-pribadi"
          style={{
            justifyContent: "flex-start",
            textAlign: "left",
            alignItems: "flex-start",
            flexDirection: "row",
          }}
        >
          <b style={{ color: "red", marginRight: 5 }}> * </b> Kosongkan
          Desa/Kelurahan atau Kodepos jika tidak ditemukan, tulis alamat
          lengkap pada kolom isi
        </div>
      </div>

      {/* PEKERJAAN */}
      <span className="data-pribadi-font-mobile title-input-mobile2row">
        Pekerjaan <b style={{ color: "red" }}> * </b>
      </span>
      <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }}>
          Pekerjaan <b style={{ color: "red" }}> * </b>
        </span>
        <input
          disabled={
            options?.[profile?.program]?.finalisasi === false ||
            profile?.finalisasi === true
          }
          onChange={(e) =>
            handleChange("pekerjaanLingkunganRumah", e.target.value)
          }
          value={sendData.pekerjaanLingkunganRumah}
          placeholder="Pekerjaan"
          className="data-pribadi-input"
          style={{
            border:
              click && !sendData.pekerjaanLingkunganRumah && "1px solid red",
          }}
        />
      </div>

      {/* ALAMAT PEKERJAAN */}
      <span className="data-pribadi-font-mobile title-input-mobile2row">
        Alamat Pekerjaan <b style={{ color: "red" }}> * </b>
      </span>
      <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }}>
          Alamat Pekerjaan <b style={{ color: "red" }}> * </b>
        </span>
        <div className="input-wrapper-data-pribadi">
          <input
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            onChange={(e) =>
              handleChange("alamatKerjaLingkunganRumah", e.target.value)
            }
            value={sendData.alamatKerjaLingkunganRumah}
            placeholder="Alamat Lengkap"
            className="data-pribadi-input3"
            style={{
              border:
                click &&
                !sendData.alamatKerjaLingkunganRumah &&
                "1px solid red",
            }}
          />
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
            onChange={(e) =>
              handleChange("propinsiKerjaLingkunganRumah", e.target.value)
            }
            value={sendData.propinsiKerjaLingkunganRumah}
            style={{
              color: sendData.propinsiKerjaLingkunganRumah ? "" : "#C9C9C9",
              border:
                click &&
                !sendData.propinsiKerjaLingkunganRumah &&
                "1px solid red",
            }}
          >
            <option
              value=""
              style={{
                color: sendData.propinsiKerjaLingkunganRumah ? "" : "#d3d5d7",
              }}
              selected
            >
              Propinsi
            </option>
            {propinsiDB &&
              propinsiDB.map((data, i) => (
                <option
                  key={i}
                  selected={
                    sendData.propinsiKerjaLingkunganRumah === data.id
                      ? true
                      : false
                  }
                  style={{
                    color:
                      sendData.propinsiKerjaLingkunganRumah === data.id
                        ? "#3c98dc"
                        : "black",
                  }}
                  value={data.id}
                >
                  {data.propinsi}
                </option>
              ))}
          </select>
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
            onChange={(e) =>
              handleChange("kabupatenKerjaLingkunganRumah", e.target.value)
            }
            value={sendData.kabupatenKerjaLingkunganRumah}
            style={{
              color: sendData.kabupatenKerjaLingkunganRumah ? "" : "#C9C9C9",
              border:
                click &&
                !sendData.kabupatenKerjaLingkunganRumah &&
                "1px solid red",
            }}
          >
            <option
              value=""
              style={{
                color: sendData.kabupatenKerjaLingkunganRumah
                  ? ""
                  : "#d3d5d7",
              }}
              selected
            >
              Kota/Kabupaten
            </option>
            {kabupatenDB[sendData.propinsiKerjaLingkunganRumah] &&
              kabupatenDB[sendData.propinsiKerjaLingkunganRumah].map(
                (data, i) => (
                  <option
                    key={i}
                    selected={
                      sendData.kabupatenKerjaLingkunganRumah === data.id
                        ? true
                        : false
                    }
                    style={{
                      color:
                        sendData.kabupatenKerjaLingkunganRumah === data.id
                          ? "#3c98dc"
                          : "black",
                    }}
                    value={data.id}
                  >
                    {data.kabupaten}
                  </option>
                )
              )}
          </select>
        </div>
      </div>
      <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }} />
        <div className="input-wrapper-data-pribadi">
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3"
            onChange={(e) =>
              handleChange("kecamatanKerjaLingkunganRumah", e.target.value)
            }
            value={sendData.kecamatanKerjaLingkunganRumah}
            style={{
              color: sendData.kecamatanKerjaLingkunganRumah ? "" : "#C9C9C9",
              border:
                click &&
                !sendData.kecamatanKerjaLingkunganRumah &&
                "1px solid red",
            }}
          >
            <option
              value=""
              style={{
                color: sendData.kecamatanKerjaLingkunganRumah
                  ? ""
                  : "#d3d5d7",
              }}
              selected
            >
              Kecamatan
            </option>
            {kecamatanDB[sendData.kabupatenKerjaLingkunganRumah] &&
              handleFilter(
                "kecamatan",
                kecamatanDB[sendData.kabupatenKerjaLingkunganRumah],
                sendData.kecamatanKerjaLingkunganRumah,
                sendData.kelurahanKerjaLingkunganRumah
              ).map((data, i) => (
                <option
                  key={i}
                  selected={
                    sendData.kecamatanKerjaLingkunganRumah === data
                      ? true
                      : false
                  }
                  style={{
                    color:
                      sendData.kecamatanKerjaLingkunganRumah === data
                        ? "#3c98dc"
                        : "black",
                  }}
                  value={data}
                >
                  {data}
                </option>
              ))}
          </select>
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
            onChange={(e) =>
              handleChange("kelurahanKerjaLingkunganRumah", e.target.value)
            }
            value={sendData.kelurahanKerjaLingkunganRumah}
            style={{
              color: sendData.kelurahanKerjaLingkunganRumah ? "" : "#C9C9C9",
            }}
          >
            <option
              value=""
              style={{
                color: sendData.kelurahanKerjaLingkunganRumah
                  ? ""
                  : "#d3d5d7",
              }}
              selected
            >
              Desa/Kelurahan
            </option>
            {kecamatanDB[sendData.kabupatenKerjaLingkunganRumah] &&
              handleFilter(
                "kelurahan",
                kecamatanDB[sendData.kabupatenKerjaLingkunganRumah],
                sendData.kecamatanKerjaLingkunganRumah,
                sendData.kelurahanKerjaLingkunganRumah
              ).map((data, i) => (
                <option
                  key={i}
                  selected={
                    sendData.kelurahanKerjaLingkunganRumah === data
                      ? true
                      : false
                  }
                  style={{
                    color:
                      sendData.kelurahanKerjaLingkunganRumah === data
                        ? "#3c98dc"
                        : "black",
                  }}
                  value={data.id}
                >
                  {data}
                </option>
              ))}
          </select>
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
            onChange={(e) =>
              handleChange("kodeposKerjaLingkunganRumah", e.target.value)
            }
            value={sendData.kodeposKerjaLingkunganRumah}
            style={{
              color: sendData.kodeposKerjaLingkunganRumah ? "" : "#C9C9C9",
            }}
          >
            <option
              value=""
              style={{
                color: sendData.kodeposKerjaLingkunganRumah ? "" : "#d3d5d7",
              }}
              selected
            >
              Kode Pos
            </option>
            {kecamatanDB[sendData.kabupatenKerjaLingkunganRumah] &&
              handleFilter(
                "kodepos",
                kecamatanDB[sendData.kabupatenKerjaLingkunganRumah],
                sendData.kecamatanKerjaLingkunganRumah,
                sendData.kelurahanKerjaLingkunganRumah
              ).map((data, i) => (
                <option
                  key={i}
                  selected={
                    sendData.kodeposKerjaLingkunganRumah === data
                      ? true
                      : false
                  }
                  style={{
                    color:
                      sendData.kodeposKerjaLingkunganRumah === data
                        ? "#3c98dc"
                        : "black",
                  }}
                  value={data}
                >
                  {data}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }} />
        <div
          className="input-wrapper-data-pribadi"
          style={{
            justifyContent: "flex-start",
            textAlign: "left",
            alignItems: "flex-start",
            flexDirection: "row",
          }}
        >
          <b style={{ color: "red", marginRight: 5 }}> * </b> Kosongkan
          Desa/Kelurahan atau Kodepos jika tidak ditemukan, tulis alamat
          lengkap pada kolom isi
        </div>
      </div>

      {/* HUBUNGAN */}
      <span className="data-pribadi-font-mobile title-input-mobile2row">
        Hubungan <b style={{ color: "red" }}> * </b>
      </span>
      <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }}>
          Hubungan <b style={{ color: "red" }}> * </b>
        </span>
        <input
          disabled={
            options?.[profile?.program]?.finalisasi === false ||
            profile?.finalisasi === true
          }
          onChange={(e) =>
            handleChange("hubunganLingkunganRumah", e.target.value)
          }
          value={sendData.hubunganLingkunganRumah}
          placeholder="Apa Hubungan dengan adik"
          className="data-pribadi-input"
          style={{
            border:
              click && !sendData.hubunganLingkunganRumah && "1px solid red",
          }}
        />
      </div>

      {/* BREAK */}
      <div
        style={{
          borderTop: "5px solid #fafafa",
          margin: "30px 0px",
        }}
      />

      {/* TEMAN DEKAT */}
      <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }} />
        <div className="info-keluarga-font-title" style={{ marginBottom: 0 }}>
          Teman Dekat
        </div>
      </div>

      <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }} />
        <span className="box-white-blue-download-berkas-text-dwd-title">
          (Sahabat, teman sebaya, teman sekelas/satu angkatan)
        </span>
      </div>

      {/* NAMA */}
      <span className="data-pribadi-font-mobile title-input-mobile">
        Nama <b style={{ color: "red" }}> * </b>
      </span>
      <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }}>
          Nama <b style={{ color: "red" }}> * </b>
        </span>
        <input
          disabled={
            options?.[profile?.program]?.finalisasi === false ||
            profile?.finalisasi === true
          }
          onChange={(e) => handleChange("namaTemanDekat", e.target.value)}
          value={sendData.namaTemanDekat}
          placeholder="Nama"
          className="data-pribadi-input"
          style={{
            border: click && !sendData.namaTemanDekat && "1px solid red",
          }}
        />
      </div>

      {/* ALAMAT & TELEPON */}
      <span className="data-pribadi-font-mobile title-input-mobile2row">
        Alamat & Telepon <b style={{ color: "red" }}> * </b>
      </span>
      <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }}>
          Alamat & Telepon <b style={{ color: "red" }}> * </b>
        </span>
        <div className="input-wrapper-data-pribadi">
          <input
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            onChange={(e) => handleChange("alamatTemanDekat", e.target.value)}
            value={sendData.alamatTemanDekat}
            placeholder="Alamat Lengkap"
            className="data-pribadi-input3"
            style={{
              border: click && !sendData.alamatTemanDekat && "1px solid red",
            }}
          />
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
            onChange={(e) =>
              handleChange("propinsiTemanDekat", e.target.value)
            }
            value={sendData.propinsiTemanDekat}
            style={{
              color: sendData.propinsiTemanDekat ? "" : "#C9C9C9",
              border:
                click && !sendData.propinsiTemanDekat && "1px solid red",
            }}
          >
            <option
              value=""
              style={{ color: sendData.propinsiTemanDekat ? "" : "#d3d5d7" }}
              selected
            >
              Propinsi
            </option>
            {propinsiDB &&
              propinsiDB.map((data, i) => (
                <option
                  key={i}
                  selected={
                    sendData.propinsiTemanDekat === data.id ? true : false
                  }
                  style={{
                    color:
                      sendData.propinsiTemanDekat === data.id
                        ? "#3c98dc"
                        : "black",
                  }}
                  value={data.id}
                >
                  {data.propinsi}
                </option>
              ))}
          </select>
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
            onChange={(e) =>
              handleChange("kabupatenTemanDekat", e.target.value)
            }
            value={sendData.kabupatenTemanDekat}
            style={{
              color: sendData.kabupatenTemanDekat ? "" : "#C9C9C9",
              border:
                click && !sendData.kabupatenTemanDekat && "1px solid red",
            }}
          >
            <option
              value=""
              style={{ color: sendData.kabupatenTemanDekat ? "" : "#d3d5d7" }}
              selected
            >
              Kota/Kabupaten
            </option>
            {kabupatenDB[sendData.propinsiTemanDekat] &&
              kabupatenDB[sendData.propinsiTemanDekat].map((data, i) => (
                <option
                  key={i}
                  selected={
                    sendData.kabupatenTemanDekat === data.id ? true : false
                  }
                  style={{
                    color:
                      sendData.kabupatenTemanDekat === data.id
                        ? "#3c98dc"
                        : "black",
                  }}
                  value={data.id}
                >
                  {data.kabupaten}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }} />
        <div className="input-wrapper-data-pribadi">
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3"
            onChange={(e) =>
              handleChange("kecamatanTemanDekat", e.target.value)
            }
            value={sendData.kecamatanTemanDekat}
            style={{
              color: sendData.kecamatanTemanDekat ? "" : "#C9C9C9",
              border:
                click && !sendData.kecamatanTemanDekat && "1px solid red",
            }}
          >
            <option
              value=""
              style={{ color: sendData.kecamatanTemanDekat ? "" : "#d3d5d7" }}
              selected
            >
              Kecamatan
            </option>
            {kecamatanDB[sendData.kabupatenTemanDekat] &&
              handleFilter(
                "kecamatan",
                kecamatanDB[sendData.kabupatenTemanDekat],
                sendData.kecamatanTemanDekat,
                sendData.kelurahanTemanDekat
              ).map((data, i) => (
                <option
                  key={i}
                  selected={
                    sendData.kecamatanTemanDekat === data ? true : false
                  }
                  style={{
                    color:
                      sendData.kecamatanTemanDekat === data
                        ? "#3c98dc"
                        : "black",
                  }}
                  value={data}
                >
                  {data}
                </option>
              ))}
          </select>
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
            onChange={(e) =>
              handleChange("kelurahanTemanDekat", e.target.value)
            }
            value={sendData.kelurahanTemanDekat}
            style={{ color: sendData.kelurahanTemanDekat ? "" : "#C9C9C9" }}
          >
            <option
              value=""
              style={{ color: sendData.kelurahanTemanDekat ? "" : "#d3d5d7" }}
              selected
            >
              Desa/Kelurahan
            </option>
            {kecamatanDB[sendData.kabupatenTemanDekat] &&
              handleFilter(
                "kelurahan",
                kecamatanDB[sendData.kabupatenTemanDekat],
                sendData.kecamatanTemanDekat,
                sendData.kelurahanTemanDekat
              ).map((data, i) => (
                <option
                  key={i}
                  selected={
                    sendData.kelurahanTemanDekat === data ? true : false
                  }
                  style={{
                    color:
                      sendData.kelurahanTemanDekat === data
                        ? "#3c98dc"
                        : "black",
                  }}
                  value={data.id}
                >
                  {data}
                </option>
              ))}
          </select>
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
            onChange={(e) =>
              handleChange("kodeposTemanDekat", e.target.value)
            }
            value={sendData.kodeposTemanDekat}
            style={{ color: sendData.kodeposTemanDekat ? "" : "#C9C9C9" }}
          >
            <option
              value=""
              style={{ color: sendData.kodeposTemanDekat ? "" : "#d3d5d7" }}
              selected
            >
              Kode Pos
            </option>
            {kecamatanDB[sendData.kabupatenTemanDekat] &&
              handleFilter(
                "kodepos",
                kecamatanDB[sendData.kabupatenTemanDekat],
                sendData.kecamatanTemanDekat,
                sendData.kelurahanTemanDekat
              ).map((data, i) => (
                <option
                  key={i}
                  selected={
                    sendData.kodeposTemanDekat === data ? true : false
                  }
                  style={{
                    color:
                      sendData.kodeposTemanDekat === data
                        ? "#3c98dc"
                        : "black",
                  }}
                  value={data}
                >
                  {data}
                </option>
              ))}
          </select>
        </div>
      </div>

      {/* TELEPON */}
      <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }} />
        <input
          disabled={
            options?.[profile?.program]?.finalisasi === false ||
            profile?.finalisasi === true
          }
          onChange={(e) => handleChange("teleponTemanDekat", e.target.value)}
          value={sendData.teleponTemanDekat}
          placeholder="Telepon"
          className="data-pribadi-input"
          style={{
            border: click && !sendData.teleponTemanDekat && "1px solid red",
          }}
        />
      </div>
      <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }} />
        <div
          className="input-wrapper-data-pribadi"
          style={{
            justifyContent: "flex-start",
            textAlign: "left",
            alignItems: "flex-start",
            flexDirection: "row",
          }}
        >
          <b style={{ color: "red", marginRight: 5 }}> * </b> Kosongkan
          Desa/Kelurahan atau Kodepos jika tidak ditemukan, tulis alamat
          lengkap pada kolom isi
        </div>
      </div>

      {/* HUBUNGAN */}
      <span className="data-pribadi-font-mobile title-input-mobile2row">
        Hubungan <b style={{ color: "red" }}> * </b>
      </span>
      <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
        <span className="data-pribadi-font title-input-desktop"
          style={{
            minWidth: 180
          }}>
          Hubungan <b style={{ color: "red" }}> * </b>
        </span>
        <input
          disabled={
            options?.[profile?.program]?.finalisasi === false ||
            profile?.finalisasi === true
          }
          onChange={(e) => handleChange("hubunganTemanDekat", e.target.value)}
          value={sendData.hubunganTemanDekat}
          placeholder="Apa Hubungan dengan adik"
          className="data-pribadi-input"
          style={{
            border: click && !sendData.hubunganTemanDekat && "1px solid red",
          }}
        />
      </div>

      <div className="data-pribadi-wrapper action-wrapper-2021-margintop">
        {options?.[profile?.program]?.finalisasi === false ||
        profile?.finalisasi === true ? (
          <div className="data-pribadi-button-wripper">
            <SaveAndOutButton
              className="btn-save-input2021-out"
              onClick={(e) => goToTab(10)}
            >
              {" "}
              Lihat Data Sebelumnya{" "}
            </SaveAndOutButton>
          </div>
        ) : (
          <div className="data-pribadi-button-wripper">
            <SaveAndOutButton
              className="btn-save-input2021-out"
              onClick={(e) => saving("out")}
            >
              {" "}
              Simpan & Keluar{" "}
            </SaveAndOutButton>
            <SaveAndNextButton
              className="btn-save-input2021-next"
              onClick={(e) => saving("next")}
            >
              {" "}
              Simpan & Lanjutkan{" "}
            </SaveAndNextButton>
          </div>
        )}
      </div>
    </form>
  );
});

export default Rekomendasi;
