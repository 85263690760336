import React, {
  useState,
  useEffect,
  forwardRef,
  Fragment,
  useImperativeHandle,
} from "react";
import "./styles.scss";
import Swal from "sweetalert2";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useSelector, useDispatch } from "react-redux";
import {
  saveDataPribadi,
  setSaveAction,
} from "../../../../store/actions/peserta";
import { DatePicker } from "@material-ui/pickers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

const SaveAndOutButton = withStyles((theme) => ({
  root: {
    border: "1px solid #3598DC",
    height: "44px",
    color: "#3598DC",
    backgroundColor: "#FFFFFF",
    fontFamily: ["Roboto", "sans-serif"],
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "19px",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      // backgroundColor: '#e5e5e5',
      // boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)',
    },
  },
}))(Button);

const SaveAndNextButton = withStyles((theme) => ({
  root: {
    height: "44px",
    color: "#ffffff",
    backgroundColor: "#3598DC",
    fontFamily: ["Roboto", "sans-serif"],
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "19px",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#2f88c6",
      boxShadow:
        "0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)",
    },
  },
}))(Button);

const DataPribadi = forwardRef((props, ref) => {
  const { NextPage, handleLogout, goTop } = props;
  const Dispatch = useDispatch();
  const options = useSelector((state) => state.pesertaState.options);
  const profile = useSelector((state) => state.pesertaState.profile);
  const save = useSelector((state) => state.pesertaState.checkerSave);
  const propinsiDB = useSelector((state) => state.daerahState.propinsi);
  const kabupatenDB = useSelector((state) => state.daerahState.kabupaten);
  const kecamatanDB = useSelector((state) => state.daerahState.kecamatan);
  const [click, setClick] = useState(false);
  const [sendData, setSendData] = useState({
    namaDepan: "",
    namaTengah: "",
    namaBelakang: "",
    tempatLahir: "",
    tanggalLahir: null,
    email: "",
    telepon: "",
    alamat: "",
    propinsi: "",
    kabupaten: "",
    kecamatan: "",
    kelurahan: "",
    kodepos: "",
    kelamin: "",
    tinggi: "",
    berat: "",
    goldar: "",
    wargaNegara: "",
    agama: "",
  });

  useEffect(() => {
    if (profile?.dataPribadi) {
      setSendData(profile.dataPribadi);
    } else {
      setSendData({
        ...sendData,
        email: profile?.email,
      });
    }
  }, [profile]);

  const handleChange = (key, value) => {
    if (!save) Dispatch(setSaveAction(true));
    switch (key) {
      case "namaDepan":
        setSendData({
          ...sendData,
          namaDepan: value,
        });
        break;
      case "namaTengah":
        setSendData({
          ...sendData,
          namaTengah: value,
        });
        break;
      case "namaBelakang":
        setSendData({
          ...sendData,
          namaBelakang: value,
        });
        break;
      case "tempatLahir":
        setSendData({
          ...sendData,
          tempatLahir: value,
        });
        break;
      case "tanggalLahir":
        setSendData({
          ...sendData,
          tanggalLahir: value,
        });
        break;
      case "email":
        setSendData({
          ...sendData,
          email: value,
        });
        break;
      case "telepon":
        setSendData({
          ...sendData,
          telepon: value,
        });
        break;
      case "alamat":
        setSendData({
          ...sendData,
          alamat: value,
        });
        break;
      case "propinsi":
        setSendData({
          ...sendData,
          propinsi: value,
          kabupaten: "",
          kecamatan: "",
          kelurahan: "",
          kodepos: "",
        });
        break;
      case "kabupaten":
        setSendData({
          ...sendData,
          kabupaten: value,
          kecamatan: "",
          kelurahan: "",
          kodepos: "",
        });
        break;
      case "kecamatan":
        setSendData({
          ...sendData,
          kecamatan: value,
          kelurahan: "",
          kodepos: "",
        });
        break;
      case "kelurahan":
        setSendData({
          ...sendData,
          kelurahan: value,
          kodepos: "",
        });
        break;
      case "kodepos":
        setSendData({
          ...sendData,
          kodepos: value,
        });
        break;
      case "kelamin":
        setSendData({
          ...sendData,
          kelamin: value,
        });
        break;
      case "tinggi":
        setSendData({
          ...sendData,
          tinggi: value,
        });
        break;
      case "berat":
        setSendData({
          ...sendData,
          berat: value,
        });
        break;
      case "goldar":
        setSendData({
          ...sendData,
          goldar: value,
        });
        break;
      case "wargaNegara":
        setSendData({
          ...sendData,
          wargaNegara: value,
        });
        break;
      case "agama":
        setSendData({
          ...sendData,
          agama: value,
        });
        break;
      default:
        break;
    }
  };

  const handleFilter = (key, data) => {
    let cloud = [];
    switch (key) {
      case "kecamatan":
        if (data) {
          data.map((props) => {
            if (!cloud.includes(props.kecamatan)) {
              cloud.push(props.kecamatan);
            }
          });
        }
        break;
      case "kelurahan":
        if (data) {
          data.map((props) => {
            if (props.kecamatan === sendData.kecamatan) {
              if (!cloud.includes(props.kelurahan)) {
                cloud.push(props.kelurahan);
              }
            }
          });
        }
        break;
      case "kodepos":
        if (data) {
          data.map((props) => {
            if (props.kecamatan === sendData.kecamatan) {
              if (props.kelurahan === sendData.kelurahan) {
                if (!cloud.includes(props.kodepos)) {
                  cloud.push(props.kodepos);
                }
              }
            }
          });
        }
        break;
      default:
        break;
    }
    return cloud;
  };

  const goToTab = (page) => {
    NextPage(page);
    goTop();
  };

  const saving = (option) => {
    const ToastMid = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    const SwalBootstrap2 = Swal.mixin({
      customClass: {
        confirmButton: "btn-finalisasi-yakin-ok",
        cancelButton: "btn-finalisasi-tidak",
      },
      buttonsStyling: false,
    });
    if (options?.[profile?.program]?.finalisasi) {
      setClick(true);
      let flag = false;
      for (let key in sendData) {
        if (
          key === "namaDepan" ||
          key === "tempatLahir" ||
          key === "tanggalLahir" ||
          key === "email" ||
          key === "telepon" ||
          key === "alamat" ||
          key === "propinsi" ||
          key === "kabupaten" ||
          key === "kecamatan" ||
          key === "kelamin" ||
          key === "tinggi" ||
          key === "berat" ||
          key === "wargaNegara" ||
          key === "agama"
        ) {
          if (!sendData[key]) {
            flag = true;
            ToastMid.fire({
              icon: "warning",
              title: `Silahkan Lengkapi Data`,
            });
            goTop();
            return;
          }
          if (flag) return;
        }
        if (flag) return;
      }
      let page = profile.page;
      if (profile.page < 1) {
        page = 1;
      }
      if (!flag) {
        Dispatch(
          saveDataPribadi(
            { data: sendData, page },
            Swal,
            option,
            NextPage,
            handleLogout,
            1,
            goTop
          )
        );
      }
    } else {
      SwalBootstrap2.fire({
        title: "Pengisian Data Telah Ditutup",
        text: "",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        cancelButtonText: "",
        reverseButtons: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.value) {
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      });
    }
  };

  useImperativeHandle(ref, () => ({
    saving(option, num) {
      const ToastMid = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      const SwalBootstrap2 = Swal.mixin({
        customClass: {
          confirmButton: "btn-finalisasi-yakin-ok",
          cancelButton: "btn-finalisasi-tidak",
        },
        buttonsStyling: false,
      });
      if (options?.[profile?.program]?.finalisasi) {
        setClick(true);
        let flag = false;
        for (let key in sendData) {
          if (
            key === "namaDepan" ||
            key === "tempatLahir" ||
            key === "tanggalLahir" ||
            key === "email" ||
            key === "telepon" ||
            key === "alamat" ||
            key === "propinsi" ||
            key === "kabupaten" ||
            key === "kecamatan" ||
            key === "kelamin" ||
            key === "tinggi" ||
            key === "berat" ||
            key === "wargaNegara" ||
            key === "agama"
          ) {
            if (!sendData[key]) {
              flag = true;
              ToastMid.fire({
                icon: "warning",
                title: `Silahkan Lengkapi Data`,
              });
              goTop();
              return;
            }
            if (flag) return;
          }
          if (flag) return;
        }
        let page = profile.page;
        if (profile.page < 1) {
          page = 1;
        }
        if (!flag) {
          Dispatch(
            saveDataPribadi(
              { data: sendData, page },
              Swal,
              option,
              NextPage,
              handleLogout,
              num,
              goTop
            )
          );
        }
      } else {
        SwalBootstrap2.fire({
          title: "Pengisian Data Telah Ditutup",
          text: "",
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "OK",
          cancelButtonText: "",
          reverseButtons: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.value) {
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
          }
        });
      }
    },
  }));

  return (
    <Fragment>
      <form autoComplete="off">
        {/* NAMA DEPAN */}
        <span className="data-pribadi-font-mobile title-input-mobile">
          Nama Depan <b style={{ color: "red" }}> * </b>
        </span>
        <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile">
          <span
            className="data-pribadi-font title-input-desktop"
            style={{
              minWidth: 200,
            }}
          >
            Nama Depan <b style={{ color: "red" }}> * </b>
          </span>
          <input
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            onChange={(e) => handleChange("namaDepan", e.target.value)}
            value={sendData.namaDepan}
            type="text"
            placeholder="Nama Depan"
            className="data-pribadi-input"
            style={{ border: click && !sendData.namaDepan && "1px solid red" }}
          />
        </div>
        {/* NAMA TENGAH */}
        <span className="data-pribadi-font-mobile title-input-mobile2row">
          Nama Tengah
        </span>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span
            className="data-pribadi-font title-input-desktop"
            style={{
              minWidth: 200,
            }}
          >
            Nama Tengah
          </span>
          <input
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            onChange={(e) => handleChange("namaTengah", e.target.value)}
            value={sendData.namaTengah}
            type="text"
            placeholder="Nama Tengah"
            className="data-pribadi-input"
          />
        </div>
        {/* NAMA BELAKANG */}
        <span className="data-pribadi-font-mobile title-input-mobile2row">
          Nama Belakang
        </span>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop ">
          <span
            className="data-pribadi-font title-input-desktop"
            style={{
              minWidth: 200,
            }}
          >
            Nama Belakang
          </span>
          <input
            onChange={(e) => handleChange("namaBelakang", e.target.value)}
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            value={sendData.namaBelakang}
            type="text"
            placeholder="Nama Belakang"
            className="data-pribadi-input"
          />
        </div>
        {/* TEMPAT & TANGGAL LAHIR */}
        <span className="data-pribadi-font-mobile title-input-mobile2row">
          Tempat & Tanggal Lahir <b style={{ color: "red" }}> * </b>
        </span>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span
            className="data-pribadi-font title-input-desktop"
            style={{
              minWidth: 200,
            }}
          >
            Tempat & Tanggal Lahir <b style={{ color: "red" }}> * </b>
          </span>
          <div className="input-wrapper-data-pribadi">
            <input
              disabled={
                options?.[profile?.program]?.finalisasi === false ||
                profile?.finalisasi === true
              }
              onChange={(e) => handleChange("tempatLahir", e.target.value)}
              value={sendData.tempatLahir}
              type="text"
              placeholder="Tempat"
              className="data-pribadi-input2"
              style={{
                border: click && !sendData.tempatLahir && "1px solid red",
              }}
            />
            <div
              className="data-pribadi-input2 data-pribadi-input-margin-top-mobile"
              style={{
                padding: 0,
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <DatePicker
                disabled={
                  options?.[profile?.program]?.finalisasi === false ||
                  profile?.finalisasi === true
                }
                clearable
                clearLabel="Hapus"
                autoOk
                views={["year", "month", "date"]}
                placeholder="Tanggal Lahir"
                value={sendData.tanggalLahir}
                onChange={(newValue) => {
                  handleChange("tanggalLahir", newValue);
                }}
                animateYearScrolling
                disableFuture
                okLabel=""
                cancelLabel=""
                helperText=""
                format="dd/MM/yyyy"
                InputProps={{
                  disableUnderline: true,
                }}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingLeft: "19px",
                  border: "0px",
                  zIndex: "9",
                  height: "44px",
                  borderRadius: "4px",
                  border: click && !sendData.tanggalLahir && "1px solid red",
                }}
                minDate={
                  profile?.program === "AFS"
                    ? new Date("2007-08-01")
                    : profile?.program === "YES" && new Date("2008-01-15")
                }
                maxDate={profile?.program === "AFS"
                  ? new Date("2009-08-01")
                  : profile?.program === "YES" && new Date("2009-08-15")}
              />
              <FontAwesomeIcon
                icon={faCalendarAlt}
                style={{
                  color: "#3c98dc",
                  fontSize: 18,
                  position: "absolute",
                  right: "60",
                }}
              />
            </div>
          </div>
        </div>
        {/* ALAMAT EMAIL */}
        <span className="data-pribadi-font-mobile title-input-mobile2row">
          Alamat Email <b style={{ color: "red" }}> * </b>
        </span>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span
            className="data-pribadi-font title-input-desktop"
            style={{
              minWidth: 200,
            }}
          >
            Alamat Email <b style={{ color: "red" }}> * </b>
          </span>
          <input
            disabled={true}
            onChange={(e) => handleChange("email", e.target.value)}
            value={sendData.email}
            type="email"
            placeholder="Alamat Email"
            className="data-pribadi-input"
            style={{ border: click && !sendData.email && "1px solid red" }}
          />
        </div>
        {/* NO PONSEL */}
        <span className="data-pribadi-font-mobile title-input-mobile2row">
          No. Ponsel <b style={{ color: "red" }}> * </b>
        </span>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span
            className="data-pribadi-font title-input-desktop"
            style={{
              minWidth: 200,
            }}
          >
            No. Ponsel <b style={{ color: "red" }}> * </b>
          </span>
          <input
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            onChange={(e) => handleChange("telepon", e.target.value)}
            value={sendData.telepon}
            placeholder="No. Ponsel"
            className="data-pribadi-input"
            style={{ border: click && !sendData.telepon && "1px solid red" }}
          />
        </div>
        {/* ALAMAT LENGKAP */}
        <span className="data-pribadi-font-mobile title-input-mobile2row">
          Alamat Lengkap<b style={{ color: "red" }}> * </b>
        </span>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span
            className="data-pribadi-font title-input-desktop"
            style={{
              minWidth: 200,
            }}
          >
            Alamat Lengkap<b style={{ color: "red" }}> * </b>
          </span>
          <div className="input-wrapper-data-pribadi">
            <input
              disabled={
                options?.[profile?.program]?.finalisasi === false ||
                profile?.finalisasi === true
              }
              onChange={(e) => handleChange("alamat", e.target.value)}
              value={sendData.alamat}
              placeholder="Alamat Lengkap Tempat Tinggal"
              className="data-pribadi-input3"
              style={{ border: click && !sendData.alamat && "1px solid red" }}
            />
            <select
              disabled={
                options?.[profile?.program]?.finalisasi === false ||
                profile?.finalisasi === true
              }
              className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
              onChange={(e) => handleChange("propinsi", e.target.value)}
              value={sendData.propinsi}
              style={{
                color: sendData.propinsi ? "" : "#C9C9C9",
                border: click && !sendData.propinsi && "1px solid red",
              }}
            >
              <option
                value=""
                style={{ color: sendData.propinsi ? "" : "#d3d5d7" }}
                selected
              >
                Propinsi
              </option>
              {propinsiDB?.map((data, i) => (
                <option
                  key={i}
                  selected={sendData.propinsi === data.id ? true : false}
                  style={{
                    color: sendData.propinsi === data.id ? "#3c98dc" : "black",
                  }}
                  value={data.id}
                >
                  {data.propinsi}
                </option>
              ))}
            </select>
            <select
              disabled={
                options?.[profile?.program]?.finalisasi === false ||
                profile?.finalisasi === true
              }
              className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
              onChange={(e) => handleChange("kabupaten", e.target.value)}
              value={sendData.kabupaten}
              style={{
                color: sendData.kabupaten ? "" : "#C9C9C9",
                border: click && !sendData.kabupaten && "1px solid red",
              }}
            >
              <option
                value=""
                style={{ color: sendData.kabupaten ? "" : "#d3d5d7" }}
                selected
              >
                Kota/Kabupaten
              </option>
              {kabupatenDB?.[sendData.propinsi]?.map((data, i) => (
                <option
                  key={i}
                  selected={sendData.kabupaten === data.id ? true : false}
                  style={{
                    color: sendData.kabupaten === data.id ? "#3c98dc" : "black",
                  }}
                  value={data.id}
                >
                  {data.kabupaten}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span
            className="data-pribadi-font title-input-desktop"
            style={{
              minWidth: 200,
            }}
          />
          <div className="input-wrapper-data-pribadi">
            <select
              disabled={
                options?.[profile?.program]?.finalisasi === false ||
                profile?.finalisasi === true
              }
              className="data-pribadi-input3"
              onChange={(e) => handleChange("kecamatan", e.target.value)}
              value={sendData.kecamatan}
              style={{
                color: sendData.kecamatan ? "" : "#C9C9C9",
                border: click && !sendData.kecamatan && "1px solid red",
              }}
            >
              <option
                value=""
                style={{ color: sendData.kecamatan ? "" : "#d3d5d7" }}
                selected
              >
                Kecamatan
              </option>
              {kecamatanDB[sendData.kabupaten] &&
                handleFilter("kecamatan", kecamatanDB[sendData.kabupaten]).map(
                  (data, i) => (
                    <option
                      key={i}
                      selected={sendData.kecamatan === data ? true : false}
                      style={{
                        color:
                          sendData.kecamatan === data ? "#3c98dc" : "black",
                      }}
                      value={data}
                    >
                      {data}
                    </option>
                  )
                )}
            </select>
            <select
              disabled={
                options?.[profile?.program]?.finalisasi === false ||
                profile?.finalisasi === true
              }
              className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
              onChange={(e) => handleChange("kelurahan", e.target.value)}
              value={sendData.kelurahan}
              style={{ color: sendData.kelurahan ? "" : "#C9C9C9" }}
            >
              <option
                value=""
                style={{ color: sendData.kelurahan ? "" : "#d3d5d7" }}
                selected
              >
                Desa/Kelurahan
              </option>
              {kecamatanDB[sendData.kabupaten] &&
                handleFilter("kelurahan", kecamatanDB[sendData.kabupaten]).map(
                  (data, i) => (
                    <option
                      key={i}
                      selected={sendData.kelurahan === data ? true : false}
                      style={{
                        color:
                          sendData.kelurahan === data ? "#3c98dc" : "black",
                      }}
                      value={data.id}
                    >
                      {data}
                    </option>
                  )
                )}
            </select>
            <select
              disabled={
                options?.[profile?.program]?.finalisasi === false ||
                profile?.finalisasi === true
              }
              className="data-pribadi-input3 data-pribadi-input-margin-top-mobile"
              onChange={(e) => handleChange("kodepos", e.target.value)}
              value={sendData.kodepos}
              style={{ color: sendData.kodepos ? "" : "#C9C9C9" }}
            >
              <option
                value=""
                style={{ color: sendData.kodepos ? "" : "#d3d5d7" }}
                selected
              >
                Kode Pos
              </option>
              {kecamatanDB[sendData.kabupaten] &&
                handleFilter("kodepos", kecamatanDB[sendData.kabupaten]).map(
                  (data, i) => (
                    <option
                      key={i}
                      selected={sendData.kodepos === data ? true : false}
                      style={{
                        color: sendData.kodepos === data ? "#3c98dc" : "black",
                      }}
                      value={data}
                    >
                      {data}
                    </option>
                  )
                )}
            </select>
          </div>
        </div>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span
            className="data-pribadi-font title-input-desktop"
            style={{
              minWidth: 200,
            }}
          />
          <div
            className="input-wrapper-data-pribadi"
            style={{
              justifyContent: "flex-start",
              textAlign: "left",
              alignItems: "flex-start",
              flexDirection: "row",
            }}
          >
            <b style={{ color: "red", marginRight: 5 }}> * </b> Kosongkan
            Desa/Kelurahan atau Kodepos jika tidak ditemukan, tulis alamat
            lengkap pada kolom isi
          </div>
        </div>
        {/* JENIS KELAMIN */}
        <span className="data-pribadi-font-mobile title-input-mobile2row">
          Jenis Kelamin <b style={{ color: "red" }}> * </b>
        </span>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span
            className="data-pribadi-font title-input-desktop"
            style={{
              minWidth: 200,
            }}
          >
            Jenis Kelamin <b style={{ color: "red" }}> * </b>
          </span>
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            onChange={(e) => handleChange("kelamin", e.target.value)}
            value={sendData.kelamin}
            style={{
              color: sendData.kelamin ? "" : "#C9C9C9",
              border: click && !sendData.kelamin && "1px solid red",
            }}
            className="data-pribadi-input"
          >
            <option
              value=""
              style={{ color: sendData.kelamin ? "" : "#d3d5d7" }}
              disabled
              selected
            >
              Pilih
            </option>
            <option
              value="laki-laki"
              selected={sendData.kelamin === "laki-laki" ? true : false}
              style={{
                color: sendData.kelamin === "laki-laki" ? "#3c98dc" : "black",
              }}
            >
              Laki-laki
            </option>
            <option
              value="perempuan"
              selected={sendData.kelamin === "perempuan" ? true : false}
              style={{
                color: sendData.kelamin === "perempuan" ? "#3c98dc" : "black",
              }}
            >
              Perempuan
            </option>
          </select>
        </div>
        {/* TINGGI BADAN */}
        <span className="data-pribadi-font-mobile title-input-mobile2row">
          Tinggi Badan <b style={{ color: "red" }}> * </b>
        </span>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span
            className="data-pribadi-font title-input-desktop"
            style={{
              minWidth: 200,
            }}
          >
            Tinggi Badan <b style={{ color: "red" }}> * </b>
          </span>
          <input
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            onChange={(e) => handleChange("tinggi", e.target.value)}
            value={sendData.tinggi}
            placeholder="Tinggi Badan"
            type="text"
            className="data-pribadi-input"
            style={{ border: click && !sendData.tinggi && "1px solid red" }}
          />
        </div>
        {/* BERAT BADAN */}
        <span className="data-pribadi-font-mobile title-input-mobile2row">
          Berat Badan <b style={{ color: "red" }}> * </b>
        </span>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span
            className="data-pribadi-font title-input-desktop"
            style={{
              minWidth: 200,
            }}
          >
            Berat Badan <b style={{ color: "red" }}> * </b>
          </span>
          <input
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            onChange={(e) => handleChange("berat", e.target.value)}
            value={sendData.berat}
            type="text"
            placeholder="Berat Badan"
            className="data-pribadi-input"
            style={{ border: click && !sendData.berat && "1px solid red" }}
          />
        </div>
        {/* GOLONGAN DARAH */}
        <span className="data-pribadi-font-mobile title-input-mobile2row">
          Golongan Darah
        </span>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span
            className="data-pribadi-font title-input-desktop"
            style={{
              minWidth: 200,
            }}
          >
            Golongan Darah
          </span>
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            onChange={(e) => handleChange("goldar", e.target.value)}
            value={sendData.goldar}
            style={{ color: sendData.goldar ? "" : "#C9C9C9" }}
            className="data-pribadi-input"
          >
            <option
              value=""
              style={{ color: sendData.goldar ? "" : "#d3d5d7" }}
              selected
            >
              Pilih
            </option>
            <option
              value="A"
              selected={sendData.goldar === "A" ? true : false}
              style={{ color: sendData.goldar === "A" ? "#3c98dc" : "black" }}
            >
              A
            </option>
            <option
              value="B"
              selected={sendData.goldar === "B" ? true : false}
              style={{ color: sendData.goldar === "B" ? "#3c98dc" : "black" }}
            >
              B
            </option>
            <option
              value="AB"
              selected={sendData.goldar === "AB" ? true : false}
              style={{ color: sendData.goldar === "AB" ? "#3c98dc" : "black" }}
            >
              AB
            </option>
            <option
              value="O"
              selected={sendData.goldar === "O" ? true : false}
              style={{ color: sendData.goldar === "O" ? "#3c98dc" : "black" }}
            >
              O
            </option>
          </select>
        </div>
        {/* KEWARGANEGARAAN */}
        <span className="data-pribadi-font-mobile title-input-mobile2row">
          Kewarganegaraan <b style={{ color: "red" }}> * </b>
        </span>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span
            className="data-pribadi-font title-input-desktop"
            style={{
              minWidth: 200,
            }}
          >
            Kewarganegaraan <b style={{ color: "red" }}> * </b>
          </span>
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            onChange={(e) => handleChange("wargaNegara", e.target.value)}
            value={sendData.wargaNegara}
            style={{
              color: sendData.wargaNegara ? "" : "#C9C9C9",
              border: click && !sendData.wargaNegara && "1px solid red",
            }}
            className="data-pribadi-input"
          >
            <option
              value=""
              style={{ color: sendData.kelamin ? "" : "#d3d5d7" }}
              disabled
              selected
            >
              Pilih
            </option>
            <option
              value="indonesia"
              selected={sendData.wargaNegara === "indonesia" ? true : false}
              style={{
                color:
                  sendData.wargaNegara === "indonesia" ? "#3c98dc" : "black",
              }}
            >
              Indonesia
            </option>
          </select>
        </div>
        {/* AGAMA */}
        <span className="data-pribadi-font-mobile title-input-mobile2row">
          Agama <b style={{ color: "red" }}> * </b>
        </span>
        <div className="data-pribadi-wrapper input-wrapper-2021-margintop">
          <span
            className="data-pribadi-font title-input-desktop"
            style={{
              minWidth: 200,
            }}
          >
            Agama <b style={{ color: "red" }}> * </b>
          </span>
          <select
            disabled={
              options?.[profile?.program]?.finalisasi === false ||
              profile?.finalisasi === true
            }
            onChange={(e) => handleChange("agama", e.target.value)}
            value={sendData.agama}
            style={{
              color: sendData.agama ? "" : "#C9C9C9",
              border: click && !sendData.agama && "1px solid red",
            }}
            className="data-pribadi-input"
          >
            <option
              value=""
              style={{ color: sendData.kelamin ? "" : "#d3d5d7" }}
              disabled
              selected
            >
              Pilih
            </option>
            <option
              value="islam"
              selected={sendData.agama === "islam" ? true : false}
              style={{
                color: sendData.agama === "islam" ? "#3c98dc" : "black",
              }}
            >
              Islam
            </option>
            <option
              value="protestan"
              selected={sendData.agama === "protestan" ? true : false}
              style={{
                color: sendData.agama === "protestan" ? "#3c98dc" : "black",
              }}
            >
              Kristen
            </option>
            <option
              value="katolik"
              selected={sendData.agama === "katolik" ? true : false}
              style={{
                color: sendData.agama === "katolik" ? "#3c98dc" : "black",
              }}
            >
              Katolik
            </option>
            <option
              value="hindu"
              selected={sendData.agama === "hindu" ? true : false}
              style={{
                color: sendData.agama === "hindu" ? "#3c98dc" : "black",
              }}
            >
              Hindu
            </option>
            <option
              value="buddha"
              selected={sendData.agama === "buddha" ? true : false}
              style={{
                color: sendData.agama === "buddha" ? "#3c98dc" : "black",
              }}
            >
              Buddha
            </option>
            <option
              value="khonghucu"
              selected={sendData.agama === "khonghucu" ? true : false}
              style={{
                color: sendData.agama === "khonghucu" ? "#3c98dc" : "black",
              }}
            >
              Khonghucu
            </option>
          </select>
        </div>
        <div className="data-pribadi-wrapper action-wrapper-2021-margintop">
          {options?.[profile?.program]?.finalisasi === false ||
          profile?.finalisasi === true ? (
            <div className="data-pribadi-button-wripper">
              <SaveAndNextButton
                className="btn-save-input2021-next"
                onClick={(e) => goToTab(1)}
              >
                {" "}
                Lihat Data Selanjutnya{" "}
              </SaveAndNextButton>
            </div>
          ) : (
            <div className="data-pribadi-button-wripper">
              <SaveAndOutButton
                className="btn-save-input2021-out"
                onClick={(e) => saving("out")}
              >
                {" "}
                Simpan & Keluar{" "}
              </SaveAndOutButton>
              <SaveAndNextButton
                className="btn-save-input2021-next"
                onClick={(e) => saving("next")}
              >
                {" "}
                Simpan & Lanjutkan{" "}
              </SaveAndNextButton>
            </div>
          )}
        </div>
      </form>
    </Fragment>
  );
});

export default DataPribadi;
