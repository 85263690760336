import React, { useState, useEffect, Fragment, forwardRef, useImperativeHandle } from 'react'
import './styles.scss'
import { DatePicker } from "@material-ui/pickers";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2'
import { useSelector, useDispatch } from 'react-redux'
import { setSaveAction, saveRiwayatKegiatan } from '../../../../store/actions/peserta'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

const SaveAndOutButton = withStyles((theme) => ({
    root: {
        border: '1px solid #3598DC',
        // marginTop: '50px',
        width: '200px',
        height: '44px',
        color: '#3598DC',
        backgroundColor: '#FFFFFF',
        fontFamily: ['Roboto', 'sans-serif'],
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '19px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
            // backgroundColor: '#e5e5e5',
            // boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)',
        },
    },
}))(Button);

const SaveAndNextButton = withStyles((theme) => ({
    root: {
        // marginTop: '50px',
        width: '200px',
        height: '44px',
        color: '#ffffff',
        backgroundColor: '#3598DC',
        fontFamily: ['Roboto', 'sans-serif'],
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '19px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#2f88c6',
            boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)',
        },
        marginLeft: 20
    },
}))(Button);

const AddButton = withStyles((theme) => ({
    root: {
        border: '1px solid #3598DC',
        height: '44px',
        color: '#3598DC',
        backgroundColor: '#FFFFFF',
        fontFamily: ['Roboto', 'sans-serif'],
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '19px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
        },
    },
}))(Button);

const RiwayatKegiatan = forwardRef((props, ref) => {
    const {
        NextPage,
        handleLogout,
        goTop
    } = props
    const Dispatch = useDispatch()
    const options = useSelector(state => state.pesertaState.options)
    const profile = useSelector(state => state.pesertaState.profile);
    const save = useSelector(state => state.pesertaState.checkerSave);
    const [sendData, setSendData] = useState({
        kegiatan: [{
            nama: '',
            jenis: '',
            jabatan: '',
            tahun: null,
        }],
        prestasi: [{
            kejuaraan: '',
            tingkat: '',
            prestasi: '',
            tahun: null,
        }]
    })

    useEffect(() => {
        if (profile) {
            if (profile.riwayatKegiatan) {
                setSendData(profile.riwayatKegiatan)
            }
        }
    }, [profile && profile.riwayatKegiatan])

    const handleChange = (key, value, i) => {
        if (!save) Dispatch(setSaveAction(true))
        switch (key) {
            case 'kegiatanNama':
                let temp = [...sendData.kegiatan]
                temp[i].nama = value
                setSendData({
                    ...sendData,
                    kegiatan: temp
                })
                break;
            case 'kegiatanJenis':
                let temp2 = [...sendData.kegiatan]
                temp2[i].jenis = value
                setSendData({
                    ...sendData,
                    kegiatan: temp2
                })
                break;
            case 'kegiatanJabatan':
                let temp3 = [...sendData.kegiatan]
                temp3[i].jabatan = value
                setSendData({
                    ...sendData,
                    kegiatan: temp3
                })
                break;
            case 'kegiatanTahun':
                let temp4 = [...sendData.kegiatan]
                temp4[i].tahun = value
                setSendData({
                    ...sendData,
                    kegiatan: temp4
                })
                break;
            case 'prestasiNama':
                let temp5 = [...sendData.prestasi]
                temp5[i].kejuaraan = value
                setSendData({
                    ...sendData,
                    prestasi: temp5
                })
                break;
            case 'prestasiTingkat':
                let temp6 = [...sendData.prestasi]
                temp6[i].tingkat = value
                setSendData({
                    ...sendData,
                    prestasi: temp6
                })
                break;
            case 'prestasi':
                let temp7 = [...sendData.prestasi]
                temp7[i].prestasi = value
                setSendData({
                    ...sendData,
                    prestasi: temp7
                })
                break;
            case 'prestasiTahun':
                let temp8 = [...sendData.prestasi]
                temp8[i].tahun = value
                setSendData({
                    ...sendData,
                    prestasi: temp8
                })
                break;
            default:
                break;
        }
    }

    const handleAddKegiatan = () => {
        if (!save) Dispatch(setSaveAction(true))
        let temp = [...sendData.kegiatan]
        temp.push({
            nama: '',
            jenis: '',
            jabatan: '',
            tahun: null,
        })
        setSendData({
            ...sendData,
            kegiatan: temp
        })
    }

    const handleRemoveKegiatan = (i) => {
        if (!save) Dispatch(setSaveAction(true))
        let temp = [...sendData.kegiatan]
        temp.splice(i, 1)
        setSendData({
            ...sendData,
            kegiatan: temp
        })
    }

    const handleAddPrestasi = () => {
        if (!save) Dispatch(setSaveAction(true))
        let temp = [...sendData.prestasi]
        temp.push({
            kejuaraan: '',
            tingkat: '',
            prestasi: '',
            tahun: null,
        })
        setSendData({
            ...sendData,
            prestasi: temp
        })
    }

    const handleRemovePrestasi = (i) => {
        if (!save) Dispatch(setSaveAction(true))
        let temp = [...sendData.prestasi]
        temp.splice(i, 1)
        setSendData({
            ...sendData,
            prestasi: temp
        })
    }

    const goToTab = (page) => {
        NextPage(page)
        goTop()
    }

    const saving = (option) => {
        let page = profile.page
        const SwalBootstrap2 = Swal.mixin({
            customClass: {
                confirmButton: 'btn-finalisasi-yakin-ok',
                cancelButton: 'btn-finalisasi-tidak'
            },
            buttonsStyling: false
        })
        if (options?.[profile?.program]?.finalisasi) {
            if (profile.page < 5) {
                page = 5
            }
            Dispatch(saveRiwayatKegiatan({ data: sendData, page }, Swal, option, NextPage, handleLogout, 5, goTop))
        } else {
            SwalBootstrap2.fire({
                title: 'Pengisian Data Telah Ditutup',
                text: "",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'OK',
                cancelButtonText: '',
                reverseButtons: true,
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.value) {
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) { }
            })
        }
    }

    useImperativeHandle(ref, () => ({
        saving(option, num) {
            let page = profile.page
            const SwalBootstrap2 = Swal.mixin({
                customClass: {
                    confirmButton: 'btn-finalisasi-yakin-ok',
                    cancelButton: 'btn-finalisasi-tidak'
                },
                buttonsStyling: false
            })
            if (options?.[profile?.program]?.finalisasi) {
                if (profile.page < 5) {
                    page = 5
                }
                Dispatch(saveRiwayatKegiatan({ data: sendData, page }, Swal, option, NextPage, handleLogout, num, goTop))
            } else {
                SwalBootstrap2.fire({
                    title: 'Pengisian Data Telah Ditutup',
                    text: "",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'OK',
                    cancelButtonText: '',
                    reverseButtons: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then((result) => {
                    if (result.value) {
                    } else if (
                        /* Read more about handling dismissals below */
                        result.dismiss === Swal.DismissReason.cancel
                    ) { }
                })
            }
        }
    }))

    return (
        <Fragment>
            <form autoComplete="off">
                {/* KEGIATAN YANG PERNAH DIIKUTI */}
                <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile" >
                    <span className="data-pribadi-font title-input-desktop" />
                    <div className="info-keluarga-font-title">
                        Kegiatan yang Pernah dan Sedang Diikuti
                    </div>
                </div>

                {
                    sendData?.kegiatan?.map((data, i) => (
                        <Fragment key={i}>
                            {/* BREAK */}
                            {
                                i > 0 &&
                                <div
                                    style={{
                                        borderTop: '2px solid #f0f0f0',
                                        margin: '15px auto',
                                        width: '70%',
                                    }}
                                />
                            }

                            {/* NAMA & JENIS KEGIATAN */}
                            <span className="data-pribadi-font-mobile title-input-mobile">
                                Nama & Jenis Kegiatan
                            </span>
                            <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile" >
                                <span className="data-pribadi-font title-input-desktop">
                                    Nama & Jenis Kegiatan
                                </span>
                                <div className="input-wrapper-data-pribadi" >
                                    <input
                                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                        onChange={e => handleChange('kegiatanNama', e.target.value, i)}
                                        value={data.nama}
                                        type="text"
                                        placeholder="Nama Kegiatan"
                                        className="data-pribadi-input2"
                                    />
                                    <input
                                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                        onChange={e => handleChange('kegiatanJenis', e.target.value, i)}
                                        value={data.jenis}
                                        type="text"
                                        placeholder="Jenis Kegiatan"
                                        className="data-pribadi-input2 data-pribadi-input-margin-top-mobile"
                                    />
                                </div>
                            </div>

                            {/* JABATAN & TAHUN KEGIATAN */}
                            <span className="data-pribadi-font-mobile title-input-mobile2row">
                                Jabatan & Tahun Kegiatan
                            </span>
                            <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                                <span className="data-pribadi-font title-input-desktop">
                                    Jabatan & Tahun Kegiatan
                                </span>
                                <div className="input-wrapper-data-pribadi" >
                                    <input
                                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                        onChange={e => handleChange('kegiatanJabatan', e.target.value, i)}
                                        value={data.jabatan}
                                        type="text"
                                        placeholder="Jabatan Dalam Kegiatan"
                                        className="data-pribadi-input2"
                                    />
                                    <div
                                        className="data-pribadi-input2 data-pribadi-input-margin-top-mobile"
                                        style={{
                                            padding: 0,
                                            boxSizing: 'border-box',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <DatePicker
                                            disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                            autoOk
                                            views={['year']}
                                            placeholder="Tahun Kegiatan"
                                            value={data.tahun}
                                            onChange={(newValue) => {
                                                handleChange('kegiatanTahun', newValue, i);
                                            }}
                                            clearable
                                            clearLabel="Hapus"
                                            animateYearScrolling
                                            disableFuture
                                            okLabel=""
                                            cancelLabel=""
                                            helperText=""
                                            format="yyyy"
                                            InputProps={{
                                                disableUnderline: true
                                            }}
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                paddingLeft: '19px',
                                                border: '0px',
                                                zIndex: '9',
                                                height: '44px',
                                                borderRadius: '4px',
                                            }}
                                            maxDate={new Date()}
                                            minDate={new Date('2000')}
                                        />
                                        <FontAwesomeIcon
                                            icon={faCalendarAlt}
                                            style={{
                                                color: "#3c98dc",
                                                fontSize: 18,
                                                position: 'absolute',
                                                right: '60',
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* ACTION */}
                            {
                                !((options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)) &&
                                <div className="data-pribadi-wrapper" style={{ marginTop: '20px' }} >
                                    <span className="data-pribadi-font title-input-desktop" />
                                    <div className="btn-saudara-wrap" >
                                        {
                                            sendData.kegiatan.length - 1 === i &&
                                            <AddButton
                                                onClick={() => handleAddKegiatan()}
                                                className="btn-add-sma"
                                            >
                                                <span className="btn-add-sma-txt" >
                                                    Tambah Kegiatan
                                                </span>
                                            </AddButton>
                                        }
                                        {
                                            sendData.kegiatan.length > 1 &&
                                            <AddButton
                                                onClick={() => handleRemoveKegiatan(i)}
                                                className="btn-delete-saudara"
                                            >
                                                Hapus
                                            </AddButton>
                                        }
                                    </div>
                                </div>
                            }
                        </Fragment>
                    ))
                }

                {/* BREAK */}
                <div
                    style={{
                        borderTop: '5px solid #fafafa',
                        margin: '30px 0px'
                    }}
                />

                {/* PRESTASI YANG PERNAH DIRAIH */}
                <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile" >
                    <span className="data-pribadi-font title-input-desktop" />
                    <div className="info-keluarga-font-title">
                        Prestasi yang Pernah Diraih
                    </div>
                </div>

                {
                    sendData?.prestasi?.map((data, i) => (
                        <Fragment key={i}>
                            {/* BREAK */}
                            {
                                i > 0 &&
                                <div
                                    style={{
                                        borderTop: '2px solid #f0f0f0',
                                        margin: '15px auto',
                                        width: '70%',
                                    }}
                                />
                            }

                            {/* KEJUARAAN & TINGKAT */}
                            <span className="data-pribadi-font-mobile title-input-mobile">
                                Kejuaraan & Tingkat
                            </span>
                            <div className="data-pribadi-wrapper data-pribadi-wrapper-mobile" >
                                <span className="data-pribadi-font title-input-desktop">
                                    Kejuaraan & Tingkat
                                </span>
                                <div className="input-wrapper-data-pribadi" >
                                    <input
                                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                        onChange={e => handleChange('prestasiNama', e.target.value, i)}
                                        value={data.kejuaraan}
                                        type="text"
                                        placeholder="Nama Kejuaraan"
                                        className="data-pribadi-input2"
                                    />
                                    <input
                                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                        onChange={e => handleChange('prestasiTingkat', e.target.value, i)}
                                        value={data.tingkat}
                                        type="text"
                                        placeholder="Tingkat Kejuaraan"
                                        className="data-pribadi-input2 data-pribadi-input-margin-top-mobile"
                                    />
                                </div>
                            </div>

                            {/* PRESTASI & TAHUN */}
                            <span className="data-pribadi-font-mobile title-input-mobile2row">
                                Prestasi & Tahun
                            </span>
                            <div className="data-pribadi-wrapper input-wrapper-2021-margintop" >
                                <span className="data-pribadi-font title-input-desktop">
                                    Prestasi & Tahun
                                </span>
                                <div className="input-wrapper-data-pribadi" >
                                    <input
                                        disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                        onChange={e => handleChange('prestasi', e.target.value, i)}
                                        value={data.prestasi}
                                        type="text"
                                        placeholder="Prestasi Kejuaraan"
                                        className="data-pribadi-input2"
                                    />
                                    <div
                                        className="data-pribadi-input2 data-pribadi-input-margin-top-mobile"
                                        style={{
                                            padding: 0,
                                            boxSizing: 'border-box',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <DatePicker
                                            disabled={(options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)}
                                            autoOk
                                            views={['year']}
                                            placeholder="Tahun Kejuaraan"
                                            value={data.tahun}
                                            onChange={(newValue) => {
                                                handleChange('prestasiTahun', newValue, i);
                                            }}
                                            clearable
                                            clearLabel="Hapus"
                                            animateYearScrolling
                                            disableFuture
                                            okLabel=""
                                            cancelLabel=""
                                            helperText=""
                                            format="yyyy"
                                            InputProps={{
                                                disableUnderline: true
                                            }}
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                paddingLeft: '19px',
                                                border: '0px',
                                                zIndex: '9',
                                                height: '44px',
                                                borderRadius: '4px',
                                            }}
                                            maxDate={new Date()}
                                            minDate={new Date('2000')}
                                        />
                                        <FontAwesomeIcon
                                            icon={faCalendarAlt}
                                            style={{
                                                color: "#3c98dc",
                                                fontSize: 18,
                                                position: 'absolute',
                                                right: '60',
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* ACTION */}
                            {
                                !((options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)) &&
                                <div className="data-pribadi-wrapper" style={{ marginTop: '20px' }} >
                                    <span className="data-pribadi-font title-input-desktop" />
                                    <div className="btn-saudara-wrap" >
                                        {
                                            sendData.prestasi.length - 1 === i &&
                                            <AddButton
                                                onClick={() => handleAddPrestasi()}
                                                className="btn-add-sma"
                                            >
                                                <span className="btn-add-sma-txt" >
                                                    Tambah Prestasi
                                                </span>
                                            </AddButton>
                                        }
                                        {
                                            sendData.prestasi.length > 1 &&
                                            <AddButton
                                                onClick={() => handleRemovePrestasi(i)}
                                                className="btn-delete-saudara"
                                            >
                                                Hapus
                                            </AddButton>
                                        }
                                    </div>
                                </div>
                            }
                        </Fragment>
                    ))
                }

                <div className="data-pribadi-wrapper action-wrapper-2021-margintop" >
                    {
                        (options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true) ?
                            <div className="data-pribadi-button-wripper">
                                <SaveAndOutButton className="btn-save-input2021-out" onClick={e => goToTab(3)} > Lihat Data Sebelumnya </SaveAndOutButton>
                                <SaveAndNextButton className="btn-save-input2021-next" onClick={e => goToTab(5)} > Lihat Data Selanjutnya </SaveAndNextButton>
                            </div>
                            :
                            <div className="data-pribadi-button-wripper">
                                <SaveAndOutButton className="btn-save-input2021-out" onClick={e => saving('out')} > Simpan & Keluar </SaveAndOutButton>
                                <SaveAndNextButton className="btn-save-input2021-next" onClick={e => saving('next')} > Simpan & Lanjutkan </SaveAndNextButton>
                            </div>
                    }
                </div>
            </form>
        </Fragment>
    )
})

export default RiwayatKegiatan